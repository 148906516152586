<template>
  <section>
    <div class="tw-max-w-screen-xxl tw-mx-auto">
      <div class="tw-max-w-screen-xxl tw-px-4 lg:tw-px-10 tw-mx-auto">
        <BaseCard class="tw-flex tw-flex-col md:tw-flex-row tw-p-9 tw-justify-beetween">
          <h3 class="tw-font-bold tw-text-[32px] tw-leading-[38px] tw-w-full md:tw-w-3/5">
            {{ $t('tbs_mp_register_text') }}
          </h3>
          <div class="tw-w-full md:tw-w-1/5" />
          <div class="tw-w-full md:tw-w-1/5 tw-flex tw-my-1.5 tw-mt-5 md:tw-mt-0">
            <MainButton class="tw-w-full" @click="emit('onRegisterClick')" @keydown.enter="emit('onRegisterClick')">{{
              $t('tbs_mp_cta_register')
            }}</MainButton>
          </div>
        </BaseCard>
      </div>
    </div>
    <div>
      <div class="tw-pt-5 tw-pb-10">
        <div class="tw-max-w-screen-xxl tw-mx-auto tw-px-4 lg:tw-px-10">
          <div class="support-faq tw-mt-8">
            <h3 class="tw-font-bold md:tw-text-2xl tw-mb-3 lg:tw-mb-6">{{ $t('tbs_mp_faq_header') }}</h3>
            <div class="tw-w-full tw-flex tw-flex-wrap">
              <div v-for="(question, index) in faq" :key="index" class="faq tw-flex">
                <span v-html="$t(question)" class="tw-flex tw-w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'

const faq = ref(['tbs_mp_faq_1', 'tbs_mp_faq_2', 'tbs_mp_faq_3', 'tbs_mp_faq_4', 'tbs_mp_faq_5', 'tbs_mp_faq_6'])
const emit = defineEmits(['onRegisterClick'])
</script>

<style scoped>
.faq {
  flex: 49.5% 0 0;
  @media (max-width: theme('screens.lg')) {
    flex: 100% 0 0;
  }
}
.faq :deep(a) {
  @apply tw-flex tw-w-full tw-bg-gray-200 tw-rounded-lg tw-p-4 tw-mb-4 tw-justify-between tw-items-center;
}
.faq :deep(a):after {
  display: inline-block;
  content: '';
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask: url("data:image/svg+xml,%3Csvg data-v-92245bab='' data-v-39bca6e1='' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' aria-labelledby='chevron-right' role='presentation' class='tw-shrink-0'%3E%3Ctitle data-v-92245bab='' id='chevron-right' lang='en'%3Echevron-right%3C/title%3E%3Cg data-v-92245bab=''%3E%3Cpath data-v-39bca6e1='' pid='1' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg data-v-92245bab='' data-v-39bca6e1='' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' aria-labelledby='chevron-right' role='presentation' class='tw-shrink-0'%3E%3Ctitle data-v-92245bab='' id='chevron-right' lang='en'%3Echevron-right%3C/title%3E%3Cg data-v-92245bab=''%3E%3Cpath data-v-39bca6e1='' pid='1' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  height: 24px;
  width: 24px;
  background-color: theme('colors.gray.900');
}

.faq :deep(a):hover:after {
  @apply tw-bg-blue-800;
}
.faq :deep(a):hover {
  @apply tw-text-blue-800;
}
.faq:nth-child(odd) {
  margin-right: 1%;
  @media (max-width: theme('screens.lg')) {
    margin-right: 0;
  }
}
</style>
