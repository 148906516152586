<template>
  <div>
    <SubscriptionStudioPlusPreview
      v-if="isStudioPlus"
      :subscription="subscription"
      :accommodationId="accommodationId"
      :accommodationName="accommodationName"
    />
    <div v-else class="tw-grid sm:tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_1fr_1fr_1fr] tw-items-start">
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full">
        <div class="tw-text-sm">{{ productName ? $t(productName) : $t('subscriptions.noAccommodationName') }}</div>
        <div class="tw-text-sm">{{ accommodationName }}</div>
      </div>
      <div>
        <p class="tw-text-sm">{{ pricingPlan }}</p>
      </div>
      <div>
        <small v-if="!isExpiredSubscription && subscription?.status" class="tw-text-sm">{{
          isActiveSubscription
            ? $t('subscriptions.nextPayment', { date: paymentNextActionDate })
            : $t('subscriptions.activeUntil', { date: paymentNextActionDate })
        }}</small>
        <small v-if="isExpiredSubscription && subscription?.status" class="tw-text-sm">{{
          $t('subscription_expiration_date', { ExpirationDate: subscriptionEndDate })
        }}</small>
      </div>
      <div class="tw-flex tw-justify-end">
        <MainButton size="medium" class="tw-w-full md:tw-w-auto" @click="onViewDetailsClick">{{
          $t('rc_bookings_list_view')
        }}</MainButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import MainButton from '@/components/base/buttons/MainButton.vue'
import SubscriptionStudioPlusPreview from './SubscriptionStudioPlusPreview.vue'

const props = defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  accommodationId: {
    type: Number,
    default: null,
  },
  accommodationName: {
    type: String,
    default: '',
  },
})
const store = useStore()
const router = useRouter()
const isStudioPlus = computed(() => props.subscription?.isStudioPlus)
const productName = computed(() => props.subscription?.product?.details?.[0]?.name)
const pricingPlan = computed(() => props.subscription?.pricing_plan?.details?.[0]?.name)
const isActiveSubscription = computed(() => props.subscription?.status?.toLowerCase() === 'active')
const isExpiredSubscription = computed(() => props.subscription?.status?.toLowerCase() === 'expired')

const paymentNextActionDate = computed(() => {
  const charges = props.subscription?.pricing_plan?.charges
  const isRecurringFeeCharge = charges?.find(charge => charge?.type === 'RECURRING_FEE')
  if (isRecurringFeeCharge) {
    const localDate = new Date(props.subscription?.endDate)?.toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
    return localDate
  } else {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    return new Date(year, month).toLocaleDateString(store.state.locale.anguage, { year: 'numeric', month: 'long' })
  }
})
const subscriptionEndDate = computed(() => {
  if (props.subscription?.endDate) {
    return new Date(props.subscription?.endDate)?.toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  }
  return null
})
const productId = computed(() => props.subscription?.product?.id)
const subscriptionNumber = computed(() => props.subscription?.subscription_number)

const onViewDetailsClick = () => {
  router.push({
    name: 'settings-subscription-details',
    query: {
      accommodationId: props.accommodationId,
      productId: productId.value,
      subscriptionNumber: subscriptionNumber.value,
    },
  })
}
</script>
