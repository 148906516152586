<template>
  <div v-if="!isPauseCampaignReasonOpen" class="tw-px-6 tw-py-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_deactivate_campaign_head') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-base tw-text-gray-900 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info md:tw-mb-0 tw-mb-5">
        <p class="tw-text-sm tw-p-0 tw-m-0 tw-mr-4">{{ $t('rc_deactivate_campaign_text') }}</p>
      </div>
      <div class="tw-flex tw-w-1/5 tw-flex-col tw-items-start type-info">
        <div class="tw-flex tw-flex-row tw-items-center">
          <span :class="currentCampaignStatus === 'active' ? 'active tw-bg-green-800' : 'paused tw-bg-red-800'" />
          <p class="tw-text-base tw-font-bold tw-ml-2">
            {{ currentCampaignStatus === 'active' ? $t('rc_settings_campaign_on') : $t('rc_settings_campaign_off') }}
          </p>
        </div>
      </div>
      <div class="tw-flex tw-w-1/5 tw-justify-end action-btn">
        <GhostButton
          v-if="currentCampaignStatus === 'active'"
          size="medium"
          class="tw-px-9"
          @click="onPauseCampaignClick"
        >
          {{ $t('rc_deactivate_campaign_button') }}
        </GhostButton>
        <MainButton v-else size="medium" class="tw-px-9" @click="onUpdateCampaignStatus">
          <span v-if="!isUpdatingCampaignStatus">{{ $t('rc_reactivate_campaign_button') }}</span>
          <span v-if="isUpdatingCampaignStatus">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </div>
  </div>
  <div v-else class="tw-bg-blue-100 tw-px-6 tw-py-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_pause_survey_header') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-base tw-text-gray-900 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-2/5 main-info md:tw-mb-0 tw-mb-5">
        <p class="tw-text-sm tw-p-0 tw-m-0">{{ $t('rc_pause_survey_text') }}</p>
        <BaseSelect
          :id="'campaign-status-change-reason'"
          :display-text-key="'label'"
          :class="'tw-my-3'"
          :value-key="'value'"
          :placeholder="$t('rc_pause_survey_select')"
          :options="reasons"
          :value="selectedReasonValue"
          fullwidth
          @onChange="onReasonSelectedChange"
        />
        <span
          v-if="selectedReason?.id === 11"
          class="tw-text-gray-700 tw-text-sm"
          v-html="$t('required_information')"
        />
        <TextareaField
          v-if="selectedReasonValue !== null"
          aria-label="pause-campaign-other-explanation"
          id="pause-campaign-other-explanation"
          fullwidth
          :value="otherReason"
          :placeholder="$t('rc_pause_survey_reason_other_specify')"
          @onChange="setOtherReason"
        />
      </div>
      <div class="tw-flex tw-w-3/5 tw-justify-end action-btn tw-pb-8">
        <GhostButton size="medium" class="tw-px-9 tw-mr-3" @click="onCancelPauseCampaignClick">
          {{ $t('rc_bookings_modal_cancel') }}
        </GhostButton>
        <MainButton
          :disabled="!selectedReasonValue || (selectedReason?.id === 11 && otherReason.length === 0)"
          size="medium"
          class="tw-px-9"
          @click="onUpdateCampaignStatus"
        >
          <span v-if="!isUpdatingCampaignStatus">{{ $t('rc_deactivate_campaign_button') }}</span>
          <span v-if="isUpdatingCampaignStatus">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </div>
  </div>
  <FullScreenLoader v-show="isUpdatingCampaignStatus" />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import TextareaField from '@/components/base/inputs/TextareaField.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import appAnalytics from '@/utils/tracking'
import { useCampaigns, useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { t } = useI18n()
const { campaignsData } = useCampaigns(selectedAccommodationId)
const { updateCampaignStatus, isUpdatingCampaignStatus } = useCampaignsMutation()
const { displayNotification } = useToastNotifications()

const currentCampaignStatus = computed(() => campaignsData.value?.overall?.status)
const currentCampaignType = computed(() => campaignsData.value?.overall?.campaignType)

const selectedReasonValue = ref(null)
const selectedReason = ref(null)
const reasons = computed(() => [
  { label: t('rc_pause_survey_reason_performance'), value: "I don't receive enough direct bookings", id: 1 },
  {
    label: t('rc_pause_survey_reason_traffic'),
    value: "I don'\t receive enough traffic from trivago to my website",
    id: 2,
  },
  {
    label: t('rc_pause_survey_reason_understanding'),
    value: "I don'\t fully understand how Rate Connect works",
    id: 3,
  },
  { label: t('rc_pause_survey_reason_functionality'), value: "I'm not happy with how Rate Connect works", id: 4 },
  { label: t('rc_pause_survey_reason_problem'), value: "I don't find my website rates on trivago", id: 5 },
  { label: t('rc_pause_survey_reason_marketing'), value: "I'm focusing on other marketing channels", id: 6 },
  { label: t('rc_pause_survey_reason_occupancy'), value: "I'm currently booked up", id: 7 },
  {
    label: t('rc_pause_survey_reason_temporary'),
    value: "I don't need to advertise during the low or off-season",
    id: 8,
  },
  { label: t('rc_pause_survey_reason_connectivity'), value: "I'm changing connectivity providers", id: 9 },
  {
    label: t('rc_pause_survey_reason_property'),
    value: 'My property is permanently closed or will change ownership',
    id: 10,
  },

  { label: t('rc_pause_survey_reason_other'), value: 'Other', id: 11 },
])
const isPauseCampaignReasonOpen = ref(false)
const otherReason = ref('')

const setOtherReason = reason => {
  otherReason.value = reason
}

const onPauseCampaignClick = () => {
  isPauseCampaignReasonOpen.value = true
}

const onCancelPauseCampaignClick = () => {
  isPauseCampaignReasonOpen.value = false
  otherReason.value = false
  selectedReasonValue.value = null
}

const onReasonSelectedChange = currentReason => {
  const reason = reasons.value.find(item => {
    return item.value === currentReason
  })

  selectedReason.value = reason
  selectedReasonValue.value = currentReason
  otherReason.value = ''
}

const onUpdateCampaignStatus = () => {
  if (!currentCampaignStatus.value) return

  trackDataInSegment()

  const successMessage =
    currentCampaignStatus.value === 'active'
      ? 'rc_deactivate_campaign_success_message'
      : 'rc_reactivate_campaign_success_message'
  updateCampaignStatus(
    {
      accommodationId: selectedAccommodationId.value,
      body: { overall: { status: currentCampaignStatus.value === 'active' ? 'inactive' : 'active' } },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: successMessage,
          isTranslationKey: true,
          type: 'success',
        })
        onCancelPauseCampaignClick()
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

const trackDataInSegment = () => {
  if (currentCampaignStatus.value === 'active' && selectedReasonValue.value) {
    appAnalytics.track(appAnalytics.events.TRC_PAUSE_CAMPAIGN, {
      item_id: selectedAccommodationId.value,
      pause_reason: selectedReasonValue.value,
      pause_custom: otherReason.value,
    })
  } else if (currentCampaignStatus.value === 'inactive') {
    appAnalytics.track(appAnalytics.events.TRC_CAMPAIGN_RESUMED, {
      item_id: selectedAccommodationId.value,
      campaign_type: currentCampaignType.value,
      budget: currentCampaignType.value === 'cpc' ? campaignsData.value?.overall?.budget : null,
      source: 'settings',
    })
  }
}
</script>

<style scoped>
.main-info {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 100%;
  }
}
.type-info,
.action-btn {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 50%;
  }
}
.active {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.paused {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.action-btn {
  @media (max-width: theme('screens.sm')) {
    flex: 0 0 100%;
  }
}
</style>
