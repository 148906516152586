<template>
  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons/Hotel/Hotel-24x24-Line">
        <rect id="Rectangle-4-Copy-64" x="0" y="0"></rect>
        <path
          id="Page-1"
          d="M18,4 L15,4 L15,9 C15,9.552 14.552,10 14,10 L10,10 C9.448,10 9,9.552 9,9 L9,4 L6,4 L6,14 L18,14 L18,4 Z M10,9 L14,9 L14,4 L10,4 L10,9 Z M11,18 L13,18 L13,17 L11,17 L11,18 Z M3,16 L21,16 L21,15 L3,15 L3,16 Z M21.5,17 C21.495,17 21.491,16.998 21.486,16.998 C21.482,16.998 21.478,17 21.474,17 L14,17 L14,20.375 C14,20.72 13.776,21 13.5,21 C13.224,21 13,20.72 13,20.375 L13,19 L11,19 L11,20.375 C11,20.72 10.776,21 10.5,21 C10.224,21 10,20.72 10,20.375 L10,17 L2.526,17 C2.522,17 2.518,16.998 2.514,16.998 C2.509,16.998 2.505,17 2.5,17 C2.224,17 2,16.808 2,16.571 L2,14.429 C2,14.192 2.224,14 2.5,14 C2.505,14 2.509,14.002 2.514,14.002 C2.518,14.002 2.522,14 2.526,14 L5,14 L5,4 L3.474,4 C3.212,4 3,3.776 3,3.5 C3,3.224 3.212,3 3.474,3 L10,3 L14,3 L20.526,3 C20.788,3 21,3.224 21,3.5 C21,3.776 20.788,4 20.526,4 L19,4 L19,14 L21.474,14 C21.478,14 21.482,14.002 21.486,14.002 C21.491,14.002 21.495,14 21.5,14 C21.776,14 22,14.192 22,14.429 L22,16.571 C22,16.808 21.776,17 21.5,17 L21.5,17 Z"
          fill="currentColor"
          transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
        ></path>
      </g>
    </g>
  </svg>
</template>
