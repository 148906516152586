<template>
  <section class="tw-max-w-screen-xxl tw-mx-auto">
    <div class="tw-justify-between tw-pl-10 tw-pr-2 tw-bg-white tw-flex-col">
      <div id="slider" class="tw-w-full tw-h-[700px] lg:tw-h-[650px] tw-relative">
        <transition-group tag="div" :name="transitionName" mode="out-in">
          <div
            :key="current"
            class="slide tw-w-full tw-h-[630px] lg:tw-h-[650px] tw-absolute tw-top-0 tw-left-0 tw-flex tw-justify-center tw-flex-col lg:tw-flex-row tw-items-center"
          >
            <div
              class="tw-w-full tw-pr-5 tw-self-start tw-mt-[40px] lg:tw-mt-32 tw-bg-white"
              :class="language === 'de-DE' && current === 2 ? 'lg:tw-w-2/5 ' : ' lg:tw-w-1/3 '"
            >
              <h3
                class="tw-mb-5 tw-text-[40px] tw-leading-[42px] lg:tw-text-[50px] tw-font-bold lg:tw-leading-[50px] tw-text-gray-900"
                :class="{ wordbreak: language === 'de-DE' && current === 2 }"
              >
                {{ $t(slides[current].title) }}
              </h3>
              <p class="tw-text-base tw-text-gray-900">{{ $t(slides[current].text) }}</p>
            </div>
            <div class="tw-hidden lg:tw-flex tw-w-2/3 tw-bg-white">
              <img :src="slides[current].image" :alt="slides[current].title" />
            </div>
            <div class="tw-flex lg:tw-hidden tw-w-full tw-relative tw-mt-[175px] md:tw-h-[350px] tw-bg-white">
              <img :src="slides[current].image" :alt="slides[current].title" />
            </div>
          </div>
        </transition-group>
        <div
          class="lg:tw-w-1/3 tw-w-full tw-absolute top"
          :class="language === 'ru-RU' && current !== 2 ? 'lg:tw-bottom-[100px]' : 'lg:tw-bottom-[145px]'"
        >
          <div class="tw-flex">
            <div
              class="btn btn-prev tw-mr-[15px] tw-rounded-lg"
              aria-label="Previous slide"
              @keypress.left="previous()"
              @click="previous()"
            >
              <BaseIcon icon-name="chevron-right" :height="24" :width="24" class="tw-rotate-180">
                <ChevronRight />
              </BaseIcon>
            </div>
            <div
              class="btn btn-next tw-ml-[15px] tw-rounded-lg"
              aria-label="Next slide"
              @keypress.right="next()"
              @click="next()"
            >
              <BaseIcon icon-name="chevron-right" :height="24" :width="24">
                <ChevronRight />
              </BaseIcon>
            </div>
          </div>
          <MainButton class="tw-mt-7 lg:tw-mt-6" @click="onRegisterClick" @keydown.enter="onRegisterClick">{{
            $t(slides[current].button)
          }}</MainButton>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-[70px] lg:tw-h-[100px]">
      <div
        v-for="(item, index) in slides"
        :key="index"
        class="tw-w-[12px] tw-mx-[5px] tw-h-[12px] tw-rounded-full tw-mb-6"
        :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import TouchEvent from '@/utils/touchEvents.js'

const store = useStore()
const Slider1 = new URL('/src/assets/img/rate-connect-landing/Slider1.svg', import.meta.url)
const Slider2 = new URL('/src/assets/img/rate-connect-landing/Slider2.svg', import.meta.url)
const Slider3 = new URL('/src/assets/img/rate-connect-landing/Slider3.svg', import.meta.url)
const emit = defineEmits(['onRegisterClick'])
const onRegisterClick = e => {
  emit('onRegisterClick', e)
}
const touchEvent = ref(null)
const current = ref(0)
const transitionName = ref('fade')
const slides = ref([
  {
    title: 'rc_mp_subbenefit_1_header',
    text: 'rc_mp_subbenefit_1_text',
    button: 'rc_mp_subbenefit_1_cta',
    image: Slider1,
  },
  {
    title: 'rc_mp_subbenefit_2_header',
    text: 'rc_mp_subbenefit_2_text',
    button: 'rc_mp_subbenefit_2_cta',
    image: Slider2,
  },
  {
    title: 'rc_mp_subbenefit_3_header',
    text: 'rc_mp_subbenefit_3_text',
    button: 'rc_mp_subbenefit_3_cta',
    image: Slider3,
  },
])
const language = computed(() => store.state.locale.language)

onMounted(() => {
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})
const next = () => {
  current.value = current.value === 2 ? 0 : current.value + 1
  const first = slides.value.shift()
  slides.value = slides.value.concat(first)
}

const previous = () => {
  const last = slides.value.pop()
  current.value = current.value === 0 ? 2 : current.value - 1
  slides.value = [last].concat(slides.value)
}
const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    previous()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.top {
  top: auto;
  @media (min-width: 1024px) {
    top: auto !important;
  }
  @media (min-width: 725px) {
    top: 150px;
  }
  @media (min-width: 600px) and (max-width: 724px) {
    top: calc(152px + 40px);
  }
  @media (min-width: 483px) and (max-width: 599px) {
    top: calc(152px + 80px);
  }
  @media (max-width: 482px) {
    top: calc(152px + 135px);
  }
}
.btn {
  @apply tw-w-[37px] tw-h-[37px] tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-z-[9] tw-bg-gray-200;
  transition: transform 0.3s ease-in-out;
}

.wordbreak {
  word-wrap: break-word;
}
</style>
