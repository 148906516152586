import { store } from '@/store'

export function useApp() {
  function checkIsLaunchedAppOwned(ownedApps) {
    // can not make it null as the check will fail inside the component
    let isAppOwned = 'NO_APP_OPENED'
    const launchedApp = store.state.app?.launchedApp

    // if the app is internal, return true
    if (launchedApp.trusted) return true

    if (launchedApp && Array.isArray(ownedApps)) {
      const isCurrentAppBelongsToOwned = ownedApps.filter(eachApp => {
        return launchedApp.app_id === eachApp.app_id
      })[0]

      isAppOwned = !!isCurrentAppBelongsToOwned
    }

    return isAppOwned
  }

  return {
    checkIsLaunchedAppOwned,
  }
}
