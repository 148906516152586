<template>
  <div class="tw-relative" :class="{ 'language-dropdown': version === 'transparent' }">
    <div v-if="withImage" class="custom-selected tw-flex tw-justify-end" :class="getClass()">
      <div class="tw-flex tw-flex-row tw-items-center">
        <img
          :alt="selectedLanguageText"
          :src="selectedLanguageFlag"
          class="tw-w-[28px] tw-h-[28px] tw-block tw-m-auto tw-mr-2"
        />
        <span :class="getTextClass()">{{ selectedLanguageText }}</span>
        <BaseIcon
          v-if="version !== 'transparent'"
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          :icon-color="'#6C707A'"
          class="tw-absolute arrow-dropdown"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </div>
    <BaseSelect
      :id="'language-selector'"
      :class="{
        'flag-language-selector': withImage,
        'language-selector': version !== 'light',
      }"
      :name="'language-selector'"
      :display-text-key="'displayText'"
      :value-key="'value'"
      :options="languages"
      :value="selectedLanguage"
      :version="version"
      :size="size"
      @onChange="e => onLanguageSelectedChange(e)"
      :data-test-id="'language-selector'"
      :error-test-id="'language-selector-error'"
      :select-test-id="'language-selector-select'"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

import { setI18nLanguage } from '@/plugins/i18n'
import BaseSelect from '@/components/BaseSelect.vue'
import { languages } from '@/constants/locales'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { useUserDetails } from '@/layouts/queries'
import { useUpdateUserLanguage } from '@/components/settings/profileSettings/queries'

const props = defineProps({
  version: {
    type: String,
    default: 'light',
  },
  withImage: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
  },
})

const selectedLanguage = computed(() => store.state.locale.language)

const store = useStore()
const userId = computed(() => store.state.session.userId)
const { userDetails } = useUserDetails(userId)
const { updateUserLanguage } = useUpdateUserLanguage()
const { displayNotification } = useToastNotifications()

const selectedLanguageText = computed(() => {
  const selected = languages.find(language => language.value === selectedLanguage.value)?.displayText
  if (selected?.includes('United States')) return selected.replace('United States', 'US')
  if (selected?.includes('United Kingdom')) return selected.replace('United Kingdom', 'UK')
  if (selected?.includes('Portugal')) return selected.replace('Portugal', 'PT')
  return selected
})

const selectedLanguageFlag = computed(() => {
  const imageUrl = new URL(`/src/assets/img/flags/${selectedLanguage.value?.toLowerCase()}.png`, import.meta.url)
  const defaultImage = new URL(`/src/assets/img/flags/en-us.png`, import.meta.url)
  return selectedLanguage.value ? imageUrl : defaultImage
})

async function setInitialSelectedLanguage() {
  if (userDetails.value?.preferredLanguage) {
    await updateAppLanguage(userDetails.value.preferredLanguage)
    return
  }

  const languageInLocalStorage = window.localStorage.getItem('stdio_locale_language')
  if (languageInLocalStorage) {
    await updateAppLanguage(languageInLocalStorage)
    return
  }

  await updateAppLanguage('en-US')
}

async function updateAppLanguage(lang) {
  store.commit('SET_LANGUAGE', lang)
  await setI18nLanguage(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
}

function onLanguageSelectedChange(lang) {
  updateAppLanguage(lang)
  if (userDetails.value) {
    sendUpdatedUserLanguage(lang)
  }
}

function sendUpdatedUserLanguage(newSelectedLanguage = '') {
  if (!newSelectedLanguage) return

  updateUserLanguage(
    {
      userId: userId.value,
      language: newSelectedLanguage,
    },
    {
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

watch(
  () => userDetails.value?.preferredLanguage,
  newValue => {
    if (newValue) updateAppLanguage(newValue)
  },
  { immediate: true }
)

onMounted(() => {
  setInitialSelectedLanguage()
})

function getClass() {
  if (props.version === 'transparent') {
    return 'tw-right-0 tw-border-0 tw-max-h-[36px] tw-px-2 tw-py-1 tw-rounded-md'
  } else if (props.version === 'dark') {
    return 'custom-selected-border lg:tw-w-[200px] tw-px-3 tw-min-h-[46px] tw-justify-center'
  } else if (props.version === 'light') {
    return 'tw-border-gray-700 lg:tw-w-[200px] tw-w-full'
  }
}

function getTextClass() {
  if (props.version === 'transparent') {
    return 'tw-text-gray-700 tw-text-xs'
  } else if (props.version === 'dark') {
    return 'tw-text-gray-100'
  } else if (props.version === 'light') {
    return 'tw-text-gray-700 tw-font-[14px]'
  }
}
</script>

<style scoped>
.custom-selected {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.language-dropdown:hover .custom-selected {
  @apply tw-bg-gray-100;
}
.flag-language-selector {
  min-width: 100px;
}
.language-selector {
  opacity: 0;
}
.flag-language-selector option {
  font-size: 14px;
  color: theme('colors.gray.700');
}
.custom-selected-border {
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding-right: 30px;
  border-radius: 10px;
}
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
</style>
