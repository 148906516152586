import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import AccommodationService from '@/services/AccommodationService'

export const getTimeFramesTrends = async ({ accommodationId, startDate, endDate }) => {
  const response = await AccommodationService.fetchTimeFramesTrends(accommodationId, startDate, endDate)
  return response.data
}

export function useTimeFrameTrends(accommodationId, startDate, endDate) {
  const { data, isError, isLoading, isFetching, fetchStatus, isSuccess } = useQuery({
    enabled: !!accommodationId?.value,
    queryKey: ['timeFrameTrends', accommodationId, startDate, endDate],
    queryFn: ({ queryKey }) =>
      getTimeFramesTrends({
        accommodationId: queryKey[1],
        startDate: queryKey[2],
        endDate: queryKey[3],
      }),
  })

  const isTimeFrameTrendsLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isTimeFrameTrendsFetching: isFetching,
    isTimeFrameTrendsLoading,
    isTimeFrameTrendsSuccess: isSuccess,
    isTimeFrameTrendsError: isError,
    timeFrameTrends: data,
  }
}

export const getVisitorsProfileCountries = async ({ accommodationId, startDate, endDate }) => {
  const response = await AccommodationService.fetchVisitorsProfileCountries(accommodationId, startDate, endDate)
  return response.data ?? []
}

export function useVisitorsProfileCountries(accommodationId, startDate, endDate) {
  const { data, isError, isLoading, isFetching, fetchStatus, isSuccess } = useQuery({
    enabled: !!accommodationId?.value,
    queryKey: ['visitorsProfileCountries', accommodationId, startDate, endDate],
    queryFn: ({ queryKey }) =>
      getVisitorsProfileCountries({
        accommodationId: queryKey[1],
        startDate: queryKey[2],
        endDate: queryKey[3],
      }),
  })
  const isVisitorsProfileCountriesLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isVisitorsProfileCountriesFetching: isFetching,
    isVisitorsProfileCountriesLoading,
    isVisitorsProfileCountriesSuccess: isSuccess,
    isVisitorsProfileCountriesError: isError,
    visitorsProfileCountries: data,
  }
}

export const getVisitorsProfileDuration = async ({ accommodationId, timeFrame }) => {
  const response = await AccommodationService.fetchVisitorsProfileDuration({ accommodationId, timeFrame })
  return response.data
}

export function useVisitorsProfileDuration(accommodationId, timeFrame) {
  const { isLoading, data, isFetching, isSuccess, isError, fetchStatus } = useQuery({
    enabled: !!accommodationId?.value,
    queryKey: ['visitorsProfileDuration', accommodationId, timeFrame],
    queryFn: ({ queryKey }) => getVisitorsProfileDuration({ accommodationId: queryKey[1], timeFrame: queryKey[2] }),
  })

  const isVisitorsProfileDurationLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isVisitorsProfileDurationFetching: isFetching,
    isVisitorsProfileDurationLoading,
    isVisitorsProfileDurationSuccess: isSuccess,
    isVisitorsProfileDurationError: isError,
    visitorsProfileDuration: data,
  }
}
