<template>
  <section class="tw-pb-24 tw-px-10 tw-max-w-screen-xxl tw-mx-auto">
    <div>
      <div class="tw-w-full">
        <BaseCard class="tw-flex tw-flex-col md:tw-flex-row tw-p-9 tw-justify-beetween tw-items-center">
          <h3 class="tw-font-bold tw-text-[32px] tw-leading-[38px] tw-w-full md:tw-w-3/5">
            {{ $t('rc_mp_cta_header') }}
          </h3>
          <div class="tw-w-full md:tw-w-1/5" />
          <div class="tw-w-full md:tw-w-1/5 tw-flex tw-my-1.5 tw-mt-5 md:tw-mt-0">
            <MainButton class="tw-w-full" @click="onRegisterClick" @keydown.enter="onRegisterClick">{{
              $t('rc_mp_cta_button')
            }}</MainButton>
          </div>
        </BaseCard>
      </div>
    </div>
  </section>
</template>
<script setup>
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const emit = defineEmits(['onRegisterClick'])
const onRegisterClick = e => {
  emit('onRegisterClick', e)
}
</script>
<style scoped>
.white-btn {
  @apply tw-flex tw-items-center tw-h-full tw-justify-center tw-w-full tw-text-center tw-cursor-pointer tw-transition-colors tw-duration-300 tw-font-bold tw-border tw-text-blue-800 tw-border-white tw-bg-white tw-px-8 tw-py-3 tw-rounded-lg hover:tw-bg-gray-200 hover:tw-border-gray-200;
  line-height: 1.25;
  font-size: 1rem;
}
</style>
