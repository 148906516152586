<template>
  <div class="tw-h-[630px]">
    <div v-if="isLoading" class="tw-h-full tw-bg-gray-300 tw-animate-pulse tw-rounded-lg" />
    <div v-else-if="isError" class="tw-h-full" />
    <div v-else>
      <BarChart
        container="vp-searches-container"
        :series="dataFormattedForGraph"
        :categories="columnsFormattedForGraph"
        :isWaitingMode="isWaitingMode"
        class="tw-pb-4 tw-border-b-2 tw-border-b-gray-200 tw-h-[550px]"
      />
      <div class="tw-pl-4 tw-mt-9 tw-flex tw-items-center tw-gap-2">
        <span class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-graph-blue tw-inline-block" />
        <span>{{ $t('Search Patterns') }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BarChart from '@/components/visitorsProfile/common/BarChart.vue'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  isLoading: Boolean,
  isError: Boolean,
  timeFrameTrends: Object,
  isWaitingMode: Boolean,
})

const columnsFormattedForGraph = computed(() => {
  if (!props.timeFrameTrends || !selectedAccommodationId.value || !props.timeFrameTrends[selectedAccommodationId.value])
    return ['weekend', 'other', 'longTrip', 'week']

  const data = props.timeFrameTrends[selectedAccommodationId.value]
  const graphData = Object.keys(data)
    .map(item => {
      return {
        name: item,
        data: data[item],
      }
    })
    .sort((a, b) => (a.data > b.data ? -1 : 1))

  const categories = graphData.map(item => item.name)
  return categories
})

const dataFormattedForGraph = computed(() => {
  if (!props.timeFrameTrends || !selectedAccommodationId.value || !props.timeFrameTrends[selectedAccommodationId.value])
    return [{ data: [0, 0, 0, 0] }]

  const data = props.timeFrameTrends[selectedAccommodationId.value]
  const graphData = Object.keys(data)
    .map(item => {
      return {
        name: item,
        data: data[item],
      }
    })
    .sort((a, b) => (a.data > b.data ? -1 : 1))

  const simplifiedSeries = graphData.map(item => item.data)
  return [{ data: simplifiedSeries }]
})
</script>
