import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import PaymentService from '@/services/PaymentService'

export function useExistingPaymentMethods(organisationId) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['paymentDetails', 'existingPaymentMethods', organisationId.value],
    queryFn: () => PaymentService.fetchExistingPaymentMethods(),
  })
  const existingPaymentMethods = computed(() => data?.value?.data?.data?.getExistingPaymentMethods)
  const defaultPaymentMethod = computed(() => existingPaymentMethods?.value?.find(method => method.default))
  return { defaultPaymentMethod, existingPaymentMethods, isError, isLoading, isFetching }
}

export function useBillingAccountDetails(organisationId) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['paymentDetails', 'billingAccountDetails', organisationId.value],
    queryFn: ({ queryKey }) => PaymentService.fetchBillingAccountDetails({ variables: { accountNumber: queryKey[2] } }),
  })
  const billingAccountDetails = computed(() => data?.value?.data?.data?.account)
  const hasBillingAccount = computed(
    () =>
      billingAccountDetails?.value &&
      typeof billingAccountDetails.value === 'object' &&
      Object.keys(billingAccountDetails.value)?.length
  )
  return { billingAccountDetails, hasBillingAccount, isError, isLoading, isFetching }
}
