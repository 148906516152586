import appAnalytics from '@/utils/tracking'
import { verifyLaunchedAppOrigin } from './app'
import { events } from '@/constants/events'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import PaymentService from '@/services/PaymentService'

const closeApp = ({ payload, closeOverlayApp, $router, name, communicateCheckoutFailure }) => {
  const isOverlay = name === 'overlayApp'
  if (isOverlay) {
    closeOverlayApp()
    communicateCheckoutFailure({
      type: events.outgoing.USER_ABORTED,
      message: 'User cancelled the checkout process.',
    })
    return
  }

  const purchasableItemId = payload?.context?.params?.id

  if (!purchasableItemId)
    return $router.push({
      name: 'index',
    })

  switch (payload.action) {
    case 'route-push':
      $router.push({
        name: payload?.context?.name,
        params: {
          id: purchasableItemId,
        },
      })
      break
    default:
      $router.push({
        name: 'index',
      })
      break
  }
}

const syncPath = ({ launchedApp, payload, origin, name, handleAppRouteChange }) => {
  if (
    !verifyLaunchedAppOrigin(launchedApp, {
      origin,
    }) ||
    name === 'overlayApp'
  )
    return

  try {
    handleAppRouteChange(payload)
  } catch {
    // ignore route syncs
  }
}

const triggerCheckoutProcess = async ({
  payload,
  locale,
  accommodationId,
  launchedApp,
  launchOverlayApp,
  generateCheckoutPath,
  appConfigurationErrorMessage,
  registerCheckoutEventHandlers,
  checkoutPath,
  communicateCheckoutFailure,
}) => {
  const { displayNotification } = useToastNotifications()
  try {
    // This allows us to test from a test app, with different product ids.
    const productId = launchedApp.product_id
    const isBillingInformationUpdate = payload?.context?.isBillingDataModification
    if (isBillingInformationUpdate) {
      await launchOverlayApp({
        id: '2',
        path: checkoutPath || generateCheckoutPath(payload),
      })
      return
    }

    let result

    // When we launch the payment client from the settings client to change the billing information
    // we don't get any productId, and we don't need to perform this call. Therefore, we skip it.
    if (productId) {
      result = await PaymentService.fetchProductForDetailsPageQuery({
        variables: {
          product_id: productId,
          languageCode: locale.value.language,
        },
      })
    }
    const productPricePlans = result?.data?.data?.product?.pricing

    if (!productPricePlans) {
      showErrorInProcessingTheRequest({
        payload,
        launchedApp,
        appConfigurationErrorMessage,
      })
      return
    }

    // In case the user owns already a paid price plan different to USAGE_BASED, we don't trigger the checkout
    const hasOwnedPaidPlanNoUsageBased = await ownsNonUsageBasedPaidPlan({
      productPricePlans,
      productId,
      accommodationId,
    })

    if (hasOwnedPaidPlanNoUsageBased) {
      displayNotification({
        message: appConfigurationErrorMessage,
        isHtml: true,
        isTranslationKey: true,
        type: 'error',
      })

      communicateCheckoutFailure({
        type: events.outgoing.INVALID_PRICE_PLAN_ID,
        message: 'Accommodation already owns a paid plan for this product',
      })
      return
    }

    let matchingPlan
    if (Array.isArray(productPricePlans)) {
      matchingPlan = productPricePlans.find(pricePlan => {
        return pricePlan?.id === payload.pricePlanId
      })
    }

    if (!matchingPlan && payload?.pricePlanId) {
      showErrorInProcessingTheRequest({
        payload,
        launchedApp,
        appConfigurationErrorMessage,
      })
      communicateCheckoutFailure({
        type: events.outgoing.INVALID_PRICE_PLAN_ID,
        message: 'Priceplan Id provided is either invalid or does not belong to the product',
      })
      return
    }

    let requiresUnits
    if (matchingPlan && Array.isArray(matchingPlan.charges)) {
      requiresUnits = matchingPlan.charges.find(charge => {
        return charge.price_schema === 'PER_UNIT'
      })
    } else if (productPricePlans.length === 1) {
      requiresUnits = productPricePlans[0].charges.find(charge => {
        return charge.price_schema === 'PER_UNIT'
      })
    }

    if ((productPricePlans.length === 1 || matchingPlan) && (!requiresUnits || (requiresUnits && payload.quantity))) {
      await launchOverlayApp({
        id: '2',
        path: checkoutPath || generateCheckoutPath(payload, result.data.data.product),
      })
    }
  } catch {}
  registerCheckoutEventHandlers()
}

const showErrorInProcessingTheRequest = ({ payload, launchedApp, appConfigurationErrorMessage }) => {
  const { displayNotification } = useToastNotifications()
  displayNotification({
    message: appConfigurationErrorMessage,
    isHtml: true,
    isTranslationKey: true,
    type: 'error',
  })

  logAppErrorInSegment({
    payload,
    launchedApp,
  })
}

const ownsNonUsageBasedPaidPlan = async ({ productPricePlans, productId, accommodationId }) => {
  const ownedPricePlan = await PaymentService.fetchOwnedPricePlan({
    variables: {
      input: {
        product_id: productId,
        accommodation_id: accommodationId,
      },
    },
  })

  const ownedPricePlanId = ownedPricePlan?.data?.data?.ownedPricePlanByProduct?.id
  if (!ownedPricePlanId) return false

  const matchingPlan = productPricePlans.find(productPricePlan => {
    return productPricePlan.id === ownedPricePlanId
  })

  const isUsageBasedOnly = matchingPlan?.charges?.length === 1 && matchingPlan?.charges?.[0]?.type === 'USAGE_BASED'

  return matchingPlan && !isUsageBasedOnly
}

const orderCompleted = ({
  payload,
  checkSuccessAppLaunch,
  checkoutSuccessDashboard,
  name,
  communicateCheckoutSuccess,
  closeOverlayApp,
}) => {
  const isOverlay = name === 'overlayApp'
  if (isOverlay) {
    closeOverlayApp()
    communicateCheckoutSuccess(payload, payload?.applications?.[0])
    return
  }

  const applications = payload?.applications
  if (applications.length === 1) {
    checkSuccessAppLaunch(applications?.[0])
  } else {
    checkoutSuccessDashboard(payload?.productName)
  }
}

const orderPending = ({ payload, name, redirectToProductDetailsPage, closeOverlayApp, communicateCheckoutFailure }) => {
  const isOverlay = name === 'overlayApp'

  if (isOverlay) {
    closeOverlayApp()
    communicateCheckoutFailure({
      type: events.incoming.ORDER_PENDING,
      payload,
      message: 'The user closed the checkout as the order takes longer to process.',
    })
    return
  }

  const productId = payload?.queryParameters?.productId
  redirectToProductDetailsPage(productId)
}

const paymentCheckoutFailedFromStudio = ({ payload, $router, $t }) => {
  const { displayNotification } = useToastNotifications()
  if (payload && payload.error) {
    displayNotification({
      message: payload.error ? payload.error : $t('errors.app_checkout_failure'),
      type: 'error',
    })

    $router.push({
      name: 'rate-connect',
    })
  }
}

const logAppErrorInSegment = ({ payload, launchedApp, launchedOverlayApp }) => {
  if (!appAnalytics.events[payload.event_name]) return
  const eventName = appAnalytics.events[payload.event_name]
  // copy the payload to local variable
  const segmentPayload = {
    ...payload,
  }
  // we don't need event_name inside the segment payload
  delete segmentPayload.event_name
  if ((launchedOverlayApp && launchedOverlayApp.trusted) || (launchedApp && launchedApp.trusted)) {
    appAnalytics.track(eventName, {
      ...segmentPayload,
    })
  }
}

export default {
  closeApp,
  syncPath,
  triggerCheckoutProcess,
  orderCompleted,
  orderPending,
  paymentCheckoutFailedFromStudio,
  logAppErrorInSegment,
}
