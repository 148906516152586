<template>
  <div>
    <table class="tw-min-w-full tw-border-none" v-if="!isLoading && !isUserDetailsLoading">
      <thead class="tw-hidden md:tw-table-header-group">
        <tr class="tw-border-b tw-border-t">
          <th
            v-for="column in columns"
            :key="column.prop"
            class="tw-px-5 tw-py-5 tw-border-none tw-bg-white tw-text-left tw-text-sm tw-leading-4 tw-font-bold tw-text-gray-700"
            :class="`tw-w-[${column.width}]`"
          >
            <span>{{ $t(column.label) }}</span>
          </th>
        </tr>
      </thead>
      <tbody class="tw-bg-white">
        <!-- current user -->
        <tr class="tw-border-none tw-text-sm tw-font-medium tw-flex md:tw-table-row tw-flex-col">
          <td class="tw-w-full md:tw-w-[40%] tw-border-none">
            <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-blue-200 tw-p-2">
              <span>{{ $t('form.labels.accounts') }}{{ ': ' }}</span>
              <span>{{ userDetails.email }}</span>
            </div>
            <span class="md:tw-flex tw-hidden">{{ userDetails.email }}</span>
          </td>
          <td class="tw-w-full md:tw-w-[30%] tw-border-none">
            <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-px-2">
              <span>{{ $t('form.labels.role') }}{{ ': ' }}</span>
              <span>{{ $t('form.labels.user_role.' + currentRole) }}</span>
            </div>
            <span class="md:tw-flex tw-hidden">{{ $t('form.labels.user_role.' + currentRole) }}</span>
          </td>
          <td class="tw-w-[30%] tw-border-none md:tw-flex tw-hidden">&nbsp;</td>
        </tr>
        <!-- Rest users -->
        <tr
          v-for="user in organisationUsersWithoutMe"
          :key="user.userId"
          class="tw-text-sm tw-border-top md:tw-border-none tw-flex md:tw-table-row tw-flex-col"
        >
          <td class="tw-border-none">
            <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-blue-200 tw-p-2">
              <span>{{ $t('form.labels.accounts') }}{{ ': ' }}</span>
              <span>{{ user.email }}</span>
            </div>
            <span class="md:tw-flex tw-hidden">{{ user.email }}</span>
          </td>
          <td class="tw-border-none">
            <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-items-center tw-w-full tw-px-2">
              <span>{{ $t('form.labels.role') }}{{ ': ' }}</span>
              <div
                class="tw-relative tw-flex tw-justify-start tw-items-center tw-w-[150px] tw-px-3 tw-py-2 tw-border tw-rounded-lg tw-border-gray-500"
              >
                <select
                  class="tw-appearance-none focus:tw-outline-none tw-w-[150px]"
                  @change.prevent="onUserRoleChange($event, user.userId)"
                >
                  <option v-for="role in availableUserRoles" :key="role" :value="role" :selected="user.role == role">
                    {{ $t('form.labels.user_role.' + role) }}
                  </option>
                </select>
                <BaseIcon
                  icon-name="chevron-right"
                  :height="24"
                  :width="24"
                  :viewbox-height="24"
                  :viewbox-width="24"
                  icon-color="#6C707A"
                  class="tw-absolute arrow-dropdown"
                >
                  <ChevronRight />
                </BaseIcon>
              </div>
            </div>
            <div class="md:tw-flex tw-hidden tw-relative tw-flex tw-justify-start tw-items-center tw-w-[150px]">
              <select
                class="tw-appearance-none focus:tw-outline-none tw-w-[150px] tw-px-3 tw-py-2 tw-border tw-rounded-lg tw-border-gray-500"
                @change.prevent="onUserRoleChange($event, user.userId)"
              >
                <option v-for="role in availableUserRoles" :key="role" :value="role" :selected="user.role == role">
                  {{ $t('form.labels.user_role.' + role) }}
                </option>
              </select>
              <BaseIcon
                icon-name="chevron-right"
                :height="24"
                :width="24"
                :viewbox-height="24"
                :viewbox-width="24"
                icon-color="#6C707A"
                class="tw-absolute arrow-dropdown"
              >
                <ChevronRight />
              </BaseIcon>
            </div>
          </td>
          <td class="tw-border-none">
            <button
              type="button"
              class="tw-flex tw-items-center tw-border tw-p-2 tw-rounded-lg tw-border-gray-900 md:tw-border-none"
              @click.prevent="openDeletingModal(user.userId)"
            >
              <BaseIcon icon-name="delete" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
                <Delete />
              </BaseIcon>
              <span class="tw-ml-2">
                {{ $t('form.info.title_remove_user') }}
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <RemoveUserModal
      :isOpen="isDeletingModalOpen"
      :closeModal="closeDeletingModal"
      :loading="removeUserFromOrgLoading"
      :isInvitationRemoval="false"
      @removeUser="onRemoveUser"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import orderBy from 'lodash/orderBy'

import BaseIcon from '@/components/BaseIcon.vue'
import Delete from '@/components/icons/Delete.vue'
import RemoveUserModal from './RemoveUserModal.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import {
  useOrganisationUsers,
  updateUserRoleMutation,
  removeUserFromOrgMutation,
} from '@/components/settings/teamSettings/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { useUserDetails } from '@/layouts/queries'

const store = useStore()
const { displayNotification } = useToastNotifications()

const isDeletingModalOpen = ref(false)
const userIdToRemove = ref(null)
const columns = ref([
  {
    label: 'form.labels.accounts',
    prop: 'accounts',
    width: '40%',
  },
  {
    label: 'form.labels.role',
    prop: 'role',
    width: '30%',
  },
  {
    label: '',
    prop: 'remove',
    width: '30%',
  },
])
const availableUserRoles = ref(['admin', 'normal'])

const currentOrganisation = computed(() => store.state.session.selectedOrganisation)
const currentRole = computed(() => store.state.session.userRole)
const userId = computed(() => store.state.session?.userId)
const { userDetails, isUserDetailsLoading } = useUserDetails(userId)
const myOrganisationId = computed(() => (currentOrganisation.value ? currentOrganisation.value.id : false))
const organisationUsersWithoutMe = computed(() => {
  if (!Array.isArray(userOrganisationData.value)) return []

  let filteredUsers = userOrganisationData.value.filter(organisationUser => {
    return userId.value !== organisationUser.userId
  })

  return orderBy(filteredUsers, 'email')
})

const { isLoading, userOrganisationData } = useOrganisationUsers(myOrganisationId)
const { updateUserRole } = updateUserRoleMutation()
const { removeUserFromOrgLoading, removeUserFromOrg } = removeUserFromOrgMutation()

const openDeletingModal = userId => {
  isDeletingModalOpen.value = true
  userIdToRemove.value = userId
}

const closeDeletingModal = () => {
  isDeletingModalOpen.value = false
  userIdToRemove.value = null
}

const onUserRoleChange = async (event, userId) => {
  const role = event.target.value
  updateUserRole(
    { organisationId: myOrganisationId.value, userId, role },
    {
      onSuccess: () => {
        displayNotification({
          message: 'form.messages.success.role_changed',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'form.messages.error.unknown',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
      },
    }
  )
}

const onRemoveUser = async () => {
  removeUserFromOrg(
    { userId: userIdToRemove.value },
    {
      onSuccess: () => {
        displayNotification({
          message: 'form.messages.success.user_removed',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        closeDeletingModal()
      },
      onError: () => {
        displayNotification({
          message: 'form.messages.error.unknown',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
        closeDeletingModal()
      },
    }
  )
}
</script>
<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
</style>
