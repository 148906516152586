<template>
  <BaseCard noPadding>
    <fieldset>
      <legend class="tw-font-bold tw-px-4 tw-py-5">{{ $t('form.header.paymentMethod') }}</legend>
      <ExistingPaymentMethodList
        :existingPaymentMethods="existingPaymentMethods"
        :selectedPaymentMethod="selectedPaymentMethod"
        @onChange="onSelectedValueChange"
      />
      <div class="tw-px-4 tw-py-5 tw-border-t tw-border-t-gray-300">
        <BaseButton
          v-if="!showNewPaymentMethodForm"
          size="noPadding"
          class="tw-text-blue-800"
          @click="showNewPaymentMethodForm = true"
        >
          <span>+ </span>{{ $t('form.paymentMethods.new') }}
        </BaseButton>
        <div v-else class="tw-flex tw-justify-between tw-gap-2">
          <span class="tw-font-bold">{{ $t('form.paymentMethods.new') }}</span>
          <BaseButton
            v-if="existingPaymentMethods.length > 0"
            size="noPadding"
            class="tw-text-blue-700"
            @click="onNewPaymentMethodFormCancel"
          >
            {{ $t('form.paymentMethods.new-cancel') }}
          </BaseButton>
        </div>
      </div>

      <NewPaymentMethodForm
        v-if="showNewPaymentMethodForm"
        :availablePaymentMethods="availablePaymentMethods"
        :fetchingPaymentMethods="fetchingPaymentMethods"
        @onChange="onSelectedValueChange"
        @onZuoraCallbackSuccess="e => emit('onZuoraCallbackSuccess', e)"
        @onZuoraCallbackError="emit('onZuoraCallbackError')"
        @onZuoraClientValidationError="emit('onZuoraClientValidationError')"
        @setError="onSetError"
      />
    </fieldset>
  </BaseCard>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import BaseButton from '@/components/base/buttons/BaseButton.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import NewPaymentMethodForm from '@/components/payment/NewPaymentMethodForm.vue'
import ExistingPaymentMethodList from './ExistingPaymentMethodList.vue'

const emit = defineEmits([
  'onSelectedValueChange',
  'onShowNewFormCancel',
  'onZuoraCallbackSuccess',
  'onZuoraCallbackError',
  'onZuoraClientValidationError',
  'setError',
])

const onSetError = error => {
  emit('setError', error)
}

const props = defineProps({
  existingPaymentMethods: {
    type: Array,
    default: () => [],
  },
  availablePaymentMethods: {
    type: Array,
    default: () => [],
  },
  selectedPaymentMethod: {
    type: String,
    default: null,
  },
  fetchingPaymentMethods: {
    type: Boolean,
    default: false,
  },
})

const showNewPaymentMethodForm = ref(false)

const onNewPaymentMethodFormCancel = () => {
  emit('onShowNewFormCancel')
  showNewPaymentMethodForm.value = false
}

const onSelectedValueChange = paymentMethod => {
  if (paymentMethod !== 'CREDIT_CARD' && paymentMethod !== 'SEPA') {
    showNewPaymentMethodForm.value = false
  }
  emit('onSelectedValueChange', paymentMethod)
}

onMounted(() => {
  if (props.existingPaymentMethods.length < 1) {
    showNewPaymentMethodForm.value = true
  }
})
</script>
