<template>
  <div class="tw-relative">
    <p class="tw-mb-1">
      <strong>{{ $t('rc_not_eligible_issue_1_text') }}</strong>
    </p>
    <BaseSelect
      id="provider-list-select"
      :display-text-key="'partnerName'"
      :value-key="'partnerId'"
      :options="sortedAllPartnersData"
    />
    <div v-show="isLoading" class="tw-inline tw-absolute tw-top-2/4 tw-left-6">
      <RCInlineLoader />
    </div>
    <p v-show="isError" class="tw-inline tw-absolute tw-top-2/4 tw-left-6">
      {{ $t('onboarding.appLaunchError.header') }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import BaseSelect from '@/components/BaseSelect.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { useAllPartners } from '@/components/rateConnect/queries'

const { allPartnersData, isLoading, isError } = useAllPartners()

const sortedAllPartnersData = computed(() =>
  Array.isArray(allPartnersData.value)
    ? [...allPartnersData.value]?.sort((a, b) =>
        a.partnerName.toLowerCase() > b.partnerName.toLowerCase()
          ? 1
          : b.partnerName.toLowerCase() > a.partnerName.toLowerCase()
          ? -1
          : 0
      )
    : allPartnersData.value
)
</script>
