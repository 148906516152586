<template>
  <div class="tw-hidden tw-mt-10 md:tw-flex md:tw-flex-row md:tw-justify-between">
    <GhostButton
      :disabled="backDisabled"
      size="medium"
      class="tw-px-9 tw-py-2 tw-mb-3.5 md:tw-mb-0"
      @click="$emit('onBack')"
      >{{ $t('setup_back') }}</GhostButton
    >
    <MainButton :disabled="nextDisabled" size="medium" :class="'tw-px-9 tw-py-2'" @click="$emit('onNext')">
      <span v-if="!isLoading">{{ $t('setup_next') }}</span>
      <span v-if="isLoading"> <RCInlineLoader color="#fff" /></span>
    </MainButton>
  </div>
  <div class="md:tw-hidden tw-flex tw-flex-col tw-gap-2">
    <MainButton @click="emit('onNext')" :disabled="nextDisabled">{{ $t('setup_next') }}</MainButton>
    <BaseButton @click="emit('onBack')" class="tw-text-gray-700 tw-font-bold" :disabled="backDisabled">{{
      $t('setup_back')
    }}</BaseButton>
  </div>
</template>
<script setup>
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

defineProps({
  nextDisabled: {
    type: Boolean,
    default: false,
  },
  backDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['onNext', 'onBack'])
</script>
