<template>
  <div class="tw-grid sm:tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_1fr_1fr_1fr] w-gap-2 tw-items-start">
    <ReactivationModal
      v-if="isReactivationModalOpen"
      :isOpen="isReactivationModalOpen"
      :accommodationId="accommodationId"
      :nextPayment="nextPaymentDate"
      :subscriptionType="subscription.pricing_plan"
      @closeModal="closeReactivationModal"
      @reactivate="reactivate"
    />
    <div class="tw-flex tw-items-left tw-flex-col tw-gap-2 tw-h-full">
      <div class="tw-text-sm">{{ $t(productName) }}</div>
      <div class="tw-text-sm tw-max-w-[250px] tw-text-ellipsis">{{ $t(accommodationName) }}</div>
    </div>
    <div>
      <p v-if="subscription.pricing_plan === 'MONTHLY'" class="tw-text-sm">
        {{ $t('business_studio_plus_monthly') }}
      </p>
      <p v-if="subscription.pricing_plan === 'YEARLY'" class="tw-text-sm">
        {{ $t('business_studio_plus_annual') }}
      </p>
    </div>
    <!-- Subscription Active -->
    <div class="tw-flex tw-flex-col" v-if="subscription.status === 'active'">
      <small class="tw-text-sm tw-leading-6">{{
        isReactivatedActive
          ? $t('business_studio_plus_reactivation_date', { ReactivationDate: startDate })
          : $t('business_studio_plus_free_trial_start', { StartDate: startDate })
      }}</small>
      <small class="tw-text-sm tw-leading-6">{{
        isReactivatedActive
          ? $t('business_studio_plus_subscription_auto_renewal', { StartDate: nextPaymentDate })
          : $t('business_studio_plus_subscription_payment_date', { PaymentDate: nextPaymentDate })
      }}</small>
    </div>
    <!-- Subscription Cancelled -->
    <div class="tw-flex tw-flex-col" v-if="subscription.status === 'canceled'">
      <small class="tw-text-sm tw-leading-6">{{
        $t('business_studio_plus_free_trial_start', { StartDate: startDate })
      }}</small>
      <small class="tw-text-sm tw-leading-6">{{
        $t('subscriptions.activeUntil', { date: subscriptionEndDate })
      }}</small>
    </div>

    <!-- Subscription Expired -->
    <div class="tw-flex tw-flex-col" v-if="subscription.status === 'expired'">
      <small class="tw-text-sm tw-leading-6">{{
        $t('subscription_expiration_date', { ExpirationDate: subscriptionEndDate })
      }}</small>
    </div>

    <div class="tw-flex tw-flex-col md:tw-items-end tw-mt-4 md:tw-mt-0">
      <button
        v-if="subscription.status !== 'active'"
        class="tw-flex tw-font-bold tw-text-sm tw-text-blue-800 tw-items-end tw-grow"
        @click="startReactivationFlow"
      >
        {{ $t('business_studio_plus_reactivate_cta') }}
      </button>

      <button
        v-if="subscription.status === 'active'"
        class="tw-flex tw-font-bold tw-text-sm tw-text-gray-700 tw-items-end tw-grow"
        @click="() => toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
      >
        {{ $t('subscriptions.details.cancel.cta') }}
      </button>
    </div>
  </div>
  <div v-if="isCancellationOpen && subscription.status === 'active'" class="tw-flex tw-flex-col md:tw-block tw-mt-6">
    <div class="tw-h-[1px] tw-w-full tw-bg-gray-300 tw-my-8" />
    <p class="tw-font-bold tw-pb-4">{{ $t('business_studio_plus_cancel_subscription_header') }}</p>
    <div class="tw-w-full md:tw-w-1/3 md:tw-my-4 tw-mb-8">
      <BaseSelect
        class="tw-w-full"
        required
        :label="$t('business_studio_plus_cancellation_question')"
        :display-text-key="'label'"
        :value-key="'value'"
        :options="sortedCancellationReasons"
        :placeholder="$t('form.options.default')"
        :value="null"
        version="light"
        size="medium"
        @onChange="e => updateReason(e)"
      />

      <BaseTextField
        v-if="reason?.id === 5 || reason?.id === 3"
        class="tw-text-sm tw-mt-2"
        size="medium"
        aria-label="other-reason-input"
        :value="reasonInfo"
        :placeholder="$t('business_studio_plus_cancellation_feedback_box')"
        @onInput="e => updateReasonInfo(e)"
      />
    </div>
    <MainButton
      size="medium"
      class="tw-w-full md:tw-w-auto md:tw-mr-2 tw-mb-2 md:tw-order-1 tw-order-2"
      @click="toggleCancelSubscription('close')"
      >{{ $t('back_cta') }}</MainButton
    >
    <GhostButton
      :disabled="
        reason === null ||
        (reason?.id === 5 && reasonInfo.length === 0) ||
        (reason?.id === 3 && reasonInfo.length === 0)
      "
      size="medium"
      class="tw-w-full tw-mb-2 md:tw-mb-0 md:tw-w-auto md:tw-order-2 tw-order-1"
      @click="cancelSubscription"
    >
      <span v-if="!isCancelStudioPlusLoading">{{ $t('subscriptions.details.cancel.cta') }}</span>
      <span v-else><RCInlineLoader /></span>
    </GhostButton>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import {
  useCancelStudioPlusMutation,
  useStudioPlusNewSubscriptionMutation,
  useStudioPlusPrice,
} from '@/components/payment/queries'
import { useUserDetails } from '@/layouts/queries'
import appAnalytics from '@/utils/tracking'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import ReactivationModal from '@/components/settings/subscriptions/ReactivationModal.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import useRateConnect from '@/components/rateConnect/queries'

const router = useRouter()
const store = useStore()
const { t } = useI18n()
const props = defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  accommodationId: {
    type: Number,
    default: null,
  },
  accommodationName: {
    type: String,
    default: '',
  },
})
const cancellationReasons = computed(() => [
  { label: t('tbs_plus_cancel_reason_1'), id: 1, value: 'The price is high' },
  {
    label: t('tbs_plus_cancel_reason_2'),
    id: 2,
    value: 'There’s a change in business strategy',
  },
  { label: t('tbs_plus_cancel_reason_3'), id: 3, value: 'My expectations weren’t met' },
  { label: t('tbs_plus_cancel_reason_4'), id: 4, value: 'There’s a change of ownership' },
])
const isCancellationOpen = ref(false)
const isReactivationModalOpen = ref(false)
const reason = ref(null)
const reasonInfo = ref('')
const { hotelierData } = useRateConnect(props.accommodationId)
const directRatesPartnerId = computed(() => hotelierData.value?.directRatesPartner)
const { cancelStudioPlus, isCancelStudioPlusLoading } = useCancelStudioPlusMutation()
const { displayNotification } = useToastNotifications()
const productName = computed(() => props.subscription?.product?.details?.[0]?.name)
const userId = computed(() => store.state.session.userId)
const { userDetails } = useUserDetails(userId)
const language = computed(() => store.state.locale.language)
const { sendStudioPlusNewSubscription } = useStudioPlusNewSubscriptionMutation()
const { studioPlusPrice } = useStudioPlusPrice({ value: props.accommodationId })
const sortedCancellationReasons = computed(() => {
  const other = { label: t('tbs_plus_cancel_reason_5'), id: 5, value: 'Other' }
  const sorted = [...cancellationReasons.value].sort((a, b) => {
    const labelA = a.label
    const labelB = b.label
    if (labelA < labelB) {
      return -1
    }
    if (labelA > labelB) {
      return 1
    }
    return 0
  })

  return [...sorted, other]
})
const startDate = computed(() =>
  new Date(props.subscription?.subscriptionStartDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
)

const subscriptionEndDate = computed(() => {
  return new Date(props.subscription?.endDate.replace('@', ' ')).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const nextPaymentDate = computed(() => {
  return new Date(props.subscription?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const reactivationDate = computed(() => {
  return new Date().toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const isReactivatedActive = computed(() => {
  const startDate = new Date(props.subscription?.subscriptionStartDate)
  const currentDate = new Date()
  if (startDate !== undefined || startDate < currentDate) {
    return false
  } else {
    return true
  }
})

const toggleCancelSubscription = action => {
  reason.value = null
  reasonInfo.value = ''
  if (action === 'open') {
    isCancellationOpen.value = true
  }
  if (action === 'close') {
    isCancellationOpen.value = false
  }
}

const updateReason = text => {
  const newReason = sortedCancellationReasons.value.find(item => item.value === text)
  reason.value = newReason
  reasonInfo.value = ''
}

const updateReasonInfo = text => {
  reasonInfo.value = text
}

const openReactivationModal = () => {
  isReactivationModalOpen.value = true
}

const closeReactivationModal = () => {
  isReactivationModalOpen.value = false
}

const startReactivationFlow = () => {
  if (props.subscription.status === 'expired') {
    router.push({
      name: 'studio-plus-payment',
      query: {
        reactivate: true,
        accommodationId: props.accommodationId,
        subscription: props.subscription.pricing_plan === 'MONTHLY' ? 'monthly' : 'annual',
      },
    })
  } else if (props.subscription.status === 'canceled') {
    openReactivationModal()
  }
}

const reactivate = accommodationId => {
  sendStudioPlusNewSubscription(
    {
      accommodationId: accommodationId,
      partnerId: directRatesPartnerId.value,
      itemName: props.accommodationName,
      email: userDetails?.value?.email,
      language: language?.value,
      studioUserId: userId.value,
      firstName: userDetails?.value?.firstName,
      lastName: userDetails?.value?.lastName,
      isFreeTrial: false,
      type: props.subscription.pricing_plan,
    },
    {
      onSuccess: data => {
        displayNotification({
          message: 'business_studio_plus_reacativation_success',
          isTranslationKey: true,
          isHtml: true,
          type: 'success',
          id: Date.now(),
        })
        closeReactivationModal()
        appAnalytics.track(appAnalytics.events.SP_SUBSCRIPTION_REACTIVATED, {
          item_id: props.accommodationId,
          partnerId: directRatesPartnerId.value,
          language: language.value,
          studioUserId: userId.value,
          email: userDetails?.value?.email,
          firstName: userDetails?.value?.firstName,
          lastName: userDetails?.value?.lastName,
          price: studioPlusPrice.value?.price,
          reactivation_date: reactivationDate.value,
          subscription_type: props.subscription.pricing_plan === 'MONTHLY' ? 'monthly' : 'annual',
          auto_renewal_date: new Date(data?.data?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }),
        })
      },
    }
  )
}

const cancelSubscription = () => {
  cancelStudioPlus(
    {
      accommodationId: props.accommodationId,
      itemName: props.accommodationName,
      email: userDetails?.value?.email,
      reason:
        reason.value?.id === 5 || reason.value?.id === 3
          ? `${reason.value.value}: ${reasonInfo.value}`
          : reason.value.value,
      partnerId: props.subscription.partnerId,
      language: language.value,
      studioUserId: userId.value,
      firstName: userDetails?.value?.firstName,
      lastName: userDetails?.value?.lastName,
    },
    {
      onSuccess: data => {
        appAnalytics.track(appAnalytics.events.SP_SUBSCRIPTION_CANCELED, {
          item_id: props.accommodationId,
          reason:
            reason.value?.id === 5 || reason.value?.id === 3
              ? `${reason.value.value}: ${reasonInfo.value}`
              : reason.value.value,
        })
        reason.value = ''
        toggleCancelSubscription('close')
        displayNotification({
          message: 'business_studio_plus_subscription_cancel_message',
          isTranslationKey: true,
          translationParams: {
            EndDate: new Date(data.data?.endDate.replace('@', ' ')).toLocaleDateString(store.state.locale.language, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }),
          },
          isHtml: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          isHtml: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
