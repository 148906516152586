<template>
  <div class="tw-px-4 tw-w-full tw-relative">
    <ConfirmDialog v-if="isRemovePopupOpen" @onConfirm="confirmLeaveOrganization" @onCancel="cancelLeaveOrganisation" />
    <OrganisationsViewLoadingSkeleton v-if="isRemovingUserFromOrganisationLoading || isLoadingCoreData" />
    <div v-else class="tw-pb-10 tw-py-4 tw-min-h-screen-minus-footer tw-max-w-4xl tw-mx-auto">
      <div>
        <router-link to="/" class="back hover:tw-cursor-pointer tw-font-bold">← {{ $t('setup_back') }} </router-link>
      </div>
      <h1 class="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-8">
        {{ $t('pages.organizationSwitcher.heading.mainHeading') }}
      </h1>
      <div class="tw-mb-10">
        <h2 class="tw-font-bold tw-mb-4">
          {{ $t('pages.organizationSwitcher.heading.selectedOrganization') }}
        </h2>
        <OrganisationCard v-if="selectedOrganisation" selected :organisation-details="selectedOrganisation" />
      </div>
      <div v-if="organisations?.length > 1" class="tw-flex tw-flex-col tw-gap-3">
        <h2 class="tw-font-bold tw-mb-1">{{ $t('pages.organizationSwitcher.heading.otherOrganizations') }}</h2>
        <template v-for="organisation in organisations">
          <OrganisationCard
            v-if="organisation.id !== selectedOrganisation.id"
            :key="organisation.id"
            :organisation-details="organisation"
            @onOrganisationSelectClick="changeOrganisation"
            @onOrganisationRemoveClick="removeOrganisation"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import OrganisationCard from '@/components/organisations/OrganisationCard.vue'
import ConfirmDialog from '@/components/base/ConfirmDialog.vue'
import OrganisationsViewLoadingSkeleton from '@/components/loadingSkeletons/OrganisationsViewLoadingSkeleton.vue'
import useAccommodations from '@/composables/useAccommodations'
import { useOrganisationsMutation } from '@/components/organisations/queries'

const isRemovePopupOpen = ref(false)
const organisationToLeave = ref(null)

const store = useStore()
const organisations = computed(() => store.state.session.organisations)
const isLoadingCoreData = computed(() => store.state.session.isLoading)
const selectedOrganisation = computed(() => store.state.session.selectedOrganisation)
const { switchOrganisation } = useAccommodations()

const changeOrganisation = newOrganisation => {
  switchOrganisation(newOrganisation)
}

const removeOrganisation = newOrganisation => {
  organisationToLeave.value = newOrganisation
  isRemovePopupOpen.value = true
}

const cancelLeaveOrganisation = () => {
  isRemovePopupOpen.value = false
  organisationToLeave.value = null
}

const { removeUserFromOrganisation, isRemovingUserFromOrganisationLoading } = useOrganisationsMutation()
const confirmLeaveOrganization = async () => {
  removeUserFromOrganisation({ organisationId: organisationToLeave.value?.id })
  isRemovePopupOpen.value = false
  organisationToLeave.value = null
}
</script>

<style scoped>
.back {
  @apply tw-text-blue-800 !important;
}
.back:hover {
  @apply tw-text-blue-900 !important;
}
</style>
