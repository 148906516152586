<template>
  <nav class="tw-w-full tw-flex tw-flex-wrap tw-border-solid tw-border-b tw-border-gray-300 tw-gap-5">
    <router-link
      v-for="item in menuItems"
      :key="item.route"
      :to="item.route"
      class="tw-text-gray-900 hover:tw-text-blue-800 tw-border-b-4 hover:tw-border-blue-800 hover:tw-no-underline tw-pb-3 tw-pt-2 tw-px-4"
      :class="isLinkActive(item.route) ? 'tw-font-bold tw-border-blue-800 tw-text-blue-800' : 'tw-border-transparent'"
    >
      {{ $t(`${item.label}`) }}
    </router-link>
  </nav>
</template>

<script setup>
import { useRouter } from 'vue-router'

defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
})

const router = useRouter()
const isLinkActive = route => route?.replace('/home', '') === router.currentRoute.value?.path
</script>
