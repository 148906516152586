export const typeMockData = {
  5035108: {
    other: 16,
    weekend: 67,
    week: 4,
    longTrip: 14,
  },
  129412: {
    other: 19,
    weekend: 59,
    week: 11,
    longTrip: 11,
  },
  4460946: {
    other: 18,
    weekend: 62,
    week: 5,
    longTrip: 14,
  },
  10611086: {
    other: 12,
    weekend: 67,
    week: 3,
    longTrip: 18,
  },
  19678506: {
    other: 6,
    weekend: 75,
    week: 2,
    longTrip: 17,
  },
  52064: {
    other: 6,
    weekend: 75,
    week: 2,
    longTrip: 17,
  },
}
