import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import AdminService from '@/services/AdminService'
import PremiumService from '@/services/PremiumService'

const fetchAccommodationData = async ({ accommodationId }) => {
  if (accommodationId) {
    const response = await AdminService.search(accommodationId)
    return response.data
  } else {
    throw { response: { status: 404 } }
  }
}

export function useAccommodation(accommodationId, onSuccessCallback, onErrorCallback) {
  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ['accommodation', accommodationId],
    enabled: false,
    cacheTime: 0,
    queryFn: ({ queryKey }) => fetchAccommodationData({ accommodationId: queryKey[1] }),
    onSuccess: () => onSuccessCallback(),
    onError: error => onErrorCallback(error),
  })

  return { isLoading, accommodationData: data, isFetching, refetchAccommodation: refetch }
}

const fetchPaidProducts = async ({ accommodationId }) => {
  if (accommodationId) {
    const response = await PremiumService.getPaidSubscriptions(accommodationId)
    return response.data
  } else {
    throw { response: { status: 404 } }
  }
}

export function usePaidSubscriptions(accommodationId) {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['paidSubscriptions', accommodationId],
    enabled: false,
    cacheTime: 0,
    queryFn: ({ queryKey }) => fetchPaidProducts({ accommodationId: queryKey[1] }),
  })

  return { paidSubscriptionsIsLoading: isLoading, paidSubscriptions: data, refetchSubscriptions: refetch }
}

export function useDeleteAssignment() {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: deleteAssignment,
  } = useMutation({
    mutationFn: async ({ accommodationId }) => await AdminService.deleteAssignment({ accommodationId }),
    onSuccess: (_, mutationInput) => {
      queryClient.removeQueries({ queryKey: ['accommodation', mutationInput.accommodationId] })
      queryClient.removeQueries({ queryKey: ['paidSubscriptions', mutationInput.accommodationId] })
    },
  })

  return {
    isLoading,
    isSuccess,
    isError,
    deleteAssignment,
  }
}

export function useInviteUserInAdminTool() {
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: inviteUserAsAdmin,
  } = useMutation({
    mutationFn: ({ organisationId, data }) => AdminService.inviteUserAsAdmin({ organisationId, data }),
  })

  return {
    isLoading,
    isSuccess,
    isError,
    inviteUserAsAdmin,
  }
}
