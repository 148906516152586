<template>
  <iframe
    class="tw-absolute tw-inset-0 tw-block tw-w-full tw-h-checkout-min-height tw-z-50"
    ref="paymentClientIframe"
    :src="paymentClientUrl"
    sandbox="allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-top-navigation allow-downloads"
    title="Payment Client"
  />
</template>

<script setup>
import { onBeforeUnmount, onBeforeMount, onMounted, computed, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

import pm from '@/utils/postMessage'
import { events } from '@/constants/events'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'

const store = useStore()

const paymentClientIframe = ref(null)
const paymentClientUrl = ref(null)

const emit = defineEmits(['paymentClientClosed', 'paymentInfoUpdated'])
pm.methods[events.incoming.TRIGGER_APP_CLOSE] = () => emit('paymentClientClosed')
pm.methods[events.incoming.BILLING_INFORMATION_UPDATED] = () => emit('paymentInfoUpdated')

const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const selectedAssignment = computed(() => {
  if (!selectedAccommodationId) {
    return {}
  } else {
    return {
      accommodationId: selectedAccommodationId.value,
      status: 'active',
      organizationId: selectedOrganisationId.value,
    }
  }
})
const trustedAppSession = computed(() => store.getters['session/trustedAppSession'])
const currency = computed(() => store.state.currency)
const locale = computed(() => store.state.locale?.language)

onBeforeMount(() => {
  paymentClientUrl.value = `${import.meta.env.VITE_PAYMENT_CLIENT_URL}/?details=${window.btoa(
    JSON.stringify({
      appId: '1',
      accommodationId: selectedAccommodationId.value,
      context: { isBillingDataModification: true },
    })
  )}`
})

onMounted(async () => {
  const accommodation = toRaw(currentAccommodation.value)

  await pm.emitToChild({
    payload: {
      ...cloneDeep(trustedAppSession.value),
      selectedAccommodation: {
        accommodation: { ...accommodation, id: accommodation.accommodationId },
        assignment: toRaw(selectedAssignment.value),
      },
      context: {
        currency: { code: toRaw(currency.value) },
        locale: { tag: toRaw(locale.value) },
      },
    },
    event: events.outgoing.SESSION,
    selector: toRaw(paymentClientIframe.value),
    retries: 3,
    messageTimeout: 2000,
  })
})

onBeforeUnmount(() => {
  delete pm.methods[events.incoming.TRIGGER_APP_CLOSE]
  delete pm.methods[events.incoming.BILLING_INFORMATION_UPDATED]
})
</script>
