<template>
  <div v-if="description" class="tw-mt-4 tw-flex tw-flex-col">
    <h3 class="tw-font-bold">
      {{ $t('app_description_language') }}:
      {{ $t(`description_language_placeholder_${props.description?.language_tag}`) }}
    </h3>
    <p class="tw-text-gray-700 tw-text-sm">
      {{ isHotelier ? $t('app_description_created_by') : $t('app_description_freelancer') }}
    </p>
    <p class="tw-text-gray-700 tw-text-sm">{{ $t('app_description_last_updated') }}: {{ modifiedAt }}</p>
    <span class="tw-font-bold tw-capitalize tw-mt-2" :class="descriptionStatusStyle">{{
      $t(`app_description_status_${description.status}`)
    }}</span>
    <p v-if="description.status !== 'draft' && description.status !== 'rejected'" class="tw-text-gray-700 tw-text-sm">
      <span v-if="description.status === 'not_live'">{{ $t('app_description_not_live') }}</span>
      <span v-else-if="description.status === 'live'">{{
        isHotelier
          ? $t('app_description_list_item_hint', {
              language: $t(`description_language_placeholder_${props.description?.language_tag}`),
            })
          : $t('app_description_created_by_trivago_freelancer')
      }}</span>
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  description: {
    type: Object,
    default: null,
  },
})

const modifiedAt = computed(() => {
  if (!props.description?.modified_at) return ''
  const modifiedAtDate = new Date(props.description?.modified_at)
  return modifiedAtDate?.toLocaleString()
})
const descriptionStatusStyle = computed(() => {
  if (props.description?.status === 'live') return 'tw-text-green-700'
  // Descriptions are automatically approved now, only legacy stuff can be in status rejected.
  if (props.description?.status === 'rejected') return 'tw-text-red-800'
  return 'tw-text-gray-700'
})

const isHotelier = computed(() => {
  return props.description?.source === 'hotelier'
})
</script>
