<template>
  <div class="tw-h-[630px]">
    <div v-if="isVisitorsProfileDurationLoading" class="tw-h-full tw-bg-gray-300 tw-animate-pulse tw-rounded-lg"></div>
    <div v-else-if="isVisitorsProfileDurationError" class="tw-h-full"></div>
    <div v-else>
      <DurationGraph :series="dataFormattedForGraph" class="tw-pb-4 tw-border-b-2 tw-border-b-gray-200 tw-h-[550px]" />
      <div class="tw-pl-4 tw-mt-9 tw-flex tw-items-center tw-gap-2">
        <span class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-graph-blue tw-inline-block" />
        <span>{{ $t('Length of stay searches') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import DurationGraph from '@/components/visitorsProfile/durationView/DurationGraph.vue'
import { useVisitorsProfileDuration } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  timeFrame: {
    type: String,
    required: true,
    default: '30',
  },
})

const timeFrameSelected = computed(() => props.timeFrame)

const { isVisitorsProfileDurationLoading, isVisitorsProfileDurationError, visitorsProfileDuration } =
  useVisitorsProfileDuration(selectedAccommodationId, timeFrameSelected)

const dataFormattedForGraph = computed(() => {
  if (
    !visitorsProfileDuration.value ||
    !selectedAccommodationId.value ||
    !visitorsProfileDuration.value[selectedAccommodationId.value]
  )
    return []
  const { lte2days, days34, days56, days78, gte9days } = visitorsProfileDuration.value[selectedAccommodationId.value]
  const series = [
    {
      data: [lte2days, days34, days56, days78, gte9days],
    },
  ]
  return series
})
</script>
