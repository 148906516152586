<template>
  <div class="button-group-container tw-border tw-border-blue-800 tw-rounded-sm tw-text-blue-800">
    <button
      v-for="item in items"
      :key="item.value"
      :value="item.value"
      class="tw-py-2 tw-px-4 tw-outline-0 focus:tw-outline-0"
      :class="
        value === item.value
          ? `${version === '2' ? 'tw-px-7 tw-bg-blue-800 tw-text-white' : 'tw-bg-blue-800 tw-text-white'}`
          : `${version === '2' ? 'tw-px-7' : ''}`
      "
      @click="$emit('onClick', $event.target.value)"
      @keydown.enter="$emit('onClick', $event.target.value)"
      :data-testid="buttonTestIdPrefix ? `${buttonTestIdPrefix}-${item.value}` : null"
    >
      {{ $t(item.label) }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    value: {
      type: [String, Number],
      required: true,
      default: '',
    },
    version: {
      type: String,
      required: false,
      default: '1',
    },
    buttonTestIdPrefix: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
.button-group-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  border-radius: 8px;
}
.button-group-container > button:not(:last-child) {
  border-right: 1px solid theme('colors.blue.500');
}
.button-group-container button:first-child {
  border-top-left-radius: 4px 5px;
  border-bottom-left-radius: 4px 5px;
}
.button-group-container button:last-child {
  border-top-right-radius: 4px 5px;
  border-bottom-right-radius: 4px 5px;
}
</style>
