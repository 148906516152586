<template>
  <div :class="{ 'app-container-checkout-page-active': checkoutActive }" class="app-container">
    <div v-if="!loaded" class="app-container-loader">
      <LaunchpadLoader class="tw-min-h-screen-minus-footer tw-flex tw-items-center tw-justify-center" />
    </div>

    <TheAppContainerIframe v-if="launchedApp" :key="launchedApp.app_id" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TheAppContainerIframe from '@/components/app/TheAppContainerIframe.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'

export default {
  components: {
    TheAppContainerIframe,
    LaunchpadLoader,
  },
  computed: {
    ...mapState('app', ['launchedApp', 'launchedOverlayApp', 'loaded']),
    checkoutActive() {
      return this.launchedApp?.app_id === '2' || this.launchedOverlayApp?.app_id === '2'
    },
  },
  methods: {
    ...mapActions('app', ['setApp']),
  },
}
</script>
<style scoped>
.app-container {
  @apply tw-absolute tw-inset-0 tw-block tw-w-full tw-h-screen tw-max-h-full;
}
.app-container-loader {
  @apply tw-absolute tw-inset-0;
}
.app-container-checkout-page-active {
  top: 0;
  @apply tw-h-checkout-min-height;
}
</style>
