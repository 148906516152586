<template>
  <div class="tw-py-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_settings_provider_head') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-base tw-text-gray-900 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info main-info md:tw-mb-0 tw-mb-5 tw-pr-3">
        <p
          class="tw-text-sm tw-p-0 tw-m-0 tw-mr-4"
          v-html="
            $t('/snippets/hgw/rc_settings_status_active_info.inc', {
              partnerName: currentPartner?.partnerName,
            })
          "
        />
        <p
          v-if="currentPartner?.status === 'temporarily_inactive'"
          class="tw-text-sm tw-p-0 tw-m-0"
          v-html="
            $t('/snippets/hgw/rc_provider_status_temporarily_inactive_info.inc', {
              partnerName: currentPartner?.partnerName,
            })
          "
        />
      </div>
      <div class="tw-flex tw-w-1/5 tw-justify-start partner-logo">
        <img
          :src="`https://imgcy.trivago.com/e_trim,f_auto,q_auto,h_50/partnerlogos-s3/${currentPartner?.partnerId}`"
          :alt="`${currentPartner?.partnerName} Logo`"
        />
      </div>
      <div class="tw-flex tw-w-1/5 tw-justify-end action-btn tw-items-center">
        <p v-if="currentPartner?.partnerStatus === 'inactive'" class="tw-text-red-800 tw-text-base tw-mr-2">
          {{ $t('rc_provider_status_inactive') }}
        </p>
        <GhostButton
          v-if="currentPartner?.partnerStatus === 'inactive' && !shouldShowPartnerChangeButton"
          :class="'tw-px-9 tw-py-2'"
          size="medium"
          @click="window.open($t('contact_link'), '_blank')"
          >{{ $t('notification_action_contact_us') }}</GhostButton
        >
        <GhostButton
          v-if="shouldShowPartnerChangeButton"
          :class="'tw-px-9 tw-py-2'"
          size="medium"
          @click="toggleConnectivityProviderChange"
          >{{ $t('rc_campaign_type_button') }}</GhostButton
        >
      </div>
    </div>
    <ConnectivityProviderChange
      v-if="isConnectivtyProviderChangeOpen"
      :currentPartner="currentPartner?.partnerName"
      :currentPartnerCampaigns="currentPartner?.canNetCpa ? ['CPC, CPA, netCPA'] : ['CPC', 'CPA']"
    />
    <div v-if="isConnectivtyProviderChangeOpen" class="tw-flex tw-justify-end tw-mt-4">
      <GhostButton :class="'tw-px-9 tw-py-2'" size="medium" @click="toggleConnectivityProviderChange">{{
        $t('rc_bookings_modal_cancel')
      }}</GhostButton>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'

import GhostButton from '@/components/base/buttons/GhostButton.vue'
import ConnectivityProviderChange from '@/components/rateConnect/settings/ConnectivityProviderChange.vue'
import { usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { currentPartner, selectablePartners } = usePartners(selectedAccommodationId)

const isConnectivtyProviderChangeOpen = ref(false)

const toggleConnectivityProviderChange = () =>
  (isConnectivtyProviderChangeOpen.value = !isConnectivtyProviderChangeOpen.value)
const shouldShowPartnerChangeButton = computed(() => selectablePartners.value?.length > 0)
</script>
<style scoped>
.main-info {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 100%;
  }
}
.partner-logo,
.action-btn {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 50%;
  }
}
</style>
