<template>
  <div class="graph tw-max-w-[750px] tw-m-auto tw-mt-12">
    <div class="graph-legend tw-flex tw-justify-between tw-items-center tw-mb-3.5">
      <span>
        <p class="graph-header">{{ $t('rc_bidding_visibility_less') }}</p>
        <p class="graph-subtitle">{{ $t('rc_bidding_duration_more') }}</p>
      </span>
      <span class="tw-flex tw-flex-col tw-items-end">
        <p class="graph-header">{{ $t('rc_bidding_visibility_more') }}</p>
        <p class="graph-subtitle">{{ $t('rc_bidding_duration_less') }}</p>
      </span>
    </div>
    <div class="background-graphic">
      <svg
        class="tw-block sm:tw-hidden"
        width="100%"
        height="56px"
        viewBox="0 0 404 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient id="linearGradient-1" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="RC-FL---Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="RC-FL-Bidding-Goals-Mobile" transform="translate(-37.000000, -462.000000)">
            <g id="Group-10" transform="translate(16.000000, 269.000000)">
              <g id="graph-mobile" transform="translate(21.009920, 193.000000)">
                <polygon
                  id="Triangle"
                  fill="#3F57BD"
                  opacity="0.3"
                  transform="translate(201.984078, 31.000000) scale(-1, 1) translate(-201.984078, -31.000000) "
                  points="403.968155 6 403.968155 56 9.25020416e-14 56"
                ></polygon>
                <polygon
                  id="Triangle"
                  fill="#007CC2"
                  opacity="0.3"
                  points="403.968155 0 403.968155 56 9.25020416e-14 56"
                ></polygon>
                <rect id="Rectangle" fill="url(#linearGradient-1)" x="0" y="1" width="403.968155" height="56"></rect>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg
        class="tw-hidden sm:tw-block"
        width="100%"
        height="80px"
        viewBox="0 0 827 80"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient id="linearGradient-1" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-306.000000, -501.000000)">
            <g transform="translate(96.000000, 294.000000)">
              <g transform="translate(210.000000, 149.000000)">
                <g transform="translate(0.000000, 58.000000)">
                  <polygon
                    id="Triangle"
                    fill="#3F57BD"
                    opacity="0.3"
                    transform="translate(413.500000, 55.000000) scale(-1, 1) translate(-413.500000, -55.000000) "
                    points="827 30 827 80 1.89369354e-13 80"
                  ></polygon>
                  <polygon id="Triangle" fill="#007CC2" opacity="0.3" points="827 0 827 80 1.89369354e-13 80"></polygon>
                  <rect id="Rectangle" fill="url(#linearGradient-1)" x="0" y="1" width="827" height="80"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="tw-flex tw-justify-between">
      <BiddingModeSelection
        v-for="biddingMode in biddingModes"
        :key="biddingMode"
        :mode="biddingMode"
        :selectedCPCLevel="selectedCPCLevel"
        @onChange="onNewModeSelected"
      />
    </div>
  </div>
</template>

<script setup>
import BiddingModeSelection from '@/components/rateConnect/biddingGoals/BiddingModeSelection.vue'

defineProps({
  biddingModes: {
    type: Array,
    default: () => [],
  },
  selectedCPCLevel: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['onNewModeSelected'])

const onNewModeSelected = newValue => emit('onNewModeSelected', newValue)
</script>

<style scoped>
.graph-header {
  @apply tw-text-blue-800 tw-text-sm tw-font-bold;
  @media (max-width: theme('screens.md')) {
    font-size: 12px;
    line-height: 1.5rem;
  }
}
.graph-subtitle {
  @apply tw-text-purple-700 tw-text-sm tw-font-bold;
  @media (max-width: theme('screens.md')) {
    font-size: 12px;
    line-height: 1.5rem;
  }
}
.background-graphic {
  margin-bottom: -13px;
}
</style>
