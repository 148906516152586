<template>
  <div class="highlights-container-grid lg:tw-pl-10">
    <div class="highlight-logo-card">
      <i18n-t keypath="tbs_mp_social_proof_header" tag="p" class="tw-font-semibold">
        <template #trivagoLogo>
          <TrivagoLogo class="tw-w-[100px]" />
        </template>
        <template #tbs_mp_social_proof_header_JP>
          <p v-html="$t('tbs_mp_social_proof_header_JP')"></p>
        </template>
      </i18n-t>
    </div>
    <div class="highlight-card highlight-card-1">
      <DoubleBedIcon width="40" class="tw-text-blue-800 tw-shrink-0" />
      <p v-html="$t('tbs_mp_social_proof_1')"></p>
    </div>
    <div class="highlight-card highlight-card-2">
      <ClickIcon width="40" class="tw-text-blue-800 tw-shrink-0" />
      <p v-html="$t('tbs_mp_social_proof_2')"></p>
    </div>
    <div class="highlight-card highlight-card-3">
      <HotelIcon width="40" class="tw-text-blue-800 tw-shrink-0" />
      <p v-html="$t('tbs_mp_social_proof_3')"></p>
    </div>
    <div class="highlight-card highlight-card-4">
      <GlobeIcon width="40" class="tw-text-blue-800 tw-shrink-0" />
      <p v-html="$t('tbs_mp_social_proof_4')"></p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ClickIcon from '@/components/icons/Click.vue'
import DoubleBedIcon from '@/components/icons/DoubleBed.vue'
import GlobeIcon from '@/components/icons/Globe.vue'
import HotelIcon from '@/components/icons/Hotel.vue'
import TrivagoLogo from '@/components/icons/TrivagoLogo.vue'

export default {
  components: {
    ClickIcon,
    DoubleBedIcon,
    GlobeIcon,
    HotelIcon,
    TrivagoLogo,
  },
  computed: {
    ...mapState(['locale']),
  },
}
</script>

<style scoped>
.highlights-container-grid {
  display: grid;
  grid-template-areas:
    'logocard logocard'
    'card1 card2'
    'card3 card4';
  grid-template-columns: 1fr 1fr;
  row-gap: 1.5rem;
}

.highlight-logo-card {
  background-color: theme('colors.gray.100');
  grid-area: logocard;
  @apply tw-p-5;
  @apply tw-mb-5;
}

.highlight-card {
  line-height: 20px;
}
.highlight-card p {
  margin-top: 0.5rem;
}
.highlight-card-1 {
  grid-area: card1;
  @apply tw-pl-4;
}
.highlight-card-2 {
  grid-area: card2;
  @apply tw-pr-4;
  @apply tw-pl-4;
  border-left: 1px solid theme('colors.gray.300');
}
.highlight-card-3 {
  grid-area: card3;
  @apply tw-pl-4;
}
.highlight-card-4 {
  grid-area: card4;
  @apply tw-pr-4;
  @apply tw-pl-4;
  border-left: 1px solid theme('colors.gray.300');
}

@media (min-width: theme('screens.lg')) {
  .highlights-container-grid {
    grid-template-areas: 'logocard card1 card2 card3 card4';
    grid-template-columns: 1.5fr repeat(4, minmax(0, 1fr));
    row-gap: 0;
  }
  .highlight-logo-card {
    margin-bottom: 0;
    @apply tw-mr-12;
    @apply tw-pr-8;
  }
  .highlight-card + .highlight-card {
    border-left: 1px solid theme('colors.gray.300');
    @apply tw-pl-9;
  }
  .highlight-card-1 {
    padding-left: 0;
    @apply tw-pr-12;
  }
  .highlight-card-2 {
    @apply tw-pr-12;
  }
  .highlight-card-3 {
    @apply tw-pr-12;
  }
  .highlight-card-4 {
    @apply tw-pr-24;
  }
}
</style>
