// The mock is almost the same as the existing production version, except we removed 'accommodationId' from inside each object.
export const durationMockData = {
  5035108: {
    lte2days: 52,
    days34: 22,
    days56: 18,
    days78: 6,
    gte9days: 3,
  },
  129412: {
    lte2days: 44,
    days34: 30,
    days56: 13,
    days78: 13,
    gte9days: 0,
  },
  4460946: {
    lte2days: 41,
    days34: 36,
    days56: 13,
    days78: 9,
    gte9days: 2,
  },
  10611086: {
    lte2days: 35,
    days34: 33,
    days56: 15,
    days78: 14,
    gte9days: 3,
  },
  19678506: {
    lte2days: 31,
    days34: 37,
    days56: 16,
    days78: 15,
    gte9days: 2,
  },
  52064: {
    lte2days: 57,
    days34: 30,
    days56: 7,
    days78: 7,
    gte9days: 0,
  },
}
