<template>
  <div
    class="tw-mx-auto tw-max-w-sm tw-py-4 tw-px-6 tw-shadow tw-bg-white tw-border-t-8 tw-border-solid tw-border-red-800 tw-rounded-lg"
    :data-testid="dataTestId"
  >
    <h4 class="tw-py-4 tw-text-2xl">
      {{ $t(headerKey) }}
    </h4>
    <p v-if="useAppName && contentKey" class="tw-text-md tw-pb-4">
      {{ $t(contentKey, [inTextAppName]) }}
    </p>
    <p v-else-if="contentKey" class="tw-text-md tw-pb-4">
      {{ $t(contentKey) }}
    </p>
    <div v-if="allowRetry">
      <MainButton v-show="retryKey" class="tw-w-full" @click.prevent="$emit('retry')" :data-testid="buttonTestId">
        {{ $t(retryKey) }}
      </MainButton>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/base/buttons/MainButton.vue'

export default {
  components: {
    MainButton,
  },
  props: {
    appName: {
      type: String,
      default: '',
    },
    useAppName: {
      type: Boolean,
      default: false,
    },
    headerKey: {
      type: String,
      default: '',
    },
    contentKey: {
      type: String,
      default: '',
    },
    retryKey: {
      type: String,
      default: '',
    },
    allowRetry: {
      type: Boolean,
      default: true,
    },
    dataTestId: {
      type: String,
      default: '',
    },
    buttonTestId: {
      type: String,
      default: '',
    },
  },
  computed: {
    inTextAppName() {
      return this.appName ? `(${this.appName})` : ''
    },
  },
}
</script>
