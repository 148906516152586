const SET_APP = (state, app = false) => {
  if (!app.isOverlay) {
    state.loaded = false
  }

  // no app given, and it's overlay, close overlay
  if (!app.app_id && app.isOverlay) {
    state.launchedOverlayApp = false
    return
  }

  // no regular app given, close all
  if (!app.app_id && !app.isOverlay) {
    state.launchedApp = false
    state.launchedOverlayApp = false
    return
  }

  // launch as regular or overlay app
  if (!app.isOverlay) {
    state.launchedApp = app
  } else {
    state.launchedOverlayApp = app
  }
}

const SET_PATH = (state, path = '') => {
  if (typeof path === 'string' && state.launchedApp) {
    state.launchedApp.path = window.decodeURIComponent(path)
  }
}

const SET_LOADED = (state, loaded = false) => {
  state.loaded = !!loaded
}

const SET_FAILED_APP = (state, { app = false }) => {
  state.failedApp = app
}

export default {
  SET_APP,
  SET_PATH,
  SET_LOADED,
  SET_FAILED_APP,
}
