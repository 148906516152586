import axios from 'axios'

import BaseService from './BaseService.js'

class AppService extends BaseService {
  constructor() {
    super({ baseUrl: import.meta.env.VITE_GATEWAY })
  }

  getAll(selectedAccommodationId) {
    if (!this.studioToken) throw new Error('Error in GET /graphql getAllApps: No studioToken provided')
    return axios({
      method: 'POST',
      url: `${this.baseUrl}/graphql`,
      data: {
        operationName: 'getAllApps',
        query: `query getAllApps($accommodationId: Int) {
          myApps(accommodationId: $accommodationId) {
            owned {
              id
              app_id
              product_id
              slug
              name
              description
              url
              categories
              is_live
              icon_file_id
              widget_url
              widget_icon_file_id
              app_tile_color
              type {
                id
                name
              }
              status {
                id
                name
              }
            }
            available {
              id
              app_id
              product_id
              slug
              name
              description
              url
              categories
              is_live
              icon_file_id
              widget_url
              widget_icon_file_id
              app_tile_color
              type {
                id
                name
              }
              status {
                id
                name
              }
            }
          }
        }`,
        variables: {
          accommodationId: Number.isInteger(selectedAccommodationId) ? selectedAccommodationId : null,
        },
        fetchPolicy: 'network-only',
      },
      headers: {
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new AppService()
