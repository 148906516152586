<template>
  <div>
    <label :for="id" :class="{ 'tw-mb-0': !label }" class="tw-w-full">
      <p class="tw-font-bold">{{ label }}</p>
      <input
        :id="id"
        :value="searchValue"
        :type="type"
        v-bind="$attrs"
        class="tw-appearance-none tw-border tw-w-full focus:tw-outline-0 tw-bg-white tw-text-gray-900 focus:tw-border-blue-900 focus:tw-text-blue-900 tw-py-2 tw-px-3 tw-rounded-lg"
        :class="`${sizeClass} tw-border-gray-500`"
        @input="triggerUpdate"
        @focus="showResultsOnFocus"
      />
    </label>
    <small v-if="error" class="tw-text-red-800">{{ error }}</small>
    <BaseCard v-if="isOpen" no-padding class="tw-max-h-[250px] tw-overflow-auto">
      <ul>
        <li
          v-for="(item, index) in suggestedOptions"
          :key="index"
          class="tw-p-2 hover:tw-cursor-pointer hover:tw-bg-gray-100 tw-border-b tw-border-gray-100"
          @click.stop="selectNewValue(item)"
          @keydown.enter="selectNewValue(item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </BaseCard>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import CountriesService from '@/services/CountriesService.js'

export default {
  name: 'AutocompleteField',
  components: { BaseCard },
  props: {
    id: {
      type: String,
      default: 'autocomplete',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      isOpen: false,
      suggestedOptions: [],
      searchValue: this.value,
      isLoading: false,
    }
  },
  computed: {
    sizeClass() {
      if (this.size === 'small') return 'tw-py-1  tw-rounded'
      if (this.size === 'medium') return 'tw-py-[0.44rem] tw-rounded-lg'
      else return 'tw-py-3 tw-rounded-lg'
    },
  },
  watch: {
    options: function (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.suggestedOptions = val
        this.isLoading = false
      }
    },
  },
  created() {
    this.search = debounce(this.search, 500)
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
      }
    },
    selectNewValue(item) {
      this.$emit('onSelect', item)
      this.isOpen = false
      this.searchValue = item.name
    },
    showResultsOnFocus() {
      this.search(this.searchValue)
      this.isOpen = true
    },
    search(value) {
      if (value.length > 0) {
        CountriesService.search({ searchTerm: value }).then(res => {
          this.suggestedOptions = res.data.map(city => {
            return {
              id: city.categoryId,
              name: city.name,
              label: city.name + ' (' + city.country + ')',
            }
          })
        })
      }
    },
    triggerUpdate(event) {
      this.searchValue = event.target.value
      this.search(event.target.value)
    },
  },
}
</script>
<style scoped>
label {
  @apply tw-mb-0;
}
</style>
