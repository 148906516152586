<template>
  <section
    class="tw-flex tw-py-12 tw-justify-between tw-px-10 tw-max-w-screen-xxl tw-mx-auto lg:tw-flex-row tw-flex-col tw-mb-[20px]"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
      class="tw-max-w-[380px] tw-flex tw-flex-col tw-items-center tw-justify-center tw-m-auto tw-h-[245px]"
    >
      <img :src="item.image" :width="item.width" :alt="$t(item.title)" class="tw-h-[113px]" />
      <h3 class="tw-text-2xl tw-font-bold tw-my-2.5 tw-text-gray-900 tw-text-center">{{ $t(item.title) }}</h3>
      <p class="tw-text-base tw-text-center tw-text-gray-900 tw-grow">{{ $t(item.text) }}</p>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

const BenefitIcon1 = new URL('/src/assets/img/rate-connect-landing/BenefitIcon1.svg', import.meta.url)
const BenefitIcon2 = new URL('/src/assets/img/rate-connect-landing/BenefitIcon2.svg', import.meta.url)
const BenefitIcon3 = new URL('/src/assets/img/rate-connect-landing/BenefitIcon3.svg', import.meta.url)

const data = ref([
  {
    text: 'rc_mp_benefit_text_1',
    title: 'rc_mp_benefit_header_1',
    width: 120,
    image: BenefitIcon1,
  },
  {
    text: 'rc_mp_benefit_text_2',
    title: 'rc_mp_benefit_header_2',
    width: 120,
    image: BenefitIcon2,
  },
  {
    text: 'rc_mp_benefit_text_3',
    title: 'rc_mp_benefit_header_3',
    width: 154,
    image: BenefitIcon3,
  },
])
</script>
