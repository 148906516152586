<template>
  <div class="tw-bg-gray-100 tw-flex tw-py-20 tw-mb-10 tw-mb-24 tw-px-4">
    <div class="tw-flex tw-flex-col tw-justify-center tw-w-full md:tw-items-center">
      <h2 class="tw-text-[32px] tw-font-bold tw-pb-3 tw-text-blue-800">
        {{ $t('studio_plus_mp_subheader') }}
      </h2>

      <p class="tw-text-base">{{ $t('studio_plus_mp_comparison_header') }}</p>
      <div class="tw-mt-20 tw-flex tw-flex-col md:tw-flex-row tw-gap-5 tw-justify-center">
        <div
          class="base-shadow tw-border md:tw-w-[350px] tw-border-gray-300 tw-rounded-xl tw-px-4 tw-pb-8 tw-pt-9 tw-flex-1 tw-relative tw-w-full tw-mx-auto md:tw-mx-0"
        >
          <h4 class="tw-font-bold tw-text-lg tw-border-b-2 tw-border-gray-300 tw-pb-4 tw-mb-6">
            {{ $t('studio_plus_mp_basic_features_header') }}
          </h4>
          <ul class="tw-flex tw-flex-col tw-gap-2 tw-mt-5 tw-px-1">
            <li v-for="feature in features.basic" :key="feature" class="tw-flex tw-justify-between tw-items-center">
              <span>{{ $t(feature) }}</span>
              <CheckMark class="tw-h-[20px] tw-w-[20px] tw-bg-green-200 tw-p-[4px] tw-rounded-full tw-fill-green-700" />
            </li>
          </ul>
        </div>
        <div
          class="strong-shadow gradient-border md:tw-w-[350px] tw-rounded-xl tw-bg-white tw-px-4 tw-pb-8 tw-pt-9 tw-flex-1 tw-relative tw-w-full tw-mx-auto md:tw-mx-0"
        >
          <h4 class="tw-font-bold tw-text-lg tw-border-b-2 tw-border-gray-300 tw-pb-4 tw-mb-6">
            {{ $t('Business Studio+ ') }}
          </h4>
          <ul class="tw-flex tw-flex-col tw-gap-2 tw-mt-5 tw-px-1">
            <li v-for="feature in features.basic" :key="feature" class="tw-flex tw-justify-between tw-items-center">
              <span>{{ $t(feature) }}</span>
              <CheckMark class="tw-h-[20px] tw-w-[20px] tw-bg-green-200 tw-p-[4px] tw-rounded-full tw-fill-green-700" />
            </li>
            <p class="tw-font-bold tw-mt-4">{{ $t('studio.plus.compare.whatsnew') }}</p>
            <li v-for="feature in features.plus" :key="feature" class="tw-flex tw-justify-between tw-items-center">
              <span>{{ $t(feature) }}</span>
              <CheckMark class="tw-h-[20px] tw-w-[20px] tw-bg-green-200 tw-p-[4px] tw-rounded-full tw-fill-green-700" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CheckMark from '@/components/icons/CheckMark.vue'

const features = {
  basic: ['studio_plus_mp_basic_feature_1', 'studio_plus_mp_basic_feature_2', 'studio_plus_mp_basic_feature_3'],
  plus: [
    'studio_plus_mp_plus_feature_1',
    'studio_plus_mp_plus_feature_2',
    'studio_plus_mp_plus_feature_3',
    'studio_plus_mp_plus_feature_4',
    'studio_plus_mp_plus_feature_5',
    'studio_plus_mp_plus_feature_6',
    'studio_plus_mp_plus_feature_7',
  ],
}
</script>

<style scoped>
.strong-shadow {
  box-shadow: 7.3626627922058105px 8.064948081970215px 8.064948081970215px 0px #6c707a26;
}
.base-shadow {
  box-shadow: 0px 3.5577166080474854px 3.5577166080474854px 0px #6c707a26;
}
.gradient-border {
  position: relative;
}
.gradient-border::before {
  @apply tw-rounded-xl;

  content: '';
  position: absolute;
  inset: 0;

  padding: 1px;
  background: linear-gradient(180deg, #007cc2 0%, #c94a30 48.96%, #f6ab3f 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
</style>
