<template>
  <div class="tw-min-h-[630px]">
    <div v-if="isLoading" class="tw-h-full tw-bg-gray-300 tw-animate-pulse tw-rounded-lg" />
    <div v-else-if="isError" class="tw-h-full" />
    <div v-else>
      <BarChart
        container="vp-countries-container"
        :series="dataFormattedForGraph"
        :categories="columnsFormattedForGraph"
        :isWaitingMode="isWaitingMode"
        class="tw-pb-4 tw-border-b-2 tw-border-b-gray-200 tw-h-[550px]"
      />
      <div class="tw-pl-4 tw-mt-9 tw-flex tw-items-center tw-gap-2">
        <span class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-graph-blue tw-inline-block" />
        <span>{{ $t('Search results originating from this country') }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BarChart from '@/components/visitorsProfile/common/BarChart.vue'

import { selectedAccommodationId } from '@/layouts/queries'

const { t } = useI18n()
const props = defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  isLoading: Boolean,
  isError: Boolean,
  visitorsProfileCountries: Object,
  isWaitingMode: Boolean,
})

const columnsFormattedForGraph = computed(() => {
  if (!props.visitorsProfileCountries || !selectedAccommodationId.value) return ['', '', '', '', '']

  const data = props.visitorsProfileCountries.slice(0, 5)

  const graphData = data.sort((a, b) => (a.share > b.share ? -1 : 1))

  const categories = graphData.map(item => item.locale)

  return categories?.map(item => t(`form.countries.${item}`))
})

const dataFormattedForGraph = computed(() => {
  if (!props.visitorsProfileCountries || !selectedAccommodationId.value) return [{ data: [0, 0, 0, 0, 0] }]

  const data = props.visitorsProfileCountries.slice(0, 5)

  const graphData = data.sort((a, b) => (a.share > b.share ? -1 : 1))

  const simplifiedSeries = graphData.map(item => item.share)
  return [{ data: simplifiedSeries }]
})
</script>
