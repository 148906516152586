<template>
  <CustomModal
    :is-modal-open="isOpen"
    @toggleModal="closeModal"
    @onClickAway="closeModal"
    :overlay="'light'"
    :with-header="false"
    :with-footer="false"
    :custom-classes="'tw-min-h-[20vh] tw-max-w-[998px] md:tw-min-h-0 tw-rounded-md'"
    no-padding
  >
    <div v-if="campaign !== undefined && campaignType !== null">
      <div class="tw-px-11 tw-py-5">
        <img :src="campaign.image" :alt="$t(campaign.title)" />
        <h2 class="tw-py-9 tw-font-bold tw-leading-[50px] tw-text-[50px] tw-text-gray-900">
          {{ $t(campaign?.title) }}
        </h2>
        <p class="tw-text-gray-900 tw-text-base">{{ $t(campaign.subtitle) }}</p>
      </div>
      <div class="tw-bg-gray-100 tw-p-8 tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
          <div
            v-for="(item, index) in campaign.cards"
            :key="index"
            class="tw-bg-white tw-rounded-xl card-shadow tw-w-full lg:tw-w-[28%] tw-h-auto lg:tw-min-h-[240px] tw-mb-2 lg:tw-mb-0 tw-py-6 tw-px-8"
          >
            <BaseIcon :height="24" :width="24" :icon-name="item.icon" class="tw-scale-125 tw-mb-2.5">
              <RateInsights v-if="item.icon === 'rate-insights'" />
              <SettingsBlue v-if="item.icon === 'settings'" />
              <Global v-if="item.icon === 'global'" />
            </BaseIcon>
            <p class="tw-text-base" v-html="$t(item.text)" />
          </div>
        </div>
        <div class="tw-mt-6">
          <p class="tw-text-sm tw-text-gray-900 tw-text-center" v-if="campaignType === 'ncpa'">
            {{ $t('rc_mp_ncpa_footnote') }}
          </p>
        </div>
      </div>
      <div class="tw-p-11">
        <h4 class="tw-text-xl tw-text-gray-900 tw-font-bold tw-mb-4">{{ $t(campaign.setup_title) }}</h4>
        <p class="link-text" v-html="$t(campaign.setup_text)" />
        <p class="tw-mt-4 setup" v-html="$t(campaign.setup)" />
        <p class="tw-mt-4" v-html="$t(campaign.setup_final)" />
        <p class="tw-mt-4">{{ $t(campaign.setup_hint) }}</p>
        <MainButton class="tw-mt-4 lg:tw-mt-6" @click="onRegisterClick" @keydown.enter="onRegisterClick">{{
          $t(campaign.setup_cta)
        }}</MainButton>
      </div>
      <div class="tw-p-11">
        <h3 class="tw-font-bold tw-text-2xl tw-text-gray-900 tw-mb-4">{{ $t('tbs_mp_faq_header') }}</h3>
        <div class="tw-w-full tw-flex tw-flex-wrap">
          <div
            v-for="(question, index) in campaign.faq"
            :key="index"
            class="faq tw-flex tw-bg-gray-200 tw-rounded-lg tw-justify-between tw-items-center tw-p-4 tw-mb-4 hover:tw-text-blue-800"
          >
            <span v-html="$t(question)" />
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              :viewbox-height="24"
              :viewbox-width="24"
              class="tw-shrink-0"
            >
              <ChevronRight />
            </BaseIcon>
          </div>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script setup>
import CustomModal from '@/components/base/CustomModal.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import RateInsights from '@/components/icons/RateInsights.vue'
import SettingsBlue from '@/components/icons/SettingsBlue.vue'
import Global from '@/components/icons/Global.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

defineProps({
  isOpen: Boolean,
  closeModal: Function,
  campaignType: [String, null],
  campaign: [Object],
})

const emit = defineEmits(['onRegisterClick'])
const onRegisterClick = e => {
  emit('onRegisterClick', e)
}
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.link-text :deep(a) {
  @apply tw-text-blue-800;
}
.setup :deep(ul) {
  list-style: circle;
  margin: 15px;
}
.faq {
  flex: 49.5% 0 0;
  @media (max-width: theme('screens.lg')) {
    flex: 100% 0 0;
  }
}
.faq:nth-child(odd) {
  margin-right: 1%;
  @media (max-width: theme('screens.lg')) {
    margin-right: 0;
  }
}
</style>
