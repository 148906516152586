<template>
  <section class="tw-bg-gray-100">
    <div
      class="tw-max-w-screen-xxl tw-py-[40px] lg:tw-py-24 tw-px-10 tw-mx-auto tw-flex tw-justify-between lg:tw-flex-row tw-flex-col"
    >
      <div class="element-width tw-mb-2 lg:tw-mb-0 lg:tw-mr-[41px]">
        <p
          class="tw-text-[40px] tw-leading-[42px] lg:tw-text-[50px] lg:tw-leading-[50px] tw-text-blue-800 tw-font-bold"
        >
          {{ $t('rc_mp_rc_description_header') }}
        </p>
      </div>
      <div class="element-width lg:tw-ml-[41px]">
        <p class="tw-text-base tw-text-gray-900">
          {{ $t('rc_mp_rc_description_subtext') }}
        </p>
      </div>
    </div>
  </section>
</template>
<style scoped>
.element-width {
  width: calc(50% - 41px);
  @media (max-width: theme('screens.lg')) {
    width: 100%;
  }
}
</style>
