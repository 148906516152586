<template>
  <section class="tw-flex tw-flex-col tw-py-12 tw-justify-between tw-px-10 tw-max-w-screen-xxl tw-mx-auto">
    <h3
      class="tw-leading-[42px] lg:tw-leading-[50px] tw-w-full lg:tw-w-3/4 tw-text-[40px] lg:tw-text-[50px] tw-text-gray-900 tw-font-bold tw-mb-14"
    >
      {{ $t('rc_mp_social_proof_header') }}
    </h3>
    <div id="slider" class="tw-w-full tw-h-[630px] lg:tw-h-[440px] tw-relative tw-mb-16">
      <transition-group tag="div" :name="transitionName" class="slides-group">
        <div
          :key="current"
          class="slide tw-bg-white tw-w-full tw-h-full lg:tw-h-[440px] tw-absolute tw-top-0 tw-left-0 tw-flex tw-flex-col lg:tw-flex-row"
        >
          <div class="lg:tw-order-1 tw-order-2">
            <img :src="slides[current].image" :alt="slides[current].title" class="slider-image tw-rounded-xl" />
          </div>
          <div
            class="lg:tw-order-2 tw-order-1 tw-w-full lg:tw-w-1/2 tw-flex tw-justify-center tw-flex-col tw-px-0 lg:tw-px-16 tw-mb-8 lg:tw-mb-0"
          >
            <h3
              class="tw-text-blue-800 tw-font-bold tw-text-[24px] tw-leading-[26px] lg:tw-text-[40px] lg:tw-leading-[40px] tw-mb-8"
            >
              {{ $t(slides[current].title) }}
            </h3>
            <p class="tw-text-gray-900 tw-text-xl">{{ $t(slides[current].text) }}</p>
            <p class="tw-mt-16" v-html="$t(slides[current].author)" />
          </div>
        </div>
      </transition-group>
      <div class="tw-absolute buttons tw-w-screen lg:tw-w-full tw-flex tw-justify-between">
        <div class="btn tw-rounded-lg" aria-label="Previous slide" @keypress.left="previous()" @click="previous()">
          <BaseIcon icon-name="chevron-right" :height="24" :width="24" class="tw-rotate-180">
            <ChevronRight />
          </BaseIcon>
        </div>
        <div class="btn btn-next tw-rounded-lg" aria-label="Next slide" @keypress.right="next()" @click="next()">
          <BaseIcon icon-name="chevron-right" :height="24" :width="24">
            <ChevronRight />
          </BaseIcon>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import TouchEvent from '@/utils/touchEvents.js'

const Testimonial_slider_image1 = new URL(
  '/src/assets/img/rate-connect-landing/Testimonial_slider_image1.webp',
  import.meta.url
)
const Testimonial_slider_image2 = new URL(
  '/src/assets/img/rate-connect-landing/Testimonial_slider_image2.webp',
  import.meta.url
)
const Testimonial_slider_image3 = new URL(
  '/src/assets/img/rate-connect-landing/Testimonial_slider_image3.webp',
  import.meta.url
)
const Testimonial_slider_image4 = new URL(
  '/src/assets/img/rate-connect-landing/Testimonial_slider_image4.webp',
  import.meta.url
)
const Testimonial_slider_image5 = new URL(
  '/src/assets/img/rate-connect-landing/Testimonial_slider_image5.webp',
  import.meta.url
)
const Testimonial_slider_image6 = new URL(
  '/src/assets/img/rate-connect-landing/Testimonial_slider_image6.jpeg',
  import.meta.url
)

const current = ref(0)
const touchEvent = ref(null)
const transitionName = ref('fade')
const slides = ref([
  {
    image: Testimonial_slider_image1,
    title: 'rc_mp_testimonial_header',
    text: 'rc_mp_testimonial_1_text',
    author: 'rc_mp_testimonial_1_hotelier',
  },
  {
    image: Testimonial_slider_image2,
    title: 'rc_mp_testimonial_header',
    text: 'rc_mp_testimonial_2_text',
    author: 'rc_mp_testimonial_2_hotelier',
  },
  {
    image: Testimonial_slider_image3,
    title: 'rc_mp_testimonial_header',
    text: 'rc_mp_testimonial_3_text',
    author: 'rc_mp_testimonial_3_hotelier',
  },
  {
    image: Testimonial_slider_image4,
    title: 'rc_mp_testimonial_header',
    text: 'rc_mp_testimonial_4_text',
    author: 'rc_mp_testimonial_4_hotelier',
  },
  {
    image: Testimonial_slider_image5,
    title: 'rc_mp_testimonial_header',
    text: 'rc_mp_testimonial_5_text',
    author: 'rc_mp_testimonial_5_hotelier',
  },
  {
    image: Testimonial_slider_image6,
    title: 'rc_mp_testimonial_header',
    text: 'rc_mp_testimonial_6_text',
    author: 'rc_mp_testimonial_6_header',
  },
])
onMounted(() => {
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})
const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    previous()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}

const next = () => {
  if (slides.value.length > 1) {
    transitionName.value = 'slide-next'
    current.value = current.value === 2 ? 0 : current.value + 1
    const first = slides.value.shift()
    slides.value = slides.value.concat(first)
  }
}

const previous = () => {
  if (slides.value.length > 1) {
    transitionName.value = 'slide-prev'
    const last = slides.value.pop()
    current.value = current.value === 0 ? 2 : current.value - 1
    slides.value = [last].concat(slides.value)
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-prev-enter,
.slide-next-leave-to {
  transform: translate(-100%);
}
.slide-prev-leave-to,
.slide-next-enter {
  transform: translate(100%);
}

.btn {
  @apply tw-w-[37px] tw-h-[37px] tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-z-[9] tw-bg-gray-200;
  transition: transform 0.3s ease-in-out;
  display: flex;
  user-select: none;
}
.buttons {
  left: -2.5rem;
  top: calc(50% - 35px);
  @media (max-width: theme('screens.md')) {
    top: 60%;
  }
}
.slider-image {
  width: 547px;
  height: 440px;
  object-fit: fill;
  @media (max-width: theme('screens.lg')) {
    width: calc(100vw - (2 * 2.5rem));
    height: 100%;
    margin-bottom: 4rem;
    max-height: 450px;
  }
}
</style>
