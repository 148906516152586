import axios from 'axios'
import * as Sentry from '@sentry/vue'

function OAuthJLoService() {
  this.clientId = 'de8c143e51e4bb3913d26db3c1be34b04c3f221f5ad4f418e33209363898c1fe'
  this.authorizeEndpoint = `${import.meta.env.VITE_OAUTH_DOMAIN}/server/oauth2/authorize`
  this.tokenEndpoint = `${import.meta.env.VITE_OAUTH_DOMAIN}/server/oauth2/token`
  this.logoutEndpoint = `${import.meta.env.VITE_OAUTH_DOMAIN}/server/logout`

  this.authorizeResponse = {
    code: null,
    state: null,
  }

  // These never get re-assigned
  this.authorizeFixedParams = {
    client_id: this.clientId,
    redirect_uri: `${import.meta.env.VITE_STUDIO_CLIENT_URL}/auth/login`,
    response_type: 'code',
    code_challenge_method: 'S256',
  }

  // These are default values only and will be replaced when generating the Auth URI
  this.authorizeVariableParams = {
    locale: 'en-US',
    state: null,
    code_challenge: null,
  }

  this.generateAuthUri = async function (locale = 'en-US', appStateData = {}) {
    this.reset()
    this.authorizeVariableParams.locale = locale
    this.setStateParam(appStateData)
    this.authorizeVariableParams.code_challenge = await this.generateCodeChallenge()
    const uriParams = generateUriParams({ ...this.authorizeFixedParams, ...this.authorizeVariableParams })
    return `${this.authorizeEndpoint}?${uriParams}`
  }

  this.generateLogoutUri = function () {
    const url = new URL(this.logoutEndpoint)
    url.searchParams.append('continue', `${import.meta.env.VITE_STUDIO_CLIENT_URL}/getstarted`)
    return url
  }

  this.requestToken = async function (locale = 'en-US') {
    try {
      const codeVerifier = window.localStorage.getItem('code_verifier')
      window.localStorage.removeItem('code_verifier')
      const requestUri = `${this.tokenEndpoint}?locale=${locale}`
      const result = await axios.post(
        requestUri,
        new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: this.clientId,
          code: this.authorizeResponse.code,
          code_verifier: codeVerifier,
          redirect_uri: this.authorizeFixedParams.redirect_uri,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      return result?.data
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(error)
      })
    }
  }

  this.requestTokenRefresh = async function (refreshToken) {
    try {
      const result = await axios.post(
        this.tokenEndpoint,
        new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: this.clientId,
          refresh_token: refreshToken,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      return result?.data
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(error)
      })
    }
  }

  this.setStateParam = function (appStateData) {
    try {
      const CSRFValue = generateRandomString(20)
      const b64State = window.btoa(JSON.stringify({ ...appStateData, CSRFValue }))
      window.localStorage.setItem('state', b64State)
      this.authorizeVariableParams.state = window.encodeURIComponent(b64State)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(error)
      })
    }
  }

  this.getAuthorizeResponse = function (responseUrl) {
    const parsedUrl = new URL(responseUrl)
    this.authorizeResponse.code = parsedUrl.searchParams.get('code')
    this.authorizeResponse.state = parsedUrl.searchParams.get('state')
    const state = JSON.parse(window.atob(window.decodeURIComponent(this.authorizeResponse.state)))
    if (!isStateResponseValid(this.authorizeResponse.state)) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(new Error('State response validation failed'))
      })
      return
    }
    return state
  }

  this.generateCodeChallenge = async function () {
    try {
      const codeVerifier = generateRandomString()
      window.localStorage.setItem('code_verifier', codeVerifier)
      const codeDigested = await digestSHA256(codeVerifier)
      return window
        .btoa(String.fromCharCode.apply(null, new Uint8Array(codeDigested)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '')
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(error)
      })
    }
  }

  this.reset = function () {
    this.code = null
    this.state = null
    window.localStorage.removeItem('state')
    window.localStorage.removeItem('code_verifier')
  }

  /**
   * Get token details from localstorage
   */
  this.getTokenFromLocalStorage = function () {
    let result

    try {
      result = window.localStorage.getItem('jlo_token')

      if (result) {
        result = JSON.parse(result)
      }
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(error)
      })
    }

    return result
  }

  function digestSHA256(plainText) {
    const encodedString = new TextEncoder().encode(plainText)
    return window.crypto.subtle.digest('SHA-256', encodedString)
  }

  function generateRandomString(length = 50) {
    const typedArray = new Uint8Array(Math.floor(length / 2))
    window.crypto.getRandomValues(typedArray)
    const strArray = Array.from(typedArray, num => num.toString(16).padStart(2, '0'))
    return strArray.join('')
  }

  function generateUriParams(params) {
    return Object.entries(params)
      .reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '')
      .slice(1)
  }

  function isStateResponseValid(responseState) {
    try {
      const savedState = window.localStorage.getItem('state')
      window.localStorage.removeItem('state')
      return savedState === responseState
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'OAuth2JLoService')
        Sentry.captureException(error)
      })
      return false
    }
  }
}

export default new OAuthJLoService()
