<template>
  <div class="tw-full tw-mb-16 tw-relative">
    <Slider :min="min" :max="max" :value="sliderValue" @onInput="sliderChange" :marks="recommendedMarks" />
    <div
      class="recommendation-info tw-flex tw-justify-center"
      :style="{ marginLeft: recMinMark + '%', width: recWidth + '%' }"
    >
      <p class="tw-text-center tw-flex tw-text-xs tw-mt-[10px]">
        {{ $t('rc_budget_slider_recommended_range') }}
        <UiTooltip
          tooltip-position="top"
          :label="$t('/snippets/hgw/rc_budget_slider_range_tooltip.inc')"
          class="tw-cursor-pointer tw-ml-1"
        >
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-blue-900 tw-h-[14px] tw-w-[14px]"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </p>
    </div>
  </div>
</template>
<script setup>
import { computed, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import cloneDeep from 'lodash/cloneDeep'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import Slider from '@/components/base/inputs/Slider.vue'

const { n } = useI18n()
const props = defineProps({
  currency: {
    type: String,
    default: 'EUR',
  },
  budget: {
    type: Number,
    required: true,
    default: 0,
  },
  defaultBudget: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    required: true,
  },
  max: {
    type: Number,
    required: true,
  },
  recommendedMin: {
    type: Number,
    required: true,
  },
  recommendedMax: {
    type: Number,
    required: true,
  },
  status: {
    type: String,
    default: () => null,
  },
})
const emit = defineEmits(['update'])
const update = value => emit('update', value)
const sliderValue = ref(cloneDeep(props.budget))
const stepWidth = computed(() => {
  return 100 / (props.max - props.min)
})
const recMinMark = computed(() => {
  return (props.recommendedMin - props.min) * stepWidth.value + 0.7
})
const recMaxMark = computed(() => {
  return (props.recommendedMax - props.min) * stepWidth.value - 0.4
})
const recWidth = computed(() => {
  return recMaxMark.value - recMinMark.value
})
const recommendedMarks = computed(() => {
  return [
    {
      value: props.recommendedMin,
      label: `${props.currency} ${n(props.recommendedMin, 'currencyCodeRounded')}`,
    },
    {
      value: props.recommendedMax,
      label: `${props.currency} ${n(props.recommendedMax, 'currencyCodeRounded')}`,
    },
  ]
})

watch(
  () => props.budget,
  newBudget => {
    if (newBudget < props.min) {
      sliderValue.value = props.min
    } else {
      sliderValue.value = newBudget
    }
  }
)

const sliderChange = val => {
  update(val)
}
</script>
<style scoped>
.recommendation-info {
  border-top: 1px dashed theme('colors.blue.500');
  position: relative;
  margin-top: 34px;
}
.recommendation-info::before,
.recommendation-info::after {
  border-left: 1px solid theme('colors.blue.500');
  content: '';
  height: 15px;
  position: absolute;
  top: -15px;
  width: 0;
}
.recommendation-info::before {
  left: 0;
}
.recommendation-info::after {
  right: 0;
}
</style>
