<template>
  <div class="tw-mb-6">
    <h3 class="tw-font-bold tw-mb-1">{{ $t('business_studio_plus_checkout_summary') }}</h3>
    <BaseCard noPadding class="tw-px-4 tw-py-5" v-if="!isLoading || !isFetching">
      <div class="tw-flex tw-items-start tw-flex-col tw-border-b tw-mb-6 tw-pb-5 tw-border-gray-300">
        <p class="tw-font-bold tw-text-xl">{{ $t('studio_plus_mp_bs_plus_features_header') }}</p>
        <p v-if="selectedPaymentType === 'annual'" class="tw-mb-6">
          {{ $t('business_studio_plus_annual_subscription') }}
        </p>
        <p v-if="selectedPaymentType === 'monthly'" class="tw-mb-6">
          {{ $t('business_studio_plus_checkout_page_monthly_price') }}
        </p>
        <div
          v-if="selectedPaymentType === 'annual'"
          class="tw-py-2 tw-px-4 tw-bg-red-800 tw-font-bold tw-text-xs tw-text-white tw-rounded-tl-xl tw-rounded-br-xl"
        >
          {{ $t('business_studio_plus_savings', { DiscountPercentage: '15' }) }}
        </div>
        <div v-if="selectedPaymentType === 'annual'">
          <p class="tw-font-light current-price">
            <i18n-t keypath="business_studio_plus_price_monthly" tag="p" scope="global">
              <template #MonthlyPrice>
                <span>{{ `${CURRENCIES[currency]}${$n(annualPriceWithoutTax / 100, 'currencyCode')}` }}</span>
              </template>
            </i18n-t>
          </p>
          <p class="tw-font-light tw-line-through">
            <i18n-t keypath="business_studio_plus_price_no_discount" tag="p" scope="global">
              <template #AnnualPrice>
                <span>{{ `${CURRENCIES[currency]}${$n(monthlyPriceWithoutTax / 100, 'currencyCode')}` }}</span>
              </template>
            </i18n-t>
          </p>
        </div>
        <div v-if="selectedPaymentType === 'monthly'">
          <p class="tw-font-light current-price">
            <i18n-t keypath="business_studio_plus_price_monthly" tag="p" scope="global">
              <template #MonthlyPrice>
                <span>{{ `${CURRENCIES[currency]}${$n(monthlyPriceWithoutTax / 100, 'currencyCode')}` }}</span>
              </template>
            </i18n-t>
          </p>
        </div>
        <p v-if="selectedPaymentType === 'annual'" class="tw-text-sm tw-text-gray-500">
          {{ $t('business_studio_plus_annual_billing') }}
        </p>
        <p v-if="selectedPaymentType === 'monthly'" class="tw-text-sm tw-text-gray-500">
          {{ $t('business_studio_plus_monthly_billing') }}
        </p>
      </div>

      <div class="tw-flex tw-justify-between tw-mb-2 tw-text-sm">
        <p>{{ $t('business_studio_plus_price') }}</p>
        <span v-if="selectedPaymentType === 'annual'">{{
          `${CURRENCIES[currency]}${$n((monthlyPriceWithoutTax * 12) / 100, 'currencyCode')}`
        }}</span>
        <span v-if="selectedPaymentType === 'monthly'">{{
          `${CURRENCIES[currency]}${$n(monthlyPriceWithoutTax / 100, 'currencyCode')}`
        }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-mb-2 tw-text-sm" v-if="selectedPaymentType === 'annual'">
        <p>
          {{ $t('business_studio_plus_discount_applied') }}
          <span class="tw-bg-red-800 tw-text-white tw-px-2 tw-py-1 tw-rounded-full tw-font-bold tw-text-xs">{{
            '15%'
          }}</span>
        </p>
        <span>{{ `- ${CURRENCIES[currency]}${$n(annualDiscount / 100, 'currencyCode')}` }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-text-sm">
        <p>{{ $t('check_out_page_VAT') }}</p>
        <span v-if="selectedPaymentType === 'annual'">{{
          `${CURRENCIES[currency]}${$n((annualTaxAmount * 12) / 100, 'currencyCode')}`
        }}</span>
        <span v-if="selectedPaymentType === 'monthly'">{{
          `${CURRENCIES[currency]}${$n(monthlyTaxAmount / 100, 'currencyCode')}`
        }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-font-bold tw-mt-6">
        <p>{{ $t('business_studio_payment_due') }}</p>
        <span v-if="selectedPaymentType === 'annual'">{{
          `${CURRENCIES[currency]}${$n((annualPriceWithTax * 12) / 100, 'currencyCode')}`
        }}</span>
        <span v-if="selectedPaymentType === 'monthly'">{{
          `${CURRENCIES[currency]}${$n(monthlyPriceWithTax / 100, 'currencyCode')}`
        }}</span>
      </div>
    </BaseCard>
    <BaseCard v-else>
      <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[221px] tw-w-full tw-mb-4" aria-hidden="true" />
      <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full tw-mb-4" aria-hidden="true" />
      <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full tw-mb-4" aria-hidden="true" />
      <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full" aria-hidden="true" />
      <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[22px] tw-w-full" aria-hidden="true" />
    </BaseCard>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import { useStudioPlusPrice } from '@/components/payment/queries'
import { CURRENCIES } from '@/constants/currencies.js'

const store = useStore()
const props = defineProps({
  noTaxId: Boolean,
  countryCode: String || null,
  countryState: String,
  zipCode: String || null,
  accommodationId: Number || String,
  taxId: String,
  selectedPaymentType: String,
})

const currency = computed(() => store.state.currency)
const countryCode = computed(() => props.countryCode)
const noTaxId = computed(() => props.noTaxId)
const zipCode = computed(() => props.zipCode)
const selectedAccommodationId = { value: props.accommodationId }
const countryState = computed(() => props.countryState)
const taxId = computed(() => props.taxId)

const { isLoading, isFetching, studioPlusPriceAnnual, studioPlusPriceMonthly } = useStudioPlusPrice(
  selectedAccommodationId,
  countryCode,
  zipCode,
  countryState,
  noTaxId.value ? null : taxId
)

const annualPriceWithoutTax = computed(() => studioPlusPriceAnnual.value?.priceBeforeTaxes ?? 0)
const monthlyPriceWithoutTax = computed(() => studioPlusPriceMonthly.value?.priceBeforeTaxes ?? 0)

const annualTaxAmount = computed(() => studioPlusPriceAnnual.value?.taxAmount ?? 0)
const monthlyTaxAmount = computed(() => studioPlusPriceMonthly.value?.taxAmount ?? 0)

const annualPriceWithTax = computed(() => studioPlusPriceAnnual.value?.price ?? 0)
const monthlyPriceWithTax = computed(() => studioPlusPriceMonthly.value?.price ?? 0)

const annualDiscount = computed(() => {
  if (props.selectedPaymentType === 'annual') {
    return monthlyPriceWithoutTax.value * 12 - annualPriceWithoutTax.value * 12
  }
  return null
})
</script>

<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
</style>
