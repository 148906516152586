<template>
  <div :id="container"></div>
</template>
<script setup>
import { watch, nextTick, computed } from 'vue'
import { useStore } from 'vuex'
import Highcharts from 'highcharts'

import * as Sentry from '@sentry/vue'

const store = useStore()
const props = defineProps({
  container: String,
  categories: Array,
  series: Array,
  isWaitingMode: Boolean,
})

const language = computed(() => store.state.locale.language)

const drawChart = (container, series, categories, isWaitingMode, language) => {
  try {
    if (!container || !series || !categories) return
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(props.container)) return

      Highcharts.chart(props.container, {
        title: null,

        chart: {
          alignTicks: true,
          type: 'bar',
          scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1,
          },
          plotBorderWidth: 0,
          style: {
            fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
          },
        },
        credits: {
          enabled: false,
        },
        series: series,
        xAxis: {
          categories: categories,
          title: {
            text: null,
          },
          gridLineColor: '#E5F2F6',
          lineColor: '#E5F2F6',
          tickColor: '#E5F2F6',
          tickLength: 0,
          gridLineWidth: 1,
          crosshair: {
            dashStyle: 'solid',
          },
          labels: {
            style: { fontSize: 16, color: '#000' },
            align: 'left',
            overflow: 'justify',
            reserveSpace: true,
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 25,
          gridLineColor: '#E5F2F6',
          type: 'percentage',
          title: false,
          labels: {
            padding: 30,
            formatter: function () {
              return `<p class="tw-text-gray-700 tw-text-xs">
                ${new Intl.NumberFormat(language.value, { style: 'percent' }).format(this.value / 100)}
                </p >`
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: '4px',
            dataLabels: {
              enabled: true,
              align: 'center',
              x: 50,
              y: 0,
              style: {
                fontSize: '24px',
              },
              useHTML: true,
              formatter: function () {
                return `
                  <p class="tw-flex tw-text-blue-900">${
                    isWaitingMode
                      ? ''
                      : new Intl.NumberFormat(language.value, { style: 'percent' }).format(this.y / 100)
                  }</p>`
              },
            },
            groupPadding: 0.1,
          },
          series: {
            color: '#1391D2',
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0 && newProps.container) {
      drawChart(newProps.container, newProps.series, newProps.categories, props.isWaitingMode, language)
    }
  },
  { deep: true, immediate: true }
)
</script>
