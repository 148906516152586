<template>
  <div>
    <div class="tw-bg-white tw-overflow-x-hidden">
      <AuthMenu
        :show-language-selector="false"
        @onRegisterClick="onAuthorize('signup')"
        @onLoginClick="onAuthorize('login')"
      />
      <div class="tw-w-full tw-mx-auto">
        <ProductPageHero
          :isFreeBookingLinks="true"
          :isStudioPlus="false"
          class="hero-container-grid hero-padding tw-mb-20 tw-max-w-screen-xxl tw-mx-auto tw-px-6 tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between"
        >
          <template #leftSide>
            <div class="tw-w-full lg:tw-w-1/2">
              <div
                class="lg:tw-w-max tw-bg-gray-100 tw-px-4 lg:tw-px-10 tw-py-4 lg:tw-py-8 tw-rounded-xl lg:tw-min-h-[445px] lg:tw-max-w-min lg:tw-min-w-[445px] tw-relative tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-start"
              >
                <h1
                  class="tw-text-[48px] tw-leading-[50px] lg:tw-text-[50px] tw-font-bold tw-mb-8"
                  v-html="$t('lp_free_link_header')"
                />
                <p v-html="$t('lp_free_link_subtext')"></p>
                <div
                  class="tw-w-full lg:tw-w- tw-flex tw-items-center tw-mt-8 tw-flex-col lg:tw-flex-row tw-grow tw-items-start"
                >
                  <MainButton
                    class="tw-w-full lg:tw-w-auto"
                    @click="onAuthorize('login')"
                    @keydown.enter="onAuthorize('login')"
                  >
                    {{ $t('lp_free_link_cta') }}
                  </MainButton>
                  <button @click="openVideo" class="tw-mt-4 lg:tw-mt-0 tw-ml-4 tw-flex">
                    <BaseIcon :height="24" :width="24" icon-name="play" class="tw-mr-2">
                      <PlayIcon />
                    </BaseIcon>
                    {{ $t('lp_free_link_video') }}
                  </button>
                </div>
                <div
                  class="tw-flex lg:tw-flex-row tw-flex-col tw-justify-between tw-mt-10 tw-max-w-[525px]"
                  :class="{ 'tw-w-[525px] tw-flex-wrap': isRussian }"
                >
                  <p v-for="(item, index) in benefits" :key="index" class="tw-flex tw-mr-2 tw-whitespace-nowrap">
                    <BaseIcon :height="24" :width="24" icon-name="check-mark-circle" class="tw-mr-1">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
                        fill="#007CC2"
                      />
                      <path d="M10 15.5L17 8.5" stroke="white" stroke-width="2" stroke-linecap="round" />
                      <path d="M7 12.5L10 15.5" stroke="white" stroke-width="2" stroke-linecap="round" />
                    </BaseIcon>
                    {{ $t(item) }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template #rightSide>
            <div class="tw-flex tw-align-start tw-w-full lg:tw-w-1/2">
              <img
                :src="WebHeroImage"
                alt="trivago business studio hero image"
                :height="534"
                :width="768"
                class="lg:tw-min-w-[640px] tw-mt-12 lg:tw-mt-0 tw-pr-4 tw-pl-4 tw-mx-auto lg:tw-mx-none lg:tw-pr-0 lg:tw-pl-0"
              />
            </div>
          </template>
        </ProductPageHero>
      </div>
      <ProductPageTwoColumnLayout
        :is-left-side="true"
        :is-button="false"
        :title="$t('lp_free_link_benefit_1_header')"
        :text="$t('lp_free_link_benefit_1_text')"
        :image="SectionOne"
        :imageWidth="563"
        :imageHeight="360"
        :is-link="true"
        :is-big="true"
        :link="$t('lp_free_link_connectivity_provider_link')"
      />
      <div
        class="tw-max-h-[530px] lg:tw-max-h-[390px] tw-relative tw-bg-gray-100 tw-rounded-xl tw-max-w-screen-xxl tw-mx-auto tw-items-center tw-flex tw-justify-between lg:tw-flex-row tw-flex-col tw-my-16"
      >
        <div class="element-width-image lg:tw-order-1 tw-order-2">
          <img
            :src="windowWidth > 1023 ? SectionTwo : SectionTwoMobile"
            :alt="$t('lp_free_link_benefit_2_header')"
            height="420"
            width="660"
            class="tw-mx-auto tw-py-0 tw-w-full lg:tw-w-auto lg:tw-px-0 tw-relative tw-top-0 tw-left-0 tw-right-0 lg:tw-top-[-25px] lg:tw-left-[-50px]"
          />
        </div>
        <div
          class="element-width tw-mb-2 tw-px-10 lg:tw-mb-0 lg:tw-mr-[41px] tw-py-10 lg:tw-py-0 lg:tw-order-2 tw-order-1"
        >
          <p
            class="tw-text-[40px] tw-mb-[20px] tw-leading-[42px] tw-font-bold"
            v-html="$t('lp_free_link_benefit_2_header')"
          />
          <p class="tw-mt-[15px]" v-html="$t('lp_free_link_benefit_2_text')" />
        </div>
      </div>
      <ProductPageTwoColumnLayout
        :is-left-side="true"
        :title="$t('lp_free_link_benefit_3_header')"
        :text="$t('lp_free_link_benefit_3_text')"
        :imageWidth="618"
        :imageHeight="243"
        :is-big="true"
        :image="SectionThree"
      />
      <ProductPageFaq :faq="faqKeys" :supportCenterLink="true" />
      <ProductPageCardBanner
        :text="$t('lp_free_link_cta_text')"
        :button="$t('lp_free_link_cta')"
        :subtitle="$t('lp_free_link_footnote')"
        :isBigText="true"
        @onRegisterClick="onAuthorize('login')"
      />
    </div>
    <ThePageFooter />
    <TheCookieNotice />
    <ProductPageVideoModal :isOpen="showVideo" :closeModal="hideVideo" />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import ProductPageHero from '@/components/productPage/ProductPageHero.vue'
import ProductPageTwoColumnLayout from '@/components/productPage/ProductPageTwoColumnLayout.vue'
import ProductPageCardBanner from '@/components/productPage/ProductPageCardBanner.vue'
import ProductPageVideoModal from '@/components/productPage/ProductPageVideoModal.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import ProductPageFaq from '@/components/productPage/ProductPageFaq.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import PlayIcon from '@/components/icons/PlayIcon.vue'

const WebHeroImage = new URL('/src/assets/img/free-booking-links-landing/hero.png', import.meta.url)
const SectionOne = new URL('/src/assets/img/free-booking-links-landing/section_one.svg', import.meta.url)
const SectionTwo = new URL('/src/assets/img/free-booking-links-landing/section_two.svg', import.meta.url)
const SectionTwoMobile = new URL('/src/assets/img/free-booking-links-landing/section_two_mobile.svg', import.meta.url)
const SectionThree = new URL('/src/assets/img/free-booking-links-landing/section_three.svg', import.meta.url)

const store = useStore()
const route = useRoute()
const windowWidth = ref(window.innerWidth)
const showVideo = ref(false)
const locale = computed(() => store.state.locale.language)
const isRussian = ref(locale.value === 'ru-RU')
const faqKeys = ref(['lp_free_link_faq_1', 'lp_free_link_faq_2', 'lp_free_link_faq_3', 'lp_free_link_faq_4'])
const benefits = ['lp_free_link_no_risk', 'lp_free_link_no_commission', 'lp_free_link_no_commitment']
onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}

watch(locale, newValue => {
  isRussian.value = newValue === 'ru-RU'
})

const openVideo = () => {
  showVideo.value = true
}
const hideVideo = () => {
  showVideo.value = false
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    locale: locale.value,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
  })
}
</script>
<style scoped>
@media (min-width: theme('screens.lg')) {
  .hero-container-grid {
    display: flex;
    overflow: hidden;
  }
}
.hero-padding {
  padding-top: calc(4rem + 20px);
  padding-bottom: 15px;
  @media (max-width: theme('screens.lg')) {
    padding-top: calc(1rem + 94px);
  }
}
h1 :deep(span),
p :deep(span) {
  color: theme('colors.blue.800');
}
p :deep(ul) {
  margin-top: 15px;
}
p :deep(ul li) {
  @apply tw-leading-8 tw-ml-6 tw-relative;
}

.element-width-image {
  width: calc(50% - 41px);
  @media (max-width: theme('screens.lg')) {
    width: auto;
  }
}

.element-width {
  width: calc(50% - 41px);
  @media (max-width: theme('screens.lg')) {
    width: 100%;
  }
}
</style>
