<template>
  <CardLoadingSkeleton v-if="imagesLoading" height="600px" />
  <div v-else-if="isImagesError">
    <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
  </div>
  <BaseCard v-else-if="images !== null">
    <div>
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-900 tw-mb-8">
        <PropertyImagesHeader
          @toggleTipsModal="toggleTipsModal"
          @deleteImages="openDeleteModal"
          :deleteDisabled="selectedItemIds.length === 0"
          @openAddImageModal="openAddImageModal"
        />
      </div>
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-900" ref="imageGrid">
        <ImagesGrid :items="images" @toggleDeleteModal="openDeleteModal" @openImageGallery="toggleImageGallery" />
      </div>
      <ImagesTipsModal :isOpen="tipsModalOpen" @closeModal="toggleTipsModal" />
      <ImagesGallery
        v-if="imageGalleryOpen"
        :accommodation="accommodation"
        :is-open="imageGalleryOpen"
        :images="images"
        @toggleImageGallery="toggleImageGallery"
        :openedImage="openedImage"
        @toggleDeleteModal="openDeleteModal"
        :initialSelectedImage="openedImage"
      />
      <DeleteImageModal
        @deleteImages="deleteImagesFn"
        @closeModal="closeDeleteModal"
        :isOpen="deleteModalOpen"
        :selectedIds="selectedItemIds"
        :isDeleting="deleteImagesLoading"
      />
      <AddNewImage :isOpen="addImageModalOpen" @closeModal="closeAddImageModal" @openModal="openAddImageModal" />
    </div>
  </BaseCard>
</template>

<script setup>
import { ref } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import PropertyImagesHeader from '@/components/propertyDetails/propertyImages/PropertyImagesHeader.vue'
import ImagesGrid from '@/components/propertyDetails/propertyImages/ImagesGrid.vue'
import ImagesTipsModal from '@/components/propertyDetails/propertyImages/ImagesTipsModal.vue'
import DeleteImageModal from '@/components/propertyDetails/propertyImages/DeleteImageModal.vue'
import ImagesGallery from '@/components/propertyDetails/propertyImages/ImagesGallery.vue'
import CardLoadingSkeleton from '@/components/loadingSkeletons/CardLoadingSkeleton.vue'
import AddNewImage from '@/components/propertyDetails/propertyImages/AddNewImage.vue'
import { useDeleteImages, useGetImages } from '@/components/propertyDetails/queries/index.js'
import { usePropertyImages } from '@/components/propertyDetails/propertyImages/usePropertyImages.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { selectedItemIds, deleteItemId, setDeletingIds, resetSelectedIds, isUploading, resetDeletingIds } = usePropertyImages()

const tipsModalOpen = ref(false)
const deleteModalOpen = ref(false)
const imageGalleryOpen = ref(false)
const addImageModalOpen = ref(false)
const openedImage = ref(null)
const imageGrid = ref(null)

const props = defineProps({
  accommodation: Object,
  images: Array,
})

const { deleteImages, deleteImagesLoading } = useDeleteImages()
const { displayNotification } = useToastNotifications()
const { imagesLoading, isImagesError } = useGetImages(selectedAccommodationId)

const closeAddImageModal = () => {
  addImageModalOpen.value = false
  if (isUploading.value) {
    window.scrollTo({
      behavior: 'smooth',
      top: imageGrid.value?.getBoundingClientRect().bottom,
    })
  }
}
const openAddImageModal = () => {
  addImageModalOpen.value = true
}
const toggleImageGallery = selectedImage => {
  openedImage.value = selectedImage
  imageGalleryOpen.value = !imageGalleryOpen.value
}

const toggleTipsModal = () => {
  tipsModalOpen.value = !tipsModalOpen.value
}
const openDeleteModal = () => {
  setDeletingIds(false)
  deleteModalOpen.value = true
}
const closeDeleteModal = () => {
  deleteModalOpen.value = false
  resetDeletingIds()
}
const findElementById = imageId => {
  return props.images.filter(item => item.imageId === imageId)
}

const deleteImagesFn = () => {
  const allItems = deleteItemId.value
    ? findElementById(deleteItemId.value)
    : props.images.filter(item => {
        return selectedItemIds.value.includes(item.imageId)
      })

  const itemsToDelete = allItems.map(item => {
    return {
      image_id: item.imageId,
      active: false,
      reason: 'outdated',
    }
  })

  let deletingIds = []
  itemsToDelete.map(item => {
    deletingIds.push(item.image_id)
  })
  setDeletingIds(deletingIds)
  deleteImages(
    {
      accommodationId: selectedAccommodationId.value,
      body: { updated: itemsToDelete },
    },
    {
      onSuccess: data => {
        if (data.status === 200) {
          displayNotification({
            message:
              itemsToDelete.length > 1
                ? 'app_images_successfully_x_images_deleted.zero'
                : 'app_images_successfully_x_images_deleted.one',
            isTranslationKey: true,
            translationParams: itemsToDelete.length > 1 ? { count: data.data.updated.length } : false,
            isHtml: false,
            type: 'success',
          })
          closeDeleteModal()
          resetSelectedIds()
        }
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
        closeDeleteModal()
      },
    }
  )
}
</script>
