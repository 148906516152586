<template>
  <div v-if="value" class="tw-text-sm" :class="isPositive ? 'tw-text-green-700' : 'tw-text-red-800'">
    <span
      class="tw-rounded-full tw-h-4 tw-w-4 tw-p-1 tw-inline-flex tw-items-center"
      :class="isPositive ? 'tw-bg-green-700' : 'tw-bg-red-800'"
    >
      <BaseIcon :height="9" :width="8" :icon-name="isPositive ? 'arrow-up' : 'arrow-down'">
        <ArrowUp v-if="isPositive" />
        <ArrowDown v-else />
      </BaseIcon>
    </span>
    {{ Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 2 }).format(value / 100) }}
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import BaseIcon from '@/components/BaseIcon.vue'
import ArrowUp from '@/components/icons/ArrowUp.vue'
import ArrowDown from '@/components/icons/ArrowDown.vue'

const store = useStore()
defineProps({
  value: Number,
  isPositive: Boolean,
})

const language = computed(() => store.state.locale.language)
</script>
