<template>
  <BaseCard no-padding class="tw-p-5">
    <MetricViewHeader
      :selectedTimeFrame="selectedTimeFrame"
      @onSelectTimeFrame="selectTimeFrame"
      title="Top Countries"
      titleTooltipLabel="TBD"
    />
    <Notification iconType="bulb">
      {{
        $t(
          `Most searches were from ${mostPopularCountry}. This data can help you create targeted advertisements and customize service offerings.`
        )
      }}
    </Notification>
    <CountriesGraph
      class="tw-mt-8"
      :selectedTimeFrame="selectedTimeFrame"
      :isLoading="isVisitorsProfileCountriesLoading"
      :isError="isVisitorsProfileCountriesError"
      :visitorsProfileCountries="visitorsProfileCountries"
      :isWaitingMode="isWaitingMode"
    />
  </BaseCard>
  <BaseCard class="tw-mt-12">
    <MetricViewHeaderTitle tooltipLabel="TBD">{{ $t('All Countries') }}</MetricViewHeaderTitle>
    <p>
      {{
        $t(
          'Access comprehensive data on guest search patterns, including Length of Stay, Time Frame patterns, and Locations to improve occupancy and marketing strategies.'
        )
      }}
    </p>
    <CountriesTable
      class="tw-mt-8"
      :visitorsProfileCountries="visitorsProfileCountries"
      :selectedTimeFrame="selectedTimeFrame"
      :isLoading="isVisitorsProfileCountriesLoading"
      :isError="isVisitorsProfileCountriesError"
    />
  </BaseCard>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import MetricViewHeader from '@/components/visitorsProfile/common/MetricViewHeader.vue'
import Notification from '@/components/visitorsProfile/common/Notification.vue'
import CountriesGraph from '@/components/visitorsProfile/countries/CountriesGraph.vue'
import CountriesTable from '@/components/visitorsProfile/countries/CountriesTable.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MetricViewHeaderTitle from '@/components/visitorsProfile/common/MetricViewHeaderTitle.vue'

import { useVisitorsProfileCountries } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'

const { t } = useI18n()
const selectedTimeFrame = ref('30')

const selectTimeFrame = timeFrame => {
  selectedTimeFrame.value = timeFrame
  appAnalytics.track(appAnalytics.events.VP_TIMEFRAME_CHANGED, {
    tabName: 'Search Countries',
    timeFrame: timeFrame,
    accommodationId: selectedAccommodationId.value,
  })
}

const startDate = computed(() => new Date().toISOString().split('T')[0])
const endDate = computed(() => {
  const today = new Date()
  const date = today.setDate(today.getDate() - Number(selectedTimeFrame.value))
  return new Date(date).toISOString().split('T')[0]
})

const { isVisitorsProfileCountriesLoading, isVisitorsProfileCountriesError, visitorsProfileCountries } =
  useVisitorsProfileCountries(selectedAccommodationId, startDate, endDate)

const isWaitingMode = computed(() => {
  return visitorsProfileCountries.value && visitorsProfileCountries.value.length === 0
})

const mostPopularCountry = computed(() => {
  const countries = !isVisitorsProfileCountriesLoading.value ? [...visitorsProfileCountries.value] : []
  const sortedCountries = countries?.sort((a, b) => (a.share > b.share ? -1 : 1))

  return t(`form.countries.${sortedCountries[0].locale}`)
})
</script>
