<template>
  <div v-if="isLoading" class="tw-h-[425px] tw-bg-gray-300 tw-animate-pulse tw-rounded-lg"></div>
  <div v-else-if="isError" class="tw-h-[425px]"></div>
  <table v-else class="tw-border-0">
    <thead class="tw-border tw-rounded-t tw-border-gray-300 tw-bg-gray-100">
      <tr class="tw-border-b tw-border-gray-300">
        <th class="tw-border-0 tw-px-5 tw-py-2 tw-font-normal" :colspan="3">
          <div class="tw-flex tw-items-baseline tw-justify-between tw-gap-2">
            <span
              ><strong>Timeframe: </strong><span>{{ $t(selectedTimeFrameLabel) }}</span></span
            >
            <small> (pre-selected in graph) </small>
          </div>
        </th>
      </tr>
      <tr class="tw-border-0 tw-text-center">
        <th
          v-for="([colKey, colName], index) in Object.entries(columns)"
          :key="colKey"
          class="tw-border-0 tw-font-normal"
          :style="{ width: getWidth(index) }"
        >
          {{ $t(colName) }}
        </th>
      </tr>
    </thead>
    <tbody class="tw-text-center">
      <tr v-for="item in tableData" :key="item.rank">
        <td v-for="(cell, index) in Object.values(item)" :key="cell" :class="index === 1 ? 'tw-text-left' : ''">
          {{ cell }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore()
const props = defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  visitorsProfileCountries: Object,
  isLoading: Boolean,
  isError: Boolean,
})

const columns = {
  rank: 'Rank',
  country: 'Country',
  share: 'Share',
}

const timeFrameOptions = [
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 90 Days', value: '90' },
]

const language = computed(() => store.state.locale.language)
const selectedTimeFrameLabel = computed(
  () => timeFrameOptions.find(option => option.value === props.selectedTimeFrame)?.label
)

const getWidth = index => {
  if (index === 0) return '20%'
  if (index === 1) return '30%'
  if (index === 2) return '50%'
}

const tableData = computed(() => {
  const data = !props.isLoading ? [...props.visitorsProfileCountries] : []
  let table = data?.sort((a, b) => (a.share > b.share ? -1 : 1))

  table = table.map(({ locale, share }, index) => ({
    rank: index + 1,
    country: t(`form.countries.${locale}`),
    share: new Intl.NumberFormat(language.value, { style: 'percent' }).format(share / 100),
  }))

  return table
})
</script>
