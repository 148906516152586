<template>
  <div class="tw-my-6 tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mx-auto">
    <LaunchpadLoader
      v-if="initialDataLoading || !redirectPath"
      class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1"
    />
    <router-view v-else />
  </div>
</template>

<script setup>
import { computed, watch, ref, onMounted, onUpdated } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import useRateConnect from '@/components/rateConnect/queries'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import { useAllApps } from '@/components/dashboard/nav/queries'
import { ELIGIBLE_COUNTRIES, ELIGIBLE_PARTNERS } from '@/constants/studioPlusEligibility.js'

const store = useStore()
const route = useRoute()
const router = useRouter()
const redirectPath = ref(false)

const { studioPlusPrice } = useStudioPlusPrice(selectedAccommodationId)
const { visibleOwnedApps } = useAllApps(selectedAccommodationId)
const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const userRole = computed(() => store.state.session?.userRole)
const { hotelierData, isStartUpLoading } = useRateConnect(selectedAccommodationId)
const { studioPlusCampaign, isLoading } = useStudioPlusCampaign(selectedAccommodationId)
const proApps = [
  import.meta.env.VITE_RATE_INSIGHTS_ID,
  import.meta.env.VITE_VISITOR_PROFILE_ID,
  import.meta.env.VITE_SPECIAL_OFFER_ID,
]
const itemHasPro = computed(() => !!visibleOwnedApps.value?.find(ownedApp => proApps.includes(ownedApp.app_id)))
const initialDataLoading = computed(() => isLoading.value || isStartUpLoading.value)

const isEligableForStudioPlus = computed(() => {
  if (userRole.value !== 'admin') return false
  if (itemHasPro.value) return false
  if (!studioPlusPrice.value) return false
  if (!ELIGIBLE_COUNTRIES.includes(currentAccommodation.value?.countryId)) return false
  if (!ELIGIBLE_PARTNERS.includes(hotelierData.value?.directRatesPartner)) return false
  if (studioPlusCampaign.value) return false
  if (hotelierData.value?.hasRateConnect) return false
  return true
})

onUpdated(() => {
  if (!redirectPath.value || route.name === 'studio-plus') decideRoute()
})
onMounted(() => {
  decideRoute()
})

const decideRoute = () => {
  if (!initialDataLoading.value) {
    if (route.query?.reactivate === 'true') {
      redirectPath.value = true
    } else {
      if (!isEligableForStudioPlus.value || studioPlusCampaign === undefined) router.replace('/')
      else redirectPath.value = true
    }
  }
}

watch(selectedAccommodationId, () => {
  router.replace('/')
})
</script>
