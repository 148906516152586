<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-5 tw-justify-center">
    <FeaturesCard
      type="free"
      :featuresList="basicFeatureList"
      :title="'Business Studio'"
      :annualPrice="annualPrice"
      :monthlyPrice="monthlyPrice"
      :planName="$t('studio.plus.compare.basic.title')"
      :currency="currency"
      :isAdmin="isAdmin"
      @select="redirectToPayment"
    />
    <FeaturesCard
      type="monthly"
      :featuresList="plusFeatureList"
      :title="$t('studio.plus.compare.features.businessstudioplus.title')"
      :planName="$t('studio.plus.compare.whatsnew')"
      :annualPrice="annualPrice"
      :monthlyPrice="monthlyPrice"
      :currency="currency"
      :isAdmin="isAdmin"
      @select="redirectToPayment"
    />
    <FeaturesCard
      type="annual"
      :featuresList="plusFeatureList"
      :title="$t('studio.plus.compare.features.businessstudioplus.title')"
      :planName="$t('studio.plus.compare.whatsnew')"
      :annualPrice="annualPrice"
      :monthlyPrice="monthlyPrice"
      :currency="currency"
      :isAdmin="isAdmin"
      @select="redirectToPayment"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import appAnalytics from '@/utils/tracking'

import FeaturesCard from './FeaturesCard.vue'

import { selectedAccommodationId, useAccommodationsByOrgId, useUserDetails } from '@/layouts/queries'
import useRateConnect from '@/components/rateConnect/queries'

const props = defineProps({
  monthlyPrice: Number,
  annualPrice: Number,
  currency: String,
})

const router = useRouter()
const store = useStore()
const isAdmin = computed(() => store.state.session?.userRole === 'admin')
const userId = computed(() => store.state.session.user?.id)
const { userDetails } = useUserDetails(userId)
const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const { hotelierData } = useRateConnect(selectedAccommodationId)
const directRatesPartnerId = computed(() => hotelierData.value?.directRatesPartner)

const language = computed(() => store.state.locale.language)

const basicFeatureList = [
  'studio.plus.compare.features.basic.property.details',
  'studio.plus.compare.features.basic.image.gallery',
  'studio.plus.compare.features.basic.performance.analytics',
]
const plusFeatureList = [
  'studio.plus.compare.features.business.studio.plus.enhanced.visibility',
  'studio.plus.compare.features.business.studio.plus.official.site.badge',
  'studio.plus.compare.features.business.studio.plus.direct.link',
  'studio.plus.compare.features.business.studio.plus.contact.badge',
  'studio.plus.compare.features.business.studio.plus.contact.details',
  'studio.plus.compare.features.business.studio.plus.rate.insights',
  'studio.plus.compare.features.business.studio.plus.analytics',
]

const redirectToPayment = type => {
  router.push({ name: 'studio-plus-payment', query: { subscription: type } })

  appAnalytics.track(appAnalytics.events.SP_COMPARE_FEATURES_CARD_CLICKED, {
    item_id: selectedAccommodationId.value,
    itemName: currentAccommodation?.value?.name,
    monthly_price: props.monthlyPrice,
    annual_price: props.annualPrice,
    partnerId: directRatesPartnerId.value,
    language: language.value,
    studioUserId: userDetails?.value?.id,
    email: userDetails?.value?.email,
    firstName: userDetails?.value?.firstName,
    lastName: userDetails?.value?.lastName,
    selectedSubscriptionType: type,
  })
}
</script>
