<template>
  <div>
    <label :for="id" :class="{ 'tw-mb-0': !label }" class="tw-w-full">
      <div v-if="tooltip" class="tw-flex tw-items-center">
        <p class="tw-font-bold">
          {{ label }}<span v-if="required" class="tw-text-red-700">{{ ' *' }}</span>
        </p>
        <UiTooltip v-if="tooltip" tooltip-position="top" :label="tooltip" class="tw-cursor-pointer tw-ml-1">
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-900 tw-h-[20px] tw-w-[20px]"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p v-else class="tw-font-bold">
        {{ label }}<span v-if="required" class="tw-text-red-700">{{ ' *' }}</span>
      </p>
      <input
        :id="id"
        :value="value"
        :disabled="disabled"
        :type="type"
        v-bind="$attrs"
        :class="disabled ? getDisabledClass : getClass"
        @blur="$emit('onBlur', $event.target.value)"
        @change="$emit('onChange', $event.target.value)"
        @input="$emit('onInput', $event.target.value)"
        :data-testid="dataTestId"
      />
    </label>
    <small v-if="error" class="tw-text-red-800" :errorTestId>{{ error }}</small>
    <small v-if="hint" class="tw-text-gray-700">{{ hint }}</small>
  </div>
</template>

<script>
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'

export default {
  name: 'BaseTextField',
  components: {
    UiTooltip,
    BaseIcon,
    InformationOutline,
  },
  props: {
    id: {
      type: String,
      default: 'textfield',
    },
    label: {
      type: [String, Number],
      required: false,
      default: '',
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null],
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    hint: {
      type: [String],
      required: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dataTestId: {
      type: String,
      default: '',
    },
    errorTestId: {
      type: String,
      default: '',
    },
  },
  computed: {
    getDisabledClass() {
      const commonClass = 'tw-appearance-none  tw-w-full focus:tw-outline-0 tw-py-2 tw-px-3 tw-rounded-lg'
      const disableClass = 'tw-bg-gray-200 tw-text-gray-500 tw-border-gray-300'
      return this.error
        ? `${commonClass} ${disableClass} ${this.sizeClass} tw-bg-red-200 tw-border-red-800`
        : `${commonClass} ${disableClass} ${this.sizeClass} tw-border tw-border-gray-500`
    },
    getClass() {
      const commonClass = 'tw-appearance-none  tw-w-full focus:tw-outline-0 tw-py-2 tw-px-3 tw-rounded-lg'
      const baseClass = 'tw-bg-white tw-border tw-text-gray-900 focus:tw-border-blue-900 focus:tw-text-blue-900 '
      return this.error
        ? `${commonClass} ${baseClass} ${this.sizeClass} tw-bg-red-200 tw-border-red-800`
        : `${commonClass} ${baseClass} ${this.sizeClass} tw-border-gray-500`
    },
    sizeClass() {
      if (this.size === 'small') return 'tw-py-1  tw-rounded'
      if (this.size === 'medium') return 'tw-py-[9px] tw-rounded-lg'
      else return 'tw-py-3 tw-rounded-lg'
    },
  },
}
</script>
<style scoped>
input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-inline-start: auto;
}
input[type='date'] {
  max-height: 38px;
}
label {
  @apply tw-mb-0;
}
</style>
