<template>
  <section>
    <div class="tw-max-w-4xl tw-py-8 tw-px-4 tw-text-justify tw-mx-auto">
      <h2
        class="tw-border-b tw-border-purple-300 tw-pb-5 tw-mb-8 tw-text-xl tw-font-bold tw-text-center"
        v-html="$t('imprint.heading')"
      ></h2>
      <div class="imprint-body" v-html="$t('imprint.infoList')"></div>
    </div>
  </section>
</template>

<style scoped>
section {
  min-height: calc(100vh - 64px - 217px);
}
.imprint-body :deep(a) {
  color: #007bff;
}
.imprint-body :deep(dl) {
  margin-bottom: 16px;
}
</style>
