<template>
  <section class="tw-pb-24 tw-px-10 tw-max-w-screen-xxl tw-mx-auto">
    <div
      class="tw-relative tw-rounded-xl tw-flex tw-items-center tw-justify-between lg:tw-flex-row tw-flex-col tw-bg-gray-100 md:tw-px-[53px] md:tw-py-[34px] tw-py-[20px] tw-px-[20px]"
    >
      <div class="tw-w-full lg:tw-w-3/4 lg:tw-mr-[41px] tw-mb-4 lg:tw-mb-0">
        <h3
          :class="
            isBigText
              ? 'tw-text-[24px] tw-leading-[30px] lg:tw-text-[32px] lg:tw-leading-[38px]'
              : 'tw-text-[24px] tw-leading-[30px] lg:tw-text-[28px] lg:tw-leading-[38px]'
          "
          class="tw-font-bold tw-text-blue-800"
          v-html="text"
        />
      </div>
      <div class="tw-w-full lg:tw-w-1/4 lg:tw-ml-[41px]">
        <button class="blue-btn" @click="onRegisterClick" @keydown.enter="onRegisterClick">
          {{ button }}
        </button>
      </div>
    </div>
    <p class="tw-mt-4 tw-text-xs">{{ subtitle }}</p>
  </section>
</template>

<script setup>
defineProps({
  text: String,
  subtitle: String,
  variant: String,
  button: String,
  isBigText: Boolean,
})
const emit = defineEmits(['onRegisterClick'])
const onRegisterClick = e => {
  emit('onRegisterClick', e)
}
</script>

<style scoped>
.blue-btn {
  @apply tw-flex tw-items-center tw-h-full tw-justify-center tw-w-full tw-text-center tw-cursor-pointer tw-transition-colors tw-duration-300 tw-font-bold tw-border tw-bg-blue-800 tw-border-blue-800 tw-text-white tw-px-8 tw-py-3 tw-rounded-lg hover:tw-bg-blue-900 hover:tw-border-blue-900;
  line-height: 20px;
  font-size: 22px;
  font-weight: bold;
  padding: 25px 0;
}

h3 :deep(span) {
  font-weight: 400;
}
</style>
