<template>
  <div class="tw-my-4">
    <BaseTextField
      :label="$t('form.labels.email_address')"
      name="email"
      aria-label="email"
      type="email"
      class="tw-w-full lg:tw-mr-2 tw-mb-1 lg:tw-mb-auto"
      size="big"
      :value="email"
      :disabled="true"
    />
    <div class="tw-flex tw-justify-start tw-px-2 tw-w-full">
      <BaseLinkButton
        :href="resetPasswordLink()"
        rel="noopener noreferrer"
        target="_blank"
        class="hover:tw-no-underline tw-text-blue-900 tw-font-bold tw-py-2 tw-text-md hover:tw-text-blue-700"
      >
        {{ $t('form.labels.reset_password') }}
        →
      </BaseLinkButton>
    </div>
  </div>
</template>
<script setup>
import BaseLinkButton from '@/components/base/buttons/BaseLinkButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'

defineProps({
  email: String,
})
const resetPasswordLink = () => {
  return `${import.meta.env.VITE_OAUTH_DOMAIN}/en-GB/forgot-password`
}
</script>
