<template>
  <BaseButton
    :size="size"
    :data-testid="dataTestId"
    class="tw-font-bold tw-border tw-border-gray-700 tw-text-gray-900 tw-bg-white"
    v-bind="$attrs"
  >
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: {
    BaseButton,
  },
  props: {
    size: {
      type: String,
      default: 'big',
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
button:disabled {
  @apply tw-text-gray-300 tw-border-gray-300;
}
@media not all and (hover: none) and (pointer: fine) {
  button:hover {
    background-color: theme('colors.gray.700');
    color: theme('colors.white');
    border-color: theme('colors.gray.700');
  }
}
</style>
