<template>
  <h4 class="tw-font-bold">
    {{ $t('subscriptions.details.transactionHistory.heading') }}
  </h4>
  <div v-if="isFetching">
    <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-max-w-xl tw-h-40" aria-hidden="true"></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-800">
    {{ $t('errors.wrong_data_from_app') }}
  </div>
  <div v-else>
    <ul>
      <li v-for="(transaction, i) in subscriptionHistory" :key="i" class="tw-text-sm tw-w-full">
        <button
          v-if="isTypeInvoice(transaction)"
          class="tw-p-2 tw-w-full tw-text-left hover:tw-bg-gray-200"
          @click="downloadPdf(transaction)"
        >
          {{
            $t('subscriptions.details.transactionHistory.invoice', getTranslationKeyParamsForSubscription(transaction))
          }}
        </button>
        <p v-else class="tw-p-2">
          {{
            $t('subscriptions.details.transactionHistory.voucher', getTranslationKeyParamsForSubscription(transaction))
          }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PaymentService from '@/services/PaymentService'
import { useSubscriptionHistory } from '@/components/settings/subscriptions/queries'

const props = defineProps({
  subscriptionNumber: {
    type: String,
    default: '',
    required: true,
  },
})

const store = useStore()
const { subscriptionHistory, isFetching, isError } = useSubscriptionHistory(props.subscriptionNumber)

const language = computed(() => store.state?.locale?.language ?? 'en-US')

const isTypeInvoice = transaction => transaction?.__typename === 'SubscriptionInvoice'

const getTranslationKeyParamsForSubscription = transaction => {
  if (!transaction) return
  if (!isTypeInvoice(transaction)) {
    const amount = transaction.currency
      ? Intl.NumberFormat(language.value, {
          style: 'currency',
          currency: transaction.currency,
        }).format(transaction.amount)
      : `${transaction.amount}%`
    const date = new Date(transaction.redeemed_date)?.toLocaleDateString(language.value, { dateStyle: 'long' })
    return { amount, date }
  } else {
    const date = new Date(transaction.posted_date)?.toLocaleDateString(language.value, { dateStyle: 'long' })
    return { date }
  }
}

const downloadPdf = async transaction => {
  if (!isTypeInvoice(transaction)) return
  const fileId = transaction.file_id
  const response = await PaymentService.fetchPdfFileBlob(fileId)
  if (response.data) {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', `${fileId}.pdf`)
    document.body.appendChild(fileLink)
    fileLink.click()
  }
}
</script>
