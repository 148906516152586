<template>
  <Notification
    :description="
      $t('notification_budget_almost_runout_message', {
        budgetLeftPercentage: $n(budgetLeftPercentage, 'percent'),
        month: $d(new Date(), 'longMonth'),
        budgetleft: `${currency} ${$n(budgetLeft, 'currencyCode')}`,
      })
    "
    :header="$t('notification_budget_almost_runout_heading')"
    v-bind="$attrs"
  >
    <template #ctaContent>
      <div class="tw-mt-3 md:tw-mt-0">
        <MainButton size="medium" class="tw-w-full" @click="navigateToBudget">
          <span>{{ $t('notification_action_update_budget') }}</span>
        </MainButton>
      </div>
    </template>
  </Notification>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import Notification from './Notification.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const store = useStore()
const router = useRouter()

const props = defineProps({
  extraParameters: {
    type: Object,
    required: true,
    default: null,
  },
})

const currency = computed(() => store.state.currency)
const budgetLeft = computed(() => {
  if (!props.extraParameters) return 0
  return (props.extraParameters?.totalBudget - props.extraParameters?.budgetConsumed || 0) / 100
})
const budgetLeftPercentage = computed(() => {
  if (!props.extraParameters) return 0
  return (
    (props.extraParameters?.totalBudget - props.extraParameters?.budgetConsumed || 0) /
    props.extraParameters?.totalBudget
  )
})

const navigateToBudget = () => {
  router.push({ path: 'budget' })
}
</script>
