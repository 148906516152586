<template>
  <PaymentClientOverlay
    v-if="isEditingPayment"
    @paymentClientClosed="onPaymentClientClosed"
    @paymentInfoUpdated="onPaymentInfoUpdated"
  />
  <BaseCard class="tw-my-4">
    <h3 class="tw-text-xl tw-font-bold">{{ $t('paymentMethods.heading') }}</h3>
    <p class="tw-text-sm tw-border-b tw-pb-4 tw-border-gray-200">{{ $t('paymentMethods.info') }}</p>
    <PaymentMethodPreview />
    <BillingInformationPreview />
    <MainButton v-if="hasBillingAccount" @click="onPaymentEditClick" class="tw-mt-4">{{
      $t('paymentMethods.edit.cta')
    }}</MainButton>
  </BaseCard>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useQueryClient } from '@tanstack/vue-query'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BillingInformationPreview from '@/components/settings/paymentMethods/BillingInformationPreview.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import PaymentMethodPreview from '@/components/settings/paymentMethods/PaymentMethodPreview.vue'
import PaymentClientOverlay from '@/components/settings/paymentMethods/PaymentClientOverlay.vue'
import { useBillingAccountDetails } from '@/components/settings/paymentMethods/queries'

const store = useStore()
const queryClient = useQueryClient()
const organisationId = computed(() => store.state?.session?.selectedOrganisation?.id)

const { hasBillingAccount } = useBillingAccountDetails(organisationId)

const isEditingPayment = ref(false)

const onPaymentEditClick = () => {
  isEditingPayment.value = true
}

const onPaymentInfoUpdated = () => {
  queryClient.invalidateQueries({ queryKey: ['paymentDetails'] })
}

const onPaymentClientClosed = () => {
  isEditingPayment.value = false
}
</script>
