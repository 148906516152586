import { computed, ref } from 'vue'
import { useQuery, useQueries, useQueryClient } from '@tanstack/vue-query'

import OrganisationManagementService from '@/services/OrganisationManagementService'
import AccommodationService from '@/services/AccommodationService'

export const selectedAccommodationId = ref(null)

export const setSelectedAccommodationId = accommodationId => {
  window.localStorage.setItem('selected_accommodation_id', accommodationId)
  selectedAccommodationId.value = accommodationId
}

export const fetchUserDetails = async () => {
  const response = await OrganisationManagementService.getUserDetails()
  return response.data
}

export function useUserDetails(userId) {
  const { isLoading, data, isFetching, isSuccess, isError, fetchStatus } = useQuery({
    enabled: !!userId?.value,
    queryKey: ['userDetails', userId],
    queryFn: () => fetchUserDetails(),
  })

  const fullName = computed(() => {
    if (!data.value?.firstName && !data.value?.lastName) return ''
    return `${data.value.firstName ? data.value.firstName : ''} ${data.value.lastName ? data.value.lastName : ''}`
  })

  const isUserDetailsLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isUserDetailsFetching: isFetching,
    isUserDetailsLoading,
    isUserDetailsSuccess: isSuccess,
    isUserDetailsError: isError,
    userDetails: data,
    fullName,
  }
}

export const fetchOrganisationAssignments = async organisationId => {
  const response = await OrganisationManagementService.getOrganisationAssignments({ organisationId })
  return response.data?.ids ?? []
}

export function useOrganisationAssignments(orgId) {
  const { isLoading, data, isFetching, isSuccess, isError } = useQuery({
    enabled: !!orgId?.value,
    queryKey: ['organisationAssignments', orgId],
    queryFn: ({ queryKey }) => fetchOrganisationAssignments(queryKey[1]),
  })

  return {
    isOrganisationAssignmentsFetching: isFetching,
    isOrganisationAssignmentsLoading: isLoading,
    isOrganisationAssignmentsSuccess: isSuccess,
    isOrganisationAssignmentsError: isError,
    assignmentsData: data,
  }
}

export function useMultipleOrganisationAssignmentsQueries(organisations) {
  const organisationQueries = computed(() =>
    organisations?.value?.map(org => {
      return {
        queryKey: ['organisationAssignments', org.id],
        queryFn: () => fetchOrganisationAssignments(org.id),
      }
    })
  )
  const organisationAssignmentQueries = useQueries({ queries: organisationQueries })

  return organisationAssignmentQueries
}

const fetchAccommodationsByOrgId = async (accommodationIds, organisationId) => {
  const response = await AccommodationService.getAccommodationsByListOfIds({
    accommodationIds,
    organisationId,
  })
  if (!response?.data || !Array.isArray(response?.data)) return []
  response.data.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return response.data
}

export function useAccommodationsByOrgId(orgId) {
  const { assignmentsData } = useOrganisationAssignments(orgId)
  const queryClient = useQueryClient()

  const isQueryEnabled = computed(() => {
    return !!assignmentsData?.value && assignmentsData?.value?.length > 0
  })

  const { isLoading, data, isFetching, isSuccess, isError, fetchStatus } = useQuery({
    queryKey: ['organisationAccommodations', orgId],
    enabled: isQueryEnabled,
    queryFn: async () => {
      const orgAssignmentsQueryData = queryClient.getQueryData(['organisationAssignments', orgId])
      const response = await fetchAccommodationsByOrgId(orgAssignmentsQueryData, orgId.value)
      return response
    },
  })

  const isInitialLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isAccommodationsByOrgIdInitialLoading: isInitialLoading,
    isAccommodationsByOrgIdFetching: isFetching,
    isAccommodationsByOrgIdLoading: isLoading,
    isAccommodationsByOrgIdSuccess: isSuccess,
    isAccommodationsByOrgIdError: isError,
    accommodationsData: data,
  }
}

export function useMultipleAccommodationsByOrgIdQueries(organisations) {
  const queryClient = useQueryClient()

  const accommodationQueries = computed(() =>
    organisations?.value?.map(org => {
      return {
        queryKey: ['organisationAccommodations', org.id],
        queryFn: async () => {
          const orgAssignmentsQueryData = await queryClient.ensureQueryData({
            queryKey: ['organisationAssignments', org.id],
            queryFn: () => fetchOrganisationAssignments(org.id),
          })
          if (!Array.isArray(orgAssignmentsQueryData) || orgAssignmentsQueryData.length === 0) return []
          const response = await fetchAccommodationsByOrgId(orgAssignmentsQueryData, org.id)
          return response
        },
      }
    })
  )
  const organisationAccommodationsQueries = useQueries({ queries: accommodationQueries })

  return organisationAccommodationsQueries
}
