<template>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g>
      <rect id="Rectangle-4-Copy-87" x="0" y="0" width="24" height="24"></rect>
      <g
        id="Page-1"
        transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) translate(5.000000, 3.000000)"
        fill="currentColor"
      >
        <path
          id="Fill-1"
          d="M13,2 C13,1.45 12.55,1 12,1 L2,1 C1.45,1 1,1.45 1,2 L1,9 C1,9.55 1.45,10 2,10 L3,10 L11,10 L12,10 C12.55,10 13,9.55 13,9 L13,2 Z M3,13 C3,15.21 4.79,17 7,17 C9.21,17 11,15.21 11,13 L11,11 L3,11 L3,13 Z M12,11 L12,13 C12,15.76 9.76,18 7,18 C4.24,18 2,15.76 2,13 L2,11 C0.9,11 0,10.1 0,9 L0,2 C0,0.9 0.9,0 2,0 L12,0 C13.1,0 14,0.9 14,2 L14,9 C14,10.1 13.1,11 12,11 L12,11 Z"
        ></path>
        <path
          id="Fill-3"
          d="M7,8 C6.45,8 6,7.55 6,7 C6,6.63 6.21,6.33 6.5,6.15 L6.5,3.5 C6.5,3.22 6.72,3 7,3 C7.28,3 7.5,3.22 7.5,3.5 L7.5,6.15 C7.79,6.33 8,6.63 8,7 C8,7.55 7.55,8 7,8"
        ></path>
      </g>
    </g>
  </g>
</template>
