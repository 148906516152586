<template>
  <div class="tw-relative">
    <BaseSelect
      v-show="!isFetchingAllCurrencies"
      :isDisabled="!isCurrencyEditable || isOrganisationCurrencyFetching"
      :id="'currencies-selector'"
      :name="'currencies-selector'"
      display-text-key="displayText"
      :value-key="'code'"
      :options="formattedCurrencies"
      :value="selectedCurrency"
      :version="version"
      :size="size"
      @onChange="updateSelectedCurrency"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, watch, onMounted } from 'vue'

import { CURRENCIES } from '@/constants/currencies.js'
import { useAllCurrencies, useGetOrganisationCurrency, useUpdateCurrency } from './queries/index.js'
import BaseSelect from '@/components/BaseSelect.vue'
import { selectedAccommodationId } from '@/layouts/queries/index.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const props = defineProps({
  // defines if the values shown in the dropdown should be only symbol of full currency name
  isFullName: Boolean,
  version: String,
  size: String,
})

const store = useStore()
const { displayNotification } = useToastNotifications()
const userId = computed(() => store.state.session.userId)
const selectedCurrency = computed(() => store.state.currency)
const isCurrencyEditable = computed(() => store.state.isCurrencyEditable)
// Returns the available supported currencies
const { currencies, isFetchingAllCurrencies } = useAllCurrencies()
// Returns the currency that the user has saved, or the corresponding by GEO IP if guest user
const { userCurrency, isOrganisationCurrencyFetching } = useGetOrganisationCurrency(selectedAccommodationId)
const { updateCurrency } = useUpdateCurrency()

// Creates the desired display text for the select drop down (symbol - full currency name)
const formattedCurrencies = computed(() => {
  if (!Array.isArray(currencies.value)) return []
  return currencies.value.map(currency => {
    return {
      ...currency,
      displayText: props.isFullName ? `${CURRENCIES[currency.code]} - ${currency.name}` : `${currency.code}`,
    }
  })
})

const updateCurrencyState = newValue => {
  store.commit('SET_CURRENCY', newValue.currency?.code)
  store.commit('SET_CURRENCY_NAME', newValue.currency?.name)
  window.localStorage.setItem('selected_currency', JSON.stringify(newValue.currency))
  store.commit('SET_IS_CURRENCY_EDITABLE', newValue?.editable)
}

const setInitialCurrency = () => {
  if (userCurrency.value?.currency && userCurrency.value?.editable === false) {
    updateCurrencyState(userCurrency.value)
    return
  }

  const currencyInLocalStorage = window.localStorage.getItem('selected_currency')
  if (currencyInLocalStorage) {
    let parsedCurrencyInLocalStorage
    try {
      parsedCurrencyInLocalStorage = JSON.parse(currencyInLocalStorage)
    } catch {
      window.localStorage.removeItem('selected_currency')
      updateCurrencyState({ currency: { code: 'EUR', name: 'Euro' }, editable: true })
      return
    }
    updateCurrencyState({ currency: parsedCurrencyInLocalStorage, editable: true })
    return
  }

  updateCurrencyState({ currency: { code: 'EUR', name: 'Euro' }, editable: true })
}

watch(
  userCurrency,
  newValue => {
    if (!newValue) return
    setInitialCurrency()
  },
  { immediate: true }
)

onMounted(() => {
  setInitialCurrency()
})

const updateSelectedCurrency = newSelectedCurrency => {
  if (!newSelectedCurrency) return

  if (userCurrency.value?.editable === true) {
    const currencyName = currencies.value?.find(currency => currency.code === newSelectedCurrency)?.name
    updateCurrencyState({
      currency: { code: newSelectedCurrency, name: currencyName },
      editable: true,
    })
  }

  if (!userId.value) return
  updateCurrency(
    {
      userId: userId.value,
      currency: newSelectedCurrency,
    },
    {
      onSuccess: data => {
        const errors = data?.data?.errors ?? []
        if (errors.length > 0) {
          displayNotification({
            message: 'something_went_wrong',
            isTranslationKey: true,
            type: 'error',
          })
          return
        }
        displayNotification({
          message: 'form.alerts.save_success',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
