<template>
  <BaseCard
    no-padding
    class="tw-absolute tw-z-[10] tw-flex tw-top-[66px] tw-w-[680px] tw-font-normal tw-text-gray-900 tw-left-[50%] tw-translate-x-[-50%] tw-cursor-auto"
  >
    <div class="tw-flex tw-w-full tw-p-3">
      <ul class="tw-w-1/2">
        <li
          v-for="item in props.links"
          :key="item.id"
          class="tw-w-[90%] tw-flex tw-rounded-md hover:tw-font-bold"
          :class="getClasses(item.id)"
          @mouseover="selectElement(item.id)"
          @blur.prevent=""
          @focus.prevent=""
        >
          <a
            class="hover:tw-no-underline tw-w-full tw-py-[15px] tw-px-[10px] tw-flex tw-items-center"
            :class="getTextClasses(item.id)"
            :href="item.url"
          >
            <img
              v-if="currentElement?.id === item.id"
              :src="`${item.iconHover}`"
              :alt="$t(item.label)"
              class="tw-inline tw-mr-[10px]"
            />
            <img v-else :src="item.icon" :alt="$t(item.label)" class="tw-inline tw-mr-[10px]" />
            <span v-html="$t(item.label)" />
          </a>
        </li>
      </ul>
      <div class="tw-w-1/2 tw-group">
        <a :href="currentElement?.url" class="tw-flex tw-flex-col hover:tw-text-gray-900 hover:tw-no-underline">
          <h3 class="tw-text-[22px] tw-leading- tw-font-bold tw-pb-2.5" v-html="$t(currentElement?.header)" />
          <p class="tw-pb-2.5">
            {{ $t(currentElement?.text) }}
          </p>
          <div class="tw-flex tw-justify-end">
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              :viewbox-height="24"
              :viewbox-width="24"
              class="tw-bg-gray-100 tw-rounded-md group-hover:tw-bg-blue-800 tw-fill-[#007CC2] group-hover:tw-fill-gray-100"
            >
              <ChevronRight />
            </BaseIcon>
          </div>
        </a>
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'

const route = useRoute()
const props = defineProps({
  links: Array,
})

const selected = ref(0)
const currentElement = computed(() => props.links.filter(item => selected.value === item.id)[0])
const getClasses = id => {
  const classes = []
  if (id === 2 || (id === 2 && id === currentElement?.value.id)) {
    classes.push('tw-bg-blue-200 tw-font-bold hover:tw-bg-blue-300 hover:tw-text-blue-700 tw-rounded-md')
  } else if (id === currentElement?.value.id) {
    classes.push('tw-bg-gray-100  hover:tw-bg-gray-100')
  }
  return classes.join(' ')
}
const getTextClasses = id => {
  const classes = []
  if (id === 2) {
    classes.push('tw-text-blue-700 hover:tw-text-blue-700 tw-rounded-md')
  } else if (id === currentElement?.value.id) {
    classes.push('tw-font-bold hover:tw-text-gray-900')
  }
  return classes.join(' ')
}

const selectElement = id => {
  selected.value = id
}
onMounted(() => {
  const path = '/home' + route.path
  const currentUrl = props.links.find(item => item.url === path)
  selectElement(currentUrl?.id || 0)
})
</script>
