import axios from 'axios'

import BaseService from './BaseService.js'

class CurrencyService extends BaseService {
  constructor() {
    super({ baseUrl: import.meta.env.VITE_GATEWAY })
  }

  getCurrencies() {
    return axios({
      method: 'POST',
      url: `${this.baseUrl}/graphql`,
      data: {
        operationName: `getCurrencies`,
        query: `query getCurrencies {
          currencies {
            code
            name
          }
        }`,
        variables: {},
        fetchPolicy: 'network-only',
      },
      headers: {
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getUserCurrency() {
    return axios({
      method: 'POST',
      url: `${this.baseUrl}/graphql`,
      data: {
        query: `query getUserCurrency {
          currency {
            currency {
              code
              name
            }
            editable
          }
        }`,
        variables: {},
        fetchPolicy: 'network-only',
      },
      headers: {
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new CurrencyService()
