<template>
  <BaseCard class="tw-my-4">
    <div v-if="(isFetching && isFetchingAccommodationDetails) || subscriptionDetails === undefined">
      <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80" aria-hidden="true"></div>
    </div>
    <div v-else-if="isError && isErrorAccommodationDetails" class="tw-text-red-800">
      {{ $t('errors.wrong_data_from_app') }}
    </div>
    <div v-else>
      <a
        class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-800"
        @click="router.push({ name: 'settings-subscriptions' })"
        @keydown.enter="router.push({ name: 'settings-subscriptions' })"
        >← {{ $t('setup_back') }}</a
      >
      <h3 class="tw-text-xl tw-font-bold tw-mt-4 tw-mb-2">
        {{ accommodationName ?? $t('subscriptions.noAccommodationName', { accommodationId: accommodationId }) }}
      </h3>
      <div class="tw-flex tw-items-center tw-gap-2">
        <img :src="iconPath ?? defaultAppIcon" alt="Product Icon" height="52" width="52" />
        <div class="tw-flex tw-flex-col">
          <strong>{{ $t(productName) || $t('subscriptions.noAccommodationName') }}</strong>
          <small class="tw-text-sm tw-text-gray-700">{{ pricingPlan }}</small>
        </div>
      </div>
      <div
        class="tw-font-bold tw-text-lg tw-mt-4 tw-leading-4"
        :class="isActiveSubscription ? 'tw-text-green-700' : ''"
      >
        {{ $t(`subscriptions.status.${subscriptionDetails?.status}`) }}
      </div>
      <p v-if="isCancelledSubscription" class="tw-mb-4">
        {{ $t('subscriptions.details.activeUntil', { subscription_end_date: cancellationTermEndDate }) }}
      </p>
      <p
        v-else-if="isRecurringFee"
        class="tw-mb-4"
        v-html="
          renewalCurrency
            ? $t('subscriptions.details.nextPayment.recurringFee', {
                renewal_date: recurringFeeRenewalDate,
                amount: recurringFeeAmount,
              })
            : $t('subscriptions.details.nextPayment.noCurrency')
        "
      ></p>
      <p v-else-if="!isExpiredSubscription" class="tw-mb-4">
        {{ $t('subscriptions.details.nextPayment.usageBased.date') }}
      </p>
      <DangerButton
        v-if="isActiveSubscription && isRecurringFee"
        @click="onCancelSubscriptionClick"
        size="medium"
        class="tw-w-full sm:tw-w-auto tw-mb-4"
        :disabled="isCancellingSubscription"
        >{{ $t('subscriptions.details.cancel.cta') }}</DangerButton
      >
      <TransactionHistory :subscriptionNumber="subscriptionNumber" />
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useQueryClient } from '@tanstack/vue-query'

import {
  useSubscriptionDetails,
  useAccommodationDetails,
  useSubscriptionCancelMutation,
} from '@/components/settings/subscriptions/queries'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import TransactionHistory from '@/components/settings/subscriptions/TransactionHistory.vue'
import defaultAppIcon from '@/assets/img/default-app-icon.png'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { useAccommodationsByOrgId } from '@/layouts/queries'

const router = useRouter()
const store = useStore()
const queryClient = useQueryClient()

const language = computed(() => store.state?.locale?.language ?? 'en-US')

const { productId, subscriptionNumber } = router.currentRoute.value.query
const accommodationId = Number(router.currentRoute.value.query?.accommodationId)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const { subscriptionDetails, isFetching, isError } = useSubscriptionDetails(
  accommodationId,
  productId,
  subscriptionNumber
)

const iconPath = computed(() => subscriptionDetails?.value?.product?.details?.[0]?.icon)
const productName = computed(() => subscriptionDetails?.value?.product?.details?.[0]?.name)
const pricingPlan = computed(() => subscriptionDetails?.value?.pricing_plan?.details?.[0]?.name)
const isActiveSubscription = computed(() => subscriptionDetails?.value?.status?.toLowerCase() === 'active')
const isExpiredSubscription = computed(() => subscriptionDetails?.value?.status?.toLowerCase() === 'expired')
const isCancelledSubscription = computed(() => subscriptionDetails?.value?.status?.toLowerCase() === 'cancelled')
const isRecurringFee = computed(() => subscriptionDetails?.value?.renewal_preview?.amount)
const cancellationTermEndDate = computed(() => {
  const termEndDate = subscriptionDetails?.value?.term_end_date
  if (!termEndDate) return ''
  return new Date(termEndDate)?.toLocaleDateString(language.value, { dateStyle: 'medium' })
})
const recurringFeeRenewalDate = computed(() => {
  const renewalDate = subscriptionDetails?.value?.renewal_preview?.renewal_date
  if (!renewalDate) return ''
  return new Date(renewalDate)?.toLocaleDateString(language.value, { dateStyle: 'medium' })
})
const renewalCurrency = computed(() => subscriptionDetails?.value?.renewal_preview?.currency)
const recurringFeeAmount = computed(() => {
  const renewalAmount = subscriptionDetails?.value?.renewal_preview?.amount
  if (!renewalAmount) return ''
  return new Intl.NumberFormat(language.value, {
    style: 'currency',
    currency: renewalCurrency.value,
  }).format(renewalAmount)
})

const {
  accommodationDetails,
  isFetching: isFetchingAccommodationDetails,
  isError: isErrorAccommodationDetails,
} = useAccommodationDetails(accommodationId)

const accommodationName = computed(() => accommodationDetails?.value?.name)

const { isLoading: isCancellingSubscription, cancelSubscription } = useSubscriptionCancelMutation()
const { displayNotification } = useToastNotifications()

const onCancelSubscriptionClick = () => {
  if (!accommodationId || !productId) return
  cancelSubscription(
    { accommodationId, productId },
    {
      onSuccess: data => {
        const errors = data?.data?.errors
        if (errors?.length > 0) {
          displayNotification({
            message: 'subscriptions.details.cancel.error.generic',
            isTranslationKey: true,
            type: 'error',
          })
          return
        }
        displayNotification({
          message: 'subscriptions.details.cancel.success',
          isTranslationKey: true,
          type: 'success',
        })
        queryClient.invalidateQueries({ queryKey: ['subscriptionDetails'] })
        queryClient.invalidateQueries({ queryKey: ['subscriptionHistory'] })
      },
      onError: () => {
        displayNotification({
          message: 'subscriptions.details.cancel.error.generic',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
onMounted(() => {
  if (!accommodationsData.value.find(item => item.accommodationId === accommodationId)) {
    router.push({ name: 'settings-subscriptions' })
  }
})
</script>
