<template>
  <BaseCard
    v-if="slideCount > 0"
    class="carousel tw-relative tw-max-w-screen-xxl tw-mx-auto tw-h-[450px] lg:tw-h-[360px]"
  >
    <div class="md:tw-flex tw-flex-col md:tw-flex-row tw-grid tw-grid-cols-12 tw-gap-4">
      <div class="carousel-inner tw-flex tw-grow tw-col-span-7 lg:tw-py-7">
        <div>
          <CarouselNav
            :slide-count="slideCount"
            :selected-slide="selectedSlide"
            :on-carousel-nav-click="onCarouselNavClick"
          />
        </div>
        <div class="tw-grow tw-pl-4 md:tw-pl-2">
          <slot :selected-slide="selectedSlide" :selected-slide-name="selectedSlideName" />
        </div>
      </div>
      <div class="carousel-icon tw-grow tw-col-span-5 tw-hidden md:tw-flex">
        <Vue3Lottie
          v-show="selectedSlideName === 'my_property'"
          class="tw-w-full tw-h-full tw-hidden md:tw-inline-block lottie-animation"
          :animationData="lottieAnimation1"
          @animCreated="handleAnimation"
        />
        <Vue3Lottie
          v-show="selectedSlideName === 'my_business_studio'"
          class="tw-w-full tw-h-full tw-hidden md:tw-inline-block lottie-animation"
          :animationData="lottieAnimation2"
          @animCreated="handleAnimation"
        />
        <Vue3Lottie
          v-show="selectedSlideName === 'all_about_trivago'"
          class="tw-w-full tw-h-full tw-hidden md:tw-inline-block lottie-animation"
          :animationData="lottieAnimation3"
          @animCreated="handleAnimation"
        />
        <img
          v-show="selectedSlideName === 'rc_promote' || selectedSlideName === 'rc_resume'"
          class="tw-hidden md:tw-inline-block tw-max-w-[435px]"
          src="@/assets/img/rc_slide.svg"
          alt="Rate Connect Image"
        />
      </div>
      <slot name="modal" :selected-slide="selectedSlide" :update-selected-slide="updateSelectedSlide" />
    </div>
  </BaseCard>
</template>
<script>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import * as animationData1 from '@/assets/animation/studio-helper-illus1.json'
import * as animationData2 from '@/assets/animation/studio-helper-illus2.json'
import * as animationData3 from '@/assets/animation/studio-helper-illus4.json'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import CarouselNav from './CarouselNav.vue'

export default {
  components: {
    BaseCard,
    CarouselNav,
    Vue3Lottie,
  },
  name: 'Carousel',
  props: {
    slides: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    let timer
    const slideCount = computed(() => props.slides?.length)
    const selectedSlide = ref(0)
    const selectedSlideName = computed(() => props.slides?.[selectedSlide.value - 1]?.name)
    const anim = ref(null)
    const lottieAnimation1 = ref(animationData1.default)
    const lottieAnimation2 = ref(animationData2.default)
    const lottieAnimation3 = ref(animationData3.default)

    onMounted(() => {
      autoplay()
    })

    onUnmounted(() => {
      clearTimeout(timer)
    })

    const autoplay = () => {
      if (selectedSlide.value < slideCount.value) {
        selectedSlide.value++
      } else {
        selectedSlide.value = 1
      }
      timer = setTimeout(autoplay, 10000)
    }

    const onCarouselNavClick = value => {
      clearTimeout(timer)
      autoplay()
      updateSelectedSlide(value)
    }

    const updateSelectedSlide = value => {
      selectedSlide.value = value
    }

    const handleAnimation = value => {
      anim.value = value
    }

    return {
      slideCount,
      selectedSlide,
      selectedSlideName,
      lottieAnimation1,
      lottieAnimation2,
      lottieAnimation3,
      anim,
      handleAnimation,
      onCarouselNavClick,
      updateSelectedSlide,
    }
  },
}
</script>
<style scoped>
.carousel {
  overflow: hidden;

  @media (min-width: theme('screens.md')) {
    max-height: 500px;
  }
}
.carousel-inner {
  @media (max-width: theme('screens.sm')) {
    min-height: 286px;
  }
}
.carousel-icon {
  max-width: 300px;
  margin: 0 auto;

  @media (min-width: theme('screens.md')) {
    margin: auto;
    max-width: 300px;
  }

  @media (min-width: theme('screens.lg')) {
    max-width: 530px;
  }
}
.lottie-animation {
  margin-top: -40px !important;
  margin-bottom: -25px !important;
}
</style>
