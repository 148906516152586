import { store } from '@/store'
import * as Sentry from '@sentry/vue'

export default async function (to, from, next) {
  try {
    await store.dispatch('session/restoreStudioSession', { from, to })
    const isOnboardingUserDetails = store.state.session.isOnboardingUserDetails
    const isOnboardingAssignments = store.state.session.isOnboardingAssignments
    if (isOnboardingAssignments || isOnboardingUserDetails) {
      if (to.name !== 'onboarding') {
        next({ name: 'onboarding' })
      } else {
        next()
      }
    } else if (to.name === 'onboarding') {
      next('/')
    } else {
      next()
    }
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('feature', 'isAuthenticated-middleware')
      Sentry.captureException(error)
    })
    return next()
  }
}
