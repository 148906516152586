<template>
  <CustomModal
    class="features-edit-modal"
    :is-modal-open="isOpen"
    @toggleModal="closeEditingFn()"
    @onClickAway="closeEditingFn()"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-[500px] md:tw-w-[500px] md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t(editedSection.name) }}</p>
    </template>
    <div v-if="!sectionFieldsLoading && sectionFields !== null" class="tw-mt-4 tw-flex tw-flex-wrap">
      <div v-for="field in sectionFields" :key="field.featureId" class="feature-field tw-my-2">
        <Checkbox
          :name="field.featureId"
          :label="field.name"
          :value="getCurrentValue(field)"
          class="tw-text-gray-900"
          @onChange="checked => toggleCheckbox(checked, field.featureId, field.studioGroupId)"
        />
      </div>
    </div>
    <LaunchpadLoader
      v-else
      class="tw-min-h-[200px] tw-flex tw-items-center tw-w-[100vw] md:tw-w-[500px] tw-justify-center"
    />

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end tw-gap-4">
        <GhostButton size="medium" @click="closeEditingFn()">{{ $t('close') }}</GhostButton>
        <MainButton size="medium" @click="savePropertyFeatures">
          <span v-if="!saveFeaturesLoading">{{ $t('save') }}</span>
          <span v-if="saveFeaturesLoading">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import CustomModal from '@/components/base/CustomModal.vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { useGetFields, useSaveFeatures } from '@/components/propertyDetails/queries'
import { usePropertyFeatures } from '@/components/propertyDetails/propertyFeatures/usePropertyFeatures.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { editSection, updateFeatures } = usePropertyFeatures()
const store = useStore()
const props = defineProps({
  isOpen: Boolean,
  editedSection: Object,
  translations: Object,
})
const emit = defineEmits(['closeEditing'])
const closeEditingFn = () => emit('closeEditing')

const languageCode = computed(() => store.state.locale?.language)
const { sectionFieldsLoading, sectionFields } = useGetFields(props.editedSection.fieldGroupIds, languageCode)
const { displayNotification } = useToastNotifications()
const { saveFeatures, saveFeaturesLoading } = useSaveFeatures()

const toggleCheckbox = (checked, fieldId, groupId) => {
  updateFeatures({ checked, fieldId, groupId })
}
const getCurrentValue = field => {
  let checkboxValue = false
  if (editSection.value !== null) {
    editSection.value.forEach(item => {
      if (item.featureId === field.featureId) {
        checkboxValue = true
      }
    })
  }
  return checkboxValue
}
const savePropertyFeatures = () => {
  saveFeatures(
    {
      accommodationId: selectedAccommodationId.value,
      groupId: props.editedSection.fieldGroupIds,
      editObj: editSection.value,
    },
    {
      onSuccess: () => {
        closeEditingFn()
        displayNotification({
          message: 'app_hotel_details_successfully_saved',
          isTranslationKey: true,
          isHtml: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
<style scoped>
.feature-field {
  flex: 50% 0 0;
  @media (max-width: theme('screens.md')) {
    flex: 100% 0 0;
  }
}
</style>
