<template>
  <BaseButton
    :size="size"
    :data-testid="dataTestId"
    class="tw-font-bold tw-border tw-bg-blue-800 tw-border-blue-800 tw-text-white"
    v-bind="$attrs"
  >
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: {
    BaseButton,
  },
  props: {
    size: {
      type: String,
      default: 'big',
    },
    dataTestId: {
      type: String,
      default: '',
    },

    variant: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'red'].includes(value)
      },
    },
  },
}
</script>

<style scoped>
button:disabled {
  @apply tw-bg-blue-300 tw-border-blue-300;
}
@media not all and (hover: none) and (pointer: fine) {
  button:hover {
    background-color: theme('colors.blue.900');
    border-color: theme('colors.blue.900');
  }
}
</style>
