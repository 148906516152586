<template>
  <div class="tw-w-full tw-my-3">
    <p class="tw-font-bold tw-text-xl tw-text-gray-900 tw-my-2">{{ $t('app_hotel_details_location_on_map') }}</p>
    <GoogleMap
      :api-key="googleMaps"
      :center="{ lat, lng }"
      :zoom="15"
      style="width: 100%; height: 400px; border-radius: 8px'"
      :default-options="{ gestureHandling: 'cooperative' }"
    >
      <Marker @dragend="locationChange" :options="{ position: { lat, lng }, draggable: true }" />
    </GoogleMap>
  </div>
</template>
<script setup>
import { GoogleMap, Marker } from 'vue3-google-map'
import { usePropertyDetailsBasicInfo } from '@/components/propertyDetails/propertyBasicInfo/usePropertyDetailsBasicInfo.js'

const googleMaps = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
const { setNewValue } = usePropertyDetailsBasicInfo()
defineProps({
  lng: {
    type: Number,
    default: 0,
  },
  lat: {
    type: Number,
    default: 0,
  },
})
const locationChange = location => {
  setNewValue({ name: 'latitude', value: location.latLng.lat() })
  setNewValue({ name: 'longitude', value: location.latLng.lng() })
}
</script>
