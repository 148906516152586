<template>
  <div
    class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-min-h-screen-minus-header-and-footer tw-px-4"
  >
    <LaunchpadLoader
      v-if="isLoadingRequiredData || !pathDecided"
      class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1"
    />
    <div v-else-if="isRequiredDataError" class="tw-flex tw-justify-center tw-items-center tw-flex-1">
      <ErrorModal
        :allow-retry="false"
        :app-name="'Rate Connect'"
        :use-app-name="true"
        header-key="onboarding.appLaunchError.header"
        content-key="onboarding.appLaunchError.text"
        retry-key="onboarding.appLaunchError.retryButton"
      />
    </div>

    <router-view v-else />
  </div>
</template>

<script setup>
import { computed, ref, onUpdated, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'
import { useCampaigns, usePartners, useStartUp } from '@/components/rateConnect/queries'
import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()
const route = useRoute()

const {
  isLoading: isStartUpLoading,
  isError: isStartUpError,
  hotelierData,
  currentCampaignType,
} = useStartUp(selectedAccommodationId)
const {
  isLoading: isPartnersLoading,
  isError: isPartnersError,
  recommendedPartner,
} = usePartners(selectedAccommodationId)
const { isLoading: isCampaignsLoading, isError: isCampaignsError } = useCampaigns(selectedAccommodationId)
const {
  studioPlusCampaign,
  isLoading: isStudioPlusCampaignLoading,
  isError: isStudioPlusCampaignError,
} = useStudioPlusCampaign(selectedAccommodationId)
const { isLoading: isStudioPlusPriceLoading, isError: isStudioPlusPriceError } =
  useStudioPlusPrice(selectedAccommodationId)

const pathDecided = ref(false)

const isLoadingRequiredData = computed(
  () =>
    isStartUpLoading.value ||
    isPartnersLoading.value ||
    isCampaignsLoading.value ||
    isStudioPlusCampaignLoading.value ||
    isStudioPlusPriceLoading.value
)
const isRequiredDataError = computed(
  () =>
    isStartUpError.value ||
    isPartnersError.value ||
    isCampaignsError.value ||
    isStudioPlusCampaignError.value ||
    isStudioPlusPriceError.value
)
const hasRateConnect = computed(() => hotelierData.value?.hasRateConnect)
const isEnabledForRateConnect = computed(() => hotelierData.value?.isEnabledForRateConnect)

if (route.query?.source === 'CPAUpsellBanner' || route.query?.source === 'CPAUpsellPopup') {
  pathDecided.value = true
  router.push({ path: '/rate-connect/setup', query: route.query })
}

watch(selectedAccommodationId, newSelectedAccommodationId => {
  if (newSelectedAccommodationId) pathDecided.value = false
})

onMounted(() => {
  if (route.name === 'rate-connect') {
    decideRoute()
  }
})

onUpdated(() => {
  if (!pathDecided.value || route.name === 'rate-connect') decideRoute()
})

const isExpiredSubscription = computed(() => {
  return studioPlusCampaign.value?.endDate ? new Date() > new Date(studioPlusCampaign.value?.endDate) : false
})

const isRateConnectHidden = computed(() => {
  if (studioPlusCampaign.value && !isExpiredSubscription.value) return true
  else return false
})

const decideRoute = () => {
  if (isLoadingRequiredData.value) return
  // Rate Connect is hidden from the user because of Studio Plus subscription
  if (isRateConnectHidden.value) {
    router.replace('/')
  }

  const currentPath = route.name

  // User is in rate-connect path and has rate connect campaign
  if (currentPath === 'rate-connect' && hasRateConnect.value === true) {
    router.replace({ name: 'rate-connect-running-performance' })
    return
    // User is in rate-connect path and doesn't have rate connect campaign and is not eligible
  } else if (currentPath === 'rate-connect' && hasRateConnect.value === false) {
    router.replace('/rate-connect/no-campaign')
    return
    // User is in rate-connect path and doesn't have rate connect campaign and is eligible
  } else if (currentPath === 'rate-connect' && isEnabledForRateConnect.value && recommendedPartner.value) {
    router.replace('/rate-connect-setup')
    return
  }
  // User is in any rate-connect/runnning or any subpath, and has no campaign yet.
  else if (currentPath.includes('rate-connect-running') && hasRateConnect.value === false) {
    router.replace('/rate-connect/no-campaign')
    return
  }
  // User is in exactly rate-connect (no sub-paths) and has an existing campaign.
  else if (
    (currentPath === 'rate-connect-no-campaign' || currentPath === 'rate-connect-noteligible') &&
    hasRateConnect.value
  ) {
    router.replace({ name: 'rate-connect-running-performance' })
    return
  }
  // User is in exactly rate-connect/running (no sub-paths) and has an existing campaign. /running has no page itself.
  else if (currentPath === 'rate-connect-running' && hasRateConnect.value) {
    router.replace('/rate-connect/running/performance')
    return
  }
  // User is in exactly rate-connect/setup (no sub-paths) and has an existing campaign.
  else if (currentPath === 'rate-connect-setup' && hasRateConnect.value) {
    router.replace('/rate-connect/running/performance')
    return
  }
  // User is in exactly rate-connect/setup, doesn't have a campaign but is not eligible
  else if (currentPath === 'rate-connect-setup' && !hasRateConnect.value && !recommendedPartner.value) {
    router.replace('/rate-connect/noteligible')
    return
  }
  // User is in exactly rate-connect/noteligible and has a valid enabled partner
  else if (currentPath === 'rate-connect-noteligible' && isEnabledForRateConnect.value && recommendedPartner.value) {
    router.replace('/rate-connect/no-campaign')
    return
  }
  // User changed from one accommodation with CPC to another without it and he was previously in Bidding or Budget page.
  else if ((currentPath.includes('bidding') || currentPath.includes('budget')) && currentCampaignType.value !== 'cpc') {
    router.replace('/rate-connect/running/performance')
    return
  }
  pathDecided.value = true
}
</script>
