import { computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'

import appAnalytics from '@/utils/tracking'
import RateConnectService from '@/services/RateConnectService'

export default function useRateConnect(selectedAccommodationId) {
  const {
    isLoading: isStartUpLoading,
    isFetching: isStartUpFetching,
    hotelierData,
  } = useStartUp(selectedAccommodationId)
  const { isLoading: isCampaignsLoading, campaignsData } = useCampaigns(selectedAccommodationId)

  return { isStartUpLoading, isStartUpFetching, hotelierData, isCampaignsLoading, campaignsData }
}

export function useStartUp(selectedAccommodationId) {
  const { isLoading, data, isFetching, isSuccess, isError } = useQuery({
    queryKey: ['RCStartUp', selectedAccommodationId],
    queryFn: ({ queryKey }) => RateConnectService.fetchHoteliersData({ accommodationId: queryKey[1] }),
  })

  const hotelierData = computed(() => data?.value?.data)
  const isItemNetCPAEligible = computed(() =>
    hotelierData.value?.eligibleCampaignTypes ? hotelierData.value?.eligibleCampaignTypes?.includes('netCpa') : null
  )
  const eligibleCommissionLevels = computed(() => {
    if (!hotelierData.value?.cpaCommissionLevels) return []
    return isItemNetCPAEligible.value === true
      ? hotelierData.value.cpaCommissionLevels.netCpa
      : hotelierData.value.cpaCommissionLevels.cpa
  })
  const currentCampaignType = computed(() =>
    hotelierData.value?.currentCampaignType === 'netCPA'
      ? 'netCpa'
      : hotelierData.value?.currentCampaignType?.toLowerCase()
  )

  return {
    isLoading,
    hotelierData,
    isItemNetCPAEligible,
    eligibleCommissionLevels,
    isFetching,
    isSuccess,
    isError,
    currentCampaignType,
  }
}

export function useCampaigns(selectedAccommodationId) {
  const { isLoading, data, isFetching, isSuccess, isError } = useQuery({
    queryKey: ['RCCampaigns', selectedAccommodationId],
    queryFn: ({ queryKey }) => RateConnectService.fetchCampaigns({ accommodationId: queryKey[1] }),
  })

  const campaignsData = computed(() => data?.value?.data)
  const currentCommissionLevel = computed(() => campaignsData?.value?.overall?.cpa)
  const isUsingAutomatedMarkets = computed(() => campaignsData.value?.overall?.useAutomatedMarkets)

  return { isLoading, campaignsData, isFetching, isSuccess, isError, currentCommissionLevel, isUsingAutomatedMarkets }
}

export function usePartners(selectedAccommodationId) {
  const { isLoading, data, isFetching, isError } = useQuery({
    queryKey: ['RCPartners', selectedAccommodationId.value],
    queryFn: ({ queryKey }) => RateConnectService.fetchPartners({ accommodationId: queryKey[1] }),
  })

  const partnersData = computed(() => data?.value?.data)
  const recommendedPartner = computed(() => partnersData.value?.find(partner => partner.isRecommendedPartner))
  const currentPartner = computed(() => {
    if (!Array.isArray(partnersData.value)) return null
    return partnersData.value.find(partner => partner.isCurrentPartner === true)
  })
  const availablePartners = computed(() => {
    if (!Array.isArray(partnersData.value)) return null
    return partnersData.value.filter(partner => !partner.isCurrentPartner)
  })
  const selectablePartners = computed(() => {
    if (!Array.isArray(partnersData.value)) return null
    return partnersData.value.filter(partner => partner.partnerStatus === 'active' && !partner.isCurrentPartner)
  })

  return {
    isLoading,
    partnersData,
    isFetching,
    isError,
    recommendedPartner,
    currentPartner,
    selectablePartners,
    availablePartners,
  }
}

export function useAllPartners() {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['RCAllPartners'],
    queryFn: () => RateConnectService.fetchAllPartners(),
  })

  const allPartnersData = computed(() => data?.value?.data)
  return { isLoading, allPartnersData, isError }
}

export function useBudgetRecommendation(selectedAccommodationId, currencyCode, partner) {
  const isQueryEnabled = computed(() => {
    return !!selectedAccommodationId?.value && !!currencyCode?.value && !!partner?.value
  })

  const { isLoading, isFetching, data, isSuccess, isError } = useQuery({
    queryKey: [
      'RCBudgetRecommendation',
      selectedAccommodationId?.value,
      currencyCode?.value,
      partner?.value?.partnerId,
    ],
    enabled: isQueryEnabled,
    queryFn: ({ queryKey }) =>
      RateConnectService.fetchRecommendation({
        accommodationId: queryKey[1],
        currencyCode: queryKey[2],
        partnerId: partner?.value?.partnerId,
      }),
  })

  const isInitialLoading = computed(() => isLoading.value && isFetching.value)
  const budgetRecommendationData = computed(() => data?.value?.data)
  const budgetRecommendedMin = computed(() =>
    budgetRecommendationData.value?.budgetRecommendedMin
      ? Math.round(budgetRecommendationData.value?.budgetRecommendedMin / 100)
      : 0
  )
  const budgetRecommendedMax = computed(() =>
    budgetRecommendationData.value?.budgetRecommendedMax
      ? Math.round(budgetRecommendationData.value?.budgetRecommendedMax / 100)
      : 0
  )
  const minBudget = computed(() =>
    budgetRecommendationData.value?.budgetMin ? Math.round(budgetRecommendationData.value.budgetMin / 100) : 0
  )
  const maxBudget = computed(() => (budgetRecommendedMax.value ? Math.round(budgetRecommendedMax.value * 1.33) : 0))
  const defaultBudget = computed(() => {
    if (!budgetRecommendedMin.value || !budgetRecommendedMax.value) return 0
    return Math.round((budgetRecommendedMin.value + budgetRecommendedMax.value) / 2)
  })

  const isBudgetValid = selectedBudget => selectedBudget >= minBudget.value

  return {
    isFetching,
    isSuccess,
    isError,
    isInitialLoading,
    budgetRecommendationData,
    minBudget,
    maxBudget,
    budgetRecommendedMin,
    budgetRecommendedMax,
    defaultBudget,
    isBudgetValid,
  }
}

export function useMarkets(selectedAccommodationId, language, currentPartner) {
  const isQueryEnabled = computed(
    () => !!selectedAccommodationId?.value && !!language?.value && !!currentPartner?.value?.partnerId
  )
  const { data, isFetching, isSuccess, isError, isLoading } = useQuery({
    queryKey: ['RCMarkets', selectedAccommodationId?.value, language?.value, currentPartner?.value?.partnerId],
    enabled: isQueryEnabled,
    queryFn: ({ queryKey }) =>
      RateConnectService.fetchMarkets({
        accommodationId: queryKey[1],
        language: queryKey[2],
        partnerId: currentPartner?.value?.partnerId,
      }),
  })

  const marketsData = computed(() => data?.value?.data)
  const isInitialLoading = computed(() => isLoading.value && isFetching.value)
  const marketsWithCampaign = computed(() => marketsData.value?.filter(market => market.hasCampaign))

  return {
    isFetching,
    isSuccess,
    isError,
    isInitialLoading,
    marketsData,
    marketsWithCampaign,
  }
}

export function useBookingLog(selectedAccommodationId, currency, startDate, endDate, marketCode) {
  const isQueryEnabled = computed(
    () =>
      !!selectedAccommodationId?.value &&
      !!currency?.value &&
      !!startDate?.value &&
      !!endDate?.value &&
      !!marketCode.value
  )
  const { data, isFetching, isSuccess, isError, isLoading } = useQuery({
    queryKey: ['RCBookingLog', selectedAccommodationId, currency, startDate, endDate, marketCode],
    enabled: isQueryEnabled,
    queryFn: () =>
      RateConnectService.fetchBookingLog({
        accommodationId: selectedAccommodationId.value,
        currencyCode: currency.value,
        startDate: startDate.value,
        endDate: endDate.value,
        marketCodes: marketCode.value === 'all' ? '' : marketCode.value,
      }),
  })

  const bookingLogData = computed(() => data?.value?.data)
  const isInitialLoading = computed(() => isLoading.value && isFetching.value)

  return {
    isFetching,
    isSuccess,
    isError,
    isInitialLoading,
    bookingLogData,
  }
}

export function useKpis(selectedAccommodationId, currency, startDate, endDate, marketCode) {
  const isQueryEnabled = computed(
    () =>
      !!selectedAccommodationId?.value &&
      !!currency?.value &&
      !!startDate?.value &&
      !!endDate?.value &&
      !!marketCode.value
  )
  const { data, isFetching, isSuccess, isError, isLoading } = useQuery({
    queryKey: ['RCKpis', selectedAccommodationId, currency, startDate, endDate, marketCode],
    enabled: isQueryEnabled,
    queryFn: () =>
      RateConnectService.fetchKPIS({
        accommodationId: selectedAccommodationId.value,
        currencyCode: currency.value,
        startDate: startDate.value,
        endDate: endDate.value,
        marketCodes: marketCode.value === 'all' ? '' : marketCode.value,
      }),
  })

  const KpisData = computed(() => data?.value?.data)

  const kpiNamesMap = {
    visits: 'rc_clicks',
    bookings: 'rc_bookings',
    revenue: 'rc_revenue',
    cpa: 'rc_cpa',
    commissionCost: 'rc_commissions',
  }

  const prettyKpis = computed(() => {
    if (!KpisData?.value || Array.isArray(KpisData.value)) return
    return Object.keys(kpiNamesMap).map(kpiKey => ({
      key: kpiKey,
      label: kpiNamesMap[kpiKey],
      number: KpisData.value[kpiKey],
      progression: KpisData[`${kpiKey}Progression`],
    }))
  })

  const isInitialLoading = computed(() => isLoading.value && isFetching.value)

  return {
    isFetching,
    isSuccess,
    isError,
    isInitialLoading,
    KpisData,
    prettyKpis,
  }
}

export function useStats(selectedAccommodationId, currency, startDate, endDate, marketCode, aggregation) {
  const isQueryEnabled = computed(
    () =>
      !!selectedAccommodationId?.value &&
      !!currency?.value &&
      !!startDate?.value &&
      !!endDate?.value &&
      !!marketCode?.value &&
      !!aggregation?.value
  )
  const { data, isFetching, isSuccess, isError, isLoading } = useQuery({
    queryKey: ['RCStats', selectedAccommodationId, currency, startDate, endDate, marketCode, aggregation],
    enabled: isQueryEnabled,
    queryFn: () =>
      RateConnectService.fetchStats({
        accommodationId: selectedAccommodationId.value,
        currencyCode: currency.value,
        startDate: startDate.value,
        endDate: endDate.value,
        marketCodes: marketCode.value === 'all' ? '' : marketCode.value,
        aggregation: aggregation.value,
      }),
  })

  const statsData = computed(() => data?.value?.data)

  const isInitialLoading = computed(() => isLoading.value && isFetching.value)

  return {
    isFetching,
    isSuccess,
    isError,
    isInitialLoading,
    statsData,
  }
}

export function useNotifications(selectedAccommodationId) {
  const { data, isLoading, isFetching, isSuccess } = useQuery({
    queryKey: ['RCNotifications', selectedAccommodationId.value],
    queryFn: ({ queryKey }) => RateConnectService.fetchNotifications({ accommodationId: queryKey[1] }),
  })

  const notificationsData = computed(() => data?.value?.data)

  return { isLoading, notificationsData, isFetching, isSuccess }
}

export function useFreeLinksMutation() {
  const {
    isLoading: isRequestingFreeLinksOptIn,
    isSuccess: isRequestingFreeLinksOptInSuccess,
    isError: isRequestingFreeLinksOptInError,
    mutate: requestFreeLinksOptIn,
  } = useMutation({
    mutationFn: ({ accommodationId, partnerId, token }) =>
      token
        ? RateConnectService.sendFreeLinksOptInWithToken({ body: { token } })
        : RateConnectService.sendFreeLinksOptIn({ body: { accommodationId, partnerId } }),
    onSuccess: (_, variables) => {
      appAnalytics.track(appAnalytics.events.FREE_LINKS_ACTIVATION_SUCCESS, {
        item_id: variables?.accommodationId,
        direct_rates_partner_id: variables?.partnerId,
      })
    },
    onError: (error, variables) => {
      const errorMsg = error?.response?.data?.errors?.[0]?.message
      Sentry.withScope(scope => {
        scope.setTag('feature', 'Opt-in activation')
        scope.setExtra('Response error', errorMsg)
        scope.setExtra('Error', error)
        Sentry.captureException(error)
      })
      appAnalytics.track(appAnalytics.events.FREE_LINKS_ACTIVATION_ERROR, {
        item_id: variables?.accommodationId,
        direct_rates_partner_id: variables?.partnerId,
        error: errorMsg ?? '',
      })
    },
  })

  return {
    isRequestingFreeLinksOptIn,
    isRequestingFreeLinksOptInSuccess,
    isRequestingFreeLinksOptInError,
    requestFreeLinksOptIn,
  }
}

export function useCampaignsMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: isSubmittingCampaign,
    isSuccess: isSubmittingCampaignSuccess,
    isError: isSubmittingCampaignError,
    mutate: submitCampaign,
  } = useMutation({
    mutationFn: ({ accommodationId, options }) => RateConnectService.sendCampaign({ accommodationId, options }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
    },
  })

  const {
    isLoading: isUpdatingCampaignType,
    isSuccess: isUpdatingCampaignTypeSuccess,
    isError: isUpdatingCampaignTypeError,
    mutate: updateCampaignType,
  } = useMutation({
    mutationFn: ({ accommodationId, campaignType, body }) =>
      RateConnectService.updateCampaignType({ accommodationId, campaignType, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCMarkets'] })
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
    },
  })

  const {
    isLoading: isUpdatingCommissionLevel,
    isSuccess: isUpdatingCommissionLevelSuccess,
    isError: isUpdatingCommissionLevelError,
    mutate: updateCommissionLevel,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => RateConnectService.updateCommissionLevel({ accommodationId, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
    },
  })

  const {
    isLoading: isUpdatingCampaignStatus,
    isSuccess: isUpdatingCampaignStatusSuccess,
    isError: isUpdatingCampaignStatusError,
    mutate: updateCampaignStatus,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => RateConnectService.toggleCampaignStatus({ accommodationId, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
    },
  })

  const {
    isLoading: isUpdatingCampaign,
    isSuccess: isUpdatingCampaignSuccess,
    isError: isUpdatingCampaignError,
    mutate: updateCampaign,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => RateConnectService.updateCampaigns({ accommodationId, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
      queryClient.invalidateQueries({ queryKey: ['RCMarkets'] })
    },
  })

  const {
    isLoading: isUpdatingCampaignMarkets,
    isSuccess: isUpdatingCampaignMarketsSuccess,
    isError: isUpdatingCampaignMarketsError,
    mutate: updateCampaignMarkets,
  } = useMutation({
    mutationFn: ({ accommodationId, markets }) =>
      RateConnectService.updateCampaigns({ accommodationId, body: { campaigns: markets } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCMarkets'] })
    },
  })

  return {
    isSubmittingCampaign,
    isSubmittingCampaignSuccess,
    isSubmittingCampaignError,
    submitCampaign,
    isUpdatingCampaignType,
    isUpdatingCampaignTypeSuccess,
    isUpdatingCampaignTypeError,
    updateCampaignType,
    isUpdatingCommissionLevel,
    isUpdatingCommissionLevelSuccess,
    isUpdatingCommissionLevelError,
    updateCommissionLevel,
    isUpdatingCampaignStatus,
    isUpdatingCampaignStatusSuccess,
    isUpdatingCampaignStatusError,
    updateCampaignStatus,
    isUpdatingCampaign,
    isUpdatingCampaignSuccess,
    isUpdatingCampaignError,
    updateCampaign,
    isUpdatingCampaignMarkets,
    isUpdatingCampaignMarketsSuccess,
    isUpdatingCampaignMarketsError,
    updateCampaignMarkets,
  }
}

export function useNotificationsMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: isDismissingNotification,
    isSuccess: isDismissingNotificationSuccess,
    isError: isDismissingNotificationError,
    mutate: dismissNotification,
  } = useMutation({
    mutationFn: ({ accommodationId, notificationName }) =>
      RateConnectService.dismissNotification({ accommodationId, notificationName }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
    },
  })

  return {
    isDismissingNotification,
    isDismissingNotificationSuccess,
    isDismissingNotificationError,
    dismissNotification,
  }
}

export function usePartnersMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: isChangingPartner,
    isSuccess: isChangingPartnerSuccess,
    isError: isChangingPartnerError,
    mutate: changePartner,
  } = useMutation({
    mutationFn: ({ accommodationId, partnerId }) =>
      RateConnectService.updatePartner({ accommodationId, body: { partnerId } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RCMarkets'] })
      queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
      queryClient.invalidateQueries({ queryKey: ['RCCampaigns'] })
      queryClient.invalidateQueries({ queryKey: ['RCNotifications'] })
      queryClient.invalidateQueries({ queryKey: ['RCPartners'] })
    },
  })

  return {
    isChangingPartner,
    isChangingPartnerSuccess,
    isChangingPartnerError,
    changePartner,
  }
}
