<template>
  <div class="tw-flex tw-items-center tw-bg-white tw-py-2 tw-px-3 tw-gap-2">
    <BaseIcon :height="17" :width="17" icon-name="search">
      <Search />
    </BaseIcon>
    <label class="tw-visually-hidden" :for="id">Search</label>
    <input
      :id="id"
      type="search"
      class="focus:tw-outline-0 tw-grow"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('onInput', $event.target.value)"
    />
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'

export default {
  components: {
    BaseIcon,
    Search,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: 'search-field',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
