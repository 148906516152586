<template>
  <div class="tw-text-gray-900">
    <ConversionFunnelHeader />
    <div class="kpi-grid">
      <BaseCard>
        <ConversionFunnelKPILoadingSkeleton v-show="isFetching" />
        <div
          v-show="!isFetching"
          class="tw-flex tw-flex-col tw-h-full"
          :class="{
            'tw-h-full': viewsDataFormattedForChart !== undefined && viewsDataFormattedForChart.graph.length > 0,
          }"
        >
          <ConversionFunnelKPITitle :tooltip-text="$t('infobubble_definition_views')" :custom-html="$t('kpi_views')" />
          <ConversionFunnelKPIHeader
            :value="views"
            :variation="viewsVariation"
            :percentage="viewsPercentage"
            :percentage-of="viewsPercentageOf"
          />
          <ConversionFunnelKPIChart
            v-show="viewsDataFormattedForChart"
            :data-formatted-for-chart="viewsDataFormattedForChart"
            :container-id="'kpi_views_chart'"
            :series-name="$t('kpi_chart_views')"
          />
        </div>
      </BaseCard>
      <BaseCard class="kpi-visits-section md:tw-flex-row tw-flex-col">
        <div class="md:tw-border-r tw-border-[#ebeced] md:tw-pr-3 md:tw-w-1/2">
          <ConversionFunnelKPILoadingSkeleton v-if="isFetching" />

          <div
            v-else
            class="tw-flex tw-flex-col tw-w-full"
            :class="{
              'tw-h-full': visitsDataFormattedForChart !== undefined && visitsDataFormattedForChart.graph.length > 0,
            }"
          >
            <ConversionFunnelKPITitle
              :tooltip-text="$t('infobubble_definition_clicks')"
              :custom-html="$t('kpi_clicks')"
            />
            <ConversionFunnelKPIHeader
              :value="visits"
              :variation="visitsVariation"
              :percentage="visitsPercentage"
              :percentage-of="visitsPercentageOf"
            />
            <ConversionFunnelKPIChart
              v-show="visitsDataFormattedForChart"
              :data-formatted-for-chart="visitsDataFormattedForChart"
              :container-id="'kpi_visits_chart'"
              :series-name="$t('kpi_chart_clicks')"
            />
          </div>
        </div>
        <div class="tw-mt-5 md:tw-mt-0 tw-pl-2 tw-flex tw-flex-col tw-h-full md:tw-w-1/2">
          <ConversionFunnelKPILoadingSkeleton v-if="partnerIsFetching || isLoading" />
          <ConversionFunnelPartnerDistribution v-else :partnerDistribution="partnerDistributionWithNames" />
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import ConversionFunnelHeader from '@/components/profilePerformance/conversionFunnel/ConversionFunnelHeader.vue'
import ConversionFunnelKPITitle from '@/components/profilePerformance/conversionFunnel/ConversionFunnelKPITitle.vue'
import ConversionFunnelKPIHeader from '@/components/profilePerformance/conversionFunnel/ConversionFunnelKPIHeader.vue'
import ConversionFunnelKPIChart from '@/components/profilePerformance/conversionFunnel/ConversionFunnelKPIChart.vue'
import ConversionFunnelPartnerDistribution from '@/components/profilePerformance/conversionFunnel/ConversionFunnelPartnerDistribution.vue'
import ConversionFunnelKPILoadingSkeleton from '@/components/loadingSkeletons/ConversionFunnelKPILoadingSkeleton.vue'
import { useConversionFunnelData, usePartnerNames } from '@/components/profilePerformance/queries/conversionFunnel.js'
import { selectedAccommodationId } from '@/layouts/queries'

const { t } = useI18n()
const {
  viewsDataFormattedForChart,
  views,
  viewsPercentage,
  viewsPercentageOf,
  viewsVariation,
  visitsDataFormattedForChart,
  visits,
  visitsVariation,
  visitsPercentage,
  visitsPercentageOf,
  partnerDistribution,
  isFetching,
} = useConversionFunnelData(selectedAccommodationId)

const partnerIds = computed(() => {
  const ids = []
  partnerDistribution.value?.map(item => {
    if (item.partner_name !== 'partner_others' && item.partner_name !== 'other_partners') {
      ids.push(Number(item.partner_name.split('_')[1]))
    }
  })

  return ids.filter(n => n)
})

const { isLoading, partnerIsFetching, partners } = usePartnerNames(partnerIds)

const partnerDistributionWithNames = computed(() => {
  return partnerDistribution.value?.map(item => {
    let name
    partners.value?.data?.getAdvertiserDetails?.advertiserDetails.forEach(partner => {
      if (item.partner_name === 'partner_others' || item.partner_name === 'other_partners') {
        name = t('tri_other_sources')
      } else if (partner.nsid.id == item.partner_name.split('_')[1]) {
        name = partner.translatedName.value
      }
    })
    return {
      ...item,
      partnerTranslatedName: name,
    }
  })
})
</script>
<style scoped>
.base-card-h {
  min-height: 260px;
  max-height: 250px;
}

.kpi-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.kpi-visits-section {
  display: flex;
}

@media (max-width: theme('screens.md')) {
  .kpi-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
