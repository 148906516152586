<template>
  <BaseCard no-padding class="tw-text-sm md:tw-flex md:tw-justify-between md:tw-items-center tw-relative md:tw-gap-4">
    <div>
      <div class="tw-flex tw-items-center">
        <span
          v-if="severity"
          aria-hidden="true"
          class="tw-h-3 tw-w-3 tw-rounded-full tw-mr-2"
          :class="severityColorClass"
        ></span>
        <strong>{{ header }}</strong>
      </div>
      <p>{{ description }}</p>
    </div>
    <slot name="ctaContent" />
    <button
      v-if="dismissible"
      class="tw-outline-none tw-absolute tw-right-2 tw-top-2"
      @click="requestDismissNotification"
      @keydown.enter="requestDismissNotification"
    >
      <BaseIcon :height="24" :width="24" :viewbox-height="24" :viewbox-width="24" icon-name="cross">
        <Cross />
      </BaseIcon>
    </button>
  </BaseCard>
</template>

<script setup>
import { computed } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'
import { useNotificationsMutation } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { dismissNotification } = useNotificationsMutation()

const props = defineProps({
  description: {
    type: String,
    required: true,
    default: '',
  },
  header: {
    type: String,
    required: true,
    default: '',
  },
  severity: {
    type: String,
    required: false,
    default: 'success',
    validator(value) {
      return ['', 'danger', 'success', 'warning'].includes(value)
    },
  },
  dismissible: {
    type: Boolean,
    required: false,
    default: false,
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
  action: {
    type: String,
    required: false,
    default: null,
  },
})

const severityColorClass = computed(() => {
  if (props.severity === 'danger') return 'tw-bg-red-800'
  if (props.severity === 'warning') return 'tw-bg-yellow-700'
  return 'tw-bg-green-700'
})

const requestDismissNotification = () => {
  dismissNotification({ accommodationId: selectedAccommodationId.value, notificationName: props.name })
}
</script>
