import RateInsightsService from '@/services/RateInsightsService'
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'

export function useRateDefaults() {
  const { isLoading, data, isFetching, isError } = useQuery({
    queryKey: ['RIDefaults'],
    queryFn: () => RateInsightsService.getRatesDefaults(),
  })

  const aggregation = computed(() => data?.value?.data.aggregation)
  const range = computed(() => data?.value?.data.range)
  return { isDefaultsLoading: isLoading, aggregation, range, isDefaultsFetching: isFetching, isDefaultsError: isError }
}

export function useRateFilters() {
  const { isLoading, data, isFetching, isError } = useQuery({
    queryKey: ['RIFilters'],
    queryFn: () => RateInsightsService.getRatesFilters(),
  })

  const filters = computed(() => data?.value?.data)
  return { isFiltersLoading: isLoading, filters, iFiltersFetching: isFetching, isFiltersError: isError }
}

export function useAvailableBookingSites(accommodationId) {
  const { isLoading, data, isFetching, isError } = useQuery({
    queryKey: ['RIAvailableBookingSites', accommodationId],
    queryFn: async ({ queryKey }) => await RateInsightsService.getAvailableChannels({ accommodationId: queryKey[1] }),
  })

  const bookingSites = computed(() =>
    data?.value?.bookingSites
      ? data?.value?.bookingSites?.map(item => {
          const partner = data.value.partnersData?.advertiserDetails.find(partner => partner.nsid.id === item.id)
          return {
            ...item,
            fullName: partner?.translatedName.value,
          }
        })
      : []
  )

  return {
    isBookingSitesLoading: isLoading,
    bookingSites,
    isBookingSitesFetching: isFetching,
    isBookingSitesError: isError,
  }
}

export function useSelectedBookingSites(accommodationId) {
  const { isLoading, data, isFetching, isError } = useQuery({
    queryKey: ['RISelectedBookingSites', accommodationId],
    queryFn: async ({ queryKey }) => await RateInsightsService.getSelectedChannels({ accommodationId: queryKey[1] }),
    retryOnMount: false,
  })

  const selectedChannels = computed(() =>
    data?.value?.selectedChannels
      ? data?.value?.selectedChannels?.map(item => {
          const partner = data.value.partnersData?.advertiserDetails.find(partner => partner.nsid.id === item.id)
          return {
            ...item,
            fullName: partner?.translatedName.value,
            logo: partner?.logo,
          }
        })
      : []
  )

  return {
    isSelectedChannelsLoading: isLoading,
    selectedChannels,
    isSelectedChannelsFetching: isFetching,
    isSelectedChannelsError: isError,
  }
}

export function useCompetitors(accommodationId) {
  const { isLoading, data, isFetching, isError } = useQuery({
    queryKey: ['RICompetitors', accommodationId],
    queryFn: ({ queryKey }) => RateInsightsService.getCompetitors({ accommodationId: queryKey[1] }),
    retryOnMount: false,
  })
  const competitors = computed(() => (data?.value === undefined ? [] : data?.value?.data))
  return {
    isCompetitorsLoading: isLoading,
    competitors,
    isCompetitorsFetching: isFetching,
    isCompetitorsError: isError,
  }
}

export function useRates(marketRates, accommodationId, params) {
  const isQueryEnabled = computed(
    () =>
      !!params.aggregation.value &&
      !!params.channels.value &&
      !!params.competitors.value &&
      !!params.from.value &&
      !!params.to.value
  )

  const { isLoading, data, isFetching } = useQuery({
    queryKey: [
      'RIRates',
      marketRates,
      accommodationId,
      params.aggregation,
      params.channels,
      params.competitors,
      params.currency,
      params.from,
      params.to,
    ],
    enabled: isQueryEnabled,
    queryFn: ({ queryKey }) =>
      RateInsightsService.getRates({
        marketRates: queryKey[1],
        accommodationId: queryKey[2],
        params: {
          aggregation: queryKey[3],
          channels: queryKey[4],
          competitors: queryKey[5],
          currency: queryKey[6],
          from: queryKey[7],
          to: queryKey[8],
        },
      }),
  })

  const rates = computed(() => data?.value?.data)

  return { isRatesLoading: isLoading, rates, isRatesFetching: isFetching }
}

export function useTravelersDemand(accommodationId, params) {
  const isQueryEnabled = computed(() => !!params.startDate.value && !!params.endDate.value)
  const { isLoading, data, isFetching } = useQuery({
    queryKey: ['RITravelersDemand', accommodationId, params.startDate, params.endDate],
    enabled: isQueryEnabled,
    queryFn: ({ queryKey }) =>
      RateInsightsService.getTravelersDemand({
        accommodationId: queryKey[1],
        params: {
          startDate: queryKey[2],
          endDate: queryKey[3],
        },
      }),
  })

  const travelersDemand = computed(() => data?.value?.data)
  return { isTravelersDemandLoading: isLoading, travelersDemand, isTravelersDemandFetching: isFetching }
}

export function useSuggestions(accommodationId) {
  const { isLoading, data, isFetching } = useQuery({
    queryKey: ['RISuggestions', accommodationId],
    queryFn: ({ queryKey }) =>
      RateInsightsService.getSuggestions({
        accommodationId: queryKey[1],
      }),
  })

  const suggestions = computed(() => data?.value?.data)
  return { isSuggestionsLoading: isLoading, suggestions, isSuggestionsFetching: isFetching }
}

export function useSearchSuggestions(inputQuery) {
  const isQueryEnabled = computed(() => inputQuery.value.length > 2)
  const { isLoading, data, isFetching } = useQuery({
    queryKey: ['RISuggestionsSearch', inputQuery],
    enabled: isQueryEnabled,
    queryFn: ({ queryKey }) => RateInsightsService.searchForCompetitors(queryKey[1]),
  })

  const searchSuggestions = computed(() => data?.value?.data)
  return { isSearchSuggestionsLoading: isLoading, searchSuggestions, isSearchSuggestionsFetching: isFetching }
}

export function useCompetitorsUpdateMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: competitorsUpdateLoading,
    isSuccess: competitorsUpdateSuccess,
    isError: competitorsUpdateError,
    mutate: competitorsUpdate,
  } = useMutation({
    mutationFn: ({ accommodationId, competitors }) =>
      RateInsightsService.updateSelectedCompetitors({ accommodationId, competitors }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RICompetitors'], refetchInactive: true })
    },
  })

  return {
    competitorsUpdateLoading,
    competitorsUpdateSuccess,
    competitorsUpdateError,
    competitorsUpdate,
  }
}

export function useSelectedBookingSitesUpdateMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: bookingSitesUpdateLoading,
    isSuccess: bookingSitesUpdateSuccess,
    isError: bookingSitesUpdateError,
    mutate: bookingSitesUpdate,
  } = useMutation({
    mutationFn: ({ accommodationId, bookingSites }) =>
      RateInsightsService.updateBookingSites({ accommodationId, bookingSites }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['RISelectedBookingSites'], refetchInactive: true })
    },
  })

  return {
    bookingSitesUpdateLoading,
    bookingSitesUpdateSuccess,
    bookingSitesUpdateError,
    bookingSitesUpdate,
  }
}
