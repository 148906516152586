<template>
  <div class="tw-hidden sm:tw-flex tw-relative" :key="selectedAccommodationId">
    <LaunchpadLoader
      v-if="initialDataLoading || !redirectPath"
      class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1"
    />
    <div v-else-if="requiredDataHasErrors" class="tw-flex tw-justify-center tw-items-center tw-flex-1">
      <ErrorModal
        :allow-retry="false"
        :app-name="'Rate Insights'"
        :use-app-name="true"
        header-key="onboarding.appLaunchError.header"
        content-key="onboarding.appLaunchError.text"
        retry-key="onboarding.appLaunchError.retryButton"
      />
    </div>
    <router-view v-else />
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
  </div>
</template>
<script setup>
import { ref, onUpdated, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  useRateDefaults,
  useRateFilters,
  useSelectedBookingSites,
  useCompetitors,
} from '@/components/rateInsights/queries'
import { useStudioPlusCampaign } from '@/components/payment/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { useAllApps } from '@/components/dashboard/nav/queries'

import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'

const router = useRouter()
const route = useRoute()
const rateInsightsId = import.meta.env.VITE_RATE_INSIGHTS_ID

const redirectPath = ref(false)

const { visibleOwnedApps, isLoadingApps, isFetchingApps } = useAllApps(selectedAccommodationId)

const { isDefaultsLoading, isDefaultsFetching, isDefaultsError } = useRateDefaults()
const { isFiltersLoading, isFiltersFetching, isFiltersError } = useRateFilters()
const { isSelectedChannelsLoading, isSelectedChannelsFetching } = useSelectedBookingSites(selectedAccommodationId)
const { isCompetitorsLoading, competitors, isCompetitorsFetching, isCompetitorsError } =
  useCompetitors(selectedAccommodationId)
const { studioPlusCampaign, isLoading, isExpiredSubscription } = useStudioPlusCampaign(selectedAccommodationId)

const requiredDataHasErrors = computed(() => isDefaultsError.value || isFiltersError.valu)
const initialDataLoading = computed(
  () =>
    isLoading.value ||
    isDefaultsLoading.value ||
    isFiltersLoading.value ||
    isSelectedChannelsLoading.value ||
    isCompetitorsLoading.value ||
    isFetchingApps.value ||
    isLoadingApps.value
)
const requiredDataIsBeingRefetched = computed(
  () =>
    !initialDataLoading.value &&
    (isDefaultsFetching.value ||
      isFiltersFetching.value ||
      isSelectedChannelsFetching.value ||
      isCompetitorsFetching.value)
)
const hasCompetitors = computed(
  () => competitors?.value.length > 0 && competitors.value !== undefined && !isCompetitorsError.value
)
const isMobile = document.body.clientWidth >= 500 ? ref(false) : ref(true)

watch(selectedAccommodationId, () => {
  redirectPath.value = false
})

watch(competitors, () => {
  redirectPath.value = false
})

onMounted(() => {
  if (route.name === 'rate-insights') {
    decideRoute()
  }
})

onUpdated(() => {
  if (!redirectPath.value || route.name === 'rate-insights') decideRoute()
})

const isAvailable = computed(() => {
  return (
    visibleOwnedApps.value.find(item => item.app_id === rateInsightsId) ||
    (studioPlusCampaign.value && !isExpiredSubscription.value)
  )
})

const decideRoute = () => {
  if (initialDataLoading.value || requiredDataIsBeingRefetched.value) return
  if (!isAvailable.value || isMobile.value) router.replace('/')

  const currentPath = route.name
  if ((currentPath === 'rate-insights' || currentPath === 'rate-insights-view') && !hasCompetitors.value) {
    router.replace('/rate-insights/onboarding')
    return
  }
  if ((currentPath === 'rate-insights' || currentPath === 'rate-insights-onboarding') && hasCompetitors.value) {
    router.replace('/rate-insights/view')
    return
  }

  redirectPath.value = true
}
</script>
