<template>
  <div :class="{ 'tw-w-full': fullwidth }">
    <label :for="id" :class="{ 'tw-mb-0': !label }">
      {{ label }}
      <textarea
        :id="id"
        :value="value"
        :type="type"
        v-bind="$attrs"
        :placeholder="placeholder"
        :rows="rowSize"
        class="tw-appearance-none tw-border tw-w-full focus:tw-outline-0 tw-bg-white tw-border-gray-500 focus:tw-border-blue-900 focus:tw-text-blue-900 tw-py-2 tw-px-3 tw-rounded-lg"
        @blur="$emit('onChange', $event.target.value)"
        @input="$emit('onChange', $event.target.value)"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'TextareaField',
  props: {
    fullwidth: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'textfield',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    rowSize: {
      type: String,
      required: false,
      default: '4',
    },
  },
}
</script>
<style scoped>
input {
  border-color: theme('colors.gray.500');
}
input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-inline-start: auto;
}
input[type='date'] {
  max-height: 38px;
}
label {
  @apply tw-w-full;
}
</style>
