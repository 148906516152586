import StdioPostMessage from '@studio.sandbox/stdio-post-message'

const pm = new StdioPostMessage({
  // branch name is empty on production so we can disable debugging
  debug: import.meta.env.VITE_DEBUG_POST_MESSAGES === 'true',
  methods: {},
  allowedOrigins: [import.meta.env.VITE_STUDIO_CLIENT_URL, import.meta.env.VITE_PAYMENT_CLIENT_URL],
})

export default pm
