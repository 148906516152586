const events = {
  incoming: {
    ORDER_COMPLETED: 'ORDER_COMPLETED',
    ORDER_PENDING: 'ORDER_PENDING',
    SYNC_PATH: 'SYNC_PATH',
    PAYMENT_CHECKOUT_FAILED: 'PAYMENT_CHECKOUT_FAILED',
    TRIGGER_APP_CLOSE: 'TRIGGER_APP_CLOSE',
    TRIGGER_CHECKOUT_PROCESS: 'TRIGGER_CHECKOUT_PROCESS',
    TRACK_IN_SEGMENT: 'TRACK_IN_SEGMENT',
    APP_READY: 'APP_READY',
    BILLING_INFORMATION_UPDATED: 'BILLING_INFORMATION_UPDATED',
  },
  outgoing: {
    SESSION: 'SESSION',
    ACCOMMODATION_SELECTED: 'ACCOMMODATION_SELECTED',
    LOCALE_CONTEXT_UPDATED: 'LOCALE_CONTEXT_UPDATED',
    CHECKOUT_PROCESS_UPDATE: 'CHECKOUT_PROCESS_UPDATE',
    USER_ABORTED: 'USER_ABORTED',
    INVALID_PRICE_PLAN_ID: 'INVALID_PRICE_PLAN_ID',
    INVALID_QUANTITY: 'INVALID_QUANTITY',
    UNABLE_TO_FETCH_INFORMATION: 'UNABLE_TO_FETCH_INFORMATION',
  },
}

export { events }
