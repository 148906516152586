<template>
  <div v-show="isInitialized">
    <iframe
      id="app"
      ref="launchedAppIframe"
      :class="{ 'is-visually-hidden': !loaded }"
      :src="launchedApp ? launchedApp.url : 'about:blank'"
      :sandbox="sandboxValues"
      :title="launchedApp && $t(launchedApp.name)"
      name="app"
      class="app-container-target"
      @load="onLoad"
    />
    <div>
      <iframe
        v-if="launchedOverlayApp"
        id="overlayApp"
        ref="launchedOverlayAppIframe"
        :src="launchedOverlayApp ? launchedOverlayApp.url : 'about:blank'"
        :sandbox="sandboxValues"
        :title="$t(launchedOverlayApp.name)"
        name="overlayApp"
        class="app-container-target overlay"
        @load="onLoad"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { toRaw, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

import { useAllApps } from '@/components/dashboard/nav/queries'
import { selectedAccommodationId, useAccommodationsByOrgId, useUserDetails } from '@/layouts/queries'

import { useEventsBus } from '@/composables/useEventBus.js'
import appAnalytics from '@/utils/tracking'
import pm from '@/utils/postMessage'
import { events } from '@/constants/events'
import incomingEvents from '@/domain/app/events-incoming'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const { displayNotification } = useToastNotifications()
const { busEvent, on } = useEventsBus()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useStore()
const isInitialized = ref(false)
const isLaunchingNewApp = ref(false)
const appToBeLaunched = ref(null)
const launchedOverlayAppIframe = ref(null)
const launchedAppIframe = ref(null)

const locale = computed(() => store.state.locale)
const apps = computed(() => store.state.apps.apps)

const launchedApp = computed(() => store.state.app.launchedApp)
const launchedOverlayApp = computed(() => store.state.app.launchedOverlayApp)
const loaded = computed(() => store.state.app.loaded)
const userId = computed(() => store.state.session.userId)
const { userDetails } = useUserDetails(userId)
const appSession = computed(() => store.getters['session/appSession'])
const trustedAppSession = computed(() => store.getters['session/trustedAppSession'])
const appContext = computed(() => store.getters['app/appContext'])
const { isLoadingApps, allApps } = useAllApps(selectedAccommodationId)
const isAppsLoaded = computed(() => !isLoadingApps.value && allApps.value)
const setApp = data => store.dispatch('app/setApp', data)
const closeOverlayApp = () => store.dispatch('app/closeOverlayApp')
const setLoaded = state => store.commit('app/SET_LOADED', state)
const setAppPath = path => store.commit('app/SET_PATH', path)
const addApplicationToStore = app => store.commit('apps/ADD_APP', app)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const selectedAssignment = computed(() => {
  if (!selectedAccommodationId) {
    return {}
  } else {
    return {
      accommodationId: selectedAccommodationId.value,
      status: 'active',
      organizationId: selectedOrganisationId.value,
    }
  }
})

const emit = defineEmits(['checkoutFailure', 'checkoutSuccess'])

const openAppsReferences = computed(() => {
  const openAppArray = [launchedAppIframe]
  if (launchedOverlayApp.value) {
    openAppArray.push(launchedOverlayAppIframe)
  }
  return openAppArray
})

const sandboxValues = computed(() => {
  let values =
    'allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-top-navigation allow-downloads'

  return values
})

watch(appContext, newAppContext => {
  if (!newAppContext) return
  emitEvent({
    event: events.outgoing.LOCALE_CONTEXT_UPDATED,
    payload: newAppContext,
  })
})

watch(selectedAccommodationId, () => {
  communicateSession()
})

// If the app session is changing, we only need to inform our internal apps
watch(appSession, async newAppSession => {
  if (!newAppSession) return
  launchedApp?.value.trusted && communicateSession()
  if (launchedOverlayApp.value) {
    communicateSession({
      selector: launchedOverlayAppIframe.value,
    })
  }
})

/**
 * When the user updates the accommodationId it updates the the owned apps as well
 * Open an app only after the store updated with the apps belonging to the selected accommodation id
 * For eg: Opening an app belongs to any accommodation from subscription manager
 */
watch(isAppsLoaded, async newIsAppLoaded => {
  if (!newIsAppLoaded) return
  if (isLaunchingNewApp.value && appToBeLaunched.value) {
    await setApp({ app: appToBeLaunched.value, source: 'trustedApps' })
    isLaunchingNewApp.value = false
  }
})

/**
 * When the user press back button and the overlay app is launched over the opened app
 * Then the user should be landed back to the opened app instead of the previous URL
 */
watch(route, async (newRoute, oldRoute) => {
  if (oldRoute.query.checkout_active && launchedOverlayApp) {
    handleBrowserbackOnOverlayApp()
  }
})

onMounted(() => {
  // allow app communication from other components
  on('app.emit.event', emitEvent)
  registerGenericAppEventHandlers()
  registerLaunchedAppEventHandlers()

  // register these only for our internal checkout app (which has the id 2)
  if (launchedApp?.value.app_id === '2') {
    registerCheckoutAppEventHandlers()
  }
  isInitialized.value = true
})

onBeforeUnmount(() => {
  // registerGenericAppEventHandlers
  delete pm.methods[events.incoming.TRIGGER_APP_CLOSE]

  // registerLaunchedAppEventHandlers
  delete pm.methods[events.incoming.SYNC_PATH]
  delete pm.methods[events.incoming.TRIGGER_CHECKOUT_PROCESS]
  delete pm.methods[events.incoming.APP_READY]

  // registerCheckoutAppEventHandlers
  delete pm.methods[events.incoming.ORDER_COMPLETED]
  delete pm.methods[events.incoming.ORDER_PENDING]
  delete pm.methods[events.incoming.PAYMENT_CHECKOUT_FAILED]
})

const triggerCheckoutMethod = ({ payload, launchedApp, isRC }) => {
  incomingEvents.triggerCheckoutProcess({
    payload,
    appConfigurationErrorMessage: 'errors.wrong_data_from_app',
    locale: locale,
    accommodationId: selectedAccommodationId.value,
    launchedApp: launchedApp.value,
    launchOverlayApp: launchOverlayApp,
    generateCheckoutPath: generateCheckoutPath,
    registerCheckoutEventHandlers: registerCheckoutAppEventHandlers,
    communicateCheckoutFailure: isRC ? rcCommunicateCheckoutFailure : communicateCheckoutFailure,
  })
}

const launchOverlayApp = async ({ path = '', id = '0' } = {}) => {
  const app = allApps.value.find(item => item.app_id === id)
  await setApp({
    path,
    app: app,
    source: 'app',
    pushRoute: false,
    isOverlay: true,
  })
}

const generateCheckoutPath = (payload = {}, product = {}) => {
  const hasSinglePlan = product?.pricing?.length === 1
  const hasSingleCharge = product?.pricing?.[0]?.charges?.length === 1
  const singleChargeType = product?.pricing?.[0]?.charges?.[0]?.type
  const planType =
    hasSinglePlan && hasSingleCharge && typeof singleChargeType === 'string' ? singleChargeType.toLowerCase() : 'paid'
  const pricePlanId = hasSinglePlan ? product?.pricing?.[0]?.id : payload?.pricePlanId
  const isBillingInformationUpdate = payload?.context?.isBillingDataModification

  if (!isBillingInformationUpdate && (!product?.id || !pricePlanId)) {
    // Additional check to avoid errors in checkout page
    communicateCheckoutFailure({
      type: events.outgoing.UNABLE_TO_FETCH_INFORMATION,
      message: 'Studio is unable to fetch the product/pricing information, please try again later',
    })
    return
  }

  // we generate a base-64 encoded string to prevent easy url tampering
  // for the regular user
  let data = {
    appId: launchedApp?.value.app_id, // needed for changing the payment methods. Don't remove!!
    productId: product?.id,
    productName: product?.details?.[0]?.name,
    accommodationId: selectedAccommodationId.value,
    pricePlanId,
    quantity: payload?.quantity,
    planType,
    context: payload?.context,
  }

  data = window.btoa(JSON.stringify(data))

  return `?details=${data}`
}
// These events can be triggered by any application and are not filtered by origin.
const registerGenericAppEventHandlers = () => {
  /**
   * #1 - Allows an app to close itself performing specific actions define below
   */
  pm.methods[events.incoming.TRIGGER_APP_CLOSE] = ({ payload, name }) => {
    if (router.currentRoute.value.name === 'rate-connect-setup') {
      emit('checkoutFailure', {
        type: events.outgoing.USER_ABORTED,
        payload,
        message: 'User cancelled the checkout process.',
      })
      closeOverlayApp()
    } else {
      incomingEvents.closeApp({
        payload,
        name,
        $router: router,
        apps: apps.value,
        closeOverlayApp: closeOverlayApp,
        communicateCheckoutFailure: communicateCheckoutFailure,
      })
      router.back()
    }
  }

  /**
   * #3 - Allows us to collect application errors and send it to segment
   */
  pm.methods[events.incoming.TRACK_IN_SEGMENT] = ({ payload }) => {
    incomingEvents.logAppErrorInSegment({
      payload,
      launchedApp: launchedApp.value,
      launchedOverlayApp: launchedOverlayApp.value,
    })
  }
}

// These events can be triggered by any application but should only work when
// the event origin matched the launched app url.
const registerLaunchedAppEventHandlers = () => {
  /**
   * #1 - Applications are able to send us route changes such that they
   * can be reflected within our routing.
   */
  pm.methods[events.incoming.SYNC_PATH] = ({ payload, origin, name }) => {
    incomingEvents.syncPath({
      payload,
      origin,
      name,
      launchedApp: launchedApp.value,
      handleAppRouteChange: handleAppRouteChange,
    })
  }

  /**
   * #2 - Initiate the checkout process from within an app
   */
  pm.methods[events.incoming.TRIGGER_CHECKOUT_PROCESS] = ({ payload, origin, launchedApp }) => {
    triggerCheckoutMethod({ payload, origin, isRC: false, launchedApp })
    const { path, query, params, name } = route
    /*
     * Checkout page is an overlay app
     * When the checkout app is open, we need to add that to the URL query so that when the user
     * clicks on the browser back button, it would be easier for us to take the user back to the app instead of dashboard
     */
    router.push({ name, path, params, query: { ...query, checkout_active: true } })
  }

  /**
   * #2 - Initiate the checkout process from within an app
   */
  pm.methods[events.incoming.APP_READY] = ({ name }) => {
    const isOverlay = name === 'overlayApp'
    const performanceMarkPrefix = isOverlay ? 'overlay-' : ''

    try {
      performance.mark(`${performanceMarkPrefix}appReady`)
    } catch {}
  }
}

/**
 * When the user presses back button when an overlay app is launched
 * Then the opened app should be getting USER_ABORTED event and land in the same app
 */
const handleBrowserbackOnOverlayApp = () => {
  incomingEvents.closeApp({
    payload: {
      details: {},
      message: 'User cancelled the checkout process.',
      type: 'USER_ABORTED',
      success: false,
    },
    name: 'overlayApp',
    $router: router,
    apps: apps.value,
    closeOverlayApp: closeOverlayApp,
    communicateCheckoutFailure: communicateCheckoutFailure,
  })
}

// These events should only work for our internal checkout application.
const registerCheckoutAppEventHandlers = () => {
  /**
   * #1 - This event is fired when an order was successfull.
   */
  pm.methods[events.incoming.ORDER_COMPLETED] = ({ payload, name }) => {
    incomingEvents.orderCompleted({
      payload,
      name,
      closeOverlayApp: closeOverlayApp,
      checkSuccessAppLaunch: checkOutSuccessAndAppLaunch,
      checkoutSuccessDashboard: checkOutSuccessAndDashboard,
      communicateCheckoutSuccess: communicateCheckoutSuccess,
      emitCurrencyRefetch: emitCurrencyRefetch,
    })
  }

  /**
   * #2 - This is a timeout event where the client is not aware of what
   * the last status is.
   */
  pm.methods[events.incoming.ORDER_PENDING] = ({ payload, name }) => {
    incomingEvents.orderPending({
      payload,
      name,
      closeOverlayApp: closeOverlayApp,
      communicateCheckoutFailure: communicateCheckoutFailure,
      redirectToProductDetailsPage: redirectToProductDetailsPage,
    })
  }

  /**
   * #3 - The checkout will let us know when the order process failed.
   */
  pm.methods[events.incoming.PAYMENT_CHECKOUT_FAILED] = ({ payload }) => {
    incomingEvents.paymentCheckoutFailedFromStudio({
      payload,
      $router: router,
      $t: t,
    })
  }

  /**
   * #5 - Handles the case when the checkout is open to only change the billing or payment information
   */
  pm.methods['BILLING_INFORMATION_UPDATED'] = () => {
    emitEvent({
      event: 'BILLING_INFORMATION_UPDATED',
      url: import.meta.env.VITE_STUDIO_CLIENT_URL,
      retries: 3,
    })
  }
}

const emitCurrencyRefetch = () => {
  busEvent('trigger.currency.refetch')
}

const checkOutSuccessAndDashboard = (productName = '') => {
  showSuccessPurchaseMessage({ name: productName })
  router.push({ name: 'rate-connect' })
}

const checkOutSuccessAndAppLaunch = async appId => {
  const app = allApps.value.find(item => item.app_id === appId)
  if (launchedOverlayApp.value) return closeOverlayApp()
  if (!app) return setApp()

  // launch the new app and show a success message after
  // the checkout process was completed successfully
  showSuccessPurchaseMessage(app)

  // optimistically attach the purchased app to our "owned" apps
  await addApplicationToStore({ app, target: 'owned' })

  setApp({ app, source: 'checkout' })
}

const showSuccessPurchaseMessage = (app, isOverlayApp = false) => {
  let titleTranslationKey = isOverlayApp
    ? 'pages.marketplace.app.messages.order_success.usage_based.title'
    : 'pages.marketplace.app.messages.order_success.title'
  let bodyTranslationKey = isOverlayApp
    ? 'pages.marketplace.app.messages.order_success.usage_based.body'
    : 'pages.marketplace.app.messages.order_success.body'
  const contactUsLinkHtml = `<a href="#" onclick="if (window._elev) window._elev.openHome()">${'pages.marketplace.app.messages.order_success.contactUs'}</a>`
  displayNotification({
    titleTranslationKey: true,
    title: titleTranslationKey,
    titleTranslationParams: { app_name: app?.name },
    message: bodyTranslationKey,
    translationParams: { app_name: app?.name, email: userDetails?.value.email, contact: contactUsLinkHtml },
    isTranslationKey: true,
    type: 'success',
  })
}

const redirectToProductDetailsPage = productId => {
  router.push({
    name: 'marketplace-products-id',
    params: {
      id: productId,
    },
  })
}

const onLoad = async (event = {}) => {
  if (!event.target) return

  const prefix = event.target.name === 'overlayApp' ? 'overlay-' : ''

  try {
    performance.mark(`${prefix}appIframeLoaded`)
  } catch {}

  pm.addAllowedOrigin(event.target.src)
  await communicateSession({ selector: event.target })
}

const handleAppRouteChange = async ({ path = '' } = {}) => {
  // only enabled for app page (not in onboarding)
  const allowedRoutes = ['apps-slug-id', 'products-id']
  if (!allowedRoutes.includes(route.name)) return

  const cleanPath = encodeURIComponent(path)

  setAppPath(path)
  try {
    await router.replace({ query: { path: cleanPath } })
  } catch {}
}

const onAppForcedClose = async () => {
  busEvent('app.close.forced', { ...launchedApp.value })

  // closing the app results in destroying this component
  // make sure to not access "this" after triggering
  onAppClose()
}
const onAppClose = () => {
  setApp()
}
// communicates to the apps that the checkout was a success and notifies the user accordingly
const communicateCheckoutSuccess = async (payload, appId) => {
  // Update the appToken in the store and send another SESSION event
  const app = allApps.value.find(item => item.app_id === appId)

  const rateConnectId = import.meta.env.PROD
    ? import.meta.env.VITE_RATE_CONNECT_ID
    : 'b2690842-bf69-4f93-9ddb-c5dd027aa7ee'
  const pricingPlanDetails = {
    pricePlanId: payload?.pricePlanId,
    quantity: payload?.quantity,
  }
  showSuccessPurchaseMessage(app, true)

  if (appId === rateConnectId && router.currentRoute.value.name === 'rate-connect-setup') {
    emit('checkoutSuccess', { zuoraSubscriptionNumber: payload?.subscriptionNumber })
  } else {
    emitEvent({
      event: events.outgoing.CHECKOUT_PROCESS_UPDATE,
      payload: {
        success: true,
        error: null,
        context: {
          subscription_number: payload?.subscriptionNumber, // Deprecated, but required for older apps
          subscriptionNumber: payload?.subscriptionNumber, // Required String of the zuora subscription number for direct usage if required.
          ...(pricingPlanDetails.pricePlanId && pricingPlanDetails), // Optional
        },
      },
    })
  }
}

// communicates to the apps that the checkout didn't succeeded.
// If the user closed the checkout, no details are provided
// If the transaction got timed out, the app will receive the checkout params in the details
const communicateCheckoutFailure = ({ type, payload = {}, message = '' }) => {
  emitEvent({
    event: events.outgoing.CHECKOUT_PROCESS_UPDATE,
    payload: {
      success: false,
      error: {
        type,
        message,
        details: payload, // optional
      }, // or null
      context: null,
    },
  })
}

// communicates to the apps that the checkout didn't succeeded in terms of rate connect triggered directly from  studio.
// If the user closed the checkout, no details are provided
// If the transaction got timed out, the app will receive the checkout params in the details
const rcCommunicateCheckoutFailure = ({ payload = {} }) => {
  emit('checkoutFailure', {
    type: events.outgoing.USER_ABORTED,
    payload,
    message: 'User cancelled the checkout process.',
  })
  closeOverlayApp()
}

const communicateSession = debounce(async ({ selector = launchedAppIframe } = {}) => {
  let loadingAppId
  try {
    loadingAppId = launchedApp?.value.app_id
    const accommodation = toRaw(currentAccommodation.value)
    const accommodationPayload = {
      accommodation: { ...accommodation, id: accommodation.accommodationId },
      assignment: toRaw(selectedAssignment.value),
    }
    // payload for all apps
    const defaultPayload = {
      selectedAccommodation: accommodationPayload,
      context: toRaw(appContext.value),
    }

    // application type based on iframe name attribute
    const appType = selector?.name || 'app'

    // we need to consider a regular and an overlay app when we determine the payload
    const isInternal =
      (appType === 'app' && launchedApp?.value.trusted) ||
      (appType === 'overlayApp' && launchedOverlayApp?.value.trusted)

    const appTokenPayload = {
      user: {
        id: userId.value,
        email: userDetails.value?.email,
        name: `${userDetails.value?.firstName} ${userDetails.value?.lastName}`,
      },
    }

    // Vue objects are normally Proxy type, which postMessage cannot handle, so we clone its value as a standard object before passing it
    const trustedAppSessionRaw = cloneDeep(trustedAppSession.value)

    // internal apps are trusted and receive more information they can use
    await pm.emitToChild({
      payload: isInternal
        ? {
            ...trustedAppSessionRaw,
            ...defaultPayload,
          }
        : {
            accessToken: `${appSession.value.accessToken.token_type} ${appSession.value.accessToken.access_token}`,
            ...defaultPayload,
            ...appTokenPayload,
          },
      event: events.outgoing.SESSION,
      selector,
      retries: 5,
      messageTimeout: 2000,
    })

    setLoaded(true)

    // track event
    if (launchedApp.value) {
      const appId = launchedOverlayApp.value ? launchedOverlayApp.value?.app_id : launchedApp.value?.app_id
      const appName = launchedOverlayApp.value ? launchedOverlayApp.value?.name : launchedApp.value?.name
      const source = launchedOverlayApp.value ? launchedApp.value?.app_id : launchedApp.value?.stdio_source
      // track launched success event
      appAnalytics.track(appAnalytics.events.APP_LAUNCHED, {
        app_id: appId,
        app_name: appName,
        source: source,
        accommodation_id: selectedAccommodationId.value,
      })
    }
  } catch {
    if (loadingAppId === launchedApp.value.app_id) {
      onAppForcedClose()
    }
  }
}, 500)

const emitEvent = async ({ event = false, payload = {} } = {}) => {
  if (typeof event !== 'string') return
  try {
    const appContextEvents = []
    openAppsReferences.value.forEach(reference => {
      const contextEvent = pm.emitToChild({
        payload: toRaw(payload),
        event,
        selector: toRaw(reference.value),
      })
      appContextEvents.push(contextEvent)
    })
    await Promise.allSettled(appContextEvents)
  } catch {
    // ignore
  }
}
defineExpose({
  triggerCheckoutMethod,
})
</script>
<style scoped>
.app-container-target {
  @apply tw-block tw-w-full tw-min-h-screen-minus-header tw-overflow-y-auto tw-border-none;
  z-index: 9;
}

.overlay {
  @apply tw-absolute tw-top-0 tw-min-h-screen tw-z-50;
}
</style>
