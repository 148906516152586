<template>
  <CustomModal
    class="reactivation-modal"
    :is-modal-open="isOpen"
    @toggleModal="closeModal"
    @onClickAway="closeModal"
    :overlay="'dark'"
    :with-header="false"
    :with-footer="false"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-screen-lg lg:tw-w-[1024px] md:tw-rounded-md tw-rounded-none'"
  >
    <div class="tw-flex tw-flex-col tw-p-4 tw-pb-6">
      <h4 class="tw-font-bold tw-text-xl">{{ $t('business_studio_plus_reactivation_checkout') }}</h4>
      <p v-if="subscriptionType === 'MONTHLY'" class="tw-flex tw-flex-wrap tw-py-6">
        {{
          $t('business_studio_plus_reactivation_info_monthly', {
            SubscriptionPrice: `${CURRENCIES[currency]}${$n(monthlyPrice / 100, 'currencyCode')}`,
            RenewalDate: `${nextPayment}`,
          })
        }}
      </p>
      <p v-if="subscriptionType === 'YEARLY'" class="tw-flex tw-flex-wrap tw-py-6">
        {{
          $t('business_studio_plus_reactivation_info_annual', {
            SubscriptionPrice: `${CURRENCIES[currency]}${$n(yearlyPrice / 100, 'currencyCode')}`,
            RenewalDate: `${nextPayment}`,
          })
        }}
      </p>
      <small>*{{ $t('business_studio_plus_tax_disclaimer') }}</small>
      <div class="tw-w-full tw-flex tw-items-end tw-justify-end tw-gap-4">
        <GhostButton size="medium" @click="closeModal">{{ $t('assign_cancel_cta') }}</GhostButton>
        <MainButton size="medium" @click="reactivate(accommodationId)">
          <span>{{ $t('business_studio_plus_reactivate_cta') }}</span>
        </MainButton>
      </div>
    </div>
  </CustomModal>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { useStudioPlusPrice } from '@/components/payment/queries'
import { CURRENCIES } from '@/constants/currencies.js'

const store = useStore()
const props = defineProps({
  isOpen: Boolean,
  nextPayment: String,
  accommodationId: Number,
  subscriptionType: String,
})

const { studioPlusPriceMonthly, studioPlusPriceAnnual } = useStudioPlusPrice({ value: props.accommodationId })
const monthlyPrice = computed(() => studioPlusPriceMonthly.value?.price ?? 0)
const yearlyPrice = computed(() => (studioPlusPriceAnnual.value?.price ? studioPlusPriceAnnual.value?.price * 12 : 0))
const emit = defineEmits(['closeModal', 'reactivate'])
const closeModal = () => emit('closeModal')
const reactivate = accommodationId => {
  emit('reactivate', accommodationId)
}

const currency = computed(() => store.state.currency)
</script>
