const appContext = (state, getters, rootState) => {
  return {
    version: 1,
    currency: {
      code: rootState.currency,
    },
    locale: {
      tag: rootState.locale.language,
    },
  }
}

export default {
  appContext,
}
