<template>
  <div>
    <div
      v-if="isVisitorsProfileDurationLoading || isCompetitorsFetching"
      class="tw-h-[425px] tw-bg-gray-300 tw-animate-pulse tw-rounded-lg"
    ></div>
    <div v-else-if="isVisitorsProfileDurationError || isCompetitorsError" class="tw-h-[425px]"></div>
    <div v-else>
      <CompetitorsComparisonTable :columns="columns" :timeFrame="timeFrame" :tableData="visitorsProfileDuration" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import CompetitorsComparisonTable from '@/components/visitorsProfile/common/CompetitorsComparisonTable.vue'
import { useVisitorsProfileDuration } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { useCompetitors } from '@/components/rateInsights/queries'

const props = defineProps({
  timeFrame: {
    type: String,
    required: true,
    default: '30',
  },
})

const { isCompetitorsFetching, isCompetitorsError } = useCompetitors(selectedAccommodationId)

const timeFrameSelected = computed(() => props.timeFrame)
const { isVisitorsProfileDurationLoading, isVisitorsProfileDurationError, visitorsProfileDuration } =
  useVisitorsProfileDuration(selectedAccommodationId, timeFrameSelected)

const columns = {
  lte2days: '1 night',
  days34: '3 - 4 nights',
  days56: '5 - 6 nights',
  days78: '6 - 7 nights',
  gte9days: '8 nights or more',
}
</script>
