<template>
  <div class="tw-relative">
    <div class="scrollable-container tw-flex tw-mb-5" :class="getClasses()">
      <div
        class="scrollable-content tw-px-2 md:tw-pl-0 tw-max-w-[300px]"
        id="slide_1"
        :class="{ 'md:tw-pl-0 tw-pl-4': isCarousel }"
      >
        <UpsellPopupCard>
          <template #image>
            <img
              :src="images.card_1"
              alt="Enhanced visibility"
              class="tw-w-full tw-h-full tw-object-cover tw-object-center"
            />
          </template>
          <template #title>{{ $t('business_studio_plus_benefit_1_header') }}</template>
          <template #description>{{ $t('business_studio_plus_benefit_1_subtext') }}</template>
        </UpsellPopupCard>
      </div>
      <div class="scrollable-content tw-px-2 tw-max-w-[300px]" id="slide_2">
        <UpsellPopupCard>
          <template #image>
            <img
              :src="images.card_2"
              alt="Enhanced visibility"
              class="tw-w-full tw-h-full tw-object-cover tw-object-center"
            />
          </template>
          <template #title>{{ $t('business_studio_plus_benefit_2_header') }}</template>
          <template #description>{{ $t('business_studio_plus_benefit_2_subtext') }}</template>
        </UpsellPopupCard>
      </div>
      <div class="scrollable-content tw-px-2 tw-max-w-[300px]" id="slide_3">
        <UpsellPopupCard>
          <template #image>
            <img
              :src="images.card_3"
              alt="Enhanced visibility"
              class="tw-w-full tw-h-full tw-object-cover tw-object-center"
            />
          </template>
          <template #title>{{ $t('business_studio_plus_benefit_3_header') }}</template>
          <template #description>{{ $t('business_studio_plus_benefit_3_subtext') }}</template>
        </UpsellPopupCard>
      </div>
      <div class="scrollable-content tw-px-2 md:tw-pr-0 tw-max-w-[300px]" id="slide_4">
        <UpsellPopupCard>
          <template #image>
            <img
              :src="images.card_4"
              alt="Enhanced visibility"
              class="tw-w-full tw-h-full tw-object-cover tw-object-center"
            />
          </template>
          <template #title>{{ $t('business_studio_plus_benefit_4_header') }}</template>
          <template #description>{{ $t('business_studio_plus_benefit_4_subtext') }}</template>
        </UpsellPopupCard>
      </div>
    </div>
    <div class="tw-justify-center sm:tw-hidden tw-flex" v-if="showNavigation">
      <a href="#slide_1" class="dot" :class="currentIndex === 1 ? 'tw-bg-blue-900' : 'tw-bg-gray-300'">
        <span class="visuallyhidden" />
      </a>
      <a href="#slide_2" class="dot" :class="currentIndex === 2 ? 'tw-bg-blue-900' : 'tw-bg-gray-300'">
        <span class="visuallyhidden" />
      </a>
      <a href="#slide_3" class="dot" :class="currentIndex === 3 ? 'tw-bg-blue-900' : 'tw-bg-gray-300'">
        <span class="visuallyhidden" />
      </a>
      <a href="#slide_4" class="dot" :class="currentIndex === 4 ? 'tw-bg-blue-900' : 'tw-bg-gray-300'">
        <span class="visuallyhidden" />
      </a>
    </div>
    <div
      class="sm:tw-flex tw-hidden tw-absolute tw-w-full tw-top-[150px] tw-px-3 tw-justify-between"
      :class="{ 'carousel-arrows': !isCarousel }"
      v-if="showNavigation"
    >
      <button
        @click="prevSlide"
        class="tw-bg-gray-200 tw-h-[40px] tw-w-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-shadow-lg"
      >
        <BaseIcon
          class="tw-rotate-180"
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
        >
          <ChevronRight />
        </BaseIcon>
      </button>
      <button
        @click="nextSlide"
        class="tw-bg-gray-200 tw-h-[40px] tw-w-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-shadow-lg"
      >
        <BaseIcon icon-name="chevron-right" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
          <ChevronRight />
        </BaseIcon>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'

import UpsellPopupCard from './UpsellPopupCard.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import BaseIcon from '@/components/BaseIcon.vue'

const store = useStore()
const props = defineProps({
  isBlue: Boolean,
  showNavigation: Boolean,
  isCarousel: Boolean,
})

const isTouchpad = ref(false)
const language = computed(() => {
  return store.state.locale.language.split('-')[0].toLowerCase()
})

const observer = ref(null)
const currentIndex = ref(1)
const images = computed(() => {
  if (language.value === 'de') {
    return {
      card_1: new URL(`@/assets/img/studio-plus/de/card_1.png`, import.meta.url),
      card_2: new URL(`@/assets/img/studio-plus/de/card_2.png`, import.meta.url),
      card_3: new URL(`@/assets/img/studio-plus/de/card_3.png`, import.meta.url),
      card_4: new URL(`@/assets/img/studio-plus/de/card_4.png`, import.meta.url),
    }
  } else if (language.value === 'it') {
    return {
      card_1: new URL(`@/assets/img/studio-plus/it/card_1.png`, import.meta.url),
      card_2: new URL(`@/assets/img/studio-plus/it/card_2.png`, import.meta.url),
      card_3: new URL(`@/assets/img/studio-plus/it/card_3.png`, import.meta.url),
      card_4: new URL(`@/assets/img/studio-plus/it/card_4.png`, import.meta.url),
    }
  } else {
    return {
      card_1: new URL(`@/assets/img/studio-plus/en/card_1.png`, import.meta.url),
      card_2: new URL(`@/assets/img/studio-plus/en/card_2.png`, import.meta.url),
      card_3: new URL(`@/assets/img/studio-plus/en/card_3.png`, import.meta.url),
      card_4: new URL(`@/assets/img/studio-plus/en/card_4.png`, import.meta.url),
    }
  }
})

const setIntersectionObserver = () => {
  const carouselContainer = window.document.querySelector('.scrollable-container')
  const cards = window.document.querySelectorAll('.scrollable-content')
  const IntersectionObserverOptions = { threshold: 0.5, root: carouselContainer }
  observer.value = new IntersectionObserver(function (entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        currentIndex.value = Number(entry.target.id.charAt(entry.target.id.length - 1))
      }
    })
  }, IntersectionObserverOptions)
  cards.forEach(card => observer.value.observe(card))
}

const nextSlide = () => {
  const cards = window.document.querySelectorAll('.scrollable-content')
  if (currentIndex.value <= cards.length) {
    window.document.querySelector('.scrollable-container').scrollLeft =
      window.document.querySelector('.scrollable-container').scrollLeft + 300
  }
}
const prevSlide = () => {
  if (currentIndex.value === 4) {
    window.document.querySelector('.scrollable-container').scrollLeft =
      window.document.querySelector('.scrollable-container').scrollLeft - 190
  } else if (currentIndex.value >= 1) {
    window.document.querySelector('.scrollable-container').scrollLeft =
      window.document.querySelector('.scrollable-container').scrollLeft - 300
  }
}

const getClasses = () => {
  const classes = []
  if (props.isBlue) {
    classes.push('blue-bg sm:tw-pl-16 sm:tw-pr-16')
  }
  if (!props.isCarousel) {
    classes.push('md:tw-justify-between')
  }
  return classes.toString().replace(',', ' ')
}

onMounted(() => {
  document.addEventListener(
    'mousewheel',
    e => {
      isTouchpad.value = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0
    },
    false
  )
  document.addEventListener(
    'DOMMouseScroll',
    e => {
      isTouchpad.value = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0
    },
    false
  )
  const scrollableContainer = window.document.querySelector('.scrollable-container')

  scrollableContainer.addEventListener('wheel', e => {
    if (!isTouchpad.value) {
      e.preventDefault()
      scrollableContainer.scrollLeft += e.deltaY
    }
  })

  setIntersectionObserver()
})

onUnmounted(() => {
  observer.value?.disconnect()
})
</script>
<style scoped>
.carousel-arrows {
  @media (min-width: theme('screens.xl')) {
    display: none;
  }
}
.scrollable-container {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-scroll-snap-destination: 0 0;
  scroll-snap-destination: 0 0;
  -ms-scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  width: 100%;
  padding-bottom: 0.25rem;
}

.blue-bg {
  background: linear-gradient(theme('colors.blue.800') 170px, theme('colors.white') 170px);
}
.scrollable-container::-webkit-scrollbar {
  display: none;
}
.scrollable-content {
  scroll-snap-align: center;
  scroll-snap-stop: always;
  width: 80%;
  flex: none;
  text-align: center;
}

.dot {
  @apply tw-h-3.5 tw-w-3.5 tw-inline-block tw-rounded-full tw-cursor-pointer tw-outline-none tw-opacity-80 tw-mr-2 last:tw-mr-0;
  transition: opacity 0.2s;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>
