<template>
  <div class="tw-max-w-[820px] tw-px-5">
    <div>
      <h1
        class="tw-text-gray-900 tw-font-bold md:tw-pb-auto tw-mb-[7px]"
        :class="isChangingCampaign ? 'tw-text-xl tw-text-center md:tw-text-left' : 'tw-text-2xl'"
      >
        {{ $t('rc_setup_budget_head') }}
      </h1>
      <p
        class="tw-text-gray-900"
        :class="isChangingCampaign ? 'tw-text-center md:tw-text-left tw-px-4 md:tw-px-0' : 'tw-text-base'"
      >
        {{ $t('/snippets/hgw/rc_setup_budget_sub.inc') }}
      </p>
    </div>
    <div v-if="!isFetching && isSuccess" class="tw-my-8" :class="{ 'tw-my-10': isChangingCampaign }">
      <BudgetSlider
        :budget="Number(sliderBudget)"
        :status="status"
        :min="minBudget"
        :max="maxBudget"
        :recommended-min="budgetRecommendedMin"
        :recommended-max="budgetRecommendedMax"
        :default-budget="defaultBudget"
        :currency="currency"
        class="tw-mx-9"
        @update="updateBudget"
      />
      <CurrencyBudget
        id="budget-input"
        aria-label="Budget"
        :label="$t('rc_budget_field')"
        :budget="selectedBudget"
        :min-budget="minBudget"
        :max="9999999999"
        :status="status"
        :default-budget="defaultBudget"
        :currency="currency"
        @onChange="updateBudget"
      />
      <BudgetStatusInfo :status="status" :min-budget="minBudget" :hide-message="false" class="tw-text-center tw-mt-2" />
    </div>
    <LaunchpadLoader v-else class="tw-flex tw-justify-center tw-items-center" />
    <p class="tw-text-center" :class="{ 'tw-mb-8': isChangingCampaign }">{{ $t('help_budget_allocation') }}</p>
  </div>
</template>
<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import BudgetSlider from '@/components/rateConnect/settings/wizards/BudgetSlider.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import CurrencyBudget from '@/components/rateConnect/settings/wizards/CurrencyBudget.vue'
import BudgetStatusInfo from '@/components/rateConnect/settings/wizards/BudgetStatusInfo.vue'
import { getBudgetStatus } from '@/utils/rateConnectUtils'
import { useBudgetRecommendation, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'


const props = defineProps({
  isChangingCampaign: {
    type: Boolean,
    default: false,
  },
  newPartner: {
    type: Object,
    default: null,
  },
})

const store = useStore()

const emit = defineEmits(['onBudgetChange'])

const selectedBudget = ref(0)
const sliderBudget = ref(0)

const currency = computed(() => store.state.currency)
const { currentPartner, recommendedPartner } = usePartners(selectedAccommodationId)
const partnerSelected = computed(() => {
  if (props.newPartner) return props.newPartner
  if (props.isChangingCampaign) return currentPartner.value
  return recommendedPartner.value
})

const { isFetching, isSuccess, minBudget, maxBudget, budgetRecommendedMin, budgetRecommendedMax, defaultBudget } =
  useBudgetRecommendation(selectedAccommodationId, currency, partnerSelected)

const status = computed(() =>
  getBudgetStatus(
    {
      budgetMin: minBudget.value,
      budgetRecommendedMin: budgetRecommendedMin.value,
      budgetRecommendedMax: budgetRecommendedMax.value,
    },
    selectedBudget.value,
    0
  )
)

watch(isSuccess, newValue => {
  if (newValue) {
    selectedBudget.value = defaultBudget.value
    sliderBudget.value = defaultBudget.value
  }
})

watch(selectedBudget, newValue => {
  emit('onBudgetChange', newValue)
})

const updateBudget = newBudget => {
  selectedBudget.value = Number(newBudget)
  if (newBudget > maxBudget.value) {
    sliderBudget.value = maxBudget.value
  } else {
    sliderBudget.value = Number(newBudget)
  }
}

onMounted(() => {
  if (isSuccess) {
    selectedBudget.value = defaultBudget.value
    sliderBudget.value = defaultBudget.value
  }
})
</script>
