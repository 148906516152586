<template>
  <div class="tw-px-4">
    <div class="tw-flex tw-justify-start tw-mb-6">
      <router-link
        class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-800 tw-flex tw-items-end"
        :to="isReactivate ? { name: 'settings-subscriptions' } : { name: 'studio-plus-compare' }"
      >
        <BaseIcon icon-name="chevron-right" class="tw-rotate-180">
          <ChevronRight />
        </BaseIcon>
        <span>{{ $t('setup_back') }}</span>
      </router-link>
    </div>
    <h2 class="tw-text-2xl tw-font-bold tw-mb-6">
      {{
        isReactivate
          ? $t('business_studio_plus_payment_details_header')
          : $t('business_studio_plus_checkout_subscription_header')
      }}
    </h2>
    <div v-if="isLoadingRequiredData">
      <div class="tw-animate-pulse tw-w-full tw-rounded-lg tw-bg-gray-300 tw-h-[300px] tw-mb-4 tw-mt-8"></div>
      <div class="tw-animate-pulse tw-w-full tw-rounded-lg tw-bg-gray-300 tw-h-[300px] tw-mb-4 tw-mt-8"></div>
    </div>
    <div v-else-if="isRequiredDataError" class="tw-mb-4 tw-mt-8">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <div v-else class="sp-payment-grid">
      <div class="sp-payment-accommodation">
        <SubscriptionSelectedAccommodation
          :accommodationId="isReactivate ? reactivateAccommodation : selectedAccommodationId"
        />
      </div>
      <div class="sp-payment-form">
        <PaymentForm
          class="tw-my-6"
          :key="selectedAccommodationId"
          ref="paymentFormRef"
          @onFormValidationSuccess="onFormValidationSuccess"
          @onFormValidationError="onFormValidationError"
          @onZuoraHostedPageLoadError="onFormValidationError"
        />
      </div>
      <div class="sp-payment-footer">
        <SubscriptionPriceSummary
          :taxId="formTaxId"
          :zipCode="formZipCode"
          :countryCode="formCountry"
          :accommodationId="isReactivate ? reactivateAccommodation : selectedAccommodationId"
          :countryState="formState"
          :noTaxId="noTaxId"
          :selectedPaymentType="subscriptionType"
        />
        <div class="tw-flex tw-gap-2 tw-items-center tw-mb-6">
          <input
            id="studio-plus-terms"
            type="checkbox"
            class="hover:tw-cursor-pointer tw-h-4 tw-w-4"
            :value="termsAccepted"
            @change="e => (termsAccepted = e.target.checked)"
          />
          <label
            for="studio-plus-terms"
            class="tw-grow tw-text-sm tw-leading-4 tw-mb-0 terms"
            v-html="$t('business_studio_plus_terms_and_conditions_checkbox')"
          />
        </div>
        <MainButton class="tw-w-full tw-mb-6" :disabled="!paymentEnabled" @click="onStartFreeTrial">{{
          isReactivate
            ? $t('business_studio_plus_reactivation_checkout')
            : $t('business_studio_plus_checkout_cta_button')
        }}</MainButton>
        <p class="tw-mb-4">
          {{ $t('checkout_subscription_tax_notification') }}
        </p>
        <p class="tw-mb-4" v-if="isReactivate">
          {{ $t('business_studio_plus_reactivation_date', { ReactivationDate: reactivationDate }) }}
        </p>
        <p class="tw-mb-4">
          {{
            subscriptionType === 'annual'
              ? $t('business_studio_plus_subscription_renewal_info_annual')
              : $t('business_studio_plus_subscription_renewal_info_month')
          }}
        </p>
        <p class="tw-mb-4">
          {{ $t('business_studio_plus_checkout_info_text') }}
        </p>
        <p class="tw-mb-4">{{ $t('checkoutBasket.detailsEntry.secureConnection.recurringFee') }}</p>
      </div>
    </div>
    <FullScreenLoader v-show="isSubmittingNewSubscription || isFetchingRequiredData" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import PaymentForm from '@/components/payment/PaymentForm.vue'
import SubscriptionPriceSummary from '@/components/studioPlus/SubscriptionPriceSummary.vue'
import SubscriptionSelectedAccommodation from '@/components/studioPlus/SubscriptionSelectedAccommodation.vue'
import {
  useAvailablePaymentMethods,
  useExistingPaymentMethods,
  useStudioPlusPrice,
  useZuoraAccount,
  useStudioPlusNewSubscriptionMutation,
} from '@/components/payment/queries'
import useRateConnect from '@/components/rateConnect/queries'
import { selectedAccommodationId, useUserDetails, useAccommodationsByOrgId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const store = useStore()
const route = useRoute()
const router = useRouter()
const isReactivate = route.query?.reactivate === 'true'
const subscriptionType = route.query?.subscription
const reactivateAccommodation = route.query?.accommodationId

const currentAccommodationId = isReactivate ? reactivateAccommodation : selectedAccommodationId.value
const { hotelierData } = useRateConnect(currentAccommodationId)
const directRatesPartnerId = computed(() => hotelierData.value?.directRatesPartner)
const userId = computed(() => store.state.session.userId)
const { userDetails } = useUserDetails(userId)
const { studioPlusPriceAnnual, studioPlusPriceMonthly } = useStudioPlusPrice({
  value: isReactivate ? reactivateAccommodation : selectedAccommodationId.value,
})
const { isLoading: isAvailablePaymentMethodsLoading, isError: isAvailablePaymentMethodsError } =
  useAvailablePaymentMethods(selectedAccommodationId, true)
const {
  isLoading: isExistingPaymentMethodsLoading,
  isFetching: isExistingPaymentMethodsFetching,
  isError: isExistingPaymentMethodsError,
} = useExistingPaymentMethods(selectedAccommodationId)
const {
  zuoraAccountData,
  isLoading: isZuoraAccountLoading,
  isFetching: isZuoraAccountFetching,
  isError: isZuoraAccountError,
} = useZuoraAccount(selectedAccommodationId)
const { sendStudioPlusNewSubscription } = useStudioPlusNewSubscriptionMutation()

const reactivationDate = computed(() => {
  return new Date().toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})
const isLoadingRequiredData = computed(
  () => isAvailablePaymentMethodsLoading.value || isExistingPaymentMethodsLoading.value || isZuoraAccountLoading.value
)
const isFetchingRequiredData = computed(() => isExistingPaymentMethodsFetching.value || isZuoraAccountFetching.value)
const isRequiredDataError = computed(
  () => isAvailablePaymentMethodsError.value || isExistingPaymentMethodsError.value || isZuoraAccountError.value
)

const paymentFormRef = ref(null)
const termsAccepted = ref(false)
const isSubmittingNewSubscription = ref(false)

const language = computed(() => store.state.locale.language)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const paymentEnabled = computed(() => {
  return (
    termsAccepted.value &&
    paymentFormRef.value?.selectedPaymentMethod !== null &&
    paymentFormRef.value?.isBillingFormValid
  )
})
const formZipCode = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.zipCode)
const formCountry = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.country)
const formState = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.state)
const formTaxId = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.taxId)
const noTaxId = computed(() => paymentFormRef.value?.billingForm?.VATrequiredData.noTaxId)

const { displayNotification } = useToastNotifications()

onMounted(() => {
  appAnalytics.track(appAnalytics.events.SP_PAYMENT_VISITED, {
    item_id: selectedAccommodationId.value,
    itemName: currentAccommodation?.value?.name,
    partnerId: directRatesPartnerId.value,
    language: language.value,
    studioUserId: userDetails?.value?.id,
    email: userDetails?.value?.email,
    firstName: userDetails?.value?.firstName,
    lastName: userDetails?.value?.lastName,
  })
})

const onStartFreeTrial = () => {
  isSubmittingNewSubscription.value = true
  paymentFormRef.value?.validateForm()
}

const onFormValidationSuccess = () => {
  sendStudioPlusNewSubscription(
    {
      accommodationId: isReactivate ? reactivateAccommodation : selectedAccommodationId.value,
      partnerId: directRatesPartnerId.value,
      itemName: currentAccommodation?.value?.name,
      email: userDetails?.value?.email,
      language: language?.value,
      studioUserId: userDetails?.value?.id,
      firstName: userDetails?.value?.firstName,
      lastName: userDetails?.value?.lastName,
      isFreeTrial: false,
      type: subscriptionType === 'annual' ? 'YEARLY' : 'MONTHLY',
    },
    {
      onSuccess: data => {
        if (isReactivate) {
          appAnalytics.track(appAnalytics.events.SP_SUBSCRIPTION_REACTIVATED, {
            item_id: reactivateAccommodation,
            price:
              subscriptionType === 'annual' ? studioPlusPriceAnnual.value?.price : studioPlusPriceMonthly.value?.price,
            partnerId: directRatesPartnerId.value,
            language: language?.value,
            studioUserId: userDetails?.value?.id,
            email: userDetails?.value?.email,
            firstName: userDetails?.value?.firstName,
            lastName: userDetails?.value?.lastName,
            reactivation_date: reactivationDate.value,
            subscription_type: subscriptionType,
            auto_renewal_date: new Date(data?.data?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }),
          })
          router.push({ name: 'settings-subscriptions', query: { reactivate: true } })
        } else {
          if (data.status === 204 && zuoraAccountData.value) {
            onFormValidationSuccess()
          } else {
            appAnalytics.track(appAnalytics.events.SP_PAYMENT_SUCCEED, {
              subscription_type: subscriptionType,
              item_id: selectedAccommodationId.value,
              price:
                subscriptionType === 'annual'
                  ? studioPlusPriceAnnual.value?.price
                  : studioPlusPriceMonthly.value?.price,
              partnerId: directRatesPartnerId.value,
              language: language?.value,
              studioUserId: userDetails?.value?.id,
              email: userDetails?.value?.email,
              firstName: userDetails?.value?.firstName,
              lastName: userDetails?.value?.lastName,
              subscription_date: new Date(data.data.subscriptionStartDate).toLocaleDateString(
                store.state.locale.language,
                {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }
              ),
              renewal_date: new Date(data?.data?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }),
            })
            isSubmittingNewSubscription.value = false
            window.localStorage.setItem(`contact_info_banner_${selectedAccommodationId.value}`, 'true')
            window.dispatchEvent(
              new CustomEvent('contact_info_banner_localstorage-changed', {
                detail: {
                  storage: localStorage.getItem(`contact_info_banner_${selectedAccommodationId.value}`),
                },
              })
            )
            router.push({ name: 'home' })
          }
        }
      },
      onError: () => {
        isSubmittingNewSubscription.value = false
      },
    }
  )
}

const onFormValidationError = () => {
  appAnalytics.track(appAnalytics.events.SP_PAYMENT_FAILED, {
    item_id: selectedAccommodationId.value,
  })
  isSubmittingNewSubscription.value = false
  displayNotification({
    message: 'subscriptions.details.transactionHistory.error',
    isTranslationKey: true,
    type: 'error',
  })
}
</script>

<style scoped>
.sp-payment-grid {
  display: block;

  @media (min-width: theme('screens.md')) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas:
      'payment-accommodation payment-footer'
      'payment-form payment-footer';
    column-gap: 4rem;
  }
}
.sp-payment-form {
  grid-area: payment-form;
}
.sp-payment-footer {
  grid-area: payment-footer;
}
.sp-payment-accommodation {
  grid-area: payment-accommodation;
}
.terms :deep(a) {
  @apply tw-text-blue-800;
}
</style>
