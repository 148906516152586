import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { languageCodes } from '@/constants/locales.js'
import en from '@/locales/en-US.json'

const dateOptionsGeneral = {
  longMonth: {
    month: 'long',
  },
  short: {
    year: '2-digit',
    month: '2-digit',
  },
  long: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  },
  datetime: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  shortMonth: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
}

const dateOptionsUK = {
  longMonth: {
    month: 'long',
  },
  short: {
    year: '2-digit',
    month: '2-digit',
  },
  long: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  },
  datetime: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
  shortMonth: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
}

const datetimeFormats = {
  'de-DE': dateOptionsGeneral,
  'el-GR': dateOptionsGeneral,
  'en-GB': dateOptionsUK,
  'en-US': dateOptionsGeneral,
  'es-ES': dateOptionsGeneral,
  'es-MX': dateOptionsGeneral,
  'fr-FR': dateOptionsGeneral,
  'it-IT': dateOptionsGeneral,
  'ja-JP': dateOptionsGeneral,
  'nl-NL': dateOptionsGeneral,
  'pl-PL': dateOptionsGeneral,
  'pt-BR': dateOptionsGeneral,
  'pt-PT': dateOptionsGeneral,
  'ru-RU': dateOptionsGeneral,
  'tr-TR': dateOptionsGeneral,
}

const currencyCode = {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

const currencyCodeRounded = {
  style: 'decimal',
  maximumFractionDigits: 0,
}

const currencyCodeRoundedEuro = {
  ...currencyCodeRounded,
  currency: 'EUR',
}

const currencyCodeEuro = {
  ...currencyCode,
  currency: 'EUR',
}

const currency = {
  style: 'decimal',
  useGrouping: true,
  maximumFractionDigits: 2,
}

const currencyEuro = {
  ...currency,
  currency: 'EUR',
}

const numberFormats = {
  'de-DE': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'el-GR': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'en-GB': {
    currency: {
      ...currency,
      currency: 'GBP',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'GBP',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'GBP',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'en-US': {
    currency: {
      ...currency,
      currency: 'USD',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'USD',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'USD',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'es-ES': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'es-MX': {
    currency: {
      ...currency,
      currency: 'MXN',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'MXN',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'MXN',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'fr-FR': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'it-IT': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'ja-JP': {
    currency: {
      ...currency,
      currency: 'JPY',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'JPY',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'JPY',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'nl-NL': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'pl-PL': {
    currency: {
      ...currency,
      currency: 'PLN',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'PLN',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'PLN',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'pt-BR': {
    currency: {
      ...currency,
      currency: 'BRL',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'BRL',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'BRL',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'pt-PT': {
    currency: currencyEuro,
    currencyCode: currencyCodeEuro,
    currencyCodeRounded: currencyCodeRoundedEuro,
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'ru-RU': {
    currency: {
      ...currency,
      currency: 'RUB',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'RUB',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'RUB',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
  'tr-TR': {
    currency: {
      ...currency,
      currency: 'TRY',
    },
    currencyCode: {
      ...currencyCode,
      currency: 'TRY',
    },
    currencyCodeRounded: {
      ...currencyCodeRounded,
      currency: 'TRY',
    },
    decimal: {
      style: 'decimal',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 1,
    },
  },
}

const options = {
  legacy: false,
  locale: window.localStorage.getItem('stdio_locale_language') ?? 'en-US',
  fallbackLocale: 'en-US',
  numberFormats,
  datetimeFormats,
  warnHtmlMessage: false,
  messages: {
    'en-US': en,
  },
}

const i18n = createI18n(options)

export function initI18n() {
  loadLocaleMessages(options.locale)
  return i18n
}

export async function setI18nLanguage(locale) {
  const newLang = languageCodes.includes(locale) ? locale : 'en-US'
  if (!i18n.global.availableLocales.includes(newLang)) {
    await loadLocaleMessages(newLang)
  }
  if (i18n.mode === 'legacy') {
    i18n.global.locale = newLang
  } else {
    i18n.global.locale.value = newLang
  }
  document.querySelector('html').setAttribute('lang', newLang?.slice(0, 2))
  window.localStorage.setItem('stdio_locale_language', newLang)
}

export async function loadLocaleMessages(locale) {
  // load locale messages with dynamic import
  const messages = await import(`../locales/${locale}.json`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export const global = i18n.global
