<template>
  <BaseCard class="tw-overflow-hidden" no-padding>
    <ul>
      <li v-for="(item, i) in itemData" :key="i" class="tw-text-sm tw-overflow-hidden tw-border-b tw-border-gray-200">
        <button
          class="tw-w-full tw-flex tw-justify-between tw-items-center tw-gap-1 tw-p-3 focus:tw-outline-none tw-text-left"
          :class="{ 'tw-bg-blue-800 tw-text-white': item.isOpen.value }"
          @click="toggleItem(i)"
        >
          <span>{{ $t(item.title) }}</span>
          <BaseIcon
            width="24"
            height="24"
            iconName="chevron-right"
            :iconColor="item.isOpen.value ? '#FFFFFF' : '#6C707A'"
            :class="item.isOpen.value ? 'tw--rotate-90 tw-shrink-0' : 'tw-rotate-90 tw-shrink-0'"
          >
            <ChevronRight />
          </BaseIcon>
        </button>
        <div class="tw-transition-all tw-px-5" :ref="el => (item.element = el)">
          <slot :name="'content-' + i"></slot>
        </div>
      </li>
    </ul>
  </BaseCard>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
})

const itemData = props.items.map(item => ({ ...item, isOpen: ref(true) }))
const paddingSize = 16

const getInitialHeights = () => {
  // Exact content heights are needed to animate their change in size later.
  itemData.forEach((item, i) => {
    item.openedHeight = item.element.clientHeight + paddingSize * 2
    toggleItem(i)
  })
}
const toggleItem = i => {
  if (!itemData[i].isOpen.value) {
    itemData[i].element.style.height = itemData[i].openedHeight + 'px'
    itemData[i].element.style.padding = `${paddingSize}px ${paddingSize}px`
    itemData[i].isOpen.value = true
  } else {
    itemData[i].element.style.padding = '0 16px'
    itemData[i].element.style.height = '0px'
    itemData[i].isOpen.value = false
  }
}

onMounted(() => {
  getInitialHeights()
})
</script>
