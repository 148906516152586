<template>
  <section class="tw-bg-white">
    <div
      :class="getClasses()"
      class="tw-flex tw-max-w-screen-xxl tw-mx-auto tw-items-center tw-flex tw-justify-between lg:tw-flex-row tw-flex-col"
    >
      <div
        class="element-width tw-mb-2 tw-px-10 lg:tw-mb-0 lg:tw-mr-[41px]"
        :class="isLeftSide ? 'tw-order-0 lg:tw-pr-[85px]' : 'lg:tw-order-1'"
      >
        <p class="tw-text-[40px] tw-mb-[20px] tw-leading-[42px] tw-font-bold" v-html="title" />
        <p class="tw-mt-[15px]" v-html="text" />
        <MainButton
          v-if="isButton"
          :class="productPage ? 'tw-mt-7 lg:tw-mt-12 tw-w-full lg:tw-w-auto' : 'tw-mt-[30px] tw-w-full lg:tw-w-[130px]'"
          @click="register"
          @keydown.enter="register"
          >{{ button }}</MainButton
        >

        <div
          v-if="isLink"
          class="tw-mt-8 arrow-link tw-text-[18px] tw-flex tw-items-center hover:tw-text-blue-800"
          v-html="link"
        />
      </div>
      <div class="element-width-image" :class="isBig ? 'lg:tw-ml-[41px]  lg:tw-px-10' : ' lg:tw-px-6'">
        <img
          :src="image"
          :alt="title"
          :width="imageWidth"
          :height="imageHeight"
          class="tw-mt-12 lg:tw-mt-0 tw-mx-auto tw-py-0"
          :class="getImageClasses"
        />
      </div>
    </div>
  </section>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const props = defineProps({
  isButton: {
    type: Boolean,
    default: false,
  },
  isLeftSide: {
    type: Boolean,
    default: true,
  },
  button: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  image: {
    type: String,
    default: '',
  },
  isLink: {
    type: Boolean,
    default: false,
  },
  link: {
    type: String,
    default: '',
  },
  imageHeight: Number,
  imageWidth: Number,
  isBig: Boolean,
})
const emit = defineEmits(['onRegisterClick'])
const register = e => emit('onRegisterClick', e.target.value)
const getImageClasses = () => {
  const classes = []
  if (props.isLeftSide) {
    if (props.isBig) {
      classes.push('tw-my-0 tw-w-full')
    } else {
      classes.push('tw-mx-0')
    }
  } else {
    if (props.isBig) {
      classes.push('tw-w-auto tw-px-10 lg:tw-px-0')
    }
  }
  return classes.join(' ')
}
const getClasses = () => {
  const classes = []
  if (props.isLeftSide) {
    if (props.isBig) {
      classes.push('tw-py-[40px] lg:tw-py-[57px] lg:tw-h-[440px]')
    } else {
      classes.push('tw-py-[40px] lg:tw-py-[57px]')
    }
  } else {
    if (props.isBig) {
      classes.push('lg:tw-min-h-[440px] tw-py-[70px] lg:tw-py-[100px]')
    } else {
      classes.push('tw-py-[40px] lg:tw-py-[57px]')
    }
  }
  return classes.join(' ')
}
</script>
<style scoped>
.element-width-image {
  width: calc(50% - 41px);
  @media (max-width: theme('screens.lg')) {
    width: auto;
  }
}

.element-width {
  width: calc(50% - 41px);
  @media (max-width: theme('screens.lg')) {
    width: 100%;
  }
}
p :deep(span) {
  @apply tw-text-blue-800;
}
.arrow-link::after {
  margin-left: 5px;
  margin-top: 3px;
  display: inline-block;
  content: '';
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask: url("data:image/svg+xml,%3Csvg data-v-92245bab='' data-v-39bca6e1='' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' aria-labelledby='chevron-right' role='presentation' class='tw-shrink-0'%3E%3Ctitle data-v-92245bab='' id='chevron-right' lang='en'%3Echevron-right%3C/title%3E%3Cg data-v-92245bab=''%3E%3Cpath data-v-39bca6e1='' pid='1' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg data-v-92245bab='' data-v-39bca6e1='' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' aria-labelledby='chevron-right' role='presentation' class='tw-shrink-0'%3E%3Ctitle data-v-92245bab='' id='chevron-right' lang='en'%3Echevron-right%3C/title%3E%3Cg data-v-92245bab=''%3E%3Cpath data-v-39bca6e1='' pid='1' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  height: 27px;
  width: 27px;
  background-color: theme('colors.gray.900');
}
.arrow-link:hover::after {
  background-color: theme('colors.blue.800');
}
</style>
