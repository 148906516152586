import { createRouter, createWebHistory } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import HomeView from '@/views/HomeView.vue'
import GetStartedView from '@/views/GetStartedView.vue'
import RateConnectPageView from '@/views/RateConnectPageView.vue'
import StudioPlusPageView from '@/views/StudioPlusPageView.vue'
import FreeBookingLinksPageView from '@/views/FreeBookingLinksPageView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import ImprintView from '@/views/ImprintView.vue'
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue'
import PublicPageLayout from '@/layouts/PublicPageLayout.vue'
import MainPageLayout from '@/layouts/MainPageLayout.vue'
import LoginView from '@/views/auth/LoginView.vue'
import SignupView from '@/views/auth/SignupView.vue'
import InviteView from '@/views/auth/InviteView.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import OrganisationsView from '@/views/OrganisationsView.vue'
import BlogAnnouncementView from '@/views/BlogAnnouncementView.vue'
import PropertyAssignmentAddNewView from '@/views/property-assignment/PropertyAssignmentAddNewView.vue'
import {
  PropertyDetailsView,
  PropertyDetailsBasicInformation,
  PropertyDetailsImages,
  PropertyDetailsDescription,
} from '@/views/property-details'
import {
  SettingsView,
  TeamSettingsView,
  ProfileSettingsView,
  PaymentMethodsView,
  SubscriptionsView,
  LocaleSettingsView,
  SubscriptionDetailsView,
} from '@/views/settings'
import {
  RateConnectView,
  RateConnectNoCampaignView,
  RateConnectPerformanceView,
  RateConnectNotEligibleView,
  RateConnectRunningView,
  RateConnectMarketsView,
  RateConnectBiddingGoalsView,
  RateConnectSettingsView,
  RateConnectSetupView,
  RateConnectBudgetView,
} from '@/views/rate-connect'
import { RateInsightsView, RateInsightsOnboarding, RateInsightsChannels, RateInsights } from '@/views/rate-insights'
import { CompareFeaturesView, StudioPlusPaymentView, StudioPlusView } from '@/views/studio-plus'
import AdminToolView from '@/views/admin-tool/AdminToolView.vue'
import IframeAppView from '@/views/apps/IframeAppView.vue'
import DirectBookingLinkActivateView from '@/views/DirectBookingLinkActivateView.vue'
import {
  VisitorsProfileView,
  VisitorsProfileStayDurationView,
  VisitorsProfileSearchCountriesView,
  VisitorsProfileSearchWindowView,
} from '@/views/visitors-profile'
import isAuthenticated from './middleware/isAuthenticated'
import isTrivagoEmail from './middleware/isTrivagoEmail'
import isAdmin from './middleware/isAdmin'
import isEligibleForStudioPlus from './middleware/isEligibleForStudioPlus'
import OnboardingPageLayout from '@/layouts/OnboardingPageLayout.vue'

import { selectedAccommodationId } from '@/layouts/queries'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: OnboardingView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: OnboardingPageLayout,
      },
    },
    {
      path: '/organisations',
      name: 'organisations',
      component: OrganisationsView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
    },
    {
      path: '/property-assignment',
      name: 'property-assignment',
      component: PropertyAssignmentAddNewView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
    },
    {
      path: '/rate-insights',
      name: 'rate-insights',
      component: RateInsights,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
      children: [
        {
          path: 'view',
          name: 'rate-insights-view',
          component: RateInsightsView,
        },
        {
          path: 'onboarding',
          name: 'rate-insights-onboarding',
          component: RateInsightsOnboarding,
        },
        {
          path: 'channels',
          name: 'rate-insights-channels',
          component: RateInsightsChannels,
        },
      ],
    },
    {
      path: '/visitors-profile',
      name: 'visitors-profile',
      component: VisitorsProfileView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
      children: [
        {
          path: '',
          redirect: { name: 'visitors-profile-stay-duration' },
        },
        {
          path: 'stay-duration',
          name: 'visitors-profile-stay-duration',
          component: VisitorsProfileStayDurationView,
        },
        {
          path: 'search-window',
          name: 'visitors-profile-search-window',
          component: VisitorsProfileSearchWindowView,
        },
        {
          path: 'search-countries',
          name: 'visitors-profile-search-countries',
          component: VisitorsProfileSearchCountriesView,
        },
      ],
    },
    {
      path: '/rate-connect',
      name: 'rate-connect',
      component: RateConnectView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
      children: [
        {
          path: 'no-campaign',
          name: 'rate-connect-no-campaign',
          component: RateConnectNoCampaignView,
        },
        {
          path: 'noteligible',
          name: 'rate-connect-noteligible',
          component: RateConnectNotEligibleView,
        },
        {
          path: 'setup',
          name: 'rate-connect-setup',
          component: RateConnectSetupView,
        },
        {
          path: 'running',
          name: 'rate-connect-running',
          component: RateConnectRunningView,
          children: [
            {
              path: 'performance',
              name: 'rate-connect-running-performance',
              component: RateConnectPerformanceView,
            },
            {
              path: 'markets',
              name: 'rate-connect-running-markets',
              component: RateConnectMarketsView,
            },
            {
              path: 'bidding',
              name: 'rate-connect-running-bidding',
              component: RateConnectBiddingGoalsView,
            },
            {
              path: 'settings',
              name: 'rate-connect-running-settings',
              component: RateConnectSettingsView,
            },
            {
              path: 'budget',
              name: 'rate-connect-running-budget',
              component: RateConnectBudgetView,
            },
          ],
        },
      ],
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
      children: [
        {
          path: '',
          redirect: { name: 'settings-team-settings' },
        },
        {
          path: 'team-settings',
          name: 'settings-team-settings',
          beforeEnter: isAdmin,
          component: TeamSettingsView,
        },
        {
          path: 'profile-settings',
          name: 'settings-profile-settings',
          component: ProfileSettingsView,
        },
        {
          path: 'payment-methods',
          name: 'settings-payment-methods',
          beforeEnter: isAdmin,
          component: PaymentMethodsView,
        },
        {
          path: 'subscriptions',
          name: 'settings-subscriptions',
          beforeEnter: isAdmin,
          component: SubscriptionsView,
        },
        {
          path: 'subscriptions/details',
          name: 'settings-subscription-details',
          beforeEnter: isAdmin,
          component: SubscriptionDetailsView,
        },
        {
          path: 'locale-settings',
          name: 'settings-locale-settings',
          component: LocaleSettingsView,
        },
      ],
    },
    {
      path: '/property-details',
      name: 'property-details',
      component: PropertyDetailsView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
      children: [
        {
          path: 'basic-information',
          name: 'property-details-basic-information',
          component: PropertyDetailsBasicInformation,
        },
        {
          path: 'images',
          name: 'property-details-images',
          component: PropertyDetailsImages,
        },
        {
          path: 'description',
          name: 'property-details-description',
          component: PropertyDetailsDescription,
        },
      ],
    },
    {
      path: '/studio-plus',
      name: 'studio-plus',
      beforeEnter: isAuthenticated,
      component: StudioPlusView,
      meta: {
        layout: MainPageLayout,
      },
      children: [
        {
          path: 'compare',
          name: 'studio-plus-compare',
          beforeEnter: isEligibleForStudioPlus,
          component: CompareFeaturesView,
        },
        {
          path: 'payment',
          name: 'studio-plus-payment',
          beforeEnter: isEligibleForStudioPlus,
          component: StudioPlusPaymentView,
        },
      ],
    },
    {
      path: '/apps/:slug/:id',
      name: 'apps-slug-id',
      component: IframeAppView,
      beforeEnter: isAuthenticated,
      meta: {
        layout: MainPageLayout,
      },
    },
    {
      path: '/admin-tool',
      name: 'admin-tool',
      component: AdminToolView,
      beforeEnter: isTrivagoEmail,
      meta: {
        layout: MainPageLayout,
      },
    },
    {
      path: '/getstarted',
      name: 'getstarted',
      component: GetStartedView,
    },
    {
      path: '/getstarted/rate-connect',
      name: 'getstarted-rate-connect',
      component: RateConnectPageView,
    },
    {
      path: '/getstarted/studio-plus',
      name: 'getstarted-studio-plus',
      component: StudioPlusPageView,
    },
    {
      path: '/getstarted/free-booking-links',
      name: 'getstarted-free-booking-links',
      component: FreeBookingLinksPageView,
    },
    {
      path: '/getstarted/dbr-activate',
      name: 'getstarted-dbr-activate',
      component: DirectBookingLinkActivateView,
    },
    {
      path: '/blogannouncement',
      name: 'blogannouncement',
      component: BlogAnnouncementView,
      meta: {
        layout: PublicPageLayout,
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicyView,
      meta: {
        layout: PublicPageLayout,
      },
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: ImprintView,
      meta: {
        layout: PublicPageLayout,
      },
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: TermsAndConditionsView,
      meta: {
        layout: PublicPageLayout,
      },
    },
    {
      path: '/auth',
      children: [
        {
          path: 'login',
          name: 'login',
          component: LoginView,
        },
        {
          path: 'invite',
          component: InviteView,
        },
        {
          path: 'signup',
          component: SignupView,
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
})

router.beforeEach(to => {
  if (import.meta.env.MODE === 'production') {
    appAnalytics.page({ to: to.fullPath, title: to.name, accommodationId: selectedAccommodationId.value ?? null })
  }
  if (to.query?.utm_medium) {
    window.sessionStorage?.setItem?.('traffic_source', to.query.utm_medium)
  }
})

export default router
