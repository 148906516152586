<template>
  <div>
    <div class="tw-bg-white tw-overflow-x-hidden">
      <AuthMenu @onRegisterClick="onAuthorize('signup')" @onLoginClick="onAuthorize('login')" />
      <ProductPageHero
        :isFreeBookingLinks="false"
        :isStudioPlus="false"
        class="hero-container-grid hero-padding tw-max-w-screen-xxl tw-mx-auto"
      >
        <template #leftSide>
          <div class="tw-rounded tw-inline-block product-badge tw-py-2.5 tw-px-8 tw-mt-[40px] lg:tw-mt-auto">
            <span class="tw-font-bold tw-text-blue-800 tw-text-xl tw-leading-5">{{ 'Rate Connect' }}</span>
          </div>
          <h1
            class="tw-text-[48px] tw-leading-[50px] lg:tw-text-[50px] tw-font-bold tw-mb-4 lg:tw-mt-0 lg:tw-w-[120%] lg:tw-mt-5 tw-mt-2"
            v-html="$t('rc_mp_header')"
          />
          <p v-html="$t('rc_mp_header_text')"></p>
          <MainButton
            :class="'tw-mt-7 lg:tw-mt-12 tw-w-full lg:tw-w-auto'"
            @click="onAuthorize('signup')"
            @keydown.enter="onAuthorize('signup')"
          >
            {{ $t('rc_mp_subbenefit_1_cta') }}
          </MainButton>
        </template>
        <template #rightSide>
          <img
            :src="windowWidth > 1023 ? WebHeroImage : MobileHeroImage"
            alt="trivago business studio hero image"
            :height="550"
            :width="903"
            class="tw-mt-12 lg:tw-mt-0 tw-mx-auto tw-pr-4 tw-pl-4 lg:tw-pr-0 lg:tw-pl-0"
          />
        </template>
      </ProductPageHero>
      <ProductPageFeatures />
      <ProductPageTextBanner />
      <ProductPageCarousel @onRegisterClick="onAuthorize('signup')" />
      <ProductPageCampaignsInfo />
      <ProductPageReviews />
      <ProductPageFaq :faq="faqKeys" :supportCenterLink="true" />
      <ProductPageWhiteBanner @onRegisterClick="onAuthorize('signup')" />
    </div>
    <ThePageFooter />
    <TheCookieNotice />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import ProductPageHero from '@/components/productPage/ProductPageHero.vue'
import ProductPageFeatures from '@/components/productPage/ProductPageFeatures.vue'
import ProductPageTextBanner from '@/components/productPage/ProductPageTextBanner.vue'
import ProductPageCarousel from '@/components/productPage/ProductPageCarousel.vue'
import ProductPageCampaignsInfo from '@/components/productPage/ProductPageCampaignsInfo.vue'
import ProductPageReviews from '@/components/productPage/ProductPageReviews.vue'
import ProductPageFaq from '@/components/productPage/ProductPageFaq.vue'
import ProductPageWhiteBanner from '@/components/productPage/ProductPageWhiteBanner.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const WebHeroImage = new URL('/src/assets/img/rate-connect-landing/hero.webp', import.meta.url)
const MobileHeroImage = new URL('/src/assets/img/rate-connect-landing/hero_mobile.webp', import.meta.url)

const store = useStore()
const route = useRoute()

const windowWidth = ref(window.innerWidth)
const faqKeys = ref(['rc_mp_faq_1', 'rc_mp_faq_2', 'rc_mp_faq_3', 'rc_mp_faq_4', 'rc_mp_faq_5', 'rc_mp_faq_6'])
const locale = computed(() => store.state.locale)
onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
<style scoped>
@media (min-width: theme('screens.lg')) {
  .hero-container-grid {
    display: grid;
    grid-template-columns: max-content max-content;
    overflow: hidden;
  }
}
.hero-padding {
  padding-top: calc(4rem + 20px);
  @media (max-width: theme('screens.lg')) {
    padding-top: calc(1rem + 64px);
  }
}
.product-badge {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
