<template>
  <BaseCard>
    <div v-if="accommodationFeatures.length > 0" class="tw-flex tw-flex-col tw-justify-between tw-gap-8">
      <div v-for="(group, index) in accommodationFeatures" :key="index">
        <FeaturesSet
          :group="group"
          @toggleEditing="toggleEditing"
          :isLast="accommodationFeatures.length - 1 === index"
        />
      </div>
      <EditFeatureSetModal v-if="isOpen" :editedSection="editedSection" :isOpen="isOpen" @closeEditing="closeEditing" />
    </div>
    <CardLoadingSkeleton v-else height="300px" />
  </BaseCard>
</template>
<script setup>
import { ref } from 'vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import FeaturesSet from '@/components/propertyDetails/propertyFeatures/FeaturesSet.vue'
import CardLoadingSkeleton from '@/components/loadingSkeletons/CardLoadingSkeleton.vue'
import EditFeatureSetModal from '@/components/propertyDetails/propertyFeatures/EditFeatureSetModal.vue'
import { usePropertyFeatures } from '@/components/propertyDetails/propertyFeatures/usePropertyFeatures.js'

const { setEditObject } = usePropertyFeatures()

defineProps({
  accommodationFeatures: Array,
})
const editedSection = ref(null)
const isOpen = ref(false)

const closeEditing = () => {
  isOpen.value = false
  editedSection.value = null
  setEditObject(null)
}
const toggleEditing = group => {
  isOpen.value = true
  setEditObject(group.fields)
  editedSection.value = isOpen.value ? group : null
}
</script>
