<template>
  <table v-if="columnCount > 0 && tableData && competitors" class="tw-border-0">
    <thead class="tw-border tw-rounded-t tw-border-gray-300 tw-bg-gray-100">
      <tr class="tw-border-b tw-border-gray-300">
        <th class="tw-border-0 tw-px-5 tw-py-2 tw-font-normal" :colspan="columnCount + 1">
          <div class="tw-flex tw-items-baseline tw-justify-between tw-gap-2">
            <span
              ><strong>Timeframe: </strong><span>{{ $t(selectedTimeFrameLabel) }}</span></span
            >
            <small> (pre-selected in graph) </small>
          </div>
        </th>
      </tr>
      <tr class="tw-border-0 tw-text-center">
        <th class="tw-border-0 tw-min-w-60"></th>
        <th
          v-for="[colKey, colName] in Object.entries(columns)"
          :key="colKey"
          class="tw-border-0 tw-font-normal"
          :style="{ width: `${100 / columnCount}%` }"
        >
          {{ $t(colName) }}
        </th>
      </tr>
    </thead>
    <tbody class="tw-text-center">
      <tr
        v-for="[accommodation, values] in formattedTableData"
        :key="accommodation.id"
        :class="selectedAccommodationId === accommodation.id ? 'tw-bg-blue-100 tw-text-blue-800' : ''"
      >
        <td class="tw-max-w-60">
          <UiTooltip theme="material" tooltip-position="top" :label="accommodation.name" class="tw-truncate">
            {{ accommodation.name }}
          </UiTooltip>
        </td>
        <td
          v-for="[valueKey, value] in Object.entries(values)"
          :key="`${accommodation.id}${valueKey}`"
          :class="maxValuePerMetricKey?.[valueKey] === value ? 'tw-bg-blue-200 tw-text-blue-800 tw-font-bold' : ''"
        >
          {{ `${value}%` }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from 'vue'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import { selectedAccommodationId } from '@/layouts/queries'
import { useCompetitors } from '@/components/rateInsights/queries'

const props = defineProps({
  timeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  /* Expected format:
    {
      lte2days: '1 night',
      days34: '3 - 4 nights',
      days56: '5 - 6 nights',
      days78: '6 - 7 nights',
      gte9days: '8 nights or more',
    }
  */
  columns: {
    type: Object,
    required: true,
    default() {
      return null
    },
  },
  /* Expected format:
    {
      "6608": {
        "other": 16,
        "weekend": 66,
        "week": 4,
        "longTrip": 14
      },
      "6612": {
        "other": 18,
        "weekend": 63,
        "week": 11,
        "longTrip": 9
      },
    }
  */
  tableData: {
    type: Object,
    required: true,
    default() {
      return null
    },
  },
})

const { competitors } = useCompetitors(selectedAccommodationId)

const columnCount = computed(() => {
  if (!props.columns) return 0
  return Object.keys(props.columns).length
})

const formattedTableData = computed(() => {
  if (!props.tableData || !selectedAccommodationId.value || !props.tableData[selectedAccommodationId.value]) return null

  // TODO: add translation for my property
  const selectedAccommodationData = [
    { id: selectedAccommodationId.value, name: 'My property' },
    props.tableData?.[selectedAccommodationId.value],
  ]
  const tableDataWithoutSelectedAccommodation = Object.entries(props.tableData).reduce((accum, currValue) => {
    if (+currValue[0] === selectedAccommodationId.value) return accum
    return [...accum, [{ id: currValue[0], name: getCompetitorName(currValue[0]) }, currValue[1]]]
  }, [])
  tableDataWithoutSelectedAccommodation.sort((a, b) => {
    if (a[0].name < b[0].name) {
      return -1
    }
    if (a[0].name > b[0].name) {
      return 1
    }
    return 0
  })

  return [selectedAccommodationData, ...tableDataWithoutSelectedAccommodation]
})

const maxValuePerMetricKey = computed(() => {
  if (!Array.isArray(formattedTableData.value) || !props.columns) return null

  return Object.keys(props.columns).reduce((accum, metricKey) => {
    const metricKeyValues = formattedTableData.value.map(accommodation => accommodation[1]?.[metricKey])
    const maxValue = Math.max(...metricKeyValues)
    return { ...accum, [metricKey]: maxValue }
  }, {})
})

const getCompetitorName = accommodationId => {
  if (!competitors.value) return accommodationId
  return competitors.value.find?.(competitor => competitor.id === +accommodationId)?.name ?? accommodationId
}

const timeFrameOptions = [
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 90 Days', value: '90' },
]

const selectedTimeFrameLabel = computed(() => timeFrameOptions.find(option => option.value === props.timeFrame)?.label)
</script>
