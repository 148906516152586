<template>
  <p class="tw-text-blue-800 tw-text-2xl tw-leading-7">
    {{ Intl.NumberFormat(language).format(value) }}
  </p>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
defineProps({
  value: {
    type: Number,
    default: 0,
  },
})
const language = computed(() => store.state.locale.language)
</script>
