<template>
  <div>
    <div class="tw-bg-white tw-pb-24">
      <AuthMenu @onRegisterClick="onAuthorize('signup')" @onLoginClick="onAuthorize('login')" />
      <div>
        <LandingPageHero
          class="hero-padding tw-pt-16 tw-max-w-screen-xxl tw-mx-auto"
          @onRegisterClick="onAuthorize('signup')"
          @onLoginClick="onAuthorize('login')"
        />
        <LandingPageHighlights class="tw-mt-14 tw-max-w-screen-xxl tw-mx-auto" />
      </div>
      <LandingPageProducts id="landing-page-products" class="tw-mt-14" @onRegisterClick="onAuthorize('login')" />
      <LandingPageSupport id="landing-page-support" class="tw-mt-14" @onRegisterClick="onAuthorize('login')" />
    </div>
    <ThePageFooter />
    <TheCookieNotice />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import LandingPageHero from '@/components/landingPage/LandingPageHero.vue'
import LandingPageHighlights from '@/components/landingPage/LandingPageHighlights.vue'
import LandingPageProducts from '@/components/landingPage/LandingPageProducts.vue'
import LandingPageSupport from '@/components/landingPage/LandingPageSupport.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import appAnalytics from '@/utils/tracking'

const route = useRoute()
const store = useStore()
const locale = computed(() => store.state.locale)
const authorize = params => store.dispatch('session/authorize', params)

const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
<style scoped>
.hero-padding {
  padding-top: calc(4rem + 20px);
  @media (max-width: theme('screens.lg')) {
    padding-top: calc(1rem + 64px);
  }
}
</style>
