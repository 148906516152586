<template>
  <div>
    <div
      v-if="isLoading || isCompetitorsFetching"
      class="tw-h-[425px] tw-bg-gray-300 tw-animate-pulse tw-rounded-lg"
    ></div>
    <div v-else-if="isError || isCompetitorsError" class="tw-h-[425px]"></div>
    <div v-else>
      <CompetitorsComparisonTable
        :columns="columns"
        :timeFrame="timeFrame"
        :tableData="timeFrameTrends"
        :isWaitingMode="isWaitingMode"
      />
    </div>
  </div>
</template>

<script setup>
import CompetitorsComparisonTable from '@/components/visitorsProfile/common/CompetitorsComparisonTable.vue'
import { useCompetitors } from '@/components/rateInsights/queries'
import { selectedAccommodationId } from '@/layouts/queries'

defineProps({
  timeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  isLoading: Boolean,
  isError: Boolean,
  timeFrameTrends: Object,
  isWaitingMode: Boolean,
})

const { isCompetitorsFetching, isCompetitorsError } = useCompetitors(selectedAccommodationId)

const columns = {
  weekend: 'Weekend',
  week: 'Week',
  longTrip: 'Long Trip',
  other: 'Other',
}
</script>
