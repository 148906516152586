<template>
  <div>
    <ResultCard
      :assignment="accommodationData"
      :subscriptions="subscriptions"
      :isModalOpen="isDeleteAssignmentModalOpen"
      @fetchData="fetchData"
      @toggleModal="toggleDeleteAssignmentModal"
    />
    <DeleteAssignmentModal
      :isOpen="isDeleteAssignmentModalOpen"
      :isDeleting="isLoading"
      @toggleModal="toggleDeleteAssignmentModal"
      @deleteAssignment="deleteAssignmentFunction"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue'

import { useDeleteAssignment } from '@/components/adminTool/queries/index.js'
import ResultCard from '@/components/adminTool/ResultCard.vue'
import DeleteAssignmentModal from '@/components/adminTool/DeleteAssignmentModal.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const { displayNotification } = useToastNotifications()

const props = defineProps({
  accommodationData: Object,
  subscriptions: Object,
})
const isDeleteAssignmentModalOpen = ref(false)

const { isLoading, deleteAssignment } = useDeleteAssignment()
const emit = defineEmits(['updateStaticMessage', 'showError', 'resetState', 'fetchData'])
const updateStaticMessage = type => emit('updateStaticMessage', type)
const showError = error => emit('showError', error)
const resetState = () => emit('resetState')
const fetchData = () => emit('fetchData')

const toggleDeleteAssignmentModal = value => {
  isDeleteAssignmentModalOpen.value = value
}

const deleteAssignmentFunction = () => {
  deleteAssignment(
    {
      accommodationId: props.subscriptions.accommodation,
    },
    {
      onSuccess: () => {
        resetState()
        toggleDeleteAssignmentModal(false)
        updateStaticMessage('welcome')
        displayNotification({
          title: 'Success!',
          message: 'The accommodation was unassigned from the Studio Organization',
          type: 'success',
        })
      },
      onError: error => {
        toggleDeleteAssignmentModal(true)
        showError(error)
        displayNotification({
          title: 'Error!',
          message: error.response.data.message,
          type: 'error',
        })
      },
    }
  )
}
</script>
