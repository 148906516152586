import { createStore } from 'vuex'
import appStore from './app'
import sessionStore from './session'
import { selectedAccommodationId } from '@/layouts/queries'

const HotjarPlugin = store => {
  store.subscribe((mutation, state) => {
    var userId = state.session.userId || null
    var language = navigator.language || navigator.userLanguage
    if (window.hj) {
      window.hj('identify', userId, {
        language,
      })
    }
  })
}

const setSentryUserPlugin = store => {
  store.subscribe((mutation, state) => {
    const user = {
      id: state.session.userId,
      selectedOrganizationId: state.session.selectedOrganisation?.id,
      selectedAccommodationId: selectedAccommodationId,
      locale: state.locale.language,
      currency: state.currency,
    }
    store.$sentry.setUser(user)
  })
}

export const plugins = [HotjarPlugin, setSentryUserPlugin]

export const store = createStore({
  plugins,
  state() {
    return {
      isInitialized: false,
      locale: {
        language: 'en-US',
      },
      currency: null,
      currencyName: null,
      isCurrencyEditable: true,
      internalApps: {
        // our own checkout app
        'ar-studio-payment-client': {
          url: import.meta.env.VITE_PAYMENT_CLIENT_URL,
          slug: 'checkout',
          app_id: '2',
          trusted: true,
          name: 'Checkout',
        },
      },
    }
  },
  mutations: {
    SET_INITIALIZED(state) {
      state.isInitialized = true
    },
    SET_LANGUAGE(state, locale = 'en-US') {
      state.locale.language = locale
    },
    SET_CURRENCY(state, currency) {
      state.currency = currency
    },
    SET_CURRENCY_NAME(state, value) {
      state.currencyName = value
    },
    SET_IS_CURRENCY_EDITABLE(state, value = true) {
      state.isCurrencyEditable = value
    },
  },
  getters: {
    getLanguage(state) {
      return state.locale.language
    },
  },
  modules: {
    app: {
      namespaced: true,
      state: appStore.state,
      actions: appStore.actions,
      mutations: appStore.mutations,
      getters: appStore.getters,
    },
    session: {
      namespaced: true,
      state: sessionStore.state,
      actions: sessionStore.actions,
      mutations: sessionStore.mutations,
      getters: sessionStore.getters,
    },
  },
})
