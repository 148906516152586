class AppAnalytics {
  constructor() {
    this.queue = []
    this.events = {
      APP_LAUNCHED: 'app_launched',
      BUTTON_CLICKED: 'button_clicked',
      TRC_PAUSE_CAMPAIGN: 'tRC_pause_campaign',
      TRC_CAMPAIGN_RESUMED: 'tRC_campaign_resumed',
      FREE_LINKS_ACTIVATION_ERROR: 'free_links_activation_error',
      FREE_LINKS_ACTIVATION_SUCCESS: 'free_links_activation_success',
      RC_ONBOARDING_PAYMENT_LAUNCHED: 'rc_onboarding_payment_launched',
      RC_ONBOARDING_PAYMENT_SUCCESS: 'rc_onboarding_payment_success',
      RC_ONBOARDING_PAYMENT_FAILURE: 'rc_onboarding_payment_failure',
      RC_ONBOARDING_STARTED: 'rc_onboarding_started',
      SP_HOMEPAGE_ONBOARDING_VISITED: 'sp_onboarding_visited',
      SP_HOMEPAGE_BANNER_CLICKED: 'sp_homepage_banner_clicked',
      SP_HOMEPAGE_POPUP_CLICKED: 'sp_homepage_popup_clicked',
      SP_COMPARE_FEATURES_VISITED: 'sp_compare_features_visited',
      SP_VISIT_RI_FROM_BANNER: 'sp_visit_ri_from_banner',
      SP_COMPARE_FEATURES_BANNER_CLICKED: 'sp_compare_features_banner_clicked',
      SP_COMPARE_FEATURES_CARD_CLICKED: 'sp_compare_features_card_clicked',
      SP_PAYMENT_VISITED: 'sp_payment_visited',
      SP_PAYMENT_SUCCEED: 'sp_payment_succeed',
      SP_PAYMENT_FAILED: 'sp_payment_failed',
      SP_SUBSCRIPTION_CANCELED: 'sp_subscription_canceled',
      SP_SUBSCRIPTION_REACTIVATED: 'sp_subscription_reactivated',
      RC_PROVIDER_CHANGE_SUCCESS: 'rc_provider_change_success',
      RC_PROVIDER_CHANGE_CAMPAIGN_CHANGE_SUCCESS: 'rc_provider_change_campaign_change_success',
      RC_PROVIDER_CHANGE_FAILED: 'rc_provider_change_failed',
      RC_PROVIDER_CHANGE_CAMPAIGN_CHANGE_FAILED: 'rc_provider_change_campaign_change_failed',
      RI_NO_BOOKING_SITES: 'ri_no_booking_sites',
      RI_NO_DATA_FOR_FILTERS: 'ri_no_data_for_filters',
      VP_TIMEFRAME_CHANGED: 'vp_timeframe_changed',
    }
    this.readTimeout = null
    this.readRetryCount = 0
  }

  identify(id = '', properties = {}) {
    if (!id) return

    this.addToQueue('identify', {
      event: id,
      properties: properties,
    })

    this.readQueue()
  }

  track(event = '', properties = {}) {
    if (!event) return

    this.addToQueue('track', {
      event,
      properties,
    })
    this.readQueue()
  }

  reset() {
    if (window.analytics) {
      window.analytics.reset()
    }
  }

  page(properties) {
    this.addToQueue('page', {
      properties,
    })
    this.readQueue()
  }

  addToQueue(type, item) {
    this.queue.push({
      type: type,
      item: item,
    })
  }

  readQueue() {
    clearTimeout(this.readTimeout)
    if (this.queue.length === 0 || this.readRetryCount >= 240) return

    if (!window.analytics) {
      // make sure to try again and not wait for the next
      // event to be tracked before reading the queue
      this.readTimeout = setTimeout(() => {
        this.readRetryCount += 1
        this.readQueue()
      }, 500)

      return
    }

    let index = 0
    while (this.queue.length > 0) {
      const data = this.queue[index]
      const type = data.type
      const item = data.item

      if (!item) {
        window.analytics[type]()
      } else {
        window.analytics[type](item.event, item.properties)
      }

      this.queue.splice(index, 1)
    }
  }
}

export default new AppAnalytics()
