import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { isInternalApp } from '@/utils/Utility.js'
import AppService from '@/services/AppService'

const internalApps = {
  // our own checkout app
  'ar-studio-payment-client': {
    url: import.meta.env.VITE_PAYMENT_CLIENT_URL,
    slug: 'checkout',
    app_id: '2',
    trusted: true,
    name: 'Checkout',
  },
}

const fetchAllApps = async accommodationId => {
  const response = await AppService.getAll(accommodationId)
  return response.data.data.myApps ?? []
}

export function useAllApps(accommodationId) {
  const { isLoading, isFetching, data, isError } = useQuery({
    queryKey: ['allApps', accommodationId],
    queryFn: ({ queryKey }) => fetchAllApps(queryKey[1]),
  })

  const available = computed(() => data.value?.available ?? [])
  const ownedApps = computed(() => data.value?.owned ?? [])
  const owned = computed(() => [...ownedApps.value, ...Object.values(internalApps)])
  const allApps = computed(() => [...owned.value, ...available.value])

  const visibleOwnedApps = computed(() => {
    const apps = data?.value
    const allOwnedApps = owned.value
    if (!apps || !ownedApps.value) return []
    return allOwnedApps?.filter(app => {
      // should have an url and not be blacklisted for dashboard listing
      const isVisible = app.url
      // should not be an internal app where we use numeric id's
      const isInternal = isInternalApp(app)
      return isVisible && !isInternal
    })
  })

  return { isLoadingApps: isLoading, isFetchingApps: isFetching, visibleOwnedApps, available, owned, allApps, isError }
}
