export const countriesMockData = [
  {
    locale: 'DE',
    share: 70,
  },
  {
    locale: 'AT',
    share: 20,
  },
  {
    locale: 'NL',
    share: 5,
  },
  {
    locale: 'GB',
    share: 4,
  },
  {
    locale: 'CZ',
    share: 1,
  },
  {
    locale: 'US',
    share: 0,
  },
]
