<template>
  <div class="tw-mb-10">
    <h2
      class="tw-text-2xl sm:tw-text-3xl tw-font-bold tw-my-10"
      v-html="$t('business_studio_plus_welcome_header')"
    ></h2>
    <div class="tw-bg-blue-200 tw-rounded-xl tw-p-8 tw-flex tw-flex-col sm:tw-flex-row">
      <div class="tw-flex tw-flex-col tw-w-full">
        <div class="tw-pb-8">
          <div class="tw-flex tw-items-center tw-justify-start tw-pb-2.5">
            <BaseIcon :height="20" :width="20" icon-name="information" class="tw-fill-gray-900 tw-h-[24px] tw-w-[24px]">
              <Information />
            </BaseIcon>
            <p class="tw-flex tw-font-bold tw-ml-2 tw-text-xl">
              {{ $t('business_studio_plus_contact_info_header') }}
            </p>
          </div>
          <p>
            {{ $t('business_studio_plus_contact_info_subtext') }}
          </p>
        </div>
        <div class="tw-flex tw-justify-between lg:tw-items-end tw-flex-col lg:tw-flex-row">
          <div class="tw-w-auto">
            <p class="tw-py-1.5 tw-break-words">
              {{ $t('business_studio_plus_telephone') + ' '
              }}<span class="tw-font-bold">{{ accommodationBasicInfo?.phone }}</span>
            </p>
            <p class="tw-py-1.5 tw-break-words">
              {{ $t('business_studio_plus_email_address') + ' '
              }}<a class="tw-font-bold tw-text-blue-800 tw-underline" :href="mailAddress" rel="noopener noreferrer">{{
                accommodationBasicInfo?.email
              }}</a>
            </p>
            <p class="tw-pt-1.5 tw-break-words">
              {{ $t('business_studio_plus_website') + ' '
              }}<a
                :href="websiteUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-font-bold tw-text-blue-800 tw-underline hover:tw-cursor-pointer"
                >{{ accommodationBasicInfo?.homepageUrl }}</a
              >
            </p>
          </div>
          <div class="tw-mt-6 lg:tw-mt-0 tw-flex">
            <GhostButton class="sm:tw-w-auto tw-w-1/2 tw-mr-4" @click="hideBanner" @keydown.enter="hideBanner">{{
              $t('business_studio_plus_contact_info_confirm')
            }}</GhostButton>
            <MainButton class="sm:tw-w-auto tw-w-1/2" @click="redirectToUpdate" @keydown.enter="redirectToUpdate">
              {{ $t('business_studio_plus_contact_info_update') }}
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import Information from '@/components/icons/Information.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { useAccommodationBasicInfo } from '@/components/propertyDetails/queries/index.js'
import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()

const { accommodationBasicInfo } = useAccommodationBasicInfo(selectedAccommodationId)

const emit = defineEmits(['hideBanner'])
const hideBanner = () => emit('hideBanner')

const mailAddress = computed(() => 'mailto:' + accommodationBasicInfo.value?.email)
const websiteUrl = computed(() =>
  accommodationBasicInfo.value?.homepageUrl?.startsWith('http://') ||
  accommodationBasicInfo.value?.homepageUrl?.startsWith('https://')
    ? accommodationBasicInfo.value?.homepageUrl
    : `https://${accommodationBasicInfo.value?.homepageUrl}`
)

const redirectToUpdate = () => {
  router.push({ name: 'property-details-basic-information', query: { open_update: true } })
}
</script>
<style scoped>
h2 :deep(span) {
  @apply tw-text-blue-800;
}
</style>
