<template>
  <section class="tw-mt-20">
    <div>
      <h2
        class="tw-text-[40px] md:tw-text-5xl tw-font-bold tw-leading-[50px] tw-text-center tw-mb-4"
        v-html="$t('tbs_mp_tbs_subtext')"
      />
      <p class="tw-text-center tw-px-4">
        {{ $t('tbs_mp_tbs_info_text') }}
      </p>
      <LandingPageProductsGrid
        imageSide="right"
        :icon="FreeBookingLinkIcon"
        iconAlt="Free Booking links icon"
        :image="FreeBookingLinksImage"
        :productTitle="$t('tbs_mp_solution_1')"
        :sectionTitle="$t('tbs_mp_solution_1_header')"
        :sectionText="$t('tbs_mp_solution_1_text')"
      >
        <template v-slot:learnMore>
          <BaseLinkButton
            class="hover:tw-text-gray-900 tw-flex"
            href="/home/getstarted/free-booking-links"
            rel="noopener noreferrer"
            :dataTestId="'free-booking-title-learn-more-btn'"
          >
            {{ $t('fbl_learn_more') }}
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              :viewbox-height="24"
              :viewbox-width="24"
              :icon-color="'#1391D2'"
              class="tw-mt-[1px]"
            >
              <ChevronRight />
            </BaseIcon>
          </BaseLinkButton>
        </template>
        <template v-slot:cta>
          <div class="tw-flex tw-items-baseline">
            <MainButton
              class="tw-mt-7 lg:tw-mt-10 tw-mr-[31px]"
              @click="onLoginClick"
              @keydown.enter="onLoginClick"
              :dataTestId="'free-booking-title-login'"
              >{{ $t('tbs_mp_cta_log_in') }}</MainButton
            >
          </div>
        </template>
      </LandingPageProductsGrid>
      <LandingPageProductsGrid
        imageSide="left"
        :image="PropertyDetailsImage"
        :icon="PropertyDetailsIcon"
        iconAlt="Property Details icon"
        :productTitle="$t('tbs_mp_solution_2')"
        :sectionTitle="$t('tbs_mp_solution_2_header')"
        :sectionText="$t('tbs_mp_solution_2_text')"
      />
      <LandingPageProductsGrid
        imageSide="right"
        :image="PerformanceAnalyticsImage"
        :icon="PerformanceAnalyticsIcon"
        iconAlt="Performance Analytics icon"
        :productTitle="$t('tbs_mp_solution_3')"
        :sectionTitle="$t('tbs_mp_solution_3_header')"
        :sectionText="$t('tbs_mp_solution_3_text')"
      />
      <LandingPageProductsGrid
        imageSide="left"
        :image="RateConnectImage"
        :icon="RateConnectIcon"
        iconAlt="Rate Connect icon"
        :productTitle="$t('tbs_mp_solution_4')"
        :sectionTitle="$t('tbs_mp_solution_4_header')"
        :sectionText="$t('tbs_mp_solution_4_text')"
      />
    </div>
  </section>
</template>

<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseLinkButton from '@/components/base/buttons/BaseLinkButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import LandingPageProductsGrid from '@/components/landingPage/LandingPageProductsGrid.vue'

const FreeBookingLinksImage = new URL('/src/assets/img/landing/free-booking-links-image.svg', import.meta.url)
const FreeBookingLinkIcon = new URL('/src/assets/img/nav-icons/free-booking-link_hover.svg', import.meta.url)
const PropertyDetailsImage = new URL('/src/assets/img/landing/property-details-image.svg', import.meta.url)
const PropertyDetailsIcon = new URL('/src/assets/img/landing/property_details.svg', import.meta.url)
const PerformanceAnalyticsImage = new URL('/src/assets/img/landing/performance-image.svg', import.meta.url)
const PerformanceAnalyticsIcon = new URL('/src/assets/img/landing/performance_analytics.svg', import.meta.url)
const RateConnectImage = new URL('/src/assets/img/landing/rate-connect-image.svg', import.meta.url)
const RateConnectIcon = new URL('/src/assets/img/nav-icons/rate-connect_hover.svg', import.meta.url)

const emit = defineEmits(['onRegisterClick'])
const onLoginClick = e => emit('onRegisterClick', e.target.value)
</script>

<style scoped>
h2:deep(span) {
  @apply tw-text-blue-700;
}
</style>
