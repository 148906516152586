<template>
  <li class="tw-border tw-border-gray-300 tw-rounded-xl">
    <header>
      <div class="tw-p-4">
        <h2 class="tw-font-bold">{{ $t(`lang_${props.description?.language_tag}`) }}</h2>
        <p class="tw-text-sm tw-text-gray-700">
          {{
            $t('app_description_list_item_hint', {
              language: $t(`description_language_placeholder_${description?.language_tag}`),
            })
          }}
        </p>
      </div>
      <hr class="tw-bg-gray-300" />
    </header>
    <section class="tw-m-2">
      <button
        class="tw-p-2 tw-rounded tw-text-left tw-cursor-pointer hover:tw-bg-gray-200 tw-transition-colors tw-duration-200 break-word"
        @click="emit('onClick', description)"
        @keydown.enter="emit('onClick')"
      >
        <p class="tw-text-sm tw-text-blue-700">
          {{ description.text.substr(0, 200) + '...' }}
        </p>
        <div class="tw-text-sm tw-mt-2">
          <span class="tw-font-bold tw-capitalize" :class="descriptionStatusStyle"
            >{{ $t(`app_description_status_${description.status}`) }} -
          </span>
          <span class="tw-text-gray-700">{{
            description.source === 'hotelier' ? $t('app_description_created_by') : $t('app_description_freelancer')
          }}</span>
        </div>
      </button>
    </section>
  </li>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  description: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['onClick'])

const descriptionStatusStyle = computed(() => {
  if (props.description.status === 'live') return 'tw-text-green-700'
  if (props.description.status === 'rejected') return 'tw-text-red-800'
  return 'tw-text-gray-700'
})
</script>

<style scoped>
.break-word {
  word-break: break-word;
}
</style>
