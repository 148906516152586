import { computed } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import OrganisationManagementService from '@/services/OrganisationManagementService'

export function useOrganisationUsers(organisationId) {
  const { isLoading, data } = useQuery({
    queryKey: ['organisationUsers', organisationId.value],
    queryFn: ({ queryKey }) => OrganisationManagementService.getOrganisationUsers({ organisationId: queryKey[1] }),
  })

  const userOrganisationData = computed(() => data?.value?.data)

  return { isLoading, userOrganisationData }
}

export function useUsersInvitations(selectedOrganisationId) {
  const { isLoading, data } = useQuery({
    queryKey: ['userInvitations', selectedOrganisationId],
    queryFn: async () => await OrganisationManagementService.getUserInvitations(),
  })

  const usersInvitations = computed(() => data?.value?.data?.invitations ?? [])

  return { isLoading, usersInvitations }
}

export function useInviteUserMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: inviteUserLoading,
    isSuccess: inviteUserSuccess,
    isError: inviteUserError,
    mutate: inviteUser,
  } = useMutation({
    mutationFn: ({ data }) => OrganisationManagementService.inviteUser({ data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisationUsers'] })
      queryClient.invalidateQueries({ queryKey: ['userInvitations'] })
    },
  })

  return {
    inviteUserLoading,
    inviteUserSuccess,
    inviteUserError,
    inviteUser,
  }
}

export function updateUserRoleMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: updateUserRoleLoading,
    isSuccess: updateUserRoleSuccess,
    isError: updateUserRoleError,
    mutate: updateUserRole,
  } = useMutation({
    mutationFn: ({ userId, organisationId, role }) =>
      OrganisationManagementService.updateUserRoleInOrganisation({ userId, organisationId, role }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisationUsers'] })
    },
  })

  return {
    updateUserRoleLoading,
    updateUserRoleSuccess,
    updateUserRoleError,
    updateUserRole,
  }
}

export function removeUserInvitationFromOrgMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: removeUserInvitationFromOrgLoading,
    isSuccess: removeUserInvitationFromOrgSuccess,
    isError: removeUserInvitationFromOrgError,
    mutate: removeUserInvitationFromOrg,
  } = useMutation({
    mutationFn: async ({ email }) => {
      await OrganisationManagementService.removeUserInvitationFromOrganisation({ email: email })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisationUsers'] })
      queryClient.invalidateQueries({ queryKey: ['userInvitations'] })
    },
  })

  return {
    removeUserInvitationFromOrgLoading,
    removeUserInvitationFromOrgSuccess,
    removeUserInvitationFromOrgError,
    removeUserInvitationFromOrg,
  }
}

export function removeUserFromOrgMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: removeUserFromOrgLoading,
    isSuccess: removeUserFromOrgSuccess,
    isError: removeUserFromOrgError,
    mutate: removeUserFromOrg,
  } = useMutation({
    mutationFn: async ({ userId }) => {
      await OrganisationManagementService.removeExistingUserFromOrganisation({ userId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisationUsers'] })
      queryClient.invalidateQueries({ queryKey: ['userInvitations'] })
    },
  })

  return {
    removeUserFromOrgLoading,
    removeUserFromOrgSuccess,
    removeUserFromOrgError,
    removeUserFromOrg,
  }
}
