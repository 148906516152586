<template>
  <div>
    <div class="tw-bg-white tw-overflow-x-hidden">
      <AuthMenu @onRegisterClick="onAuthorize('signup')" @onLoginClick="onAuthorize('login')" />
      <div class="tw-bg-gray-100">
        <ProductPageHero
          :isFreeBookingLinks="false"
          :isStudioPlus="true"
          class="hero-container-grid hero-padding tw-max-w-screen-xxl tw-mx-auto tw-pb-10 md:tw-pb-28 tw-relative"
        >
          <template #leftSide>
            <h1
              class="tw-text-[48px] tw-leading-[50px] lg:tw-text-[50px] tw-font-bold tw-mb-4 lg:tw-mt-0 lg:tw-w-[120%] lg:tw-mt-5 tw-mt-2"
              v-html="$t('business_studio_plus_header')"
            />
            <p class="tw-mt-7" v-html="$t('studio_plus_mp_subtext')"></p>
            <MainButton
              :class="'tw-mt-7 lg:tw-mt-12 tw-w-full lg:tw-w-auto'"
              @click="onAuthorize('signup')"
              @keydown.enter="onAuthorize('signup')"
            >
              {{ $t('studio_plus_mp_cta') }}
            </MainButton>
          </template>
          <template #rightSide>
            <img
              :src="heroImageURL"
              alt="Business Studio+"
              :height="350"
              :width="646"
              class="tw-mt-12 lg:tw-mt-0 tw-mx-auto tw-pr-4 tw-pl-4 lg:tw-pr-0 lg:tw-pl-0"
            />
          </template>
          <template #row>
            <div class="tw-m-auto tw-w-full md:tw-absolute tw-bottom-[-31px] tw-z-1 tw-mt-10 tw-px-4">
              <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center">
                <div
                  v-for="feature in featuresKeys"
                  :key="feature"
                  class="tw-bg-white box-shadow tw-mx-2 tw-mb-2 tw-text-gray-900 tw-text-xl tw-font-bold tw-py-3 tw-px-5 tw-rounded-full tw-flex tw-items-center"
                >
                  <span
                    class="tw-mr-2 tw-bg-blue-800 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-w-[30px] tw-h-[30px] tw-pt-[4px]"
                  >
                    <BaseIcon :height="14" :width="14" icon-name="checkmark-blue" icon-color="#fff">
                      <CheckmarkBlue />
                    </BaseIcon>
                  </span>
                  {{ $t(feature) }}
                </div>
              </div>
            </div>
          </template>
        </ProductPageHero>
      </div>
      <ProductPageFeatureCards />
      <div class="tw-w-full md:tw-w-[990px] tw-m-auto tw-flex tw-flex-col tw-justify-center">
        <ProductPageTwoColumnLayout
          :is-left-side="true"
          :is-button="false"
          :title="$t('studio_plus_mp_benefit_1_header')"
          :text="$t('studio_plus_mp_benefit_1_text')"
          :image="sectionOneImageURL"
          :imageWidth="380"
          :imageHeight="175"
          :is-link="false"
          :is-big="false"
        />
        <ProductPageTwoColumnLayout
          :is-left-side="false"
          :is-button="false"
          :title="$t('studio_plus_mp_benefit_2_header')"
          :text="$t('studio_plus_mp_benefit_2_text')"
          :image="sectionTwoImageURL"
          :imageWidth="285"
          :imageHeight="262"
          :is-link="false"
          :is-big="false"
        />
        <ProductPageTwoColumnLayout
          :is-left-side="true"
          :is-button="false"
          :title="$t('studio_plus_mp_benefit_3_header')"
          :text="$t('studio_plus_mp_benefit_3_text')"
          :image="sectionThreeImageURL"
          :imageWidth="365"
          :imageHeight="267"
          :is-link="false"
          :is-big="false"
        />
        <ProductPageTwoColumnLayout
          :is-left-side="false"
          :is-button="false"
          :title="$t('studio_plus_mp_benefit_4_header')"
          :text="$t('studio_plus_mp_benefit_4_text')"
          :image="sectionFourImageURL"
          :imageWidth="355"
          :imageHeight="231"
          :is-link="false"
          :is-big="false"
        />
      </div>

      <ProductPageCardBanner
        :text="$t('studio_plus_mp_cta_text')"
        :button="$t('studio_plus_mp_cta')"
        @onRegisterClick="onAuthorize('login')"
        :isBigText="false"
      />
    </div>
    <ThePageFooter />
    <TheCookieNotice />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import ProductPageHero from '@/components/productPage/ProductPageHero.vue'
import ProductPageTwoColumnLayout from '@/components/productPage/ProductPageTwoColumnLayout.vue'
import ProductPageFeatureCards from '@/components/productPage/ProductPageFeatureCards.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import CheckmarkBlue from '@/components/icons/CheckmarkBlue.vue'
import ProductPageCardBanner from '@/components/productPage/ProductPageCardBanner.vue'

const WebHeroImages = ref({
  EN: new URL(`/src/assets/img/studio-plus-landing/en/banner.png`, import.meta.url),
  BR: new URL(`/src/assets/img/studio-plus-landing/br/banner.png`, import.meta.url),
  DE: new URL(`/src/assets/img/studio-plus-landing/de/banner.png`, import.meta.url),
  IT: new URL(`/src/assets/img/studio-plus-landing/it/banner.png`, import.meta.url),
  FR: new URL(`/src/assets/img/studio-plus-landing/fr/banner.png`, import.meta.url),
  ES: new URL(`/src/assets/img/studio-plus-landing/es/banner.png`, import.meta.url),
  EL: new URL(`/src/assets/img/studio-plus-landing/gr/banner.png`, import.meta.url),
  JA: new URL(`/src/assets/img/studio-plus-landing/jp/banner.png`, import.meta.url),
  MX: new URL(`/src/assets/img/studio-plus-landing/mx/banner.png`, import.meta.url),
  NL: new URL(`/src/assets/img/studio-plus-landing/nl/banner.png`, import.meta.url),
  PL: new URL(`/src/assets/img/studio-plus-landing/pl/banner.png`, import.meta.url),
  PT: new URL(`/src/assets/img/studio-plus-landing/pt/banner.png`, import.meta.url),
  RU: new URL(`/src/assets/img/studio-plus-landing/ru/banner.png`, import.meta.url),
  TR: new URL(`/src/assets/img/studio-plus-landing/tr/banner.png`, import.meta.url),
})
const SectionOneImages = ref({
  EN: new URL(`/src/assets/img/studio-plus-landing/en/card_1.svg`, import.meta.url),
  BR: new URL(`/src/assets/img/studio-plus-landing/br/card_1.svg`, import.meta.url),
  DE: new URL(`/src/assets/img/studio-plus-landing/de/card_1.svg`, import.meta.url),
  IT: new URL(`/src/assets/img/studio-plus-landing/it/card_1.svg`, import.meta.url),
  FR: new URL(`/src/assets/img/studio-plus-landing/fr/card_1.svg`, import.meta.url),
  ES: new URL(`/src/assets/img/studio-plus-landing/es/card_1.svg`, import.meta.url),
  EL: new URL(`/src/assets/img/studio-plus-landing/gr/card_1.svg`, import.meta.url),
  JA: new URL(`/src/assets/img/studio-plus-landing/jp/card_1.svg`, import.meta.url),
  MX: new URL(`/src/assets/img/studio-plus-landing/mx/card_1.svg`, import.meta.url),
  NL: new URL(`/src/assets/img/studio-plus-landing/nl/card_1.svg`, import.meta.url),
  PL: new URL(`/src/assets/img/studio-plus-landing/pl/card_1.svg`, import.meta.url),
  PT: new URL(`/src/assets/img/studio-plus-landing/pt/card_1.svg`, import.meta.url),
  RU: new URL(`/src/assets/img/studio-plus-landing/ru/card_1.svg`, import.meta.url),
  TR: new URL(`/src/assets/img/studio-plus-landing/tr/card_1.svg`, import.meta.url),
})
const SectionTwoImages = ref({
  BR: new URL(`/src/assets/img/studio-plus-landing/br/card_2.svg`, import.meta.url),
  EN: new URL(`/src/assets/img/studio-plus-landing/en/card_2.svg`, import.meta.url),
  DE: new URL(`/src/assets/img/studio-plus-landing/de/card_2.svg`, import.meta.url),
  IT: new URL(`/src/assets/img/studio-plus-landing/it/card_2.svg`, import.meta.url),
  FR: new URL(`/src/assets/img/studio-plus-landing/fr/card_2.svg`, import.meta.url),
  ES: new URL(`/src/assets/img/studio-plus-landing/es/card_2.svg`, import.meta.url),
  EL: new URL(`/src/assets/img/studio-plus-landing/gr/card_2.svg`, import.meta.url),
  JA: new URL(`/src/assets/img/studio-plus-landing/jp/card_2.svg`, import.meta.url),
  MX: new URL(`/src/assets/img/studio-plus-landing/mx/card_2.svg`, import.meta.url),
  NL: new URL(`/src/assets/img/studio-plus-landing/nl/card_2.svg`, import.meta.url),
  PL: new URL(`/src/assets/img/studio-plus-landing/pl/card_2.svg`, import.meta.url),
  PT: new URL(`/src/assets/img/studio-plus-landing/pt/card_2.svg`, import.meta.url),
  RU: new URL(`/src/assets/img/studio-plus-landing/ru/card_2.svg`, import.meta.url),
  TR: new URL(`/src/assets/img/studio-plus-landing/tr/card_2.svg`, import.meta.url),
})
const SectionThreeImages = ref({
  BR: new URL(`/src/assets/img/studio-plus-landing/br/card_3.svg`, import.meta.url),
  EN: new URL(`/src/assets/img/studio-plus-landing/en/card_3.svg`, import.meta.url),
  DE: new URL(`/src/assets/img/studio-plus-landing/de/card_3.svg`, import.meta.url),
  IT: new URL(`/src/assets/img/studio-plus-landing/it/card_3.svg`, import.meta.url),
  FR: new URL(`/src/assets/img/studio-plus-landing/fr/card_3.svg`, import.meta.url),
  ES: new URL(`/src/assets/img/studio-plus-landing/es/card_3.svg`, import.meta.url),
  EL: new URL(`/src/assets/img/studio-plus-landing/gr/card_3.svg`, import.meta.url),
  JA: new URL(`/src/assets/img/studio-plus-landing/jp/card_3.svg`, import.meta.url),
  MX: new URL(`/src/assets/img/studio-plus-landing/mx/card_3.svg`, import.meta.url),
  NL: new URL(`/src/assets/img/studio-plus-landing/nl/card_3.svg`, import.meta.url),
  PL: new URL(`/src/assets/img/studio-plus-landing/pl/card_3.svg`, import.meta.url),
  PT: new URL(`/src/assets/img/studio-plus-landing/pt/card_3.svg`, import.meta.url),
  RU: new URL(`/src/assets/img/studio-plus-landing/ru/card_3.svg`, import.meta.url),
  TR: new URL(`/src/assets/img/studio-plus-landing/tr/card_3.svg`, import.meta.url),
})
const SectionFourImages = ref({
  BR: new URL(`/src/assets/img/studio-plus-landing/br/card_4.svg`, import.meta.url),
  EN: new URL(`/src/assets/img/studio-plus-landing/en/card_4.svg`, import.meta.url),
  DE: new URL(`/src/assets/img/studio-plus-landing/de/card_4.svg`, import.meta.url),
  IT: new URL(`/src/assets/img/studio-plus-landing/it/card_4.svg`, import.meta.url),
  FR: new URL(`/src/assets/img/studio-plus-landing/fr/card_4.svg`, import.meta.url),
  ES: new URL(`/src/assets/img/studio-plus-landing/es/card_4.svg`, import.meta.url),
  EL: new URL(`/src/assets/img/studio-plus-landing/gr/card_4.svg`, import.meta.url),
  JA: new URL(`/src/assets/img/studio-plus-landing/jp/card_4.svg`, import.meta.url),
  MX: new URL(`/src/assets/img/studio-plus-landing/mx/card_4.svg`, import.meta.url),
  NL: new URL(`/src/assets/img/studio-plus-landing/nl/card_4.svg`, import.meta.url),
  PL: new URL(`/src/assets/img/studio-plus-landing/pl/card_4.svg`, import.meta.url),
  PT: new URL(`/src/assets/img/studio-plus-landing/pt/card_4.svg`, import.meta.url),
  RU: new URL(`/src/assets/img/studio-plus-landing/ru/card_4.svg`, import.meta.url),
  TR: new URL(`/src/assets/img/studio-plus-landing/tr/card_4.svg`, import.meta.url),
})

const store = useStore()
const route = useRoute()

const windowWidth = ref(window.innerWidth)
const featuresKeys = ['studio_plus_mp_higlight_1', 'studio_plus_mp_highlight_2', 'studio_plus_mp_highlight_3']
const locale = computed(() => store.state.locale.language)
const isRussian = ref(locale.value === 'ru-RU')

const country = computed(() => locale.value.split('-')[0].toUpperCase() || 'EN')
const heroImageURL = computed(() => {
  if (locale.value === 'es-MX') {
    return WebHeroImages.value['MX']
  } else if (locale.value === 'pt-BR') {
    return WebHeroImages.value['BR']
  } else {
    return WebHeroImages.value[country.value]
  }
})

const sectionOneImageURL = computed(() => {
  if (locale.value === 'es-MX') {
    return SectionOneImages.value['MX']
  } else if (locale.value === 'pt-BR') {
    return SectionOneImages.value['BR']
  } else {
    return SectionOneImages.value[country.value]
  }
})
const sectionTwoImageURL = computed(() => {
  if (locale.value === 'es-MX') {
    return SectionTwoImages.value['MX']
  } else if (locale.value === 'pt-BR') {
    return SectionTwoImages.value['BR']
  } else {
    return SectionTwoImages.value[country.value]
  }
})
const sectionThreeImageURL = computed(() => {
  if (locale.value === 'es-MX') {
    return SectionThreeImages.value['MX']
  } else if (locale.value === 'pt-BR') {
    return SectionThreeImages.value['BR']
  } else {
    return SectionThreeImages.value[country.value]
  }
})
const sectionFourImageURL = computed(() => {
  if (locale.value === 'es-MX') {
    return SectionFourImages.value['MX']
  } else if (locale.value === 'pt-BR') {
    return SectionFourImages.value['BR']
  } else {
    return SectionFourImages.value[country.value]
  }
})
onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

watch(locale, newValue => {
  isRussian.value = newValue === 'ru-RU'
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    locale: locale.value,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
  })
}
</script>
<style scoped>
@media (min-width: theme('screens.lg')) {
  .hero-container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.hero-padding {
  padding-top: calc(4rem + 76px);
  @media (max-width: theme('screens.lg')) {
    padding-top: calc(1rem + 84px);
  }
}
.box-shadow {
  box-shadow: 0px 3px 15.03px 0px #6c707a26;
}
.hero-margin-left {
  margin-left: calc((100% - 1440px) / 2);
}
.hero-margin-right {
  margin-right: calc((100% - 1440px) / 2);
}
.nowrap {
  text-wrap: nowrap;
}
</style>
