import { computed } from 'vue'

export default store => {
  const launchedApp = computed(() => store.state.app.launchedApp)

  const startApp = (app, additionalPayload) => {
    store.dispatch('app/setApp', { app, ...additionalPayload })
  }

  const destroyExistingAppComponent = () => {
    store.dispatch('app/setApp')
  }

  const isNewApp = (app = null) => {
    if (!launchedApp.value) {
      return true
    }

    return app && app.app_id !== launchedApp.value.app_id
  }

  return {
    destroyExistingAppComponent,
    launchedApp,
    startApp,
    isNewApp,
  }
}
