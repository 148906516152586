<template>
  <TabDescription
    text="This analysis provides insights into how long guests are planning to stay based on their search patterns."
    note="Please note: The insights provided are based on user search behaviors, not actual bookings."
  />
  <BaseCard no-padding class="tw-p-5">
    <MetricViewHeader
      :selectedTimeFrame="selectedTimeFrame"
      @onSelectTimeFrame="selectTimeFrame"
      title="Length of Stay Search Trends"
      titleTooltipLabel="TBD"
    />
    <Notification iconType="bulb">
      {{
        $t(
          'Most searches were looking for a stay of [3 - 4 nights]. Make use of this data by tailoring room rates, creating attractive packages, and optimizing promotional strategies for different lengths of stay.'
        )
      }}
    </Notification>
    <DurationGraphContainer class="tw-mt-8" :timeFrame="selectedTimeFrame" />
  </BaseCard>
  <BaseCard class="tw-mt-12">
    <MetricViewHeaderTitle class="tw-mb-9">{{ $t('Comparison with competitors') }}</MetricViewHeaderTitle>
    <Notification iconType="info">
      {{ $t('View how your property’s length of stay searches compares to your selected competitors.') }}
    </Notification>
    <DurationTable class="tw-mt-4" :timeFrame="selectedTimeFrame" />
  </BaseCard>
</template>

<script setup>
import { ref } from 'vue'

import MetricViewHeader from '@/components/visitorsProfile/common/MetricViewHeader.vue'
import TabDescription from '@/components/visitorsProfile/common/TabDescription.vue'
import Notification from '@/components/visitorsProfile/common/Notification.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import DurationGraphContainer from '@/components/visitorsProfile/durationView/DurationGraphContainer.vue'
import MetricViewHeaderTitle from '@/components/visitorsProfile/common/MetricViewHeaderTitle.vue'
import DurationTable from '@/components/visitorsProfile/durationView/DurationTable.vue'
import { selectedAccommodationId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'

const selectedTimeFrame = ref('30')

const selectTimeFrame = timeFrame => {
  selectedTimeFrame.value = timeFrame
  appAnalytics.track(appAnalytics.events.VP_TIMEFRAME_CHANGED, {
    tabName: 'Stay Duration',
    timeFrame: timeFrame,
    accommodationId: selectedAccommodationId.value,
  })
}
</script>
