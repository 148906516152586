<template>
  <BaseCard no-padding class="tw-p-5">
    <MetricViewHeader
      :selectedTimeFrame="selectedTimeFrame"
      @onSelectTimeFrame="selectTimeFrame"
      title="Search Window Patterns"
      titleTooltipLabel="TBD"
    />
    <Notification iconType="bulb">
      {{
        isWaitingMode
          ? $t(
              'The data is on the way! It may take up to 24 hours to show your potential guests’ length of stay search patterns.'
            )
          : $t(
              'Most searches were looking for a stay that falls on weekends. Use this data to optimize your service offerings and marketing campaigns for weekend stays.'
            )
      }}
    </Notification>
    <SearchGraph
      class="tw-mt-8"
      :selectedTimeFrame="selectedTimeFrame"
      :isWaitingMode="isWaitingMode"
      :isLoading="isTimeFrameTrendsLoading"
      :isError="isTimeFrameTrendsError"
      :timeFrameTrends="timeFrameTrends"
    />
  </BaseCard>
  <BaseCard class="tw-mt-12">
    <MetricViewHeaderTitle class="tw-mb-9" tooltipLabel="TBD">{{
      $t('Comparison with competitors')
    }}</MetricViewHeaderTitle>
    <Notification iconType="info">
      {{ $t('View how your property’s length of stay searches compares to your selected competitors.') }}
    </Notification>
    <SearchTable
      class="tw-mt-8"
      :timeFrame="selectedTimeFrame"
      :isWaitingMode="isWaitingMode"
      :isLoading="isTimeFrameTrendsLoading"
      :isError="isTimeFrameTrendsError"
      :timeFrameTrends="timeFrameTrends"
    />
  </BaseCard>
</template>

<script setup>
import { ref, computed } from 'vue'

import MetricViewHeader from '@/components/visitorsProfile/common/MetricViewHeader.vue'
import Notification from '@/components/visitorsProfile/common/Notification.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import SearchGraph from '@/components/visitorsProfile/searchView/SearchGraph.vue'
import MetricViewHeaderTitle from '@/components/visitorsProfile/common/MetricViewHeaderTitle.vue'

import SearchTable from '@/components/visitorsProfile/searchView/SearchTable.vue'
import { useTimeFrameTrends } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'

const selectedTimeFrame = ref('30')

const selectTimeFrame = timeFrame => {
  selectedTimeFrame.value = timeFrame
  appAnalytics.track(appAnalytics.events.VP_TIMEFRAME_CHANGED, {
    tabName: 'Search Window',
    timeFrame: timeFrame,
    accommodationId: selectedAccommodationId.value,
  })
}

const startDate = computed(() => new Date().toISOString().split('T')[0])
const endDate = computed(() => {
  const today = new Date()
  const date = today.setDate(today.getDate() - Number(selectedTimeFrame.value))
  return new Date(date).toISOString().split('T')[0]
})

const { isTimeFrameTrendsLoading, isTimeFrameTrendsError, timeFrameTrends } = useTimeFrameTrends(
  selectedAccommodationId,
  startDate,
  endDate
)

const isWaitingMode = computed(() => {
  return timeFrameTrends.value && !timeFrameTrends.value?.[selectedAccommodationId.value]
})
</script>
