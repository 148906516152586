<template>
  <div
    class="tw-fixed tw-flex tw-flex-col-reverse tw-w-full tw-top-0 tw-left-0 tw-right-0 tw-p-10 tw-z-[90] tw-pointer-events-none tw-overflow-hidden"
  >
    <TransitionGroup name="list" tag="ul">
      <li v-for="notification in notificationList" :key="notification.id" class="tw-flex tw-justify-end">
        <div
          class="shadow tw-font-normal tw-relative tw-flex tw-flex-row tw-self-end tw-overflow-hidden tw-w-[315px] tw-h-auto tw-rounded-xl tw-cursor-pointer tw-shadow-xl tw-mb-5 tw-pointer-events-auto"
          :class="typeStyle(notification.type)"
          role="alert"
        >
          <button
            class="tw-pb-0 tw-mb-0 focus:tw-outline-none tw-absolute tw-right-[12px] tw-top-[11px] no-lineheight tw-w-[20px] tw-justify-end"
            @click="closeNotification(notification.id)"
            @keydown.enter="closeNotification(notification.id)"
          >
            <BaseIcon
              icon-name="close"
              width="14"
              height="14"
              viewbox-width="20"
              class="tw-cursor-pointer"
              :icon-color="closeButtonStyle(notification.type)"
            >
              <Close />
            </BaseIcon>
          </button>
          <div class="tw-flex tw-text-sm tw-flex-col tw-py-2.5 tw-px-3 message-width">
            <h3 v-if="notification.title" class="tw-font-bold">
              {{
                notification.titleTranslationKey
                  ? $t(notification.title, notification.titleTranslationParams)
                  : notification.title
              }}
            </h3>
            <p
              v-if="notification.isHtml"
              v-html="
                notification.isTranslationKey
                  ? $t(notification.message, notification.translationParams)
                  : notification.message
              "
            ></p>
            <p v-else>
              {{
                notification.isTranslationKey
                  ? $t(notification.message, notification.translationParams)
                  : notification.message
              }}
            </p>
            <button
              v-if="notification.actionButton"
              class="tw-font-bold tw-border-none tw-bg-transparent tw-flex tw-justify-end"
              @click="
                () => {
                  notification.action()
                  closeNotification(notification.id)
                }
              "
            >
              {{ $t(notification.actionLabel) }}
            </button>
          </div>
        </div>
      </li>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Close from '@/components/icons/Close.vue'
import useToastNotifications from './useToastNotifications'

const { notifications, closeNotification } = useToastNotifications()

const notificationList = computed(() => Object.values(notifications?.value))
const closeButtonStyle = type => {
  switch (type) {
    case 'info':
      return '#0A1121'
    case 'alert':
      return '#FFCF44'
    case 'success':
      return '#005F00'
    case 'error':
      return '#DB3734'
    default:
      return '#0A1121'
  }
}

const typeStyle = type => {
  switch (type) {
    case 'info':
      return ' tw-bg-white tw-text-gray-900'
    case 'alert':
      return ' tw-bg-yellow-200 tw-text-yellow-700'
    case 'success':
      return ' tw-bg-green-300 tw-text-green-900'
    case 'error':
      return ' tw-bg-red-200 tw-text-red-800'
    default:
      return ' tw-bg-white tw-text-gray-900'
  }
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 2px 10px 0px rgba(0, 41, 77, 0.12);
}
.no-lineheight {
  line-height: 0px;
}
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.message-width {
  width: calc(100% - 20px);
}
</style>
