<template>
  <div v-if="isOpen" class="gallery-container">
    <div class="tw-absolute tw-right-0 tw-top-0 tw-mr-3 tw-mt-3">
      <button
        class="tw-outline-none tw-bg-black"
        data-cy="close-modal-button"
        @click="closeWindow"
        @keydown.enter="closeWindow"
      >
        <BaseIcon class="tw-text-white" icon-name="close" height="14" width="14" viewbox-height="20" viewbox-width="20">
          <Close />
        </BaseIcon>
      </button>
    </div>
    <div class="tw-flex tw-flex-row" v-if="selectedImage">
      <ImageGalleryThumbnails :items="images" @changeImage="changeImage" :selectedImage="selectedImage" />
      <div class="main-image-container tw-flex tw-h-screen tw-flex tw-flex-col">
        <div class="tw-mr-3 tw-mt-3 tw-ml-2">
          <p class="tw-text-white tw-font-bold">{{ accommodation.name }}</p>
        </div>
        <div class="tw-flex tw-w-[90%] tw-m-auto tw-relative tw-flex-col">
          <div class="tw-h-full tw-w-full tw-mb-2 tw-min-h-[320px]">
            <div
              class="image-size-badge tw-py-[3px] tw-px-[7px] tw-text-sm tw-text-white tw-absolute tw-top-[10px] tw-left-[10px]"
            >
              {{ $t('app_images_image_size') }}{{ ' ' }}{{ selectedImage?.width }}{{ ' x ' }}{{ selectedImage?.height }}
            </div>
            <div
              v-if="!selectedImage?.isMainImage && selectedImage?.customProperties?.isMainImageable"
              class="image-size-badge tw-py-[3px] tw-px-[7px] tw-text-sm tw-text-white tw-absolute tw-top-[10px] tw-right-[10px]"
            >
              <Checkbox
                name="main_image"
                :label="$t('nba_button_set_main_image')"
                :value="isSetAsMainImageSelected"
                @onChange="toggleMainImage"
              />
            </div>
            <button
              class="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-outline-none"
              @keydown.left="prevImg"
              @click="prevImg"
            >
              <BaseIcon
                icon-name="chevron-right"
                :height="24"
                :width="24"
                class="slider-btn tw-w-[26px] tw-h-[30px] tw-text-white tw-rotate-180"
              >
                <ChevronRight />
              </BaseIcon>
            </button>
            <button
              class="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-outline-none"
              @keydown.right="nextImg"
              @click="nextImg"
            >
              <BaseIcon
                icon-name="chevron-right"
                :height="24"
                :width="24"
                class="slider-btn tw-w-[26px] tw-h-[30px] tw-text-white"
              >
                <ChevronRight />
              </BaseIcon>
            </button>
            <div>
              <div v-if="isImageLoading" class="tw-flex tw-items-center tw-justify-center tw-h-[50vh] tw-max-h-[80vh]">
                <RCInlineLoader color="#fff" />
              </div>
              <img
                v-if="imageSRC && !isImageLoading"
                referrerPolicy="strict-origin-when-cross-origin"
                class="tw-rounded-lg tw-m-auto tw-max-h-[80vh]"
                :src="imageSRC"
                alt="Hotel image"
              />
            </div>
          </div>
          <div class="tw-flex tw-flex-row">
            <div class="tw-flex tw-flex-row tw-items-center tw-w-1/2">
              <div v-show="selectedImage?.customProperties?.isLowQuality" class="tw-flex tw-items-center">
                <BaseIcon icon-name="excalamation-mark" :height="24" :width="24" class="tw-w-[50px]">
                  <ExclamationMark />
                </BaseIcon>
                <p class="tw-text-yellow-700 tw-ml-2 tw-text-sm">{{ $t('app_images_low_quality_hint') }}</p>
              </div>
            </div>
            <div class="tw-flex tw-justify-end tw-w-1/2">
              <BaseButton
                v-if="selectedImage?.customProperties?.isDeletable && !selectedImage?.isMainImage"
                size="medium"
                class="md:tw-self-center tw-px-9 tw-transparent tw-font-bold tw-border tw-border-gray-300 tw-text-gray-300 tw-mr-3"
                :disabled="isUpdating"
                @click="openDeleteModal"
                @keydown.enter="openDeleteModal"
              >
                {{ $t('app_images_delete_image') }}
              </BaseButton>
              <MainButton
                size="medium"
                class="tw-px-9 md:tw-self-center tw-min-w-[7rem]"
                :disabled="isUpdating"
                @click="mainImageUpdate"
                @keydown.enter="mainImageUpdate"
              >
                <span v-if="isUpdating"><RCInlineLoader color="#fff" /></span>
                <span v-else>{{ $t('save') }}</span>
              </MainButton>
            </div>
          </div>
        </div>
        <div class="tw-bg-black tw-text-white tw-p-3 tw-flex tw-justify-center tw-text-sm">
          {{ selectedImageIndex + 1 }}{{ '/' }}{{ images?.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import BaseIcon from '@/components/BaseIcon.vue'
import Close from '@/components/icons/Close.vue'
import ExclamationMark from '@/components/icons/ExclamationMark.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import ImageGalleryThumbnails from '@/components/propertyDetails/propertyImages/ImageGalleryThumbnails.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { useSetMainImage } from '@/components/propertyDetails/queries/index.js'
import { usePropertyImages } from '@/components/propertyDetails/propertyImages/usePropertyImages.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  accommodation: {
    type: Object,
    default: () => {},
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  images: {
    type: Array,
    default: () => [],
  },
  initialSelectedImage: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['toggleImageGallery', 'toggleDeleteModal'])

const store = useStore()
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { isUpdating, setUpdatedImage, setDeletingIds } = usePropertyImages()
const { setMainImage } = useSetMainImage()
const imageSRC = ref(null)
const isImageLoading = ref(false)
const { displayNotification } = useToastNotifications()

const isSetAsMainImageSelected = ref(false)
const selectedImage = ref(props.initialSelectedImage)
const imageCache = ref(new Array(props.images?.length).fill(null))

const selectedImageIndex = computed(() => {
  if (props.images?.length < 1 || !selectedImage.value) return 0
  return props.images.findIndex(image => image.imageId === selectedImage.value.imageId) ?? 0
})

onMounted(() => {
  preloadSurroundingImages()
  loadImage(selectedImage.value)
})

const closeWindow = () => {
  emit('toggleImageGallery')
}

const prevImg = () => {
  if (selectedImageIndex.value < 1) return
  changeImage(props.images?.[selectedImageIndex.value - 1])
}

const nextImg = () => {
  if (selectedImageIndex.value === props.images?.length - 1) return
  changeImage(props.images?.[selectedImageIndex.value + 1])
}

const changeImage = image => {
  selectedImage.value = image
  loadImage(image)
  isSetAsMainImageSelected.value = false
  preloadSurroundingImages()
}

const loadImage = async imagePath => {
  imageSRC.value = null
  isImageLoading.value = true
  const img = new Image()
  img.src = buildImageUrl(imagePath)
  await new Promise(resolve => (img.onload = resolve))
  imageSRC.value = img.src
  isImageLoading.value = false
}

const preloadSurroundingImages = () => {
  const amountOfSurroundingImagesToLoad = 2
  for (let i = 1; i <= amountOfSurroundingImagesToLoad; i++) {
    if (selectedImageIndex.value + i >= props.images.length) {
      break
    }
    if (imageCache[selectedImageIndex.value + 1]) {
      continue
    }
    imageCache[selectedImageIndex.value] = new Image()
    imageCache[selectedImageIndex.value].src = buildImageUrl(props.images[selectedImageIndex.value + i])
  }
  for (let i = 1; i <= amountOfSurroundingImagesToLoad; i++) {
    if (selectedImageIndex.value - i < 0) {
      break
    }
    if (imageCache[selectedImageIndex.value - 1]) {
      continue
    }
    imageCache[selectedImageIndex.value] = new Image()
    imageCache[selectedImageIndex.value].src = buildImageUrl(props.images[selectedImageIndex.value - i])
  }
}

const buildImageUrl = ({ height, width, imagePath }) => {
  return `https://imgcy.trivago.com/c_limit,d_dummy.jpeg,f_auto,c_scale,h_${height},q_auto,w_${width}/${imagePath}`
}

const toggleMainImage = () => {
  isSetAsMainImageSelected.value = !isSetAsMainImageSelected.value
}

const openDeleteModal = () => {
  emit('toggleDeleteModal')
  emit('toggleImageGallery')
  setDeletingIds(selectedImage.value.imageId)
}

const mainImageUpdate = () => {
  if (isSetAsMainImageSelected.value) {
    setUpdatedImage(selectedImage.value.imageId)
    setMainImage(
      {
        accommodationId: selectedAccommodationId.value,
        imageId: selectedImage.value.imageId,
        imagePath: selectedImage.value.imagePath,
        organisationId: selectedOrganisationId.value,
      },
      {
        onSuccess: data => {
          if (data.status === 200) {
            setUpdatedImage(false)
            displayNotification({
              message: 'app_images_successfully_set_main_image',
              isTranslationKey: true,
              isHtml: false,
              type: 'success',
            })
            emit('toggleImageGallery')
          }
        },
        onError: () => {
          setUpdatedImage(false)
          displayNotification({
            message: 'something_went_wrong',
            isTranslationKey: true,
            isHtml: false,
            type: 'error',
          })
        },
      }
    )
  }
}
</script>
<style scoped>
.gallery-container {
  @apply tw-fixed tw-inset-0 tw-z-[80];
  background-color: rgba(0, 0, 0, 0.9);
}
.main-image-container {
  width: calc(100% - (130px * 2 + 4 * 10px));
  @media (max-width: theme('screens.lg')) {
    width: calc(100% - (130px + 4 * 10px));
  }
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}

.old-main-image {
  height: 620px;
  background-size: cover;
  width: 100%;
  margin: auto;
  background-position: center;
  @media (max-width: theme('screens.lg')) {
    height: 520px;
  }
}

.main-image {
  background-size: cover;
  width: 100%;
  margin: auto;
  background-position: center;
  @media (max-width: theme('screens.lg')) {
    height: 520px;
  }
}
.image-size-badge {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}
.slider-btn {
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
