<template>
  <router-link v-if="studioPlusCampaign && !isExpiredSubscription" to="/" class="tw-h-[64px] tw-flex tw-items-center">
    <span class="tw-visually-hidden">trivago Business Studio Plus</span>
    <img
      class="tw-cursor-pointer"
      src="@/assets/img/studio-plus/studio-plus-logo-color.png"
      alt="Studio Plus logo"
      width="190"
    />
  </router-link>
  <router-link v-else to="/">
    <span class="tw-visually-hidden">trivago Business Studio</span>
    <BaseIcon
      icon-name="trivago Business Studio"
      :height="25"
      :width="242"
      :viewbox-height="25"
      :viewbox-width="242"
      class="tw-w-56 md:tw-w-18 tw-h-16 tw-cursor-pointer"
      :class="isLanding ? 'tw-inline-block' : 'tw-hidden sm:tw-inline-block'"
      :dataTestId="'studio-icon'"
    >
      <TrivagoLogoWeb />
    </BaseIcon>
    <BaseIcon
      v-show="!isLanding"
      icon-name="trivago Business Studio"
      :height="38"
      :width="125"
      :viewbox-height="38"
      :viewbox-width="125"
      class="tw-w-24 md:tw-w-18 tw-h-16 tw-inline-block sm:tw-hidden"
      :dataTestId="'studio-icon'"
    >
      <TrivagoLogo />
    </BaseIcon>
  </router-link>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoLogo from '@/components/icons/TrivagoLogo.vue'
import TrivagoLogoWeb from '@/components/icons/TrivagoLogoWeb.vue'
import { useStudioPlusCampaign } from '@/components/payment/queries'
import { selectedAccommodationId } from '@/layouts/queries'

defineProps({
  isLanding: {
    type: Boolean,
    default: false,
  },
})

const { studioPlusCampaign, isExpiredSubscription } = useStudioPlusCampaign(selectedAccommodationId)
</script>
