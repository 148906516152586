<template>
  <div>
    <SearchBar
      :searchValue="searchValue"
      @updateSearchValue="updateSearchValue"
      @search="search"
      :disabled="staticMessage === 'unauthorized'"
    />
    <AdminLoadingSkeleton v-if="isLoading && paidSubscriptionsIsLoading && enableSearch" />
    <ResultsList
      v-else-if="accommodationData && !isLoading && !paidSubscriptionsIsLoading && paidSubscriptions && !enableSearch"
      :accommodationData="accommodationData"
      :subscriptions="paidSubscriptions"
      @updateStaticMessage="updateStaticMessage"
      @showError="showError"
      @resetState="resetState"
      @fetchData="fetchData"
    />
    <StaticMessage v-else :staticMessageType="staticMessage" />
  </div>
</template>
<script setup>
import { ref } from 'vue'

import SearchBar from '@/components/adminTool/SearchBar.vue'
import ResultsList from '@/components/adminTool/ResultsList.vue'
import AdminLoadingSkeleton from '@/components/loadingSkeletons/AdminLoadingSkeleton.vue'
import StaticMessage from '@/components/adminTool/StaticMessage.vue'

import { useAccommodation, usePaidSubscriptions } from '@/components/adminTool/queries/index.js'

const staticMessage = ref('welcome')
const searchValue = ref('')
const enableSearch = ref(false)

const updateSearchValue = value => {
  searchValue.value = value
  if (value.length === 0) {
    updateStaticMessage('welcome')
  }
}

const resetState = () => {
  updateSearchValue('')
  fetchData()
}

const showError = error => {
  switch (error.response.status) {
    case 404:
      enableSearch.value = false
      if (searchValue.value === '') {
        updateStaticMessage('welcome')
      } else {
        updateStaticMessage('noResults')
      }
      break
    case 401:
      updateSearchValue('')
      updateStaticMessage('unauthorized')
      break
    default:
      updateSearchValue('')
      updateStaticMessage('genericError')
  }
}

const onSuccessCallback = () => {
  enableSearch.value = false
  staticMessage.value = 'none'
}
const onErrorCallback = error => {
  showError(error)
  enableSearch.value = false
}

const updateStaticMessage = type => {
  staticMessage.value = type
}

const fetchData = () => {
  refetchAccommodation()
  refetchSubscriptions()
}
const search = async () => {
  if (staticMessage.value === 'unauthorized') return
  enableSearch.value = true
  fetchData()
}
const { isLoading, accommodationData, refetchAccommodation } = useAccommodation(
  searchValue,
  onSuccessCallback,
  onErrorCallback
)
const { paidSubscriptionsIsLoading, paidSubscriptions, refetchSubscriptions } = usePaidSubscriptions(searchValue)
</script>
