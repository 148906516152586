<template>
  <div
    class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-min-h-screen-minus-header-and-footer tw-px-4"
  >
    <div
      class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-mb-8"
    >
      <AppMenu :data="navEntries" :loading="false" />
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import AppMenu from '@/components/appMenu/AppMenu.vue'

const router = useRouter()
const navEntries = [
  {
    label: 'app_hotel_details_basic_information',
    route: '/property-details/basic-information',
  },
  {
    label: 'app_images',
    route: '/property-details/images',
  },
  {
    label: 'app_description',
    route: '/property-details/description',
  },
]

onBeforeRouteUpdate((to, from) => {
  if (from.path.includes('/property-details') && to.path === '/property-details') return false
  if (to.path === '/property-details') return '/property-details/basic-information'
})

onMounted(() => {
  goToBasicInfo()
})

const goToBasicInfo = () => {
  if (router.currentRoute.value.name === 'property-details') {
    router.push('/property-details/basic-information')
  }
}
</script>
