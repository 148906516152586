<template>
  <button @click="toggleUserMenu" @keydown.enter="toggleUserMenu" data-testid="header-loggedin-user-menu">
    <div
      :class="getClass()"
      class="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-px-2 md:tw-py-1 tw-transition-all tw-ease-in-out tw-duration-200 tw-py-1 tw-text-gray-900"
    >
      <div class="md:tw-flex tw-items-center" :class="{ 'tw-hidden': !showWelcomeTextOnMobile }">
        <div class="tw-flex tw-flex-col tw-items-start">
          <i18n-t
            v-if="language === 'ja-JP'"
            keypath="accommodationMenu.greetings"
            tag="span"
            class="tw-text-base md:tw-text-xs tw-font-bold tw-text-left tw-w-[250px] md:tw-w-[150px] tw-block tw-overflow-hidden tw-truncate"
            scope="global"
          >
            <template #last_name>
              <span class="tw-text-base md:tw-text-sm tw-capitalize tw-font-normal">{{ lastName }}</span>
            </template>
          </i18n-t>
          <i18n-t
            v-else
            keypath="accommodationMenu.greetings"
            tag="span"
            class="tw-text-base md:tw-text-xs tw-font-bold tw-text-left tw-w-[250px] md:tw-w-[150px] tw-block tw-overflow-hidden tw-truncate"
            scope="global"
          >
            <template #user_name>
              <br />
              <span class="tw-text-base md:tw-text-sm tw-capitalize tw-font-normal">{{ fullName }}</span>
            </template>
          </i18n-t>
        </div>
      </div>
      <div>
        <BaseIcon :height="18" :width="5" icon-name="dots" class="tw-w-4 tw-h-4 tw-opacity-75">
          <Dots class="tw-fill-gray-900" />
        </BaseIcon>
      </div>
    </div>
  </button>
</template>
<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Dots from '@/components/icons/Dots.vue'

const emit = defineEmits(['toggleUserMenu'])
const toggleUserMenu = () => emit('toggleUserMenu')

const props = defineProps({
  isUserMenuOpen: Boolean,
  fullName: String,
  lastName: String,
  language: String,
  showWelcomeTextOnMobile: Boolean,
})

const getClass = () => {
  let classes = []
  if (props.showWelcomeTextOnMobile) {
    classes.push('tw-mr-2 md:tw-mr-0')
  }
  if (props.isUserMenuOpen) {
    classes.push('tw-bg-gray-300')
  } else {
    classes.push('hover:tw-bg-gray-200')
  }
  return classes.join(' ')
}
</script>
