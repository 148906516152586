import '@/utils/urlPolyfill'

const initiateAppPerformanceCollection = (isOverlay = false) => {
  if (!performance) {
    return
  }

  if (isOverlay) {
    // overlay measures are cleared separately
    ;['appLaunched', 'appReady', 'appSessionAcknowledged', 'appIframeLoaded'].forEach(singlePerformanceMark => {
      try {
        performance.clearMarks(`overlay-${singlePerformanceMark}`)
      } catch {}
    })

    try {
      performance.mark('overlay-appLaunched')
    } catch {}
  } else {
    // when an app closed we are sure that all entries can get cleared
    try {
      performance.clearMarks()
      performance.mark('appLaunched')
    } catch {}
  }
}

const setApp = async function (
  { commit, state },
  { app = false, path = '', onboarding = false, pushRoute = true, source = false, isOverlay = false } = {}
) {
  // abort when app already launched
  if (state.launchedApp && app && state.launchedApp.app_id === app.app_id) {
    return
  }

  commit('SET_LOADED')
  let application =
    typeof app === 'object'
      ? {
          ...app,
        }
      : false // definitely create a new object
  let cleanPath = null
  if (!application || !application.slug || !application.app_id) return commit('SET_APP')

  // To collect the performance metrics of each app
  // We consider this as the starting point for metrics collection
  initiateAppPerformanceCollection(isOverlay)

  // inject onboarding info
  if (onboarding === true) application.onboarding = onboarding

  // update full path for deep linking
  if (path && typeof path === 'string') {
    try {
      cleanPath = window.encodeURIComponent(path)

      const originalUrl = new URL(application.url)
      let appPath = window.decodeURIComponent(path)

      if (originalUrl.pathname.slice(-1) !== '/' && appPath.charAt(0) !== '/') {
        appPath = '/' + appPath
      } else if (originalUrl.pathname.slice(-1) === '/' && appPath.charAt(0) === '/') {
        appPath = appPath.slice(1)
      }

      const newUrl = new URL(`${originalUrl.pathname}${appPath}`, application.url)

      application.url = newUrl.href
      application.path = appPath
    } catch {
      // no deep linking possible
    }
  }

  // Adding studio client properties not contained in API response
  const stdioProperties = {
    stdio_source: source,
    isOverlay,
  }

  await commit('SET_APP', {
    ...application,
    ...stdioProperties,
  })

  if (onboarding || pushRoute === false || isOverlay) return

  // allow navigation to different pages when an app is launched
  let query = {}

  switch (pushRoute) {
    default:
      // only navigate to separate page when your are
      // outside the onboarding context
      if (cleanPath) query.path = cleanPath

      this.$router.push({
        name: 'apps-slug-id',
        params: {
          slug: application.slug,
          id: application.app_id,
        },
        query,
      })
  }
}

const closeOverlayApp = async function ({ commit }) {
  commit('SET_APP', {
    isOverlay: true,
  })
}

const setFailedApp = async function ({ commit }, app = false) {
  commit('SET_FAILED_APP', {
    app,
  })
}

export default {
  setApp,
  closeOverlayApp,
  setFailedApp,
}
