<template>
  <div class="tw-inline-block tw-text-gray-700 hover:tw-text-blue-800 hover:tw-bg-gray-200 tw-p-2 tw-rounded-lg">
    <a
      :href="supportCenterUrl({ language })"
      data-tooltip
      target="_blank"
      class="tw-flex tw-items-center tw-relative tw-text-gray-900 hover:tw-no-underline"
      @click="trackClickEvent"
      @keydown.enter="trackClickEvent"
      data-testid="header-loggedin-support-center-link"
    >
      <BaseIcon
        icon-name="question-mark"
        :height="24"
        :width="24"
        :viewbox-height="24"
        :viewbox-width="24"
        class="tw-fill-gray-900 tw-mr-2"
        :class="isMobile ? 'tw-mr-1' : 'tw-mr-2'"
      >
        <QuestionMark />
      </BaseIcon>
      <span v-if="!isMobile" class="tw-text-sm tw-text-gray-900 tw-mr-1">{{ $t('navigation.supportCenter') }}</span>
    </a>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import QuestionMark from '@/components/icons/QuestionMark.vue'
import { getSupportCenterUrl } from '@/utils/Utility.js'
import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
defineProps({
  isMobile: Boolean,
})
const language = computed(() => store.state.locale.language)

const supportCenterUrl = locale => getSupportCenterUrl(locale.language, 'supportCentre')
const trackClickEvent = () => {
  appAnalytics.track(appAnalytics.events.BUTTON_CLICKED, {
    button: 'support_center',
    property_id: selectedAccommodationId.value,
  })
}
</script>
