<template>
  <div class="tw-min-h-screen-minus-header-and-footer tw-w-full" :class="{ 'tw-relative': !isCheckoutActive }">
    <div
      v-if="hasLoadingError"
      class="tw-min-h-screen-minus-header-and-footer tw-flex tw-justify-center tw-items-center"
    >
      <ErrorModal
        header-key="dashboard.loadFail.header.app"
        retry-key="dashboard.loadFail.retryButton"
        @retry="handleRetry"
      />
    </div>

    <TheAppContainer v-else-if="isLaunchedAppOwned" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useAllApps } from '@/components/dashboard/nav/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { useEventsBus } from '@/composables/useEventBus.js'
import { useApp } from '@/composables/useApp.js'
import TheAppContainer from '@/components/app/TheAppContainer.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { on } = useEventsBus()
const { checkIsLaunchedAppOwned } = useApp()

const hasLoadingError = ref(false)
const { allApps, owned, isLoadingApps } = useAllApps(selectedAccommodationId)
const appPath = computed(() => route.query.path)
const appId = computed(() => route.params.id)
const launchedApp = computed(() => store.state.app.launchedApp)
const launchedOverlayApp = computed(() => store.state.app.launchedOverlayApp)
const isCheckoutActive = computed(() => launchedApp?.value.app_id === '2' || launchedOverlayApp?.value.app_id === '2')
const failedApp = computed(() => store.state.app.failedApp)
const isLaunchedAppOwned = computed(() => checkIsLaunchedAppOwned(owned.value))
const setApp = data => store.dispatch('app/setApp', data)
const setFailedApp = () => store.dispatch('app/setFailedApp')

const showNotFound = () => {
  router.replace('/')
}

watch(isLoadingApps, newIsLoadingApps => {
  if (launchedApp?.value.app_id === appId.value || newIsLoadingApps) return

  // start app from url params
  const app = allApps.value.find(item => item.app_id === appId.value)

  if (app) {
    setApp({
      app: app,
      path: appPath.value,
      pushRoute: false,
      source: 'direct_link',
    })
  } else {
    showNotFound()
  }
})

watch(isLaunchedAppOwned, newIsLaunchedAppOwned => {
  /**
   * If the opened app is not owned for a user's accommodation, close the app and
   * redirect to its product page.
   */

  if (newIsLaunchedAppOwned === 'NO_APP_OPENED' || !newIsLaunchedAppOwned) {
    router.push('/')
  }
})

onBeforeUnmount(() => {
  on('app.close.forced', handleForcedAppClose)
})

const handleForcedAppClose = failedApp => {
  setFailedApp(failedApp)
  hasLoadingError.value = true
}
const handleRetry = async () => {
  await setApp({ app: failedApp.value, path: failedApp?.value.path, source: 'trustedApps' })
  await setFailedApp()
  hasLoadingError.value = false
}
</script>
