<template>
  <footer class="tw-bg-gray-900">
    <div
      class="tw-text-center lg:tw-flex lg:tw-justify-between tw-max-w-screen-xl tw-mx-auto tw-text-sm tw-px-4 tw-py-5 lg:tw-py-8"
    >
      <div class="tw-text-gray-100 tw-mb-6 lg:tw-mb-0 tw-flex tw-items-center lg:tw-gap-6 tw-gap-4 tw-flex-wrap">
        <BaseLink
          :href="getSupportCenterUrl(selectedLanguage, 'contactUs')"
          target="_blank"
          class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
        >
          {{ $t('legal.contact') }}
        </BaseLink>
        <BaseLink
          :href="getSupportCenterUrl(selectedLanguage, 'supportCentre')"
          target="_blank"
          class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
        >
          {{ $t('navigation.supportCenter') }}
        </BaseLink>
        <BaseLink
          :to="getTermsAndConditionsUrl()"
          target="_blank"
          class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
        >
          {{ $t('legal.termsAndConditions') }}
        </BaseLink>
        <BaseLink
          to="/privacy-policy"
          target="_blank"
          class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
        >
          {{ $t('legal.privacyPolicy') }}
        </BaseLink>
        <BaseLink
          to="/imprint"
          target="_blank"
          class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
        >
          {{ $t('legal.legalInformation') }}
        </BaseLink>
      </div>
      <div class="tw-flex tw-flex-col lg:tw-flex-row">
        <CurrencySelector
          v-show="isCurrencyEditable"
          size="big"
          class="tw-mr-0 lg:tw-mr-4"
          :with-image="false"
          version="dark"
        />
        <LanguageSelector size="big" class="tw-mt-4 lg:tw-mt-0" :with-image="true" version="dark" />
      </div>
    </div>
    <div class="tw-mx-auto tw-h-[0px] lg:tw-h-[1px] tw-max-w-screen-xl tw-text-center tw-bg-purple-300" />
    <div
      class="lg:tw-pt-8 tw-pb-5 tw-max-w-screen-xl tw-mx-auto tw-flex tw-flex-col lg:tw-flex-row tw-space-between tw-items-center tw-px-4"
    >
      <address class="tw-m-0 tw-text-gray-100 tw-pt-3 lg:tw-pt-0 tw-pb-8 lg:tw-pb-0 lg:tw-w-1/3 tw-text-sm">
        trivago N.V. , Kesselstraße 5 - 7, 40221 Düsseldorf, Germany.
      </address>
      <div class="tw-text-center lg: tw-w-1/3">
        <BaseIcon
          class="tw-mx-auto"
          :height="32"
          :width="102"
          :viewbox-height="248.77"
          :viewbox-width="800"
          name="trivago-white"
        >
          <TrivagoWhite />
        </BaseIcon>
        <small class="tw-block tw-text-xs tw-text-gray-500 tw-mt-4">
          {{ $t('legal.brand.copyright', { year: currentYear }) }}
          | {{ $t('legal.brand.rights') }}
        </small>
      </div>
      <div class="lg:tw-w-1/3" />
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import BaseLink from '@/components/BaseLink.vue'
import CurrencySelector from '@/components/locale/CurrencySelector.vue'
import LanguageSelector from '@/components/locale/LanguageSelector.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoWhite from '@/components/icons/TrivagoWhite.vue'
import { getSupportCenterUrl } from '@/utils/Utility'

const store = useStore()
const route = useRoute()

const isCurrencyEditable = computed(() => store.state.isCurrencyEditable)
const selectedLanguage = computed(() => store.state.locale.language)
const currentYear = computed(() => {
  const localYear = new Date().getFullYear()
  return localYear >= 2019 ? localYear : 2019
})

function getTermsAndConditionsUrl() {
  if (route.path.includes('rate-connect')) {
    return '/terms-and-conditions?app=rate-connect'
  } else {
    return '/terms-and-conditions'
  }
}
</script>
