<template>
  <div class="tw-max-w-[480px] tw-relative">
    <div
      class="tw-rounded-xl tw-py-6 tw-px-8 tw-shadow-mdLg tw-relative tw-border-2 tw-border-blue-800 tw-bg-blue-100 tw-h-full"
    >
      <div
        class="tw-absolute tw-top-0 tw-left-0 tw-py-1 tw-px-3 tw-text-xs tw-text-white tw-font-bold tw-bg-blue-800 tw-rounded-tl-xl tw-rounded-br-xl"
      >
        {{ $t('rc_upgrade_ncpa_recommended_campaign') }}
      </div>
      <h5 class="tw-font-bold tw-text-2xl tw-mb-5 tw-mt-4">
        {{ $t('rc_pay_per_stay') }}
      </h5>
      <div class="benefits-list" v-html="$t('rc_upgrade_pay_per_stay_benefits')"></div>
    </div>
    <div class="tw-absolute tw-bottom-[-14px] tw-w-full tw-text-center">
      <span
        class="tw-py-1 tw-px-3 tw-bg-green-300 tw-border-4 tw-border-white tw-rounded-[20px] tw-text-green-800 tw-inline-flex tw-items-center tw-gap-1"
      >
        <CheckMark height="10px" width="10px" class="tw-fill-green-800" />
        <small class="tw-font-bold tw-text-xs tw-mr-1">{{ $t('rc_upgrade_ncpa_highlight_1') }}</small>
        <CheckMark height="10px" width="10px" class="tw-fill-green-800" />
        <small class="tw-font-bold tw-text-xs tw-mr-1">{{ $t('rc_upgrade_ncpa_highlight_2') }}</small>
        <CheckMark height="10px" width="10px" class="tw-fill-green-800" />
        <small class="tw-font-bold tw-text-xs">{{ $t('rc_upgrade_ncpa_highlight_3') }}</small>
      </span>
    </div>
    <div class="tw-absolute tw-left-[-145px] tw-bottom-[-25px] tw-hidden lg:tw-block">
      <img :src="pricingIcon2" alt="Direct rates icon" width="185" height="60" />
    </div>
  </div>
</template>

<script setup>
import CheckMark from '@/components/icons/CheckMark.vue'

const pricingIcon2 = new URL('/src/assets/img/rate-connect-landing/Pricing_Icons2.svg', import.meta.url)
</script>

<style scoped>
.benefits-list :deep(ul) {
  @apply tw-text-left tw-flex tw-flex-col tw-gap-1 tw-list-disc tw-pl-5 tw-mb-6;
}
</style>
