<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-900">
    <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
      <div
        class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-my-4"
        :class="error ? 'tw-items-center' : 'tw-items-end'"
      >
        <BaseTextField
          :label="$t('form.labels.email_address')"
          :placeholder="$t('form.placeholder.email')"
          name="email"
          aria-label="email"
          type="email"
          class="lg:tw-w-[300px] tw-w-full lg:tw-mr-2 tw-mb-1 lg:tw-mb-auto"
          :error="error ? $t(error) : false"
          :value="emailValue"
          @onChange="e => setEmail(e)"
        />
        <BaseSelect
          id="'select-user-role'"
          :label="$t('form.labels.role')"
          class="lg:tw-w-[300px] tw-w-full lg:tw-mr-2 tw-mb-1 lg:tw-mb-auto"
          :value="userRole"
          :options="availableUserRoles"
          display-text-key="displayText"
          @onChange="e => setUserRole(e)"
        />
        <MainButton class="tw-ml-2" @click="() => submitInvitation()" :disabled="emailValue === '' || error">
          <span v-if="isSaving"><RCInlineLoader color="#fff" /></span>
          <span v-else>{{ $t('form.actions.invite') }}</span>
        </MainButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import BaseSelect from '@/components/BaseSelect.vue'

import { validate, required, emailAddress } from '@/utils/propertyDetailsHelpers.js'
import { useInviteUserInAdminTool } from '@/components/adminTool/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const { inviteUserAsAdmin } = useInviteUserInAdminTool()
const store = useStore()
const { t } = useI18n()

const props = defineProps({
  organisationId: String,
  users: Array,
})
const error = ref(false)
const emailValue = ref('')
const userRole = ref('normal')
const emit = defineEmits(['fetchData'])
const fetchData = () => emit('fetchData')

const availableUserRoles = computed(() => [
  {
    displayText: t('form.labels.user_role.admin'),
    value: 'admin',
  },
  { displayText: t('form.labels.user_role.normal'), value: 'normal' },
])
const isSaving = ref(false)

const setUserRole = role => {
  userRole.value = role
}
const langauge = computed(() => store.state.locale?.language)

const setEmail = inputValue => {
  const validationError = validate(inputValue, [required, emailAddress])
  error.value = validationError !== undefined ? validationError : false
  emailValue.value = inputValue
}

const isEmailAdded = () => {
  let isAdded = false
  props.users.forEach(item => {
    if (item.email === emailValue.value) {
      isAdded = true
    }
  })
  return isAdded
}

const { displayNotification } = useToastNotifications()
const submitInvitation = () => {
  const isEmail = isEmailAdded()
  if (isEmail) {
    emailValue.value = ''
    displayNotification({
      message: 'invite_user_error_notification',
      isTranslationKey: true,
      isHtml: false,
      type: 'error',
    })
    return
  }
  if (emailValue.value === '') return
  if (error.value) return

  // enables loader on the button
  isSaving.value = true

  inviteUserAsAdmin(
    {
      organisationId: props.organisationId,
      data: { invitedEmail: emailValue.value, language: langauge.value, role: userRole.value },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'form.messages.success.invitation',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        isSaving.value = false
        emailValue.value = ''
        fetchData()
      },
      onError: error => {
        isSaving.value = false
        displayNotification({
          message:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'form.messages.error.unknown',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
      },
    }
  )
}
</script>
