<template>
  <div class="tw-flex tw-gap-3 tw-items-center">
    <span v-if="leftLabel" :class="value ? 'tw-text-gray-500' : 'tw-text-gray-900'">{{ leftLabel }}</span>
    <button
      class="tw-w-14 tw-h-8 tw-rounded tw-p-1 focus:tw-outline-0"
      :class="value ? 'tw-bg-green-700' : 'tw-bg-gray-500'"
      :disabled="disabled"
      @click="$emit('onClick')"
      @keydown.enter="$emit('onClick')"
    >
      <span
        class="tw-block tw-w-1/2 tw-h-6 tw-bg-white tw-rounded-sm tw-transition-transform"
        :class="{ 'tw-translate-x-full': value }"
      ></span>
    </button>
    <span v-if="rightLabel" :class="value ? 'tw-text-gray-900' : 'tw-text-gray-500'">{{ rightLabel }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    leftLabel: {
      type: String,
      required: false,
      default: null,
    },
    rightLabel: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['onClick'],
}
</script>
