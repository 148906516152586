<template>
  <div>
    <div
      v-show="checkoutInProgress"
      :class="{ 'app-container-checkout-page-active': checkoutActive }"
      class="app-container"
    >
      <div v-if="!loaded" class="app-container-loader">
        <LaunchpadLoader class="tw-min-h-screen tw-flex tw-items-center tw-justify-center" />
      </div>

      <TheAppContainerIframe
        v-show="isLoadingApps"
        ref="iframeComponent"
        key="checkout-client"
        @checkoutFailure="onCheckoutFailure"
        @checkoutSuccess="onCheckoutSuccess"
      />
    </div>
    <div v-if="!checkoutInProgress" class="tw-w-auto md:tw-w-[827px] md:tw-m-auto">
      <Stepper :current-step="currentStep" />
      <div class="tw-my-8">
        <SetupSelectCampaignType
          v-if="currentStep === 1"
          :eligibleTypeOfCPACampaign="eligibleTypeOfCPACampaign"
          :selectedCampaignType="selectedCampaignType"
          @onSelectedCampaignTypeChange="onSelectedCampaignTypeChange"
        />
        <CPCCampaignSetup v-if="currentStep === 2 && !isSelectedCampaignTypeCPA" @onBudgetChange="onBudgetChange" />
        <CPACampaignSetup
          v-if="currentStep === 2 && isSelectedCampaignTypeCPA"
          :eligibleCommissionLevels="eligibleCommissionLevels"
          :selectedCampaignType="selectedCampaignType"
          :selectedCommissionLevel="selectedCommissionLevel"
          @onSelectedCommissionChange="onSelectedCommissionChange"
        />
      </div>
      <SetupFooter
        :next-disabled="isNextDisabled"
        :is-loading="checkoutInProgress"
        @onNext="onNextAction"
        @onBack="onBackAction"
      />
    </div>
    <FullScreenLoader v-show="isStartUpFetching || rcCampaignCreationInProgress" />
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import Stepper from '@/components/rateConnect/setup/SetupStepper.vue'
import SetupFooter from '@/components/rateConnect/setup/SetupFooter.vue'
import CPCCampaignSetup from '@/components/rateConnect/setup/CPCCampaignSetup.vue'
import CPACampaignSetup from '@/components/rateConnect/setup/CPACampaignSetup.vue'
import SetupSelectCampaignType from '@/components/rateConnect/setup/SetupSelectCampaignType.vue'
import TheAppContainerIframe from '@/components/app/TheAppContainerIframe.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useSetup } from './useSetup'
import {
  useBudgetRecommendation,
  useCampaignsMutation,
  usePartners,
  useStartUp,
} from '@/components/rateConnect/queries'
import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'
import { useAllApps } from '@/components/dashboard/nav/queries'

const store = useStore()
const router = useRouter()
const route = useRoute()
const {
  currentStep,
  selectedCampaignType,
  isSelectedCampaignTypeCPA,
  selectedCommissionLevel,
  selectedBudget,
  resetSetup,
} = useSetup()
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale?.language)
const {
  isFetching: isStartUpFetching,
  isItemNetCPAEligible,
  eligibleCommissionLevels,
} = useStartUp(selectedAccommodationId)
const { recommendedPartner } = usePartners(selectedAccommodationId)
const { isBudgetValid } = useBudgetRecommendation(selectedAccommodationId, currency, recommendedPartner)
const { submitCampaign } = useCampaignsMutation()
const { allApps, isLoadingApps } = useAllApps(selectedAccommodationId)

const iframeComponent = ref(null)
const checkoutInProgress = ref(false)
const rcCampaignCreationInProgress = ref(false)

const isNextDisabled = computed(() => {
  if (currentStep.value === 1) {
    return selectedCampaignType.value === null
  } else if (currentStep.value === 2) {
    return isSelectedCampaignTypeCPA.value
      ? selectedCommissionLevel.value === null
      : !isBudgetValid(selectedBudget.value)
  }
  return false
})

const loaded = computed(() => store.state.app.loaded)
const launchedApp = computed(() => store.state.app.launchedApp)

const checkoutActive = computed(() => launchedApp.value?.app_id === 2)

const eligibleTypeOfCPACampaign = computed(() => (isItemNetCPAEligible.value === true ? 'netCpa' : null))

const onCheckoutFailure = () => {
  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_PAYMENT_FAILURE, {
    item_id: selectedAccommodationId.value,
    partner_id: recommendedPartner.value?.partnerId,
    campaign_type: selectedCampaignType.value,
    commission_level: selectedCommissionLevel.value,
    budget: selectedBudget.value * 100,
  })
  resetSetup()
  checkoutInProgress.value = false
  router.push('/rate-connect')
}

const onCheckoutSuccess = ({ zuoraSubscriptionNumber }) => {
  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_PAYMENT_SUCCESS, {
    item_id: selectedAccommodationId.value,
    partner_id: recommendedPartner.value?.partnerId,
    campaign_type: selectedCampaignType.value,
    commission_level: selectedCommissionLevel.value,
    budget: selectedBudget.value * 100,
  })
  rcCampaignCreationInProgress.value = true
  const requestOptions = {
    headers: {
      'X-Trv-Localization': language.value,
    },
    body: {
      overall: {
        campaignType: selectedCampaignType.value,
        currencyCode: currency.value,
        partnerId: recommendedPartner.value?.partnerId,
        useAutomatedMarkets: true,
        zuoraSubscriptionNumber: zuoraSubscriptionNumber,
        ...(isSelectedCampaignTypeCPA.value === true && { cpa: selectedCommissionLevel.value }),
        ...(selectedCampaignType.value === 'cpc' && { budget: { limit: selectedBudget.value * 100 } }),
      },
      ...(selectedCampaignType.value === 'cpc' && { campaigns: [] }),
    },
  }
  submitCampaign(
    { accommodationId: selectedAccommodationId.value, options: requestOptions },
    {
      onSettled: () => {
        resetSetup()
        window.location = '/home/rate-connect'
      },
    }
  )
  resetSetup()
}

const onNextAction = () => {
  if (currentStep.value === 1) {
    if (isSelectedCampaignTypeCPA.value) selectedCommissionLevel.value = 15
    currentStep.value = 2
  } else if (currentStep.value === 2) {
    checkout()
  }
}
const onBackAction = () => {
  if (currentStep.value === 1) {
    router.push('/rate-connect')
  } else if (currentStep.value > 1) {
    currentStep.value -= 1
  }
}

const checkout = () => {
  checkoutInProgress.value = true
  const rateConnectId = import.meta.env.PROD
    ? import.meta.env.VITE_RATE_CONNECT_ID
    : 'b2690842-bf69-4f93-9ddb-c5dd027aa7ee'

  const rateConnectApp = allApps?.value?.find?.(item => item.app_id === rateConnectId)
  triggerCheckout({ value: rateConnectApp })
}
const triggerCheckout = rateConnectApp => {
  const amount = isSelectedCampaignTypeCPA.value ? 0 : selectedBudget.value * 100
  const payload = {
    context: {
      budgets: [
        {
          currency: currency.value,
          amount,
        },
      ],
      markets: [],
    },
  }

  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_PAYMENT_LAUNCHED, {
    item_id: selectedAccommodationId.value,
    partner_id: recommendedPartner.value?.partnerId,
    campaign_type: selectedCampaignType.value,
    commission_level: selectedCommissionLevel.value,
    budget: selectedBudget.value * 100,
  })

  iframeComponent.value?.triggerCheckoutMethod({
    payload,
    launchedApp: rateConnectApp,
    isRC: true,
  })
}

const onSelectedCampaignTypeChange = type => (selectedCampaignType.value = type)
const onSelectedCommissionChange = level => (selectedCommissionLevel.value = level)
const onBudgetChange = budget => (selectedBudget.value = budget)

onMounted(() => {
  resetSetup()
  selectedCampaignType.value = isItemNetCPAEligible.value === true ? 'netCpa' : 'cpc'
  if (route.query?.source) {
    selectedCommissionLevel.value = 15
    currentStep.value = 2
  }
  const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_STARTED, {
    item_id: selectedAccommodationId.value,
    partner_id: recommendedPartner.value?.partnerId,
    source: route.query?.source ? route.query.source : 'RCCreateCampaign',
    traffic_source: trafficSource ?? '',
  })
})
</script>
<style scoped>
.app-container-loader {
  @apply tw-absolute tw-inset-0 tw-z-50;
  background: theme('colors.white');
  opacity: 0.8;
}
.app-container {
  @apply tw-absolute tw-inset-0 tw-block tw-w-full tw-h-screen;
}
.app-container-checkout-page-active {
  top: 0;
  @apply tw-h-checkout-min-height tw-z-50;
}
</style>
