<template>
  <div class="tw-min-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center">
    <Logo class="tw-mb-8" />
    <div v-if="freeLinkActivationStatus === 'inProgress'">
      <LaunchpadLoader />
    </div>
    <div
      v-else-if="freeLinkActivationStatus === 'success'"
      class="tw-rounded-lg tw-bg-blue-200 tw-px-5 tw-py-3 tw-flex tw-items-center tw-gap-3"
    >
      <BaseIcon :height="20" :width="20" icon-name="information" class="tw-fill-blue-700">
        <Information />
      </BaseIcon>
      <p v-html="$t('fbl_link_activated_success')" />
    </div>
    <div
      v-else-if="freeLinkActivationStatus === 'error'"
      class="tw-rounded-lg tw-bg-red-300 tw-px-5 tw-py-3 tw-flex tw-items-center tw-gap-3"
    >
      <BaseIcon :height="20" :width="20" icon-name="information" class="tw-fill-red-800">
        <Information />
      </BaseIcon>
      <p v-html="$t('fbl_link_error_message')" />
    </div>
    <div
      v-else-if="freeLinkActivationStatus === 'alreadyActive'"
      class="tw-rounded-lg tw-bg-yellow-300 tw-px-5 tw-py-3 tw-flex tw-items-center tw-gap-3"
    >
      <BaseIcon :height="20" :width="20" icon-name="information" class="tw-fill-yellow-700">
        <Information />
      </BaseIcon>
      <p v-html="$t('fbl_link_activated_message')" />
    </div>
    <MainButton
      class="tw-mt-12"
      @click="onLogin"
      @keydown.enter="onLogin"
      :disabled="freeLinkActivationStatus === 'inProgress'"
    >
      {{ $t('tbs_mp_cta_log_in') }}
    </MainButton>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import * as Sentry from '@sentry/vue'

import { useFreeLinksMutation } from '@/components/rateConnect/queries'

import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Information from '@/components/icons/Information.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import Logo from '@/components/dashboard/Logo.vue'

const store = useStore()
const route = useRoute()
const { requestFreeLinksOptIn } = useFreeLinksMutation()
const language = computed(() => store.state?.locale?.language)

const freeLinkActivationStatus = ref('inProgress')

const onLogin = async () => {
  store.dispatch('session/authorize', { type: 'login', locale: language.value })
}

onMounted(() => {
  let accommodationId, partnerId
  const token = route.query?.token
  try {
    const decodedToken = JSON.parse(window.atob(window.decodeURIComponent(token.split('.')[1])))
    accommodationId = decodedToken?.itemId
    partnerId = decodedToken?.partnerId
  } catch (e) {
    freeLinkActivationStatus.value = 'error'
    Sentry.withScope(scope => {
      scope.setTag('feature', 'Opt-in activation')
      Sentry.captureException(e)
    })
    return
  }
  requestFreeLinksOptIn(
    { accommodationId, partnerId, token },
    {
      onSuccess: () => {
        freeLinkActivationStatus.value = 'success'
      },
      onError: error => {
        const errorMsg = error?.response?.data?.errors?.[0]?.message
        freeLinkActivationStatus.value = errorMsg.includes('already opted-in') ? 'alreadyActive' : 'error'
      },
    }
  )
})
</script>
