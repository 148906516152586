<template>
  <div
    v-if="!accepted"
    class="tw-fixed banner-wrapper md:tw-bottom-16 tw-bottom-0 tw-max-w-base-banner tw-left-0 tw-right-0 tw-p-4"
  >
    <div class="tw-bg-blue-200 tw-px-5 tw-py-4 tw-rounded-md tw-shadow-lg tw-flex tw-flex-col md:tw-flex-row">
      <p class="tw-pr-4 tw-text-sm tw-mb-4 md:tw-mb-0">
        <span>
          <i18n-t keypath="cookieNotice.message" tag="p" scope="global">
            <template>
              <BaseLink
                :to="{ name: 'privacy-policy', hash: '#cookie-policy' }"
                class="tw-text-blue-800 hover:tw-text-blue-900 tw-underline tw-inline-block tw-font-semibold"
                target="_blank"
              >
                {{ $t('cookieNotice.link') }}
              </BaseLink>
            </template>
          </i18n-t>
        </span>
      </p>
      <MainButton
        size="small"
        class="cookie-ok-button"
        @click="handleAcceptCookieNotice"
        :dataTestId="'cookie-accept-btn'"
        >{{ $t('cookieNotice.ok') }}</MainButton
      >
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import BaseLink from '@/components/BaseLink.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const accepted = ref(false)
onMounted(() => isCookieNoticeAccepted())
const isCookieNoticeAccepted = () => {
  let acceptanceState

  try {
    acceptanceState = window.localStorage.getItem('cookie_notice_accepted')
  } catch {
    return false
  }

  if (acceptanceState === 'true') {
    accepted.value = true
  } else {
    accepted.value = false
  }
}

const handleAcceptCookieNotice = () => {
  try {
    window.localStorage.setItem('cookie_notice_accepted', 'true')
  } catch {}
  accepted.value = true
}
</script>

<style scoped>
.cookie-ok-button {
  min-width: 100px;
  @media (min-width: theme('screens.md')) {
    align-self: center;
  }
}
.base-banner {
  @apply tw-shadow-sm;
  @apply tw-rounded-lg;

  grid-column: 1 / -1;
}

.banner-wrapper {
  z-index: 9999;
  margin: 0 auto;
}
</style>
