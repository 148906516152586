<template>
  <component
    :is="tag"
    :to="to"
    :class="{ 'hover:tw-no-underline': isUnderlineHover }"
    class="tw-py-2.5 sm:tw-py-3 tw-flex tw-items-center tw-cursor-pointer"
    @click="onClick"
  >
    <span
      class="tw-truncate tw-duration-300 tw-transition-opacity tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-auto"
      ><slot
    /></span>
  </component>
</template>

<script setup>
defineProps({
  to: {
    type: String,
    default: '#',
  },
  tag: {
    type: String,
    default: 'a',
  },
  isIcon: {
    type: Boolean,
    default: true,
  },
  isUnderlineHover: Boolean,
})

const emit = defineEmits(['onClick'])

const onClick = e => {
  emit('onClick', e)
}
</script>
