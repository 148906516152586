<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 18C21 18.552 20.552 19 20 19H4C3.448 19 3 18.552 3 18V6C3 5.448 3.448 5 4 5H20C20.552 5 21 5.448 21 6V18ZM20 4H4C2.897 4 2 4.897 2 6V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4Z"
      fill="#171717"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5 16H6V7.5C6 7.224 5.776 7 5.5 7C5.224 7 5 7.224 5 7.5V16.5C5 16.776 5.224 17 5.5 17H18.5C18.776 17 19 16.776 19 16.5C19 16.224 18.776 16 18.5 16Z"
      fill="#171717"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 11L15 8H18V11ZM18 7H15C14.596 7 14.231 7.244 14.076 7.617C13.921 7.991 14.007 8.421 14.293 8.707L15.439 9.854L12.5 12.793L10.854 11.146C10.658 10.951 10.342 10.951 10.146 11.146L7.14601 14.146C6.95101 14.342 6.95101 14.658 7.14601 14.854C7.24401 14.951 7.37201 15 7.50001 15C7.62801 15 7.75601 14.951 7.85401 14.854L10.5 12.207L12.146 13.854C12.342 14.049 12.658 14.049 12.854 13.854L16.146 10.561L17.293 11.707C17.484 11.898 17.74 12 18 12C18.129 12 18.259 11.975 18.383 11.924C18.756 11.769 19 11.404 19 11V8C19 7.448 18.552 7 18 7Z"
      fill="#171717"
    />
  </svg>
</template>
