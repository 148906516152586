<template>
  <div class="tw-z-20 tw-left-12 tw-pt-2.5">
    <ul class="tw-flex tw-flex-col tw-h-full tw-grow tw-justify-start tw-pl-4 lg:tw-pl-10 lg:tw-pr-6">
      <li
        v-for="i in slideCount"
        :key="i"
        class="carousel-nav-item tw-flex tw-justify-center tw-border-l-2 tw-border-blue-200 tw-pb-4 tw-items-center"
      >
        <button
          :class="selectedSlide === i ? 'tw-bg-blue-800' : 'tw-bg-blue-200 tw-border-blue-200'"
          class="tw-text-base md:tw-text-xl tw-rounded-full tw-w-5 tw-h-5 md:tw-w-6 md:tw-h-6 tw-outline-none focus:tw-outline-none tw-relative tw-transition-all tw-duration-300 tw-ease-in-out"
          @click="onCarouselNavClick(i)"
          @keydown.enter="onCarouselNavClick(i)"
        ></button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'CarouselNav',
  props: {
    slideCount: {
      type: Number,
      default: 0,
    },
    selectedSlide: {
      type: Number,
      default: 1,
    },
    onCarouselNavClick: {
      type: Function,
    },
  },
}
</script>
<style scoped>
.carousel-nav-item:last-child {
  @apply tw-pb-0;
}
.carousel-nav-item button {
  left: -11px;

  @media (min-width: theme('screens.md')) {
    left: -13px;
  }
}
</style>
