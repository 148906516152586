<template>
  <div :id="containerName"></div>
</template>

<script setup>
import { nextTick, watch } from 'vue'
import Highcharts from 'highcharts'
import * as Sentry from '@sentry/vue'

const props = defineProps({
  series: {
    type: Array,
    default() {
      return []
    },
  },
})

const containerName = 'duration-graph-container'

const drawChart = data => {
  if (!data) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(containerName)) return
      Highcharts.chart(containerName, {
        title: null,
        chart: {
          type: 'column',
          style: {
            fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
          },
        },
        credits: {
          enabled: false,
        },
        series: data,
        xAxis: {
          // TODO: translations
          categories: ['1 night', '2 - 3 nights', '4 - 5 nights', '6 - 7 nights', '8 or more nights'],
          labels: {
            style: {
              fontSize: '16',
            },
          },
        },
        yAxis: {
          title: null,
          gridLineColor: '#DBDDE1',
          min: 0,
          max: 100,
          tickPositions: [0, 20, 40, 60, 80, 100],
          labels: {
            format: '{value}%',
            style: {
              fontSize: '14',
            },
          },
          stackLabels: {
            style: {
              color: '#005FA3',
              fontSize: '24',
              textOutline: 0,
            },
            format: '{total}%',
            enabled: true,
            y: -16,
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            maxPointWidth: '90',
            borderRadius: 4,
          },
          series: {
            color: '#368CDC',
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0) {
      drawChart(newProps.series)
    }
  },
  { deep: true, immediate: true }
)
</script>
