<template>
  <div class="tw-w-full">
    <p class="tw-font-bold tw-text-xl tw-text-gray-900 tw-my-2">{{ $t('app_hotel_details_address') }}</p>
    <div class="tw-flex tw-flex-col">
      <BaseTextField
        :label="$t('app_hotel_details_address')"
        name="streetAddress"
        aria-label="streetAddress"
        type="text"
        required
        class="tw-w-full tw-mb-4 md:tw-mb-auto"
        size="medium"
        :error="errors['streetAddress'] ? $t(errors['streetAddress']) : false"
        :value="address"
        @onChange="e => setValue('streetAddress', e)"
      />
      <BaseTextField
        :label="$t('app_hotel_details_zip_code')"
        name="postalCode"
        aria-label="postalCode"
        type="text"
        required
        class="tw-w-full tw-mb-4 md:tw-mb-auto"
        size="medium"
        :error="errors['postalCode'] ? $t(errors['postalCode']) : false"
        :value="zip"
        @onChange="e => setValue('postalCode', e)"
      />
      <CityAutocompleteField
        :label="$t('app_hotel_details_city')"
        name="city"
        aria-label="city"
        type="text"
        class="tw-w-full tw-mb-4 md:tw-mb-auto"
        size="medium"
        :options="[]"
        :value="city"
        @onSelect="e => setCityInfo(e)"
      />
      <BaseTextField
        :label="$t('tBS_alternative_accommodation_ID')"
        :hint="$t('tBS_alternative_accommodation_ID_info_text')"
        :placeholder="$t('tBS_alternative_accommodation_ID_prompt_text')"
        name="propertyId"
        aria-label="propertyId"
        type="string"
        class="tw-w-full tw-mb-4 md:tw-mb-auto"
        size="medium"
        :error="errors['propertyId'] ? $t(errors['propertyId']) : false"
        :value="propertyId"
        @onChange="e => setValue('propertyId', e)"
      />
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { usePropertyDetailsBasicInfo } from '@/components/propertyDetails/propertyBasicInfo/usePropertyDetailsBasicInfo.js'
import { validate, required } from '@/utils/propertyDetailsHelpers.js'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import CityAutocompleteField from '@/components/propertyDetails/propertyBasicInfo/edit/CityAutocompleteField.vue'

const { setNewValue, setValidation } = usePropertyDetailsBasicInfo()
const props = defineProps({
  errors: Object,
  address: String,
  zip: String,
  city: String,
  propertyId: String,
})

const getValidations = name => {
  if (name === 'streetAddress' || name === 'postalCode') {
    return [required]
  } else {
    return []
  }
}

onMounted(() => {
  const fieldsToValidate = [
    { name: 'streetAddress', value: props.address },
    { name: 'postalCode', value: props.zip },
  ]
  fieldsToValidate.forEach(item => {
    const validationFunctions = getValidations(item.name)
    const error = validate(item.value, validationFunctions)
    setValidation({
      name: item.name,
      value: error !== undefined ? error : false,
    })
  })
})

const setValue = (name, value) => {
  const validationFunctions = getValidations(name)
  const error = validate(value, validationFunctions)
  setValidation({ name, value: error !== undefined ? error : false, isFormValid: error === undefined })
  setNewValue({ name, value })
}
const setCityInfo = item => {
  setNewValue({ name: 'city', value: item.name })
  setNewValue({ name: 'categoryId', value: item.id })
}
</script>
