<template>
  <BaseCard>
    <div
      v-if="(allAvailableLanguages !== null || undefined) && useLanguageFieldsData !== null"
      class="tw-flex tw-flex-col tw-justify-between"
    >
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row md:tw-justify-between tw-mb-4">
          <div>
            <h4 class="tw-text-xl tw-font-bold">{{ $t('app_description_language') }}</h4>
            <p class="tw-text-sm">{{ $t('app_hotel_details_language_information') }}</p>
          </div>
          <div>
            <GhostButton
              size="medium"
              class="tw-px-6 tw-py-2 tw-w-full tw-whitespace-nowrap"
              @click="() => openAdding()"
              >{{ $t('app_hotel_details_add_language') }}</GhostButton
            >
          </div>
        </div>
        <div v-for="language in useLanguageFieldsData" :key="language.languageCode" class="tw-mb-2">
          <LanguageInfo
            :language="
              language.languageCode.includes('-') ? language.languageCode.split('-')[0] : language.languageCode
            "
            :languageCode="language.languageCode"
            :address="language.streetAddress"
            :name="language.name"
            :web="language.web"
          />
        </div>
      </div>
      <AddLanguageModal
        :is-open="isAdding"
        :availableLanguages="allAvailableLanguages"
        :isLoading="isAvailableLanguagesLoading"
        :isUpdating="addLanguageLoading"
        :usedLanguages="useLanguageFieldsData"
        @closeAdding="closeAdding"
        @save="language => saveNewLanguage(language)"
      />
    </div>
  </BaseCard>
</template>

<script setup>
import { ref } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import LanguageInfo from '@/components/propertyDetails/propertyLanguages/LanguageInfo.vue'
import AddLanguageModal from '@/components/propertyDetails/propertyLanguages/AddLanguageModal.vue'
import { selectedAccommodationId } from '@/layouts/queries'
import {
  useLanguageFields,
  useAddLanguage,
  useAvailableLanguages,
} from '@/components/propertyDetails/propertyLanguages/queries'
import { usePropertyLanguages } from '@/components/propertyDetails/propertyLanguages/usePropertyLanguages.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const { resetValidation, setEditItem, editItem } = usePropertyLanguages()

const isAdding = ref(false)

const { addLanguage, addLanguageLoading } = useAddLanguage()
const { useLanguageFieldsData } = useLanguageFields(selectedAccommodationId)
const { isAvailableLanguagesLoading, allAvailableLanguages } = useAvailableLanguages()

const { displayNotification } = useToastNotifications()

const closeAdding = () => {
  isAdding.value = false
}
const openAdding = () => {
  resetValidation()
  setEditItem({
    name: '',
    streetAddress: '',
    web: '',
    languageCode: '',
  })

  isAdding.value = true
}
const saveNewLanguage = language => {
  let body = editItem.value
  body = {
    ...body,
    languageCode: language,
  }
  addLanguage(
    { accommodationId: selectedAccommodationId.value, body },
    {
      onSuccess: () => {
        displayNotification({
          message: 'app_hotel_details_success_message',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        isAdding.value = false
        setEditItem(null)
      },
      onError: () => {
        isAdding.value = false
        setEditItem(null)
      },
    }
  )
}
</script>
