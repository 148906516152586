<template>
  <section class="tw-relative tw-mb-8 tw-w-[90%] md:tw-w-[615px] tw-mx-auto">
    <div v-if="isUserDetailsLoading" class="tw-flex tw-justify-center tw-items-center">
      <LaunchpadLoader />
    </div>
    <div v-else>
      <OnboardingStepper class="tw-mb-10 md:tw-mb-14" :currentStep="onboardingStep" />
      <transition name="slide-fade">
        <PersonalInformationForm
          v-if="onboardingStep === 1"
          :is-onboarding="true"
          @on-create-profile-submit="onCreateProfileSubmit"
        />
      </transition>
      <transition name="slide-fade">
        <div v-if="onboardingStep === 2" class="tw-flex tw-flex-col tw-mx-auto">
          <p class="tw-text-[32px] tw-leading-10 tw-mb-3">{{ $t('header_title') }}</p>
          <p class="tw-font-bold tw-text-[32px] tw-leading-10 tw-max-w-full md:tw-max-w-[78%]">
            {{ $t('header_subtitle') }}
          </p>
          <AddPropertyForm :isOnboarding="true" @assign-property-click="onAssignProperty" />
        </div>
      </transition>
    </div>
    <FullScreenLoader v-show="createUserProfileLoading || assignPropertyLoading" />
  </section>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import PersonalInformationForm from '@/components/onboarding/PersonalInformationForm.vue'
import OnboardingStepper from '@/components/onboarding/OnboardingStepper.vue'
import AddPropertyForm from '@/components/assignedProperties/AddPropertyForm.vue'
import {
  useUserDetails,
  setSelectedAccommodationId,
  useMultipleOrganisationAssignmentsQueries,
  useMultipleAccommodationsByOrgIdQueries,
} from '@/layouts/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { useCreateUserProfile } from '@/components/settings/profileSettings/queries'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useAssignProperty } from '@/components/assignedProperties/queries/index.js'
import { useAssignedProperty } from '@/components/assignedProperties/useAssignedProperty'

const router = useRouter()
const store = useStore()
const userId = computed(() => store.state.session.userId)
const organisationId = computed(() => store.state.session.selectedOrganisation?.id)

const { assignPropertyLoading, assignProperty } = useAssignProperty()
const { resetHotelAssignment } = useAssignedProperty()
const { isUserDetailsLoading } = useUserDetails(userId)
const { createUserProfile, createUserProfileLoading } = useCreateUserProfile()
const { displayNotification } = useToastNotifications()

const onboardingStep = computed(() => {
  if (store.state.session.isOnboardingUserDetails) return 1
  if (store.state.session.isOnboardingAssignments) return 2
  return 3
})

const organisations = computed(() => store.state.session.organisations)

watch(onboardingStep, newValue => {
  if (newValue === 2) {
    preloadOtherOrganisationsAccommodations()
  }
})

onMounted(() => {
  preloadOtherOrganisationsAccommodations()
})

const preloadOtherOrganisationsAccommodations = () => {
  useMultipleOrganisationAssignmentsQueries(organisations)
  useMultipleAccommodationsByOrgIdQueries(organisations)
}

const onCreateProfileSubmit = form => {
  createUserProfile(
    { body: form },
    {
      onSuccess: async () => {
        displayNotification({
          message: 'form.alerts.save_success',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        await store.dispatch('session/restoreStudioSession')
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
      },
    }
  )
}

const onAssignProperty = accommodationId => {
  assignProperty(
    {
      organisationId: organisationId.value,
      accommodationId: accommodationId,
    },
    {
      onSuccess: async () => {
        displayNotification({
          message: 'successfully_assigned',
          isTranslationKey: true,
          type: 'success',
        })
        store.commit('session/SET_IS_ONBOARDING_ASSIGNMENTS', false)
        setSelectedAccommodationId(accommodationId)
        resetHotelAssignment()
        await router.push('/')
      },
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-enter {
  transform: translateX(150px);
  opacity: 0;
}
</style>
