<template>
  <div class="autocomplete" ref="root">
    <p v-if="label" class="tw-font-bold">{{ label }}</p>
    <div class="tw-relative" tabindex="1" @focus="showResultsOnFocus">
      <input
        :id="id"
        v-model="searchValue"
        type="text"
        :class="`${sizeClass} tw-border-gray-500`"
        class="tw-appearance-none tw-relative z-index-10 tw-border tw-w-full focus:tw-outline-0 tw-bg-white tw-text-gray-900 focus:tw-border-blue-900 focus:tw-text-blue-900 tw-py-2 tw-px-3 tw-rounded-lg"
        v-bind="$attrs"
        @input="onChange"
        @focus="showResultsOnFocus"
      />
      <BaseIcon
        v-if="!isOpen || searchValue.length === 0"
        :height="20"
        :width="20"
        icon-name="search"
        class="tw-absolute tw-top-[12px] tw-bottom-[8px] tw-right-[10px]"
      >
        <Search />
      </BaseIcon>
      <button @click="() => resetSelection()">
        <BaseIcon
          v-show="searchValue.length > 0 && isOpen"
          :height="14"
          :width="14"
          viewbox-height="20"
          viewbox-width="20"
          icon-name="search"
          class="tw-absolute tw-top-[14px] tw-bottom-[8px] tw-right-[10px]"
        >
          <Close class="tw-fill-gray-700" />
        </BaseIcon>
      </button>
    </div>
    <small v-if="error" class="tw-text-red-800">{{ error }}</small>
    <BaseCard
      v-show="isOpen"
      id="autocomplete-results"
      no-padding
      class="autocomplete-results tw-max-h-[250px] tw-overflow-auto tw-absolute tw-left-0 tw-right-0 tw-z-[9999999]"
      :class="label ? 'tw-top-[65px]' : 'tw-top-[50px]'"
    >
      <ul>
        <li v-if="isLoading" class="tw-p-2 tw-min-h-[30px]">{{ $t('appTileList.loading') }}</li>
        <li v-else-if="suggestedOptions.length === 0" class="tw-p-2 tw-min-h-[30px]">{{ $t('no_results') }}</li>
        <li
          v-for="result in suggestedOptions"
          v-else
          :key="result.value"
          class="autocomplete-result tw-p-2 hover:tw-cursor-pointer hover:tw-bg-gray-100 tw-border-b tw-border-gray-100"
          @click="setResult(result)"
        >
          {{ result.withTranslation ? $t(result.name) : result.name }}
        </li>
      </ul>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import cloneDeep from 'lodash/cloneDeep'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'
import Close from '@/components/icons/Close.vue'

const { t } = useI18n()
const props = defineProps({
  id: {
    type: String,
    default: 'autocomplete',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  ariaLabel: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  error: {
    type: [Boolean, String],
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
  },
  options: {
    type: Array,
    default: () => [],
  },
  isAsync: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['onSelect'])
const root = ref(null)
const isOpen = ref(false)
const isLoading = ref(false)
const suggestedOptions = ref(cloneDeep(props.options))
const searchValue = ref(cloneDeep(props.value))

const sizeClass = computed(() => {
  if (props.size === 'small') return 'tw-py-1  tw-rounded'
  if (props.size === 'medium') return 'tw-py-[0.44rem] tw-rounded-lg'
  else return 'tw-py-3 tw-rounded-lg'
})

watch(
  () => props.options,
  (newOptions, oldOptions) => {
    if (newOptions.length !== oldOptions.length) {
      suggestedOptions.value = newOptions
      isLoading.value = false
    }
  }
)

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

const resetSelection = () => {
  searchValue.value = ''
  suggestedOptions.value = cloneDeep(props.options)
  isOpen.value = true
}
const onChange = () => {
  if (props.isAsync) {
    isLoading.value = true
  } else {
    filterResults()
    isOpen.value = true
  }
}
const filterResults = () => {
  suggestedOptions.value = props.options.filter(item => {
    const name = item.withTranslation ? t(item.name) : item.name
    return name.toLowerCase().indexOf(searchValue.value.toLowerCase()) > -1
  })
}
const setResult = result => {
  emit('onSelect', result)
  searchValue.value = result.withTranslation ? t(result.name) : result.name
  isOpen.value = false
  suggestedOptions.value = cloneDeep(props.options)
}
const showResultsOnFocus = () => {
  isOpen.value = true
}

const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    isOpen.value = false
    if (searchValue.value === '') {
      const defaultValue = props.options.find(item => t(item.name) === props.value)

      setResult(defaultValue)
    }
  }
}
</script>
