<template>
  <h1 class="tw-font-bold tw-text-xl tw-mb-6 tw-text-xl">
    {{ $t('rc_campaign_type_changes_head') }}
  </h1>
  <p class="tw-mb-12">
    {{ isNetCPA ? $t('rc_campaign_type_changes_text_pay_per_stay') : $t('rc_campaign_type_changes_text_cpa') }}
  </p>
  <div class="tw-flex tw-flex-wrap md:tw-max-w-[800px] tw-mb-16">
    <div v-for="(item, index) in modeInfos" :key="index" class="icon-element tw-flex tw-mb-6">
      <div class="tw-pr-2 tw-w-[32px]">
        <BaseIcon :height="24" :width="24" :icon-name="item.icon">
          <RateInsights v-if="item.icon === 'rate-insights'" />
          <Calendar v-if="item.icon === 'calendar'" />
          <SettingsBlue v-if="item.icon === 'settings-blue'" />
          <Info v-if="item.icon === 'info'" />
          <Global v-if="item.icon === 'global'" />
        </BaseIcon>
      </div>
      <div class="tw-max-w-[350px]">
        <h3 class="tw-text-base tw-font-bold tw-text-gray-900">{{ $t(item.head) }}</h3>
        <p class="tw-text-sm tw-text-gray-900">{{ $t(item.text) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import RateInsights from '@/components/icons/RateInsights.vue'
import Calendar from '@/components/icons/Calendar.vue'
import SettingsBlue from '@/components/icons/SettingsBlue.vue'
import Info from '@/components/icons/Info.vue'
import Global from '@/components/icons/Global.vue'

const props = defineProps({
  isNetCPA: {
    type: Boolean,
    default: false,
  },
})
const modeInfos = [
  {
    icon: 'rate-insights',
    head: 'rc_cpa_change_budget_head',
    text: 'rc_cpa_change_budget_text',
  },
  {
    icon: 'calendar',
    head: 'rc_cpa_change_invoice_head',
    text: props.isNetCPA ? 'rc_pay_per_stay_change_invoice_text' : 'rc_cpa_change_invoice_text',
  },
  {
    icon: 'settings-blue',
    head: 'rc_cpa_change_bidding_head',
    text: 'rc_cpa_change_bidding_text',
  },
  {
    icon: 'info',
    head: 'rc_cpa_change_commission_head',
    text: 'rc_cpa_change_commission_text',
  },
  {
    icon: 'global',
    head: 'rc_cpa_change_markets_head',
    text: 'rc_cpa_change_markets_text',
  },
]
</script>
<style scoped>
.icon-element {
  flex-basis: 50%;
  @media (max-width: theme('screens.md')) {
    flex-basis: 100%;
  }
}
</style>
