<template>
  <div class="tw-relative tw-flex tw-items-center tw-w-full">
    <div
      class="tw-flex tw-items-center tw-border tw-border-gray-500 tw-rounded-lg tw-py-2.5 tw-px-3 tw-mr-2 tw-font-base tw-w-full"
      :class="disabled ? 'tw-bg-gray-100' : 'tw-bg-white'"
    >
      <input
        data-hj-allow
        type="number"
        id="property_search"
        class="focus:tw-outline-none tw-grow tw-w-full"
        :class="{ 'tw-bg-gray-100': disabled }"
        :placeholder="'Type the accommodation id'"
        :disabled="disabled"
        :value="searchValue"
        @input="updateSearchValue"
        @keydown="
          e => {
            if (['+', 'e', 'E', '-', '.', ',', '>'].includes(e.key)) {
              e.preventDefault()
            }
          }
        "
        @keypress.enter="search"
      />
      <BaseIcon :height="17" :width="17" icon-name="search">
        <Search />
      </BaseIcon>
    </div>
    <MainButton @click="search">{{ 'Search' }}</MainButton>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

defineProps({
  searchValue: String,
  disabled: Boolean,
})
const emit = defineEmits(['updateSearchValue', 'search'])
const updateSearchValue = e => emit('updateSearchValue', e.target.value)
const search = () => emit('search')
</script>
