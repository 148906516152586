<template>
  <header class="hero-container-grid">
    <div class="lg:tw-max-w-lg tw-px-4 lg:tw-pl-10 lg:tw-pr-0 lg:tw-self-center">
      <h1
        class="tw-text-5xl md:tw-text-[50px] tw-font-bold tw-mb-4 tw-mt-16 lg:tw-mt-0"
        v-html="$t('tbs_mp_headline')"
      ></h1>
      <p class="tw-font-bold tw-text-xl tw-mb-4" v-html="$t('tbs_mp_header')" />
      <p v-html="$t('tbs_mp_header_text')"></p>
      <div class="tw-flex">
        <MainButton
          class="tw-mt-7 lg:tw-mt-12 tw-mr-2"
          @click="onRegisterClick"
          @keydown.enter="onRegisterClick"
          :dataTestId="'hero-register-button'"
          >{{ $t('tbs_mp_cta_register') }}</MainButton
        >
        <MainButtonOutline
          @click="onLoginClick"
          @keydown.enter="onLoginClick"
          class="tw-mt-7 lg:tw-mt-10 tw-mr-5 tw-flex md:tw-hidden"
          >{{ $t('tbs_mp_cta_log_in') }}</MainButtonOutline
        >
      </div>
    </div>
    <img
      src="@/assets/img/landing/hero.webp"
      alt="trivago business studio hero image"
      height="559"
      width="897"
      class="tw-mt-12 lg:tw-mt-0 tw-mx-auto tw-pl-4 lg:tw-pl-0"
    />
  </header>
</template>

<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'
import MainButtonOutline from '@/components/base/buttons/MainButtonOutline.vue'

const emit = defineEmits(['onLoginClick', 'onRegisterClick'])
const onRegisterClick = e => emit('onLoginClick', e.target.value)
const onLoginClick = e => emit('onRegisterClick', e.target.value)
</script>
<style scoped>
@media (min-width: theme('screens.lg')) {
  .hero-container-grid {
    display: grid;
    grid-template-columns: max-content max-content;
    overflow: hidden;
  }
}
</style>
