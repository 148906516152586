<template>
  <ul>
    <li
      v-for="paymentMethod in existingPaymentMethods"
      :key="paymentMethod.hpp_id"
      class="tw-flex tw-items-center tw-border-t tw-border-t-gray-300 tw-p-4"
    >
      <input
        type="radio"
        name="paymentMethod"
        :value="paymentMethod.details.id"
        :id="paymentMethod.details.id"
        :checked="selectedPaymentMethod === paymentMethod.details.id"
        @change="emit('onChange', paymentMethod.details.id)"
        class="tw-cursor-pointer"
      />
      <label
        :for="paymentMethod.details.id"
        class="tw-cursor-pointer tw-m-0 tw-ml-2 tw-grow tw-flex tw-items-center tw-flex-wrap"
      >
        <div v-if="paymentMethod.type === 'SEPA'">
          <span class="tw-grow">{{
            `SEPA - ${paymentMethod.details.account_number_mask} - ${paymentMethod.details.account_name}`
          }}</span>
        </div>
        <div v-else-if="paymentMethod.type === 'CREDIT_CARD'" class="tw-flex tw-gap-x-2 tw-flex-wrap">
          <div>
            <img
              :src="getCreditCardLogoUrl(paymentMethod.details.type)"
              alt="Credit card logo"
              height="24"
              width="38"
              class="tw-shadow-[0_0_1px_0_rgba(41,52,58,0.75)] tw-mr-2 tw-inline"
            />
            <span>{{ paymentMethod.details.mask_number }}</span>
          </div>
          <div>- Exp. date: 03/2030</div>
        </div>
      </label>
    </li>
  </ul>
</template>

<script setup>
const visaLogo = new URL('/src/assets/img/settings-payment/visa.png', import.meta.url)
const mastercardLogo = new URL('/src/assets/img/settings-payment/mastercard.png', import.meta.url)
const americanexpressLogo = new URL('/src/assets/img/settings-payment/americanexpress.png', import.meta.url)

defineProps({
  existingPaymentMethods: {
    type: Array,
    default: () => [],
  },
  selectedPaymentMethod: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['onChange'])

const getCreditCardLogoUrl = type => {
  if (type === 'Visa') return visaLogo
  if (type === 'MasterCard') return mastercardLogo
  if (type === 'AmericanExpress') return americanexpressLogo
}
</script>
