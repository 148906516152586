<template>
  <div>
    <div class="tw-relative tw-overflow-x-auto tw-w-full table-wrapper">
      <table class="tw-w-full tw-text-sm tw-text-right tw-text-gray-900">
        <thead class="tw-hidden md:tw-table-header-group tw-text-xs tw-text-gray-700 tw-bg-white">
          <tr class="tw-border-b-1 tw-px-6 tw-py-5">
            <th
              v-for="column in newColumns"
              :key="column.id"
              scope="col"
              class="tw-py-6 tw-relative tw-border-r-0 tw-border-l-0 tw-text-right table-header"
              :class="{ 'tw-text-blue-800': column.id === kpiHighlight }"
              @click="sortTable(column.id)"
              @keydown.enter="sortTable(column.id)"
            >
              <UiTooltip theme="customlightwithpadding" :label="$t(getHint(column))" tooltip-position="top">
                <span class="tw-flex tw-items-center tw-relative tw-justify-end">
                  {{ $t(column.header) }}
                  <div v-if="column.header.length > 1" class="tw-relative">
                    <BaseIcon
                      :height="10"
                      :width="11"
                      class="tw-ml-2 tw-text-gray-100"
                      icon-name="table-sort-arrows"
                      :icon-color="
                        sortColumn === column.id ? (column.id === kpiHighlight ? '#69A9E4' : '#AFB3BC') : '#AFB3BC'
                      "
                    >
                      <TableSortArrows />
                    </BaseIcon>
                    <BaseIcon
                      :height="4"
                      :width="11"
                      class="tw-ml-2"
                      icon-name="table-sort-arrow-up"
                      :icon-color="
                        sortColumn === column.id ? (column.id === kpiHighlight ? '#C4E3F4' : '#007CC2') : '#007CC2'
                      "
                      :class="!ascending ? 'tw-rotate-180 tw-absolute bottom-sort-arrow' : 'tw-absolute top-sort-arrow'"
                    >
                      <TableSortArrowUp />
                    </BaseIcon>
                  </div>
                </span>
              </UiTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in getData()" :key="index">
            <tr
              class="row tw-text-gray-900 tw-bg-white table-row tw-px-6 tw-py-5"
              :class="{
                'expanded-table-row': index === expandRowIndex,
                'mobile-full-view': expandMobileElement == index,
                'extra-info-row': row.extraInfo !== undefined && haveExtraInfo,
              }"
              @click="toggleExpandRow(index, row)"
              @keydown.enter="toggleExpandRow(index, row)"
            >
              <td
                v-for="column in newColumns"
                :key="column.id"
                scope="row"
                class="cell tw-font-medium tw-whitespace-nowrap tw-border-0 tw-relative"
                :class="getClass(column.id, row, column.className)"
              >
                <div v-if="isIncompatibleMetric(row.aggregateCampaignType, column.id)">
                  <UiTooltip
                    theme="customlightwithpadding"
                    :label="getTooltipType(row.aggregateCampaignType)"
                    tooltip-position="top"
                  >
                    <p class="md:tw-hidden">{{ $t(column.header) }}</p>
                    -
                  </UiTooltip>
                </div>
                <div v-else>
                  <UiTooltip
                    theme="customlightwithpadding"
                    :label="$t(getExplonationTip(row.aggregateCampaignType, column.id))"
                    tooltip-position="top"
                  >
                    <span v-if="column.colType === 'date'">
                      {{ getDateFormat(row[column.id].start, row[column.id].end) }}
                    </span>
                    <span
                      v-else-if="column.id === 'unavailability' && row[column.id] !== null"
                      class="md:tw-flex tw-items-center tw-justify-end"
                    >
                      <span :class="{ 'tw-text-orange-700': row[column.id] >= 0.3 }">
                        <p class="md:tw-hidden">{{ $t(column.header) }}</p>
                        {{ formatTableCell(row[column.id], column.colType) }}
                      </span>
                      <div v-if="haveExtraInfo && row.extraInfo" class="tw-flex">
                        <div
                          class="tw-border tw-ml-2 tw-top-3 tw-absolute tw-border-gray-500 tw-rounded-md focus:tw-outline-none tw-w-[27px] tw-h-[27px]"
                        >
                          <BaseIcon
                            :height="26"
                            :width="23"
                            icon-name="button-arrow"
                            class="tw-mt-[2px]"
                            :class="expandRowIndex !== index ? 'transform-closed' : 'transform-open'"
                          >
                            <ButtonArrow />
                          </BaseIcon>
                        </div>
                      </div>
                    </span>
                    <span v-else-if="column.id === 'unavailability' && row[column.id] === null">
                      <UiTooltip
                        theme="customlightwithpadding"
                        :label="$t('rc_blank_unavailability')"
                        class="unavailablity-row"
                        tooltip-position="top"
                        ><p class="md:tw-hidden">
                          {{ $t(column.header) }}
                        </p>
                        -</UiTooltip
                      >
                      <div v-if="haveExtraInfo && row.extraInfo" class="tw-flex">
                        <div
                          class="tw-absolute tw-mt-1 tw-mr-2 tw-top-3 tw-border tw-border-gray-500 tw-rounded-md focus:tw-outline-none tw-w-[27px] tw-h-[27px]"
                        >
                          <BaseIcon
                            :height="26"
                            :width="23"
                            icon-name="button-arrow"
                            class="tw-mt-[2px]"
                            :class="expandRowIndex !== index ? 'transform-closed' : 'transform-open'"
                          >
                            <ButtonArrow />
                          </BaseIcon>
                        </div>
                      </div>
                    </span>
                    <span
                      v-else
                      :class="{
                        'tw-hidden':
                          row['bookingRefundedRevenue'] === null && row['bookingRefundedRevenue'] === undefined,
                      }"
                      ><p class="md:tw-hidden">
                        {{ $t(column.header) }}
                      </p>
                      {{ formatTableCell(row[column.id], column.colType) }}
                    </span>
                  </UiTooltip>
                </div>
              </td>
            </tr>
            <tr
              v-if="expandRowIndex === index"
              :key="`key_${index}`"
              class="hover:tw-bg-blue-100 tw-bg-blue-100 bottom-shadow tw-mb-2 md:tw-mb-0"
            >
              <td :colspan="newColumns.length" class="tw-border-0">
                <div class="tw-flex tw-flex-row tw-w-full tw-justify-end">
                  <div
                    v-for="col in additionalInfoColumns"
                    :key="col.id"
                    :class="{ 'orange-badge': col.id === 'cancelledBookings' }"
                  >
                    <span :class="{ 'tw-font-bold': col.id !== 'cancelledBookings' }"
                      >{{ $t(col.header) }}{{ ' ' }}</span
                    >
                    <span class="badge-value">{{ formatTableCell(row[col.id], col.colType) }}</span>
                  </div>
                </div>
              </td>
            </tr>
            <div class="show-button tw-mx-4 tw-flex md:tw-hidden tw-mt-2">
              <GhostButton size="small" @click="toggleExpandMobileElement(index)">{{
                expandMobileElement == index ? $t('price_plan.features.show_less') : $t('price_plan.features.show_more')
              }}</GhostButton>
            </div>
          </template>
        </tbody>
      </table>
    </div>
    <div class="table-footer tw-hidden md:tw-flex tw-justify-between tw-text-sm tw-items-center tw-px-6 tw-py-5">
      <div>
        <div v-if="haveExtraInfo" class="tw-hidden md:tw-flex tw-items-center">
          <span class="tw-block tw-h-2 tw-w-2 tw-bg-orange-700 tw-rounded-full tw-mr-2" />
          <p class="tw-w-2/3">{{ $t('rc_cancellation_notification_pay_per_stay') }}</p>
        </div>
      </div>
      <MainButtonOutline
        size="small"
        :disabled="loadedElements >= tableRows.length"
        @click="loadMore()"
        @keydown.enter="loadMore()"
      >
        {{ $t('show_more') }}
      </MainButtonOutline>
      <div class="tw-hidden md:tw-flex" />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import MainButtonOutline from '@/components/base/buttons/MainButtonOutline.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TableSortArrows from '@/components/icons/TableSortArrows.vue'
import TableSortArrowUp from '@/components/icons/TableSortArrowUp.vue'
import ButtonArrow from '@/components/icons/ButtonArrow.vue'
import { CAMPAIGN_MODES, isCpaCampaign } from '@/utils/rateConnectUtils'

const store = useStore()
const props = defineProps({
  columns: Array,
  data: Array,
  kpiHighlight: String,
  haveExtraInfo: Boolean,
  getSelectedPeriodCampaignType: String,
  getSelectedAggregation: String,
  currency: String,
})

const windowWidth = ref(window.innerWidth)
const loadedElements = ref(props.data?.length < 6 ? props.data?.length : 6)
const ascending = ref(false)
const sortColumn = ref('clicks')
const additionalInfoColumns = ref(props.columns.filter(column => column.additional))
const tableRows = ref(props.data)
const EXCLUDED_METRICS = ref({
  costs: [CAMPAIGN_MODES.CPA, CAMPAIGN_MODES.NET_CPA],
  cpa: [CAMPAIGN_MODES.CPA, CAMPAIGN_MODES.NET_CPA],
  cpc: [CAMPAIGN_MODES.CPA, CAMPAIGN_MODES.NET_CPA],
  commissionCost: [CAMPAIGN_MODES.CPC],
})
const expandRowIndex = ref(null)
const expandMobileElement = ref(null)

const newColumns = computed(() => {
  return windowWidth.value > 768 ? props.columns : props.columns.filter(column => !column.additional)
})
const locale = computed(() => store.state.locale.language)

watch(
  () => props,
  newProps => {
    tableRows.value = newProps.data
  },
  { deep: true, immediate: true }
)

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
const onResize = () => {
  windowWidth.value = window.innerWidth
}
const getClass = (columnId, row, className) => {
  let classes = [`${props.kpiHighlight}_${className}`]
  if (columnId === props.kpiHighlight) {
    classes.push('md:tw-text-blue-700')
  }
  if (row.extraInfo !== undefined && props.haveExtraInfo) {
    classes.push('extra-info tw-py-4')
  } else if (!columnId === 'showMore') {
    classes.push('tw-px-6 tw-py-4')
  }
  return classes.toString().replaceAll(',', ' ')
}
const getDateFormat = (startDate, endDate) => {
  if (props.getSelectedAggregation === 'month') {
    return new Date(startDate).toLocaleDateString(locale, {
      year: '2-digit',
      month: '2-digit',
    })
  } else if (props.getSelectedAggregation === 'week') {
    return `${new Date(startDate).toLocaleDateString(locale, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })} - ${new Date(endDate).toLocaleDateString(locale, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })}`
  } else {
    return new Date(startDate).toLocaleDateString(locale, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
  }
}
const getData = () => {
  return tableRows.value?.slice(0, loadedElements.value)
}
const loadMore = () => {
  if (tableRows.value.length > loadedElements.value) {
    loadedElements.value = loadedElements.value + 10
    getData()
  }
}
const formatTableCell = (value, type) => {
  if (value !== null && value !== undefined) {
    if (type === 'percentage') {
      return new Intl.NumberFormat(locale, { maximumFractionDigits: 1, style: 'percent' }).format(value)
    } else if (type === 'number') {
      return new Intl.NumberFormat(locale, { style: 'decimal' }).format(value)
    } else if (type === 'currency') {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: props.currency,
      }).format(value)
    }
  } else return '-'
}
const sortTable = col => {
  if (sortColumn.value === col) {
    ascending.value = !ascending.value
  } else {
    ascending.value = true
    sortColumn.value = col
  }

  var ascendingValue = ascending.value

  tableRows.value.sort(function (a, b) {
    if (col === 'date') {
      if (a[col].start > b[col].start) {
        return ascendingValue ? 1 : -1
      } else if (a[col].start < b[col].start) {
        return ascendingValue ? -1 : 1
      }
    } else {
      if (a[col] > b[col]) {
        return ascendingValue ? 1 : -1
      } else if (a[col] < b[col]) {
        return ascendingValue ? -1 : 1
      }
    }
    return 0
  })
}
const isExplanationRequired = (campaignType, selectedPeriodCampaignType, metricName) => {
  return (
    campaignType !== CAMPAIGN_MODES.UNKNOWN &&
    selectedPeriodCampaignType === CAMPAIGN_MODES.BOTH &&
    EXCLUDED_METRICS.value[metricName]
  )
}
const isIncompatibleMetric = (campaignType, metricName) => {
  return EXCLUDED_METRICS.value[metricName] === campaignType
}
const toggleExpandRow = (rowIndex, row) => {
  if (row.extraInfo && props.haveExtraInfo) {
    if (expandRowIndex.value === rowIndex) {
      expandRowIndex.value = null
    } else {
      expandRowIndex.value = rowIndex
    }
  }
}
const toggleExpandMobileElement = rowIndex => {
  if (expandMobileElement.value === rowIndex) {
    expandMobileElement.value = null
    expandRowIndex.value = null
  } else {
    expandMobileElement.value = rowIndex
  }
}
const getTooltipType = campaignType => {
  return isCpaCampaign(campaignType) ? 'rc_blank_cpa' : 'rc_blank_cpc'
}
const getExplonationTip = (campaignType, metricName) => {
  const selectedPeriodCampaignType = props.getSelectedPeriodCampaignType
  const metricExcludedInCampaign = isExplanationRequired(campaignType, selectedPeriodCampaignType, metricName)
  let explanationTip = ''
  if (
    metricExcludedInCampaign &&
    (metricExcludedInCampaign.includes(CAMPAIGN_MODES.CPA) || metricExcludedInCampaign.includes(CAMPAIGN_MODES.NET_CPA))
  ) {
    explanationTip = 'rc_partial_data_cpc'
  } else if (metricExcludedInCampaign && metricExcludedInCampaign.includes(CAMPAIGN_MODES.CPC)) {
    explanationTip = 'rc_partial_data_cpa'
  }
  return explanationTip
}
const getHint = column => {
  return column?.hint === undefined ? '' : column.hint
}
</script>
<style scoped>
.clicks_col--additional,
.revenue_col--additional,
.commissionCost_col--additional,
.cpa_col--additional {
  @media (max-width: theme('screens.md')) {
    display: none;
  }
}
table thead th,
table tbody td {
  font-size: 14px;
}
table thead th:last-child {
  padding-right: 45px;
  @media (max-width: theme('screens.md')) {
    padding-right: 16px;
  }
}
.table-header:hover {
  cursor: pointer;
}
.cell {
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}
.icon-fill {
  fill: theme('colors.blue.800');
}
.table-row:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
  transform: scale(1);
  cursor: pointer;
  @media (max-width: theme('screens.md')) {
    box-shadow: none;
  }
}
.bottom-sort-arrow {
  right: 0;
  top: 6px;
}
.table-footer {
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  gap: 1rem;
  @media (max-width: theme('screens.md')) {
    display: flex;
    justify-content: center;
  }
}
.top-sort-arrow {
  right: 0;
  top: -1px;
}
.table-row {
  @media (max-width: theme('screens.md')) {
    flex: 1 0 auto;
    box-sizing: border-box;
    flex-wrap: wrap;
    padding-bottom: 8px;
    padding-bottom: 16px;
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }
}
.bottom-shadow {
  @media (min-width: theme('screens.md')) {
    box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.22);
    transform: scale(1);
    @apply tw-pr-11;
  }
}
.table-row td:first-child {
  padding-left: 25px;
  @media (max-width: theme('screens.md')) {
    padding-left: 16px;
    background-color: theme('colors.blue.100');
    font-weight: bold;
  }
}
.expanded-table-row {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
}
.table-row td:last-child {
  padding-right: 45px;
  @media (max-width: theme('screens.md')) {
    /* padding-right: 16px; */
  }
}
.table-row td.extra-info:first-child::before {
  content: '';
  height: 8px;
  width: 8px;
  background-color: theme('colors.orange.700');
  border-radius: 100%;
  position: absolute;
  bottom: 23px;
  left: 15px;
  z-index: 9;
  display: flex;
  @media (max-width: theme('screens.md')) {
    right: 20px;
    left: auto;
    bottom: 19px;
  }
}
.orange-badge {
  @apply tw-bg-orange-300;
  @apply tw-text-orange-700;
  @apply tw-rounded-md;
  @apply tw-mr-4;
  @apply tw-text-xs;
  @apply tw-pl-2;
  @apply tw-pr-8;
  @apply tw-py-1;
  @apply tw-relative;
}

tr:not(.mobile-full-view) .clicks_col--bookings,
tr:not(.mobile-full-view) .clicks_col--revenue,
tr:not(.mobile-full-view) .clicks_col--cpa,
tr:not(.mobile-full-view) .clicks_col--abv,
tr:not(.mobile-full-view) .clicks_col--costs,
tr:not(.mobile-full-view) .clicks_col--unavailability,
tr:not(.mobile-full-view) .clicks_col--bookingRefundedRevenue,
tr:not(.mobile-full-view) .clicks_col--cancelledBookings,
tr:not(.mobile-full-view) .revenue_col--clicks,
tr:not(.mobile-full-view) .revenue_col--cpa,
tr:not(.mobile-full-view) .revenue_col--cpc,
tr:not(.mobile-full-view) .revenue_col--costs,
tr:not(.mobile-full-view) .revenue_col--unavailability,
tr:not(.mobile-full-view) .revenue_col--bookingRefundedRevenue,
tr:not(.mobile-full-view) .revenue_col--cancelledBookings,
tr:not(.mobile-full-view) .commissionCost_col--clicks,
tr:not(.mobile-full-view) .commissionCost_col--cpa,
tr:not(.mobile-full-view) .commissionCost_col--revenue,
tr:not(.mobile-full-view) .commissionCost_col--cpc,
tr:not(.mobile-full-view) .commissionCost_col--costs,
tr:not(.mobile-full-view) .commissionCost_col--unavailability,
tr:not(.mobile-full-view) .commissionCost_col--bookingRefundedRevenue,
tr:not(.mobile-full-view) .commissionCost_col--cancelledBookings,
tr:not(.mobile-full-view) .cpa_col--clicks,
tr:not(.mobile-full-view) .cpa_col--bookings,
tr:not(.mobile-full-view) .cpa_col--cpc,
tr:not(.mobile-full-view) .cpa_col--abv,
tr:not(.mobile-full-view) .cpa_col--costs,
tr:not(.mobile-full-view) .cpa_col--unavailability,
tr:not(.mobile-full-view) .cpa_col--bookingRefundedRevenue,
tr:not(.mobile-full-view) .cpa_col--cancelledBookings,
tr:not(.extra-info-row) .clicks_col--cancelledBookings,
tr:not(.extra-info-row) .clicks_col--bookingRefundedRevenue,
tr:not(.extra-info-row) .cpa_col--cancelledBookings,
tr:not(.extra-info-row) .cpa_col--bookingRefundedRevenue,
tr:not(.extra-info-row) .commissionCost_col--cancelledBookings,
tr:not(.extra-info-row) .commissionCost_col--bookingRefundedRevenue,
tr:not(.extra-info-row) .revenue_col--cancelledBookings,
tr:not(.extra-info-row) .revenue_col--bookingRefundedRevenue {
  @media (max-width: theme('screens.md')) {
    display: none;
  }
}

tr.extra-info-row .clicks_col--cancelledBookings,
tr.extra-info-row .cpa_col--cancelledBookings,
tr.extra-info-row .commissionCost_col--cancelledBookings,
tr.extra-info-row .revenue_col--cancelledBookings {
  @apply tw-text-orange-700;
}

.show-button:last-child {
  @media (max-width: theme('screens.md')) {
    margin-bottom: 24px;
  }
}

tr.extra-info-row .clicks_col--bookingRefundedRevenue,
tr.extra-info-row .cpa_col--bookingRefundedRevenue,
tr.extra-info-row .commissionCost_col--bookingRefundedRevenue,
tr.extra-info-row .revenue_col--bookingRefundedRevenue {
  @apply tw-text-blue-800 tw-font-bold;
}

.orange-badge span.badge-value {
  @apply tw-bg-orange-700;
  @apply tw-text-white;
  @apply tw-rounded-md;
  @apply tw-absolute;
  @apply tw-inset-y-0;
  @apply tw-right-0;
  @apply tw-px-2;
  line-height: 1.43rem;
}
.cell span,
.unavailablity-row {
  @media (max-width: theme('screens.md')) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.unavailablity-row {
  @media (max-width: theme('screens.md')) {
    width: 96%;
  }
}
.transform-open {
  transform: translateX(4px) translateY(1px);
}
.transform-closed {
  transform: translateX(-1px) translateY(-5px) rotate(180deg);
}
</style>
