<template>
  <section class="tw-flex tw-py-24 tw-justify-between tw-bg-gray-100 tw-flex-row">
    <!-- Web version -->
    <div
      class="tw-hidden lg:tw-flex tw-items-center tw-px-10 tw-justify-center tw-flex-col tw-max-w-screen-xxl tw-mx-auto"
    >
      <h3 class="tw-text-[50px] tw-leading-[50px] tw-text-blue-800 tw-font-bold tw-mb-3">
        {{ $t('rc_mp_campaign_header') }}
      </h3>
      <p class="tw-text-base tw-text-gray-900 tw-text-center tw-w-2/3">
        {{ $t('rc_mp_campaign_text') }}
      </p>
      <div class="tw-flex tw-flex-row tw-mt-12 tw-mb-10 tw-w-full tw-justify-center">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="box tw-bg-white tw-rounded-lg tw-w-[305px] tw-flex tw-flex-col tw-min-h-[380px] tw-p-6 card-shadow"
        >
          <img :src="item.image" :alt="$t(item.title)" class="tw-my-5 tw-mx-auto" />
          <h4 class="tw-mb-1 tw-font-bold tw-text-xl tw-text-gray-900">{{ $t(item.title) }}</h4>
          <p class="tw-text-gray-900 tw-text-sm tw-flex-1">{{ $t(item.text) }}</p>
          <MainButtonOutline
            class="tw-font-normal tw-mt-4 tw-w-full hover:tw-border-blue-100"
            @click="showModal(item.type)"
            >{{ $t(item.button) }}</MainButtonOutline
          >
        </div>
      </div>

      <p class="tw-text-sm tw-text-gray-900 tw-text-center">
        {{ $t('rc_mp_campaign_footnote') }}
      </p>
    </div>

    <!-- Mobile version -->
    <div class="lg:tw-hidden tw-flex tw-flex-col">
      <h3 class="tw-text-[48px] tw-leading-[50px] tw-text-blue-800 tw-font-bold tw-mb-3 tw-px-10 tw-text-center">
        {{ $t('rc_mp_campaign_header') }}
      </h3>
      <p class="tw-text-base tw-text-gray-900 tw-px-10 tw-text-center">
        {{ $t('rc_mp_campaign_text') }}
      </p>

      <div class="carousel-container tw-my-10">
        <transition-group class="carousel" tag="div" :name="transitionName">
          <div
            v-for="item in data"
            :key="`key_${item.title}`"
            class="slide tw-bg-white tw-rounded-lg tw-mx-[17px] tw-flex tw-flex-col tw-h-[380px] tw-p-6 tw-mb-3 card-shadow"
          >
            <img :src="item.image" :alt="$t(item.title)" class="tw-my-5 tw-mx-auto" />
            <h4 class="tw-mb-1 tw-font-bold tw-text-xl tw-text-gray-900">{{ $t(item.title) }}</h4>
            <p class="tw-text-gray-900 tw-text-sm tw-flex-1">{{ $t(item.text) }}</p>
            <MainButtonOutline class="tw-font-normal tw-mt-4 tw-w-full" @click="showModal(item.type)">{{
              $t(item.button)
            }}</MainButtonOutline>
          </div>
        </transition-group>
      </div>

      <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="tw-w-[12px] tw-mx-[5px] tw-h-[12px] tw-rounded-full tw-mb-6"
          :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
        />
      </div>
      <p class="tw-text-sm tw-text-gray-900 tw-text-center">
        {{ $t('rc_mp_campaign_footnote') }}
      </p>
    </div>
    <ProductPageCampaignModal
      :isOpen="openModal"
      :campaignType="campaignType"
      :campaign="campaigns[campaignType]"
      :closeModal="hideModal"
      @onRegisterClick="onAuthorize('signup')"
    />
  </section>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import MainButtonOutline from '@/components/base/buttons/MainButtonOutline.vue'
import ProductPageCampaignModal from '@/components/productPage/ProductPageCampaignModal.vue'
import TouchEvent from '@/utils/touchEvents.js'

const PricingIcon1 = new URL('/src/assets/img/rate-connect-landing/Pricing_Icons1.svg', import.meta.url)
const PricingIcon3 = new URL('/src/assets/img/rate-connect-landing/Pricing_Icons3.svg', import.meta.url)

const $store = useStore()
const $route = useRoute()

const touchEvent = ref(null)
const current = ref(0)
const transitionName = ref('swipe')
const campaignType = ref(null)
const openModal = ref(false)
const data = ref([
  {
    image: PricingIcon1,
    title: 'rc_mp_campaign_cpc',
    text: 'rc_mp_campaign_cpc_text',
    button: 'rc_mp_campaign_cpc_cta',
    type: 'cpc',
  },
  {
    image: PricingIcon3,
    title: 'rc_mp_campaign_ncpa',
    text: 'rc_mp_campaign_ncpa_text',
    button: 'rc_mp_campaign_ncpa_cta',
    type: 'ncpa',
  },
])
const campaigns = ref({
  cpc: {
    image: PricingIcon1,
    title: 'rc_mp_campaign_cpc',
    subtitle: 'rc_mp_cpc_text',
    setup_title: 'rc_mp_cpc_setup_header',
    setup_text: 'rc_mp_cpc_setup_subtext',
    setup_final: 'rc_mp_cpc_setup_final',
    setup_hint: 'rc_mp_cpc_onboarding_hint',
    setup_cta: 'rc_mp_cpc_cta',
    setup: 'rc_mp_cpc_setup_steps',
    cards: [
      { icon: 'settings', text: 'rc_mp_cpc_benefit_1' },
      { icon: 'global', text: 'rc_mp_cpc_benefit_2' },
      { icon: 'rate-insights', text: 'rc_mp_cpc_benefit_3' },
    ],
    faq: [
      'rc_mp_cpc_faq_1',
      'rc_mp_cpc_faq_2',
      'rc_mp_cpc_faq_3',
      'rc_mp_cpc_faq_4',
      'rc_mp_cpc_faq_5',
      'rc_mp_cpc_faq_6',
    ],
  },
  ncpa: {
    image: PricingIcon3,
    title: 'rc_mp_campaign_ncpa',
    subtitle: 'rc_mp_ncpa_text',
    setup_title: 'rc_mp_ncpa_setup_header',
    setup_text: 'rc_mp_ncpa_setup_subtext',
    setup_final: 'rc_mp_ncpa_setup_final',
    setup_hint: 'rc_mp_ncpa_onboarding_hint',
    setup_cta: 'rc_mp_ncpa_cta',
    setup: 'rc_mp_ncpa_setup_steps',
    cards: [
      { icon: 'settings', text: 'rc_mp_ncpa_benfit_1' },
      { icon: 'global', text: 'rc_mp_ncpa_benefit_2' },
      { icon: 'rate-insights', text: 'rc_mp_ncpa_benefit_3' },
    ],
    faq: ['rc_mp_ncpa_faq_1', 'rc_mp_ncpa_faq_2', 'rc_mp_ncpa_faq_3', 'rc_mp_ncpa_faq_4'],
  },
})

const locale = computed(() => $store.locale)
onMounted(() => {
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})

const showModal = type => {
  campaignType.value = type
  openModal.value = true
}
const hideModal = () => {
  campaignType.value = null
  openModal.value = false
}
const next = () => {
  current.value = current.value === 1 ? 0 : current.value + 1
  const first = data.value.shift()
  data.value = data.value.concat(first)
}
const previous = () => {
  const last = data.value.pop()
  current.value = current.value === 0 ? 1 : current.value - 1
  data.value = [last].concat(data.value)
}
const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    previous()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
const authorize = () => $store.dispatch('session/authorize')
const onAuthorize = async (type = 'login') => {
  await authorize({
    type,
    stateParams: {
      redirectFrom: $route.query.redirectFrom,
    },
    locale: locale.value?.language,
  })
}
</script>
<style scoped>
.card-shadow {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.carousel {
  @apply tw-flex tw-justify-start tw-items-center tw-overflow-hidden;
  width: 100vw;
}

.slide {
  @apply tw-flex tw-justify-center tw-mx-[17px];
  flex: 0 0 92%;
  transition: all 0.3s;
}
.box {
  @apply tw-mx-[25px];
}
.box:first-child {
  margin-left: 0;
}
.box:last-child {
  margin-right: 0;
}
</style>
