<template>
  <div class="tw-relative">
    <BaseCard
      v-if="isMenuOpen"
      v-click-outside="onClickaway"
      no-padding
      class="tw-absolute tw-z-[10] tw-flex mobile-menu-width tw-mx-[20px] menu-card-mobile tw-rounded-xl"
    >
      <div class="tw-p-[15px] tw-w-full">
        <div class="tw-flex tw-w-full tw-flex-wrap">
          <ul
            id="tabs"
            class="tw-flex tw-flex-row tw-bg-gray-100 tw-rounded-sm tw-items-center tw-overflow-scroll tw-w-full"
            role="tablist"
            data-te-nav-ref
          >
            <li
              class="text-nowrap tw-text-center tw-py-[15px] tw-px-[10px] hover:tw-cursor-pointer"
              :class="{ selected: selectedTab === 'account-features' }"
              @click="toggleTab('account-features')"
              @keydown.enter="toggleTab('account-features')"
            >
              {{ $t('navigation_menu_account_features') }}
            </li>
            <li
              class="text-nowrap tw-text-center tw-py-[15px] tw-px-[10px] hover:tw-cursor-pointer"
              :class="{ selected: selectedTab === 'solutions' }"
              @click="toggleTab('solutions')"
              @keydown.enter="toggleTab('solutions')"
            >
              {{ $t('navigation_menu_our_solutions') }}
            </li>
            <li
              class="text-nowrap tw-text-center tw-py-[15px] tw-px-[10px] hover:tw-cursor-pointer"
              :class="{ selected: selectedTab === 'support-center' }"
              @click="toggleTab('support-center')"
              @keydown.enter="toggleTab('support-center')"
            >
              {{ $t('navigation_menu_support_center') }}
            </li>
            <li
              class="text-nowrap tw-text-center tw-py-[15px] tw-px-[10px] hover:tw-cursor-pointer"
              :class="{ selected: selectedTab === 'contact-us' }"
              @click="toggleTab('contact-us')"
              @keydown.enter="toggleTab('contact-us')"
            >
              {{ $t('navigation_menu_contact_us') }}
            </li>
          </ul>
        </div>
        <div>
          <div v-show="selectedTab === 'account-features'" class="tw-mt-7">
            <ul>
              <li v-for="item in accountFeaturesLinks" :key="item.id" class="tw-py-3">
                <a :href="item.url" class="hover:tw-no-underline tw-flex tw-items-start tw-justify-between">
                  <div class="tw-flex">
                    <img class="tw-h-[34px] tw-m-1.5" :src="`${item.iconHover}`" :alt="$t(item.label)" />
                    <div class="tw-ml-2">
                      <p class="tw-text-2xl tw-font-bold" v-html="$t(item.label)" />
                      <p class="tw-text-gray-700" v-html="$t(item.header)" />
                    </div>
                  </div>
                  <BaseIcon
                    icon-name="chevron-right"
                    :height="24"
                    :width="24"
                    :viewbox-height="24"
                    :viewbox-width="24"
                    icon-color="#007CC2"
                    class="tw-bg-gray-100 tw-rounded-md tw-m-1.5"
                  >
                    <ChevronRight />
                  </BaseIcon>
                </a>
              </li>
            </ul>
          </div>
          <div v-show="selectedTab === 'solutions'" class="tw-mt-7">
            <ul>
              <li v-for="item in solutionsLinks" :key="item.id" class="tw-py-3">
                <a :href="item.url" class="hover:tw-no-underline tw-flex tw-items-start tw-justify-between">
                  <div class="tw-flex">
                    <img class="tw-h-[34px] tw-m-1.5" :src="`${item.iconHover}`" :alt="$t(item.label)" />
                    <div class="tw-ml-2">
                      <p class="tw-text-2xl tw-font-bold" v-html="$t(item.label)" />
                      <p class="tw-text-gray-700" v-html="$t(item.header)" />
                    </div>
                  </div>
                  <BaseIcon
                    icon-name="chevron-right"
                    :height="24"
                    :width="24"
                    :viewbox-height="24"
                    :viewbox-width="24"
                    icon-color="#007CC2"
                    class="tw-bg-gray-100 tw-rounded-md tw-m-1.5"
                  >
                    <ChevronRight />
                  </BaseIcon>
                </a>
              </li>
            </ul>
          </div>
          <div v-show="selectedTab === 'support-center'" class="tw-mt-7">
            <p class="tw-text-2xl tw-font-bold tw-mb-1">{{ $t('navigation_menu_support_center_header') }}</p>
            <p class="tw-text-gray-700">{{ $t('navigation_menu_support_center_subtext') }}</p>
            <a :href="supportCenterUrl" class="tw-flex tw-mt-7 tw-text-blue-800 tw-items-center">
              {{ $t('navigation_menu_support_center_cta') }}
              <BaseIcon
                icon-name="chevron-right"
                :height="24"
                :width="24"
                :viewbox-height="24"
                :viewbox-width="24"
                icon-color="#007CC2"
                class="tw-bg-gray-100 tw-rounded-md tw-m-1.5 tw-mr-2.5"
              >
                <ChevronRight />
              </BaseIcon>
            </a>
          </div>
          <div v-show="selectedTab === 'contact-us'" class="tw-mt-7">
            <p class="tw-text-2xl tw-font-bold tw-mb-1">{{ $t('navigation_menu_contact_us_header') }}</p>
            <p class="tw-text-gray-700">{{ $t('navigation_menu_contact_us_subtext') }}</p>
            <p class="tw-flex tw-mt-7 tw-text-blue-800 tw-items-center">
              <span :href="contactUsUrl" target="_blank" v-html="$t('navigation_menu_contact_us_cta')" />
              <BaseIcon
                icon-name="chevron-right"
                :height="24"
                :width="24"
                :viewbox-height="24"
                :viewbox-width="24"
                icon-color="#007CC2"
                class="tw-bg-gray-100 tw-rounded-md tw-m-1.5 tw-mr-2.5"
              >
                <ChevronRight />
              </BaseIcon>
            </p>
          </div>
        </div>
        <div class="tw-flex tw-px-[15px] tw-mt-5 sign-buttons">
          <div class="tw-flex tw-w-1/2 tw-flex-row tw-justify-center tw-m-auto">
            <BaseButton
              size="noPadding"
              class="tw-text-lg tw-flex tw-items-center tw-m-auto sign-btn"
              @click="() => onAuthorize('login')"
              @keydown.enter="() => onAuthorize('login')"
            >
              <BaseIcon
                width="24"
                height="24"
                viewbox-height="24"
                viewbox-width="24"
                class="tw-text-blue-800 tw-shrink-0"
              >
                <LockClosedIcon />
              </BaseIcon>
              <span class="tw-ml-2 tw-leading-5 tw-w-full">{{ $t('tbs_mp_cta_log_in') }}</span>
            </BaseButton>
          </div>
          <MainButton
            size="medium"
            no-padding
            class="tw-px-6 tw-flex tw-items-center tw-w-1/2 tw-justify-center sign-btn"
            @click="() => onAuthorize('signup')"
            @keydown.enter="() => onAuthorize('signup')"
          >
            {{ $t('tbs_mp_cta_register_mobile') }}
          </MainButton>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { getSupportCenterUrl } from '@/utils/Utility.js'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import LockClosedIcon from '@/components/icons/LockClosedIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

defineProps({
  solutionsLinks: Array,
  accountFeaturesLinks: Array,
  isMenuOpen: Boolean,
  onClickaway: Function,
})
const selectedTab = ref('solutions')
const store = useStore()
const route = useRoute()
const locale = computed(() => store.state.locale.language)
const supportCenterUrl = computed(() => getSupportCenterUrl(locale.value, 'supportCentre'))
const contactUsUrl = computed(() => getSupportCenterUrl(locale.value, 'contactUs'))

const toggleTab = name => {
  selectedTab.value = name
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
<style scoped>
.text-nowrap {
  text-wrap: nowrap;
}
.selected {
  @apply tw-font-bold tw-text-blue-800 tw-border-b-[4px] tw-border-blue-800;
}
.menu-card-mobile {
  top: 15px;
}
.mobile-menu-width {
  width: calc(100% - 40px);
}
.sign-buttons {
  @media (min-width: 541px) {
    display: none;
  }
  @media (max-width: 360px) {
    flex-direction: column;
  }
}
.sign-btn {
  @media (max-width: 360px) {
    @apply tw-w-full;
    margin-top: 10px;
  }
}
</style>
