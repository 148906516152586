<template>
  <div class="tw-flex tw-items-center tw-justify-end">
    <p for="toggle" class="tw-text-base tw-font-bold tw-text-gray-900 tw-mr-2">{{ $t('kpi_chart_views') }}</p>
    <label class="tw-relative tw-inline-block tw-w-[60px] tw-h-[34px] tw-mb-0">
      <input
        type="checkbox"
        :checked="isEnabled"
        class="tw-opacity-0 tw-h-0 tw-w-0 focus:tw-outline-none"
        @change="e => toggleImpressions(e.target.checked)"
      />
      <span class="slider round tw-absolute tw-inset-0 tw-cursor-pointer tw-bg-gray-300"></span>
    </label>
  </div>
</template>
<script setup>
defineProps({
  isEnabled: Boolean,
})
const emit = defineEmits(['toggleImpressions'])
const toggleImpressions = value => {
  emit('toggleImpressions', value)
}
</script>
<style scoped>
.slider {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: theme('colors.white');
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 3px 1px 0px #0000000f;
  box-shadow: 0px 3px 8px 0px #00000026;
}

input:checked + .slider {
  background-color: theme('colors.blue.700');
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
