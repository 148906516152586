<template>
  <div ref="root">
    <UiTooltip tooltip-position="top" theme="material" :label="`Timeframe: ${selectedValueLabel}`">
      <div
        @click="toggleDropdown"
        class="tw-relative tw-cursor-pointer tw-border tw-rounded-lg tw-flex tw-items-center tw-px-3 tw-py-2 tw-pr-8 tw-border-gray-500 tw-text-gray-900"
      >
        <span class="tw-truncate tw-max-w-[220px]"
          >{{ $t('Timeframe:') }} <strong class="tw-capitalize">{{ selectedValueLabel }}</strong></span
        >
        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          class="tw-absolute"
          :class="show ? 'arrow-dropdown-open' : 'arrow-dropdown'"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </UiTooltip>
    <div class="tw-relative tw-top-[5px]" v-if="show">
      <div class="tw-absolute tw-min-h-[50px] tw-min-w-full tw-w-auto tw-rounded tw-bg-white dropdown-card tw-z-[10]">
        <ul>
          <li
            v-for="option in timeFrameOptions"
            :key="option.value"
            @click="onSelect(option.value)"
            class="tw-cursor-pointer hover:tw-bg-blue-100 hover:tw-text-blue-800 tw-py-2 tw-px-3 nowrap"
          >
            {{ $t(option.label) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onDeactivated, computed } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'

const props = defineProps({
  selectedValue: {
    type: String,
    required: true,
    default: '30',
  },
})

const emit = defineEmits(['onSelect'])

const timeFrameOptions = [
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 90 Days', value: '90' },
]

const selectedValueLabel = computed(() => timeFrameOptions.find(option => option.value === props.selectedValue)?.label)

const root = ref(null)
const show = ref(false)

const toggleDropdown = () => {
  show.value = !show.value
}

const onSelect = option => {
  emit('onSelect', option)
  toggleDropdown()
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onDeactivated(() => {
  document.removeEventListener('click', handleClickOutside)
})
const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    show.value = false
  }
}
</script>

<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
.arrow-dropdown-open {
  top: 50%;
  transform: translateY(-45%) rotate(270deg);
  right: 6px;
  pointer-events: none;
}
.dropdown-card {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.nowrap {
  text-wrap: nowrap;
}
</style>
