<template>
  <img :src="url" alt="icon" class="app-icon" />
</template>

<script>
import defaultAppIcon from '@/assets/img/default-app-icon.png'

export default {
  name: 'AppIcon',
  props: {
    app: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const url = props.app.icon_file_id
      ? `${import.meta.env.VITE_APP_MANAGEMENT_API_URL}/files/${props.app.icon_file_id}/download`
      : defaultAppIcon

    return { url }
  },
}
</script>
