<template>
  <form class="md:tw-relative md:tw-w-64 tw-w-48">
    <div
      v-if="isOpen"
      aria-label="overlay"
      class="tw-fixed tw-bottom-0 tw-right-0 tw-top-0 tw-z-30 tw-w-full tw-h-full"
    />
    <fieldset
      v-if="isOpen"
      v-click-outside="closeSwitcher"
      class="tw-z-50 tw-bg-white tw-border tw-border-solid tw-border-gray-200 tw-rounded-xl tw-absolute tw-left-0 tw-max-h-64px md:tw-w-nav md:tw-ml-2 md:tw-top-auto tw-m-0 tw-top-0 tw-w-full"
    >
      <div class="submenu tw-rounded-xl">
        <ul class="tw-flex tw-items-center tw-border-b tw-border-gray-300">
          <li class="tw-inline-block">
            <button
              id="all"
              :class="isSelectedTabAll ? 'tw-border-blue-800 tw-text-blue-800 tw-font-bold' : 'tw-border-transparent'"
              class="tw-outline-none focus:tw-outline-none tw-text-sm tw-p-4 tw-border-b-4 tw-duration-400 tw-ease-in-out tw-transition-border hover:tw-border-blue-800"
              @click.prevent="onTabClick"
              @keydown.prevent="onTabClick"
            >
              {{ 'All' }}
            </button>
          </li>
          <li class="tw-inline-block">
            <button
              id="org"
              :class="
                isSelectedTabOrg
                  ? 'tw-border-blue-800 tw-text-blue-800 tw-border-b-4 tw-font-bold'
                  : 'tw-border-transparent'
              "
              class="tw-outline-none focus:tw-outline-none tw-text-sm tw-border-b-4 tw-p-4 tw-duration-400 tw-ease-in-out tw-transition-border hover:tw-border-blue-800"
              @click.prevent="onTabClick"
              @keydown.prevent="onTabClick"
            >
              {{ $t('accommodationSwitcher.headerOrganizations') }}
            </button>
          </li>
        </ul>
        <div>
          <div class="tw-flex tw-justify-between">
            <div :class="{ 'tw-hidden': isSelectedTabAll }" class="tw-w-1/2 tw-border-r tw-border-gray-300">
              <OrganisationList
                class="tw-flex-1"
                @organisation-in-switcher-click="onOrganisationInSwitcherClick"
                :selected-organisation-in-switcher="selectedOrganisationInSwitcher"
              />
            </div>
            <div :class="{ 'tw-w-full': isSelectedTabAll, 'tw-w-1/2': isSelectedTabOrg }">
              <AccommodationSwitcherList
                :accommodations="selectedOrganisationInSwitcherAccommodationsData"
                class="tw-flex-1"
                @accommodationSelected="onAccommodationSelected"
                :enableSearch="isSelectedTabAll"
              />
            </div>
          </div>
          <div v-if="isSelectedTabOrg" class="sm:tw-flex">
            <router-link
              to="/organisations"
              class="change-organisation tw-block tw-bg-white hover:tw-bg-blue-200 tw-py-4 tw-px-6 tw-text-blue-800 hover:tw-text-blue-800 hover:tw-no-underline tw-border-t tw-border-gray-200 sm:tw-w-1/2"
              :class="{ 'tw-pointer-events-none tw-opacity-70': isOnboardingAssignments }"
              @click="closeSwitcher"
            >
              <p class="tw-text-sm tw-flex tw-items-center">
                <span class="tw-mr-2 tw-pb-1 tw-text-3xl tw-leading-none">+</span>
                <span class="tw-font-bold">{{ $t('accommodationSwitcher.manageOrganizations') }}</span>
              </p>
            </router-link>
            <router-link
              to="/property-assignment"
              class="manage-property tw-block tw-items-center tw-bg-white hover:tw-bg-blue-200 tw-py-4 tw-px-6 tw-text-blue-800 hover:tw-text-blue-800 hover:tw-no-underline tw-border-t tw-border-gray-200 sm:tw-w-1/2"
              :class="{ 'tw-pointer-events-none tw-opacity-70': isOnboardingAssignments }"
              @click="closeSwitcher"
            >
              <p class="tw-text-sm tw-flex tw-items-center">
                <span class="tw-mr-2 tw-pb-1 tw-text-3xl tw-leading-none">+</span>
                <span class="tw-font-bold">{{ $t('add_new_property_cta') }}</span>
              </p>
            </router-link>
          </div>
          <router-link
            v-else
            to="/property-assignment"
            class="manage-property-single tw-block tw-items-center tw-bg-white hover:tw-bg-blue-200 tw-py-4 tw-px-6 tw-text-blue-800 hover:tw-text-blue-800 hover:tw-no-underline tw-border-t tw-border-gray-200"
            :class="{ 'tw-pointer-events-none tw-opacity-70': isOnboardingAssignments }"
            @click="closeSwitcher"
          >
            <p class="tw-text-sm tw-flex tw-items-center">
              <span class="tw-mr-2 tw-pb-1 tw-text-3xl tw-leading-none">+</span>
              <span class="tw-font-bold">{{ $t('add_new_property_cta') }}</span>
            </p>
          </router-link>
        </div>
      </div>
    </fieldset>
    <button
      type="button"
      class="tw-relative tw-outline-none tw-z-20 tw-overflow-hidden tw-p-2 tw-border-l tw-border-gray-300 focus:tw-outline-none tw-w-full"
      @click.stop="toggleSwitcher"
      @keydown.stop="toggleSwitcher"
      data-testid="header-accommodation-switcher-button"
    >
      <div v-if="selectedAccommodation" class="tw-flex tw-items-center tw-justify-start tw-text-left tw-w-full">
        <AccommodationImage
          :image="selectedAccommodation.mainImageUrls"
          class="tw-block tw-min-h-full tw-h-8 tw-w-8 tw-shrink-0"
        />
        <div class="tw-flex tw-flex-col tw-ml-2 tw-mr-1 tw-truncate tw-w-full">
          <strong class="tw-z-10 tw-text-sm tw-block tw-leading-tight tw-truncate">{{
            selectedAccommodation.name
          }}</strong>
          <span class="tw-text-xxs tw-leading-none tw-text-gray-700">
            <strong>ID:</strong> {{ selectedAccommodationId }}
          </span>
        </div>
        <div
          class="tw-h-8 tw-rounded-full tw-w-8 tw-shrink-0 tw-relative tw-bg-gray-200 :tw-bg-gray-300 tw-right-0 tw-transition-colors tw-ease-in-out tw-duration-200"
        >
          <div
            :class="{
              'down-triangle': !isOpen,
              'right-triangle': isOpen,
            }"
            class="tw-z-0 tw-h-2 tw-w-2 tw-absolute tw-right-0"
          ></div>
        </div>
      </div>
      <div v-else class="tw-w-full tw-flex tw-items-center tw-justify-start tw-text-left">
        <div
          :style="`background-image: url('/home/property-image-placeholder.svg')`"
          class="tw-rounded-md tw-h-8 tw-w-8 tw-shrink-0 tw-bg-gray-300 tw-shadow tw-bg-center tw-bg-cover"
        />
        <div class="tw-flex tw-flex-col tw-mr-1 tw-truncate tw-w-full">
          <strong class="tw-ml-2 tw-truncate tw-z-10 tw-text-sm">
            {{ $t('accommodationSwitcher.noPropertySelected') }}
          </strong>
        </div>
        <div
          class="tw-h-8 tw-rounded-full tw-w-8 tw-shrink-0 tw-relative tw-bg-gray-200 :tw-bg-gray-300 tw-right-0 tw-transition-colors tw-ease-in-out tw-duration-200"
        >
          <div
            :class="{
              'down-triangle': !isOpen,
              'right-triangle': isOpen,
            }"
            class="tw-z-0 tw-h-0 tw-w-0 tw-absolute"
          ></div>
        </div>
      </div>
    </button>
  </form>
</template>
<script setup>
import { ref, computed, toRaw, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'

import { events } from '@/constants/events'
import { useEventsBus } from '@/composables/useEventBus.js'
import AccommodationImage from '@/components/dashboard/accommodation/AccommodationImage.vue'
import AccommodationSwitcherList from '@/components/dashboard/accommodation/AccommodationSwitcherList.vue'
import OrganisationList from '@/components/dashboard/accommodation/OrganisationList.vue'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import useAccommodations from '@/composables/useAccommodations'

defineProps({
  isMobile: {
    type: Boolean,
    required: true,
  },
})

const isOpen = ref(false)
const selectedTab = ref('org')

const { busEvent } = useEventsBus()
const store = useStore()
const { switchAccommodation } = useAccommodations()

const isOnboardingAssignments = computed(() => store.state.session.isOnboardingAssignments)
const selectedOrganisation = computed(() => store.state.session.selectedOrganisation)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const selectedOrganisationInSwitcher = ref(null)
const { accommodationsData: selectedOrganisationAccommodationsData } = useAccommodationsByOrgId(
  selectedOrganisation.value?.id
)
const selectedOrganisationInSwitcherId = computed(() => selectedOrganisationInSwitcher.value?.id)
const { accommodationsData: selectedOrganisationInSwitcherAccommodationsData } = useAccommodationsByOrgId(
  selectedOrganisationInSwitcherId
)
const selectedAccommodation = computed(() =>
  selectedOrganisationAccommodationsData.value?.find(
    accommodation => accommodation.accommodationId === selectedAccommodationId.value
  )
)
const isSelectedTabOrg = computed(() => selectedTab.value === 'org')
const isSelectedTabAll = computed(() => selectedTab.value === 'all')

const onOrganisationInSwitcherClick = organisation => {
  if (organisation) selectedOrganisationInSwitcher.value = organisation
}

const onTabClick = e => {
  selectedTab.value = e.target.id
}

const toggleSwitcher = () => {
  isOpen.value = !isOpen.value
}

const closeSwitcher = () => {
  isOpen.value = false
}

const onAccommodationSelected = async newAccommodation => {
  closeSwitcher()
  await switchAccommodation(newAccommodation, selectedOrganisationInSwitcher.value)
}

const selectedAssignment = computed(() => {
  if (!selectedAccommodationId) {
    return {}
  } else {
    return {
      accommodationId: selectedAccommodationId.value,
      status: 'active',
      organizationId: selectedOrganisationId.value,
    }
  }
})

watch(selectedAccommodationId, newSelectedAccommodationId => {
  if (!newSelectedAccommodationId) return
  busEvent('app.emit.event', {
    event: events.outgoing.ACCOMMODATION_SELECTED,
    payload: {
      accommodation: toRaw(selectedAccommodation.value),
      assignment: toRaw(selectedAssignment.value),
    },
  })
})

onBeforeMount(() => {
  selectedOrganisationInSwitcher.value = selectedOrganisation.value
})
</script>

<style scoped>
.submenu {
  box-shadow: 0px 2px 4px 0px rgba(63, 87, 189, 0.1), 0px 2px 10px 0px rgba(108, 112, 122, 0.15);
}
.right-arrow {
  @apply tw-bg-white;
  @apply tw-w-6;
  @apply tw-h-6;

  transform: rotate(45deg);
  left: calc(theme('spacing.2') * -1);
  top: theme('spacing.4');
}

.top-arrow {
  @apply tw-bg-white;
  @apply tw-w-6;
  @apply tw-h-6;

  transform: rotate(45deg);
  top: calc(theme('spacing.2') * -1);
  left: theme('spacing.4');
}

.down-triangle {
  @apply tw-border-l-4;
  @apply tw-border-r-4;
  @apply tw-border-t-4;

  top: 15px;
  left: 12px;

  /* right: theme('spacing.4'); */

  border-left-color: theme('colors.transparent');
  border-right-color: theme('colors.transparent');
  border-top-color: theme('colors.black');
}

.right-triangle {
  @apply tw-border-l-4;
  @apply tw-border-t-4;
  @apply tw-border-b-4;

  right: theme('spacing.4');

  border-bottom-color: theme('colors.transparent');
  border-top-color: theme('colors.transparent');
  border-left-color: theme('colors.black');
}

.arrow-left {
  @apply tw-border-l-2;
  @apply tw-border-b-2;
  @apply tw-block;
  @apply tw-w-3;
  @apply tw-h-3;
  @apply tw-border-gray-500;
  @apply tw-transform;
  @apply tw-rotate-45;
  @apply tw-mr-2;
}

.fieldset-mobile {
  max-height: calc(100vh - 64px);
}

.full-height {
  max-height: calc(100% - 48px);
}

/**
 * tw-outline-none does an `outline: 0` which do not remove the outline on buttons.
 */
button.tw-outline-none {
  outline: none;
}

.delayed-shadow {
  transition-delay: 0.3s;
}
.change-organisation {
  border-bottom-left-radius: 12px;
}
.manage-property {
  border-bottom-right-radius: 12px;
}
.manage-property-single {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
</style>
