<template>
  <p v-if="!paginatedMarkets || paginatedMarkets.length === 0" class="tw-my-12 tw-text-gray-700 tw-text-base">
    {{ $t('rc_no_search_results') }}
  </p>
  <ul class="tw-flex tw-flex-col tw-gap-3">
    <li v-for="{ marketCode, marketName, domain, estimatedClicks } in paginatedMarkets" :key="marketCode">
      <div class="tw-border tw-rounded-xl tw-border-gray-300 tw-py-3 md:tw-px-6 curr-markets-tile-grid">
        <MarketItem
          :market-name="$t(marketName)"
          :domain="domain"
          :market-code="marketCode"
          class="tw-ml-4 md:tw-m-0 market-item-container"
        />
        <MarketPotential
          :average-clicks="averageClicks"
          :estimated-clicks="estimatedClicks"
          class="tw-px-4 md:tw-p-0 market-potential-container"
        />
        <MainButton
          size="small"
          class="market-cta-container"
          @click="emit('onAddMarket', { marketCode, marketName: $t(marketName), status: 'active' })"
          >{{ $t('rc_activate') }}</MainButton
        >
      </div>
    </li>
  </ul>
  <BaseButton
    v-if="!isLastPage"
    class="tw-text-blue-800 tw-mt-3 hover:tw-underline"
    size="medium"
    @click="showMoreMarkets"
    >{{ $t('rc_markets_show_more') }}</BaseButton
  >
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import MarketPotential from '@/components/rateConnect/markets/MarketPotential.vue'
import MarketItem from '@/components/rateConnect/markets/MarketItem.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'

const { t } = useI18n()
const props = defineProps({
  availableMarkets: {
    type: Array,
    default: () => [],
  },
  searchFilterValue: {
    type: String,
    default: '',
  },
  paginationSize: {
    type: Number,
    default: 10,
  },
  averageClicks: {
    type: Number,
    default: null,
  },
})
const currentPage = ref(1)
const emit = defineEmits(['onAddMarket'])
const availableMarketsFilteredByCountry = computed(() => {
  return props.availableMarkets?.filter(({ marketName }) =>
    t(marketName)?.toLowerCase()?.includes(props.searchFilterValue?.toLowerCase())
  )
})
const paginatedMarkets = computed(() => {
  return availableMarketsFilteredByCountry.value?.slice?.(0, currentPage.value * props.paginationSize)
})
const isLastPage = computed(() => {
  return currentPage.value * props.paginationSize >= availableMarketsFilteredByCountry.value?.length
})

const showMoreMarkets = () => {
  currentPage.value = currentPage.value + 1
}
</script>

<style scoped>
.market-item-container {
  grid-area: item;
}

.market-potential-container {
  grid-area: pot;
  border-top: 1px solid theme('colors.gray.300');
  padding-top: 12px;
  margin-top: 12px;
  justify-content: center;
}

.market-cta-container {
  grid-area: cta;
  min-width: 100px;
}

@media (max-width: theme('screens.md')) {
  .market-cta-container {
    color: theme('colors.blue.800');
    background-color: transparent;
    border: none;
    font-size: 14px;
    justify-self: end;
  }
  .market-cta-container:hover,
  .market-cta-container:focus,
  .market-cta-container:active {
    background-color: transparent;
    color: theme('colors.blue.800');
    border: none;
  }
}

.curr-markets-tile-grid {
  display: grid;
  grid-template-areas:
    'item cta'
    'pot pot';
}

@media (min-width: theme('screens.md')) {
  .curr-markets-tile-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: 'item pot cta';
    align-items: center;
  }

  .market-potential-container {
    border: none;
    justify-self: center;
    padding: 0;
    margin: 0;
  }

  .market-cta-container {
    justify-self: end;
  }
}
</style>
