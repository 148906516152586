<template>
  <div class="tw-flex-col tw-flex" :class="{ open: activeAccordion === language }">
    <div class="tw-relative tw-w-full tw-flex hover:tw-cursor-pointer tw-justify-between tw-items-center">
      <div class="tw-w-full" @keydown.enter="openAccordion(language)" @click="openAccordion(language)">
        <span
          class="toggle-icon tw-h-8 tw-w-8 tw-cursor-pointer tw-relative"
          :class="{ active: activeAccordion === language }"
        ></span>
        {{ $t(`lang_${languageKey}`) }}
      </div>
      <div class="tw-flex tw-items-center">
        <a
          class="tw-text-sm tw-text-blue-700 hover:tw-text-blue-900 tw-text-center tw-cursor-pointer tw-transition-colors hover:tw-no-underline tw-duration-300"
          @click="toggleEditLanguage()"
          @keydown.enter="toggleEditLanguage()"
        >
          {{ $t('app_hotel_details_edit') }}
        </a>
        <a
          class="tw-ml-2 tw-text-center tw-cursor-pointer tw-transition-colors hover:tw-no-underline tw-duration-300"
          @click="openDeleteLanguage()"
          @keydown.enter="openDeleteLanguage()"
        >
          <BaseIcon icon-name="trash" :height="18" :width="18" :viewbox-height="24" :viewbox-width="24">
            <Trash />
          </BaseIcon>
        </a>
      </div>
    </div>
    <div v-if="activeAccordion === language" class="tw-flex tw-w-full tw-py-2 tw-px-9 tw-flex-col">
      <p class="tw-text-sm">
        {{ $t('field.propertyName') }}
        <span class="tw-font-bold">{{ name }}</span>
      </p>
      <p class="tw-text-sm">
        {{ $t('field.streetAddress') }}
        <span class="tw-font-bold">{{ address }}</span>
      </p>
      <p class="tw-text-sm">
        {{ $t('field.websiteLink') }}
        <span class="tw-font-bold">{{ web }}</span>
      </p>
    </div>
    <DeleteLanguageModal
      :is-deleting="removeLanguageLoading"
      :language="language"
      :languageCode="languageCode"
      :is-open="isDeleting"
      @closeDelete="closeDeleting"
      @deleteLanguage="language => deleteLanguage(language)"
    />
    <EditLanguageModal
      :is-open="isEditing"
      :language="language"
      :fullLanguage="getLanguage(language)"
      :name-label="$t('app_hotel_details_hotel_name_form')"
      :address-label="$t('app_hotel_details_hotel_address_form')"
      :web-label="$t('app_hotel_details_hotel_website_form')"
      :isUpdating="updateLanguageLoading"
      @closeEditing="closeEditing"
      @save="language => changeLanguageValues(language)"
    />
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import Trash from '@/components/icons/Trash.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import DeleteLanguageModal from '@/components/propertyDetails/propertyLanguages/DeleteLanguageModal.vue'
import EditLanguageModal from '@/components/propertyDetails/propertyLanguages/EditLanguageModal.vue'
import { useRemoveLanguage, useUpdateLanguage } from '@/components/propertyDetails/propertyLanguages/queries/index.js'
import { usePropertyLanguages } from '@/components/propertyDetails/propertyLanguages/usePropertyLanguages.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { setEditItem, resetValidation, editItem } = usePropertyLanguages()
const props = defineProps({
  languageCode: String,
  language: {
    type: String,
    default: 'en',
  },
  name: {
    type: [String],
    required: false,
    default: '',
  },
  address: {
    type: [String],
    required: false,
    default: '',
  },
  web: {
    type: [String],
    required: false,
    default: '',
  },
})

const activeAccordion = ref(null)
const isDeleting = ref(false)
const isEditing = ref(false)

const languageKey = computed(() => {
  if (props.language === 'sv') return 'se'
  if (props.language === 'ja') return 'jp'
  else return props.language
})

const { displayNotification } = useToastNotifications()

const { removeLanguageLoading, removeLanguage } = useRemoveLanguage()
const { updateLanguageLoading, updateLanguage } = useUpdateLanguage()

const openAccordion = language => {
  if (activeAccordion.value === language) {
    activeAccordion.value = null
  } else {
    activeAccordion.value = language
  }
}
const getLanguage = language => {
  return `lang_${language}`
}

const deleteLanguage = language => {
  removeLanguage(
    { accommodationId: selectedAccommodationId.value, language },
    {
      onSuccess: () => {
        displayNotification({
          message: 'app_hotel_details_success_message',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        isDeleting.value = false
      },
      onError: () => {
        isDeleting.value = false
      },
    }
  )
}

const toggleEditLanguage = () => {
  isEditing.value = !isEditing.value
  resetValidation()
  if (isEditing.value) {
    setEditItem({
      name: props.name === null ? '' : props.name,
      streetAddress: props.address === null ? '' : props.address,
      web: props.web === null ? '' : props.web,
    })
  }
}

const closeEditing = () => {
  isEditing.value = false
}
const openDeleteLanguage = () => {
  isDeleting.value = !isDeleting.value
}
const closeDeleting = () => {
  isDeleting.value = false
}
const changeLanguageValues = language => {
  let body = editItem.value
  body = {
    ...body,
    languageCode: language,
    accommodationId: selectedAccommodationId.value,
    modifiedAt: new Date(),
  }
  updateLanguage(
    { accommodationId: selectedAccommodationId.value, language, body },
    {
      onSuccess: () => {
        displayNotification({
          message: 'app_hotel_details_success_message',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        isEditing.value = false
        setEditItem(null)
      },
      onError: () => {
        isEditing.value = false
        setEditItem(null)
      },
    }
  )
}
</script>
<style scoped>
.toggle-icon {
  position: relative;
  width: 14px;
  height: 14px;
  background: transparent;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
}

.toggle-icon::after,
.toggle-icon::before {
  position: absolute;
  content: '';
  height: 12px;
  width: 2px;
  background: #1a0d07;
  left: 50%;
  top: 50%;
  transition: transform 0.4s ease;
  transform: translate(-50%, -50%);
}
.toggle-icon.active::before {
  transition: transform 0.2s ease;
}

.toggle-icon::before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.toggle-iconParent:hover .toggle-icon::before,
.toggle-icon:hover::before,
.toggle-icon:active::before {
  transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
}

.toggle-icon:hover::after,
.toggle-iconParent:hover .toggle-icon::after,
.toggle-icon:active::after,
.toggle-icon.active::after {
  transform: translate(-50%, -50%) rotate(-90deg) scale(1.2);
}

.toggle-icon.active::before {
  transform: translate(-50%, -50%) rotate(90deg) scale(1.2);
}

.toggle-icon.active::after,
.toggle-icon.active::before {
  width: 1px;
}
</style>
