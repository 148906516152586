import { store } from '@/store'

export default async function (_, __, next) {
  try {
    const sessionModule = store.state.session
    if (sessionModule.userRole !== 'admin') {
      return next({ name: 'home' })
    }

    return next()
  } catch {
    return next({ name: 'getstarted' })
  }
}
