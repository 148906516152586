<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-900">
    <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-900">
        <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
          <div>
            <table class="tw-min-w-full tw-border-none">
              <thead class="tw-hidden md:tw-table-header-group">
                <tr class="tw-border-b tw-border-t">
                  <th
                    v-for="column in columns"
                    :key="column.prop"
                    class="tw-px-5 tw-py-5 tw-border-none tw-bg-white tw-text-left tw-text-sm tw-leading-4 tw-font-bold tw-text-gray-700"
                    :class="`tw-w-[${column.width}]`"
                  >
                    <span>{{ $t(column.label) }}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="tw-bg-white">
                <tr
                  v-for="(user, index) in usersList"
                  :key="index"
                  class="tw-text-sm tw-border-top md:tw-border-none tw-flex md:tw-table-row tw-flex-col"
                >
                  <td class="tw-border-none">
                    <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-blue-200 tw-p-2">
                      <span>{{ $t('form.labels.accounts') }}{{ ': ' }}</span>
                      <span>{{ user.email }}</span>
                    </div>
                    <span class="md:tw-flex tw-hidden">{{ user.email }}</span>
                  </td>
                  <td class="tw-border-none">
                    <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-gray-100 tw-p-2">
                      <span>{{ 'User id' }}{{ ': ' }}</span>
                      <span>{{ user.id }}</span>
                    </div>
                    <span class="md:tw-flex tw-hidden">{{ user.id }}</span>
                  </td>
                  <td class="tw-border-none">
                    <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-gray-100 tw-p-2">
                      <span>{{ $t('form.labels.role') }}{{ ': ' }}</span>
                      <span>{{
                        user.role === 'normal' ? $t('form.labels.user_role.normal') : $t('form.labels.user_role.admin')
                      }}</span>
                    </div>
                    <span class="md:tw-flex tw-hidden">{{
                      user.role === 'normal' ? $t('form.labels.user_role.normal') : $t('form.labels.user_role.admin')
                    }}</span>
                  </td>
                  <td class="tw-border-none">
                    <div
                      class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-gray-100 tw-p-2 tw-lowercase"
                    >
                      <span>{{ $t('Status') }}{{ ': ' }}</span>
                      <span>{{ user.status.toLowerCase() }}</span>
                    </div>
                    <span
                      :class="user.status.toLowerCase() === 'pending' ? 'tw-bg-yellow-700' : 'tw-bg-green-500'"
                      class="md:tw-flex tw-hidden tw-w-auto tw-rounded-lg tw-p-1 tw-w-fit tw-lowercase"
                      >{{ user.status.toLowerCase() }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  users: Array,
  invitations: Array,
})

const usersList = computed(() => {
  let all = [...props.users, ...props.invitations]
  all = all.map(user => {
    return {
      email: user.email ?? user.invitedEmail,
      id: user.id ?? '-',
      role: user.role,
      status: user.userInvitationStatus ?? 'ACTIVE',
    }
  })
  all.sort((a, b) => (a.email > b.email ? 1 : b.email > a.email ? -1 : 0))
  return all
})
const columns = ref([
  {
    label: 'User email',
    prop: 'email',
    width: '30%',
  },
  {
    label: 'User id',
    prop: 'id',
    width: '30%',
  },
  {
    label: 'User role',
    prop: 'role',
    width: '20%',
  },
  {
    label: 'Status',
    prop: 'status',
    width: '20%',
  },
])
</script>
